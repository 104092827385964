import i18next from 'i18next';
import {ConfigurationProperties} from '../../ng-properties-configuration/configuration.properties';
import {errorProperties, ErrorStatusCode} from '../../ng-properties-error/error.properties';
import {ObjectUtil} from '../../ng-helpers-util/object.util';


export class AipError {

    // Les informations sur l'erreur
    type: string|number;
    code: string|number;
    title: string;
    subTitle: string;
    msg: string[];
    statusCode: number;
    logoutUser: boolean = false;    // Indique que l'on doit déloguer l'utilisateur si cette erreur survient
    url: string;                    // Url ayant provoqué l'erreur (pour les notifications au serveur)

    // Informations supplémentaires
    source: string;
    cause: string;

    /**
     *
     * @param _type
     * @param _code
     * @param _title
     * @param _subTitle
     * @param _msg
     * @param _statusCode
     */
    constructor(
        _type: number,
        _code: string|number,
        _title: string,
        _subTitle: string,
        _msg: string|string[],
        _statusCode: number) {

        this.type = _type;
        this.code = _code;
        this.title = _title;
        this.subTitle = _subTitle;
        this.msg = (_msg instanceof Array) ? _msg : Array(_msg);
        this.statusCode = _statusCode;
    }

    /**
     * Fonction qui converti un object en AipError.
     * Les champs non compatibles seront initialisés avec les valeurs par défaut.
     *
     * Le paramètre value peut être soit :
     *      - une string, dans ce cas cette string sera affecté au champs msg de l'erreur (utile pour les notifications toaster)
     *      - un array de strings, dans ce cas ce tableau sera affecté au champs msg de l'erreur (utile pour les notifications toaster)
     *      - un objet possédant certaines ou toutes les propriétés de l'objet AipError. Les propriétés présentes seront affectés à l'objet AipError.
     *
     * @param value
     * @returns
     */
    public static convertToAipError(value: any): AipError {
        let error: AipError = AipError.getDefaultAipError();

        if(ObjectUtil.isArray(value) && value.every(elem => ObjectUtil.isString(elem))) {
          error.msg = value;
        } else if(ObjectUtil.isString(value)) {
            error.msg = [value];
        } else if(value) {
            if (ObjectUtil.isString(value.type) || ObjectUtil.isInt(value.type)) {
                error.type = value.type;
            }
            if (ObjectUtil.isString(value.code) || ObjectUtil.isInt(value.code)) {
                error.code = value.code;
            }
            if (ObjectUtil.isString(value.title)) {
                error.title = value.title;
            }
            if (ObjectUtil.isString(value.subTitle)) {
                error.subTitle = value.subTitle;
            }
            if (
              (ObjectUtil.isArray(value.msg) && (value.msg.every(elem => ObjectUtil.isString(elem)))) ||
              ObjectUtil.isString(value.msg)
            ) {
                error.msg = (value.msg instanceof Array) ? value.msg : Array(value.msg);
            }
            if (ObjectUtil.isInt(value.status)) {
                error.statusCode = value.status;
            }
            // Le statusCode est prioritaire sur le status
            if (ObjectUtil.isInt(value.statusCode)) {
                error.statusCode = value.statusCode;
            }
            // La demande de déconnexion de l'utilisateur
            if (ObjectUtil.isBoolean(value.logoutUser)) {
                error.logoutUser = value.logoutUser;
            }
        }

        return error;
    }

    /**
     * Fonction qui retourne une erreur atel par défaut.
     *
     * @returns
     */
    public static getDefaultAipError(): AipError {
        return new AipError(
            ConfigurationProperties.cli_error_type_fatal,
            errorProperties.ERROR_UNKNOWN,
            i18next.t('error.title'),
            i18next.t('error.unknown.subTitle'),
            i18next.t('error.unknown.message'),
            ErrorStatusCode.INTERNAL_ERROR);
    }

}
