import {Degree} from './Degree';
import {Course} from './Course';
import {ArrayUtil} from '../../../ng-helpers-util/array.util';
import {Lesson} from "./Lesson";
import {LearningElementsUtil} from "../../utils/learningElements.util";
import {ObjectUtil} from "../../../ng-helpers-util/object.util";
import {CurrentUserService} from "../../../ng-manager-user/services/current-user.service";
import {ShoppingCartUtil} from "../../../ng-helpers-util/shoppingCart.util";

export class LearningContainerStatus {
  featured: boolean = false;
  active: boolean = false;
  published: boolean = false;
  expired: boolean = false;
  expiryDate: Date;
  publicationDate: Date;
  deleted: boolean = false;
  deletionDate: Date;
  creationDate: Date;
  modificationDate: Date;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
  }
}

export class LearningContainerDetails {
  language: string;
  languages: string[];  // Pour les cours et le degrees
  reference: string;
  emailQuestions: string;
  summary: string;
  authors: Array<string> = [];
  speakers: Array<string> = [];
  objectives: Array<string> = [];
  program: Array<string> = [];
  audience: Array<string> = [];
  duration: number;
  difficultyLevels: Array<number> = [];
  fields: Array<string> = [];
  tags: Array<string> = [];
  prerequisitesDegrees: Array<Degree | string> = [];
  prerequisitesCourses: Array<Course | string> = [];
  prerequisitesLessons: Array<Lesson | string> = [];
  zones: Array<string> = [];
  resources: Array<string> = [];
  comments: string;
  properties: any;

  // Pour les publications du projet aip-atp
  category: string;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
  }
}

export class LearningContainer {
  public _id: string;
  public code: string;
  public title: string;
  public version: string;
  public url: string;
  public picture: string;
  public price: any;            // Le prix
  public priceDiscounted: any;  // Le prix avec les promos
  public discounts: any;        // La liste des promos
  public type: string;
  public status: LearningContainerStatus;
  public details: LearningContainerDetails;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
    if (_data && _data.status) {
      this.status = new LearningContainerStatus(_data.status);
    }
    if (_data && _data.details) {
      this.details = new LearningContainerDetails(_data.details);
    }
  }

  /**
   * Fonction qui test si l'objet est valide
   */
  public isValid(): boolean {
    return (
      !this.status.deleted &&
      this.status.active &&
      this.status.published
      )
  }

  /**
   * Fonction qui retourne les devises applicables pour le total du panier.
   *
   * @returns
   */
  public getCurrencies(): Array<string> {
    if (this.price) {
      return Object.keys(this.price);
    } else {
      return [];
    }
  }

  /**
   * Fonction qui récupère le min et le max des difficultyLevels d'un learningContainer.
   */
  public getDifficultyLevel(): { min: number, max: number } {
    if (this.details) {
      let difficultyLevels: Array<number> = this.details.difficultyLevels;
      let min: number = Math.min(...difficultyLevels);    // Si le tableau est vide, retourne "Infinity"
      let max: number = Math.max(...difficultyLevels);    // Si le tableau est vide, retourne "Infinity"
      return {
        min: isFinite(min) ? min : undefined,
        max: isFinite(max) ? max : undefined
      }
    } else {
      return {
        min: undefined,
        max: undefined
      }
    }
  }

  /**
   * Fonction qui retourne la durée d'un learningContainer (en secondes)
   */
  public getDuration(): number {
    let duration: number = 0;
    if (this.details && this.details.duration) {
      duration = this.details.duration;
    }
    return duration;
  }

  /**
   * Fonction qui retourne la ou les langues d'un learningContainer
   */
  public getLanguage(): string {
    return this.details.language;
  }

  /**
   * Fonction qui retourne la ou les zones d'un learningContainer
   */
  public getZones(): Array<string> {
    let zones: Array<string> = [];
    if (this.details && this.details.zones) {
      zones = this.details.zones;
    }
    return ArrayUtil.arrayUnique(zones);
  }

  /**
   * Fonction qui retourne la ou les auteurs d'un learningContainer
   */
  public getAuthors(): Array<string> {
    let authors: Array<string> = [];
    if (this.details && this.details.authors) {
      authors = this.details.authors;
    }
    return ArrayUtil.arrayUnique(authors);
  }

  /**
   * Fonction qui affiche le difficultyLevel d'un learningContainer
   */
  public displayDifficultyLevel(shortDisplay?: boolean): string {
    let minMax: { min: number, max: number } = this.getDifficultyLevel();
    return LearningElementsUtil.displayDifficultyLevel(minMax, shortDisplay);
  }


  /**
   * Fonction qui retourne true si le learningContainer a un prix.
   */
  public hasPrice(): boolean {
    return (
      ObjectUtil.isArray(this.price) &&
      (this.price.length > 0) &&
      this.price.every(pr => ObjectUtil.isNumeric(pr.price))
    );
    /*
        return (
          ObjectUtil.isArray(this.priceDiscounted) &&
          (this.priceDiscounted.length > 0)
        );
    */
  }

  /**
   * Fonction qui retourne le prix total au format string du cours dans la devise spécifiée pour l'affichage.
   */
  displayPrice(currentUserService: CurrentUserService): string {
    if (ObjectUtil.isArray(this.price)) {
      try {
        return currentUserService.displayPriceInUserCurrency(
          currentUserService.getPriceInUserCurrency(this.price));
      } catch (err) {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  /**
   * Fonction qui retourne le prix total soldé au format string du cours dans la devise spécifiée pour l'affichage.
   * On fait essentielleemnt des checks sur le prix initia et final et on ne rend le prix discounté que si il ya une difference sinon on rend undefined
   */
  displayPriceDiscounted(currentUserService: CurrentUserService): string {
    if (!ObjectUtil.isArray(this.discounts)) {
      return undefined
    }
    let discounts = (this.discounts|| []).filter(d=>d && d.includedInFinalVAT);
    // Maintenanat on utilise une fonction de type bibliotheque afin a termer de deplacer cette fonctions dans les utiles et la rendre utilisable pour le cart et les vignettes
    return ShoppingCartUtil.computePriceDiscounted(currentUserService, discounts, this.priceDiscounted)
  }

  /**
   * Fonction qui retourne la discount au format string de l'objet.
   * Nouveau code de Ryad: maintenant les discounts sont plus touffus, il faut selectionner les bons types
   * Par exemple dans les vignettes il ne faut prendre que les elemnts inclus dans le VAT
   * (en principe il n'y en a pas d'element non inlcus mais on prend une precaution qd meme)
   * PS : les elements non inclus dans le VAT ne sont interessants que pour le cart et les factures
   * De plus il peut y avoir des disocunts en forfait (Ex 5euros) ou en pourcentage (Ex 5%)
   * On n'affiche un % si tous les disounts sont en % sinon on affiche une chaine plus complexe
   * L'operation de prix magique (ie le prix sans discount a partir duquel on applique les discount pour trouver le prix avec discount)
   * est maintenat aussi inclus a la tete du tableau de discounts afin de mieux tracker les operations effectue sur les prix
   * (l'objet repreentant l'operation magique contient des information supplementaire sur comment a ete calcule le prix maagique)
   * Il faut evidemement l'exclure car il ne s'agit pas du prix discounté mais du prx original
   * A noter qu'ici on peut etre plus restricif pour l'affichage que pour displayPriceDiscounted
   * car displayDiscount n'affiche pas une information tres importante
   * c'est juste une aide visuelle pour guider l'utilisateur alors qu'il faut vraiment etre tres rigoureux avec displayPriceDiscounted
   * Par exemple si il y a un prix qui passe de 0.50 euro a 10 euros ca fait une grosse augmentation bizarre
   * donc inutile de l'afficher : par contre il faut absolument afficher le priceDiscounted final = 10 euros
   *
   */
  displayDiscount(currentUserService: CurrentUserService): string {
    if (!ObjectUtil.isArray(this.discounts)) {
      return undefined
    }
    let discounts = (this.discounts|| []).filter(d=>d && d.includedInFinalVAT);
    // Maintenanat on utilise une fonction de type bibliotheque afin a termer de deplacer cette fonctions dans les utiles et la rendre utilisable pour le cart et les vignettes
    return ShoppingCartUtil.computeDiscount(currentUserService,discounts);
  }

  /**
   * Fonction qui retourne la discount au format string de l'objet.
   * Nouveau code de Ryad: maintenant les discounts sont plus touffus, il faut selectionner les bons types
   * Par exemple dans les vignettes il ne faut prendre que les elemnts inclus dans le VAT
   * (en principe il n'y en a pas d'element non inlcus mais on prend une precaution qd meme)
   * PS : les elements non inclus dans le VAT ne sont interessants que pour le cart et les factures
   * De plus il peut y avoir des disocunts en forfait (Ex 5euros) ou en pourcentage (Ex 5%)
   * On n'affiche un % si tous les disounts sont en % sinon on affiche une chaine plus complexe
   * L'operation de prix magique (ie le prix sans discount a partir duquel on applique les discount pour trouver le prix avec discount)
   * est maintenat aussi inclus a la tete du tableau de discounts afin de mieux tracker les operations effectue sur les prix
   * (l'objet repreentant l'operation magique contient des information supplementaire sur comment a ete calcule le prix maagique)
   * Il faut evidemement l'exclure car il ne s'agit pas du prix discounté mais du prx original
   */
  /*
  displayDiscount(currentUserService: CurrentUserService): string {
    try {

      if (!ObjectUtil.isArray(this.discounts)) {
        return undefined
      }
      let discounts = (this.discounts|| []).filter(d=>d.includedInFinalVAT);
      if (discounts.length > 1) {
        // On ne peut faire un affichage simplifié que si tous les discounts sont de meme nature
        let natures = discounts.map(d=>d.nature);
        natures = [...new Set(natures)]; //élimination des élements dupliqués
        // Tous les discounts sont de meme nature et faclement multipliable on peut donc simplifié
        if (natures.length==1 && (natures[0] == 'bonus*' || natures[0] == 'malus*')) {
            const totalPriceMultiplicatorPercent = Math.min(
              Math.max(
                100 * discounts.reduce((o, d) => o * (1 - d.discount/100), 1),
                0),
              100); // mulitplication des disocunts

            const totalDiscountPercent = MathUtil.round(100 - totalPriceMultiplicatorPercent, 1);
            if (!totalDiscountPercent) {
              // Le prix n'a pas changé : ne rien afficher
              return undefined;
            }
            if (natures[0] == 'malus*') {
              return '+' + totalDiscountPercent + '%';
            } else { // sinon c'est un malus
              return '-' + totalDiscountPercent + '%';
            }
          } else {
            //Dans les autres cas ou a des additions forfetaires on calcule le pourcenatge comme prix de d'arrivéé du pipe d'operation sur prix de depart
            let initialPrice = discounts[0] && discounts[0].price;
            let finalPrice = discounts[discounts.length-1] && discounts[discounts.length-1].price;
            if (!finalPrice || !initialPrice) {
              // Cas impossible
              return undefined
            }
            let priceInitial = currentUserService.getPriceInUserCurrency(initialPrice);
            if (!priceInitial) { // Si le denominateur est 0 : mathemathiquement non défini donc ne rien afficher
              return undefined
            }

            let priceFinal = currentUserService.getPriceInUserCurrency(finalPrice);
            let totalDiscountPercent = MathUtil.round(100*(priceFinal-priceInitial)/priceInitial , 1);
            if (!totalDiscountPercent) {
              // Le prix n'a pas changé : ne rien afficher
              return undefined;
            }
            let totalIsMalus = priceFinal>priceInitial;
            if (totalDiscountPercent>200 && totalIsMalus) {
              // Ne pas faire paniquer l'utilisiateur pour les gros malus : il s'agit probablement d'une augmentation sur un petit prix de depart
              return undefined
            }
            if  (totalIsMalus) {
              return '+' + totalDiscountPercent + '%';
            } else {
              return '-' + totalDiscountPercent + '%';
            }
          }
        } else {
          if (discounts.length == 1 && discounts[0] && discounts[0].type === "magic") {
            // Il n'y a que le discount magic qui est un fake discount trackant l'operation de prix magique et representant le prix en input du pipe d'operation
            // => correspond au cas ou il n'y a pas de veritable discount => le prix d'entree represente le prix en sortie
            return undefined;
          } else {
            // Ce cas est theoriquemnt impossible : on doit tjs avoir au minima le magic discount traquant/decrivant l'operation magique
            return undefined;
          }
      }
    } catch (err) {
      return undefined;
    }
  }
  */

  /**
   * Fonction qui retourne le prix total soldé au format string du cours dans la devise spécifiée pour l'affichage.
   * Ancienne fonction n'implementant pas les details de discount
   */
  /*
  displayPriceDiscounted(currentUserService: CurrentUserService): string {
    try {
      if (ObjectUtil.isArray(this.discounts) && (this.discounts.length > 0)) {
        // Il y a une discount
        return currentUserService.displayPriceInUserCurrency(
          currentUserService.getPriceInUserCurrency(this.priceDiscounted));
      } else {
        // Il n'y a pas de discount
        return undefined;
      }
    } catch (err) {
      return undefined;
    }
  }
  */

  /**
   * Fonction qui retourne la discount au format string de l'objet.
   * Ancienne fonction n'implementant pas les details de discount
   */
  /*
  displayDiscount(): string {
    try {
      if (ObjectUtil.isArray(this.discounts) && (this.discounts.length > 0)) {
        // Il y a une discount
        const totalPriceMultiplicatorPercent = Math.min(
          Math.max(
            100 * (this.discounts || []).reduce((o, d) => o * (1 - d.discount), 1),
            0),
          100); // mulitplication des disocunts
        const totalDiscountPercent = MathUtil.round(100 - totalPriceMultiplicatorPercent, 1);
        return totalDiscountPercent ? totalDiscountPercent + '%' : undefined;
      } else {
        // Il n'y a pas de discount
        return undefined;
      }
    } catch (err) {
      return undefined;
    }
  }
  */

}

