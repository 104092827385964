import UiJsonConfig from '../../../config/current/aip-ui.json';

export class DatabaseUtil {

  /**
   * Fonction qui retourne true si le bouton ADOC est masqué dans le menu et false sinon.
   */
  public static isMenuButtonAdocHidden(): boolean {
    return ((UiJsonConfig.MENU_BUTTONS_HIDDEN || []).includes('MENU_BUTTON_ADOC'));
  }

}
