import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {appConfigurationProperties} from '../config/appConfigurationProperties';
import {Observable, Subject} from 'rxjs';
import {StringUtil} from '../modules/ng-helpers-util/string.util';
import {AipError, PaginateOptions} from '../modules/ng-models-ui';
import {ConfigurationProperties} from '../modules/ng-properties-configuration/configuration.properties';
import {appErrorProperties} from '../config/appErrorProperties';
import i18next from 'i18next';
import * as removeAccents from 'remove-accents';
import {ErrorStatusCode} from '../modules/ng-properties-error/error.properties';
import {AuthenticationService} from '../modules/ng-manager-authentication/authentication.service';
import {ISearchItem} from '../modules/ng-models-ui/interfaces/searchItem.interface';

@Injectable({
  providedIn: 'root',
})
export class SearchService {

  // Observable pour la détection de l'affichage des modals (prend en paramètre le nom de la fenêtre modal a lancer).
  private updateSearchKeySource = new Subject<string>();
  updateSearchKey$ = this.updateSearchKeySource.asObservable();

  private _searchKey = '';
  public set searchKey(value: string) {
    this._searchKey = value;
  }
  public get searchKey(): string {
    return this._searchKey;
  }

  /**
   *
   */
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) {
  }

  /**
   *
   */
  updateSearchKey(searchKey: string) {
    this.searchKey = searchKey;
    return this.updateSearchKeySource.next(searchKey);
  }

  /**
   *
   */
  search(paginateOptions: PaginateOptions): Observable<{ list: Array<ISearchItem>, count: number }> {
    const keywords = removeAccents.remove(this._searchKey); // Suppression des accents avant l'envoie au serveur
    if (!StringUtil.isUndefinedOrEmpty(this._searchKey)) {
      return this.http.get<{ list: Array<ISearchItem>, count: number }>(
        `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${appConfigurationProperties.URL_SERVER_SEARCH}/${paginateOptions.page}/${paginateOptions.limit}/${keywords}`,
        {
          headers: this.authenticationService.generateHeadersForUnprotectedRoutes()
        });
    } else {
      throw new AipError(
        ConfigurationProperties.cli_error_type_notification,
        appErrorProperties.ERROR_PARAMETERS_INVALID,
        i18next.t('error.title'),
        i18next.t('error.parametersInvalid.subTitle'),
        i18next.t('error.parametersInvalid.message'),
        ErrorStatusCode.TEAPOT);
    }
  }

}
