import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {registerLocaleData} from '@angular/common';
import i18n from 'i18next';
import i18next from 'i18next';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { getThemeLang, setThemeLang } from 'src/app/utils/util';
import {applicationProperties} from '../modules/ng-properties-application/application.properties';
import {StringUtil} from '../modules/ng-helpers-util/string.util';
import {UiUtil} from '../modules/ng-helpers-util/ui.util';

/*
 * // TODO : Connecter ce service avec le UserManager pour détercter automatiquement la langue de l'utilisateur
 */

@Injectable({
  providedIn: 'root',
})
export class LangService {
  private REGISTERED_ANGULAR_LOCALES: Array<any> = [localeFr, localeEn];
  private DEFAULT_ANGULAR_LOCALE: any = localeFr;

  private defaultLanguage: string;
  renderer: Renderer2;
  isSingleLang = false;

  private _fallbackLng: string; // La langue fallback initialisée lors de la construction du singleton
  private _serverLng: string;   // La langue définit par le serveur
  private _language: string;

  // Le getter et le setter de _language
  set language(lang: string) {
    this._language = lang;
  }
  get language(): string {
    return this._language;
  }

  // Le getter et le setter de _fallbackLng
  get fallbackLng(): string {
    return this._fallbackLng;
  }
  set fallbackLng(lang: string) {
    this._fallbackLng = lang;
  }

  // Le getter et le setter de _serverLng
  get serverLng(): string {
    return this._serverLng;
  }
  set serverLng(lang: string) {
    this._serverLng = lang || applicationProperties.LANGUAGE_DEFAULT;
  }

  /**
   *
   */
  constructor(
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this._fallbackLng = applicationProperties.LANGUAGE_DEFAULT;
    this.language = this.getLanguage();
    setThemeLang(this._language);
    this.init(this._language, this.getDefaultLanguage());
  }

  init(language, defaultLanguage): void {
    this.language = language;
    this.defaultLanguage = defaultLanguage;
  }

  checkForDirectionChange(): void {
    this.renderer.removeClass(document.body, 'ltr');
    this.renderer.removeClass(document.body, 'rtl');
    this.renderer.addClass(document.body, this.direction);
    this.renderer.setAttribute(
      document.documentElement,
      'direction',
      this.direction
    );
  }

  get languageShorthand(): string {
    return UiUtil.findLanguage(this.language, this.defaultLanguage).shorthand;
  }

  get direction(): string {
    return UiUtil.findLanguage(this.language, this.defaultLanguage).direction;
  }

  get languageLabel(): string {
    return UiUtil.findLanguage(this.language, this.defaultLanguage).label;
  }

  /**
   * Fonction qui retourne la langue par défaut pour l'affichage de la plate-forme.
   */
  public getDefaultLanguage(): string {
    // 1. On définit la langue par défaut
    let lang: string = this._fallbackLng;
    // 2. Si le serveur a définit une langue par défaut, on l'utilise
    // let serverLang: string = ConfigurationUtil.cli_language_default;
    if (!StringUtil.isUndefinedOrEmpty(this._serverLng)) {
      lang = this._serverLng;
    }
    // 3. Si i18next a une langue, on l'utilise
    const i18NextLang: string = i18n.language;
    if (!StringUtil.isUndefinedOrEmpty(i18NextLang)) {
      lang = i18NextLang;
    }
    // 4. Si le navigateur a définit une langue, on l'utilise
    const browserLang = UiUtil.getBrowserLanguage();
    if (!StringUtil.isUndefinedOrEmpty(browserLang)) {
      lang = browserLang;
    }
    return lang;
  }

  /**
   * Fonction qui retourne la langue pour l'affichage de la plate-forme.
   *
   * @param preferredLanguage - langue préférée
   */
  public getLanguage(preferredLanguage?: string): string {
    // 1. On définit la langue par défaut
    const langDef: string = this.getDefaultLanguage();
    let lang = langDef;
    // 2. S'il y a une langue dans le localstorage on l'utilise
    const themeLang = getThemeLang();
    if (themeLang) {
      lang = themeLang;
    }
    // 3. Si on a une langue préférée, on l'utilise
    if (!StringUtil.isUndefinedOrEmpty(preferredLanguage)) {
      lang = preferredLanguage;
    }
    // 4. On regarde si la langue est suportée
    const supportedLanguage = UiUtil.findLanguage(lang);
    // 5. On retourne le résultat
    return (supportedLanguage) ? supportedLanguage.code : langDef;
  }

  /**
   * Fonction qui change la langue de l'interface.
   * Retourne la nouvelle langue.
   *
   * @param lang - langue a appliquer
   */
  public changeLanguage(lang?: string): string {
    // Récupération de la langue
    let language = lang || this.getLanguage();
    // On regarde si la langue est supportée
    if (!UiUtil.findLanguage(language)) {
      language = this.getDefaultLanguage();
    }
    // On regarde si la langue a changé
    if (!StringUtil.isUndefinedOrEmpty(this._language) && (this._language !== language)) {
      // On regarde si la direction a changé
      /*
      const directionChanged =
        (this.langService.direction !== appConfigurationProperties.SUPPORTED_LANGUAGES.find((item) => item.code === lang).direction);
      */
      window.location.reload();
    }
    // On change la langue du site en fonction de l'utilisateur
    i18next.changeLanguage(language,
      (err, t) => {
        // resources have been loaded
      });
    // Enregistrement de la langue dans angular
    this.registerLocale(language);
    // Enregistrement de la langue dans le localstorage
    setThemeLang(language);
    // Enregistrement de la langue dans le service
    this.language = language;
    // On change le sens d'écriture si necéssaire
    this.checkForDirectionChange();
    // La valeur de retour
    return language;
  }

  /**
   * Fonction qui enregistre les langues dans angular pour la gestion des pipes notamment.
   *
   * @param lang - langue préférée
   */
  public registerLocale(lang: string): void {
    /*
    if(lang.startsWith("fr")) {
        registerLocaleData(localeFr);
    } else if(lang.startsWith("en")) {
        registerLocaleData(localeEn);
    } else {
        // On n'a pas trouvé la langue.
    }
    */

    let localeFound = false;
    let locale: any = this.DEFAULT_ANGULAR_LOCALE;

    // On cherche une correspondance exacte
    for (const loc of this.REGISTERED_ANGULAR_LOCALES) {
      if (lang === loc[0]) {    // Le premier élément des locals angular est le nom de la locale
        localeFound = true;
        locale = loc;
        break;
      }
    }
    // Une correspondance exacte n'a pas été trouvée => On cherche une correspondance partielle
    if (!localeFound) {
      for (const loc of this.REGISTERED_ANGULAR_LOCALES) {
        if (lang.startsWith(loc[0])) {    // Le premier élément des locals angular est le nom de la locale
          locale = loc;
          break;
        }
      }
    }
    // Enregistrement de la locale
    registerLocaleData(locale);
  }

}

/**
 *
 */
export class Language {
  code: string;
  alias: string[] = []; // Autres codes identifant cette langue
  direction: string;
  label: string;
  shorthand: string;
}
