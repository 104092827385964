import {UserDegree} from './UserDegree';
import {UserCourse} from './UserCourse';
import {UserTeachingContainer} from './UserTeachingContainer';
import {IElearningUserProduct} from './IElearningUserProduct';
import {UserTeachingDb} from '../../../modules/ng-models-atel3/models/db/userAtel/UserTeachingDb';
import {Source} from '../../../modules/ng-models-ats/models/db/Source';
import {CurrentUserService} from '../../../modules/ng-manager-user/services/current-user.service';
import {appConfigurationProperties} from '../../../config/appConfigurationProperties';
import {ObjectUtil} from '../../../modules/ng-helpers-util/object.util';
import {UserDependenciesService} from '../../../modules/ng-module-login/services/user-dependencies.service';

export class UserSource extends UserTeachingContainer implements IElearningUserProduct {

  public userTeachings: UserTeachingDb[] = [];
  public userCourses: UserCourse[] = [];
  public userDegrees: UserDegree[] = [];

  /**
   *
   */
  constructor(
    public source: Source,
    currentUserService: CurrentUserService,
    userDependenciesService: UserDependenciesService) {

    super();
    this._id = `###${this.source._id}###`; // Création d'un _id pour l'unicité dans les listes
    this.initUserTeachings(currentUserService, userDependenciesService);
  }

  // Le type d'objet
  public getType(): string {
    return appConfigurationProperties.ENTITY_TYPE_SOURCE;
  }

  // L'objet associé
  public getEmbeddedObject(): Source {
    return this.source;
  }

  // Fonction qui test si l'objet associé existe
  public hasEmbeddedObject(): boolean {
    return this.source !== undefined;
  }

  /**
   * Fonction qui retourne le nombre de degrees de la source de l'utilisateur.
   */
  public get nbDegrees(): number {
    return this.userDegrees.length;
  }

  // Fonction qui test si l'objet est valide
  public isEmbeddedObjectValid(): boolean {
    return this.source.isValid();
  }

  // Fonction qui test si l'objet possède cette source
  public hasSource(sourceId: string): boolean {
    return false;
  }

  /**
   * Fonction qui retourne le nombre de cours de la source de l'utilisateur.
   */
  public get nbCourses(): number {
    return this.userCourses.length;
  }

  /**
   * Fonction qui initialise les userTeachings du UserSource.
   */
  private initUserTeachings(
    currentUserService: CurrentUserService,
    userDependenciesService: UserDependenciesService): void {

    // Seuls les lc ayant le bon lng sont inclus dans les statistiques
    const lng = currentUserService.getUserPreferredLanguage();
    const lcsWithLng = userDependenciesService.lessonsDependencies.filter(lc => lc.details.language === lng);

    if (
      ObjectUtil.hasProperty(currentUserService, 'user', 'atel', 'teachings') &&
      ObjectUtil.isArray(currentUserService.user.atel.teachings)) {
      /*
      currentUserService.user.atel.teachings.forEach(userTeach => {
        if (
          userTeach.hasSource(this.source._id) &&
          !this.hasUserTeaching(userTeach.teaching_ref) &&
          userTeach.isUserTeachingValid(false)) {
          // Le userTeaching n'était pas présent
          this.userTeachings.push(userTeach);
        }
      });
      */
      // Seuls les lc aynat le bon lng sont inclus dans les statistiques
      this.userTeachings = currentUserService.user.atel.teachings.filter(userTeach =>
        userTeach.hasSource(this.source._id) &&
        !this.hasUserTeaching(userTeach.teaching_ref) &&
        userTeach.isUserTeachingValid(false) &&
        lcsWithLng.find(lc => userTeach.teaching_ref === lc._id));

      // Génération des informations de progression
      this.generateProperties();
    }
  }

  // L'objet est partiellement possédé par l'utilisateur
  public isPartiallyOwnByUser(
    userTeachingsGetter?: CurrentUserService | UserTeachingDb[],
    userDependenciesService?: UserDependenciesService,
    considerUserTeachingActiveFlag?: boolean): boolean {
    return true;
  }

  // L'objet est entièrement possédé par l'utilisateur
  public isFullyOwnByUser(
    userTeachingsGetter?: CurrentUserService | UserTeachingDb[],
    userDependenciesService?: UserDependenciesService,
    considerUserTeachingActiveFlag?: boolean): boolean {
    return true;
  }

  /**
   *
   */
  public hasInfoProgression(): boolean {
    return false;
  }

  // L'objet a été acheté par l'utilisateur
  isPurchased(): boolean {
    return false;
  }

  // L'objet peut être acheté par cet utilisateur
  isPurchasable(): boolean {
    return false;
  }

}
