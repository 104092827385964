/**
 * Classe qui permet de charger des scripts locaux ou distants dynamiquement.
 *
 * Usage:
 *       let scriptUtil = new ScriptUtil([
 *           {name: 'filepicker', src: 'https://api.filestackapi.com/filestack.js', id: 'picker'},
 *           {name: 'rangeSlider', src: '../../../assets/js/ion.rangeSlider.min.js'}
 *       ]);
 *       scriptUtil.load('filepicker', 'rangeSlider').then(data => {
 *           console.log('script loaded ', data);
 *       }).catch(error => console.log(error));
 */
import {ObjectUtil} from "./object.util";
import {StringUtil} from "./string.util";

declare let document: any;

interface Script {
    name: string;     // nom unique du script pour l'identifier
    id?: string;      // id du script dans la dom
    src?: string;     // path du script
    text?: string;    // contenu de la balise script
    attributs?: any;  // les attributs de la balise script (peut overwiter les autres propriétés : id, src, text, ...)
}

export class ScriptUtil {

    private scripts: any = {};

    constructor(scripts: Script[]) {
        scripts.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                id: script.id,
                src: script.src,
                text: script.text,
                attributs: script.attributs
            };
        });
    }

    load(...scripts: string[]): Promise<any> {
        let promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    loadScript(name: string): Promise<any> {
        return new Promise((resolve, reject) => {
            // Resolve if already loaded
            if (this.scripts[name].loaded) {  // Already Loaded
                resolve({script: name, loaded: true, status: 'Already Loaded'});
            } else {  // Load script
                // Création de la balise
                let script = document.createElement('script');
                // Génération des attributs de la balise
                script.type = 'text/javascript'; // Type par défaut. Peut être redéfinit dans les "attributs"
                if (ObjectUtil.isObject(
                  this.scripts[name].attributs, true, true) &&
                  !ObjectUtil.isEmptyObject(this.scripts[name].attributs)) {
                  ObjectUtil.forEachProperty(
                    this.scripts[name].attributs,
                    (obj, property) => {
                      script.setAttribute(property, obj[property]);
                    })
                }
                // La source
                if (!StringUtil.isUndefinedOrEmpty(this.scripts[name].src)) {
                  script.src = this.scripts[name].src;
                }
                // Le contenu de la balise
                if (!StringUtil.isUndefinedOrEmpty(this.scripts[name].text)) {
                  script.text = this.scripts[name].text;
                }
                // L'id
                if(this.scripts[name].id) {
                    script.id = this.scripts[name].id;
                }
                // Chargement
                if (script.readyState) {  //IE
                    script.onreadystatechange = () => {
                        if (script.readyState === "loaded" || script.readyState === "complete") {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({script: name, loaded: true, status: 'Loaded'});
                        }
                    };
                } else {  // Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({script: name, loaded: true, status: 'Loaded'});
                    };
                }
                script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }

}
