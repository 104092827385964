import {Theme} from './Theme';
import {atelProperties} from '../../../ng-properties-application/atel.properties';
import {applicationProperties} from '../../../ng-properties-application/application.properties';
import {ArrayUtil} from '../../../ng-helpers-util/array.util';
import {LearningContainer} from "./LearningContainer";
import {Lesson} from "./Lesson";
import {LearningElementsUtil} from "../../utils/learningElements.util";
import {ObjectUtil} from "../../../ng-helpers-util/object.util";
import {IElearningProduct} from "../../../../data/catalogueProduct/elearning/IElearningProduct";

/**
 *
 */
export class Course extends LearningContainer implements IElearningProduct {

  public themes: Theme[] = [];
  public lessons: CourseLesson[] = [];

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    super(_data);
    $.extend(this, _data);
    if (_data && _data.lessons && ObjectUtil.isArray(_data.lessons)) {
      // this.lessons = _data.lessons;
      this.lessons = _data.lessons
        .map(elem => new CourseLesson(elem));
        // .filter(elem => elem !== undefined);
    } else {
      this.lessons = [];
    }
  }

  /**
   * Fonction qui met à jour les urls des images du cours avec les paths du système.
   */
  public updatePictureUrls() {
    this.picture = this.formatCourseForDisplay().picture;
  }

  /**
   * Fonction qui retourne un cours formaté pour l'affichage :
   *    - mise à jour des urls des images
   */
  public formatCourseForDisplay(): Course {
    let newCourse: Course = JSON.parse(JSON.stringify(this));
    if (!newCourse.picture) {
      newCourse.picture = atelProperties.URL_DATA_COURSE() + applicationProperties.FOLDER_PICTURES + atelProperties.DEFAULT_FILE_PICTURE_COURSE();
    } else {
      newCourse.picture = atelProperties.URL_DATA_COURSE(newCourse._id) + applicationProperties.FOLDER_PICTURES + newCourse.picture;
    }
    return newCourse;
  }

  /**
   * Fonction qui récupère le min et le max des difficultyLevels des sections du cours.
   *
   * @override
   */
  public getCourseDifficultyLevel(): { min: number, max: number } {
    let difficultyLevels: Array<number> = [];
    if (this.details && this.details.difficultyLevels &&
      (this.details.difficultyLevels.length > 0)) {
      difficultyLevels = this.details.difficultyLevels;
    } else {
      if (this.isPopulated()) {
        difficultyLevels = this.lessons.reduce((accumulator, currentValue) => {
          if (
            currentValue.active &&
            ObjectUtil.isObject(currentValue._ref) &&
            (currentValue._ref as Lesson).isValid() &&
            (currentValue._ref as Lesson).details &&
            (currentValue._ref as Lesson).details.difficultyLevels) {
            accumulator = accumulator.concat((currentValue._ref as Lesson).details.difficultyLevels)
          }
          return accumulator;
        }, []);
      } else {
        return undefined;
      }
    }
    let min: number = Math.min(...difficultyLevels);    // Si le tableau est vide, retourne "Infinity"
    let max: number = Math.max(...difficultyLevels);    // Si le tableau est vide, retourne "Infinity"
    return {
      min: isFinite(min) ? min : undefined,
      max: isFinite(max) ? max : undefined
    }
  }

  /**
   * Fonction qui affiche le difficultyLevel d'un cours
   */
  public displayCourseDifficultyLevel(shortDisplay?: boolean): string {
    let minMax: { min: number, max: number } = this.getCourseDifficultyLevel();
    return LearningElementsUtil.displayDifficultyLevel(minMax, shortDisplay);
  }

  /**
   * Fonction qui retourne le nombre de lessons
   */
  public getNbTeachings(): number {
    if (this.isPopulated()) {
      return this.lessons.filter(lesson => (
        (ObjectUtil.isObject(lesson._ref))
          ? lesson.active && (lesson._ref as Lesson).isValid()
          : lesson.active
      )).length;
    }
    return 0;
  }

  /**
   * Fonction qui retourne la durée du cours (en secondes)
   *
   * @override
   */
  public getDuration(): number {
    let duration: number = 0;
    if (this.isPopulated()) {
      this.lessons.forEach(lesson => {
        if (
          lesson.active &&
          ObjectUtil.isObject(lesson._ref) &&
          (lesson._ref as Lesson).isValid() &&
          (lesson._ref as Lesson).details &&
          (lesson._ref as Lesson).details.duration) {
          duration += (lesson._ref as Lesson).details.duration;
        }
      });
    }
    return duration;
  }

  /**
   * Fonction qui retourne la ou les langues du cours
   *
   * @override
   */
  public getLanguages(): Array<string> {
    let languages: Array<string> = [];
    if (this.isPopulated()) {
      this.lessons.forEach(lesson => {
        if (
          lesson.active &&
          ObjectUtil.isObject(lesson._ref) &&
          (lesson._ref as Lesson).isValid() &&
          (lesson._ref as Lesson).details &&
          (lesson._ref as Lesson).details.language) {
          languages.push((lesson._ref as Lesson).details.language);
        }
      });
    }
    return ArrayUtil.arrayUnique(languages);
  }

  /**
   * Fonction qui retourne la ou les zones du cours
   *
   * @override
   */
  public getZones(): Array<string> {
    let zones: Array<string> = [];
    if (this.isPopulated()) {
      this.lessons.forEach(lesson => {
        if (
          lesson.active &&
          ObjectUtil.isObject(lesson._ref) &&
          (lesson._ref as Lesson).isValid() &&
          (lesson._ref as Lesson).details &&
          (lesson._ref as Lesson).details.zones) {
          zones = zones.concat((lesson._ref as Lesson).details.zones);
        }
      });
    }
    return ArrayUtil.arrayUnique(zones);
  }

  /**
   * Fonction qui retourne la ou les auteurs du cours
   *
   * @override
   */
  public getAuthors(): Array<string> {
    let authors: Array<string> = [];
    if (this.isPopulated()) {
      this.lessons.forEach(lesson => {
        if (
          lesson.active &&
          ObjectUtil.isObject(lesson._ref) &&
          (lesson._ref as Lesson).isValid() &&
          (lesson._ref as Lesson).details &&
          (lesson._ref as Lesson).details.authors) {
          authors = authors.concat((lesson._ref as Lesson).details.authors);
        }
      });
    }
    return ArrayUtil.arrayUnique(authors);
  }

  /**
   * Retourne true si le cours est peuplé avec des lesson.
   */
  isPopulated(): boolean {
    return this.lessons
      .reduce((accumulator, currentValue) => { // Suppression des cours non actifs
        return (currentValue.active) ? accumulator.concat([currentValue._ref]) : accumulator;
      }, [])
      .every(lessonRef => ObjectUtil.isFunction(lessonRef.isPopulated) && lessonRef.isPopulated());
  }

  /**
   * Peuple le cours les lessons passées en paramètre.
   */
  populate(lessons: Lesson[]): void {
    if (ObjectUtil.isArray(lessons)) {
      this.lessons
        .forEach(lessonCourse => {
          if (lessonCourse.active && ObjectUtil.isString(lessonCourse._ref)) { // Non prise en compte des cours non actifs
            const lesson = lessons.find(elem => lessonCourse._ref === elem._id);
            if (lesson) {
              lessonCourse._ref = lesson;
            }
          }
        }, []);
    }
  }

}

/**
 *
 */
export class CourseLesson {
  public _id: string;
  public _ref: string | Lesson;
  public active: boolean;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
    if (_data && ObjectUtil.isObject(_data._ref)) {
      this._ref = new Lesson(_data._ref)
    }
  }

  isValid(): boolean {
    return (
      this.active &&
      ObjectUtil.isObject(this._ref) && new Lesson(this._ref).isValid()
    );
  }

}

