/**
 * Classe contenant les properiétés de configuartion à partir du fichier du serveur.
 * Cette classe est inialisée par le configuration.service au démarrage de l'application.
 *      Proriétés :
 *      - cli_url_root : l'url root de la plate-forme.
 *      - cli_language_default : la langue par defaut de la plate-forme.
 *      - cli_error_type_fatal : le type d'erreur fatal.
 *      - cli_error_type_notification : le type d'erreur notification.
 *      - cli_createur_type_from_admin_ui : code pour les utilisateurs crées à partir de l'interface graphique administrateur
 *      - cli_user_username_min_length : la taille minimale de l'identifiant
 *      - cli_source_name_min_length : la taille minimale du nom de la source
 *      - cli_user_password_min_length : la taille minimale du mot de passe
 */
import {ErrorType} from '../ng-properties-error/error.properties';
import {UrlUtil} from '../ng-helpers-util/url.util';
import {ObjectUtil} from "../ng-helpers-util/object.util";
import {LangService} from "../../shared/lang.service";

// @dynamic
export class ConfigurationProperties {

    /* Variables récupérés du serveur */
    // L'url root de la plate-forme
    public static cli_url_root: string = "";

    // La langue par défaut
    public static cli_language_default: string;

    // Les types d'erreur
    public static cli_error_type_fatal: number;
    public static cli_error_type_notification: number;

    // Le type de créateur
    public static cli_creator_type_from_admin_ui: number;

    // Les informations pour les formulaires
    public static cli_user_username_min_length: number;
    public static cli_source_name_min_length: number;
    public static cli_user_password: {
      min_length: number,
      min_upper: number,
      min_lower: number,
      min_nums: number,
      min_specials: number
    };

    // Les informations sur le nom du fichier des images
    public static cli_file_common_picture_name: string;
    public static cli_file_common_picture_sd_name: string;
    public static cli_file_common_picture_default_name: string;
    public static cli_file_common_picture_default_sd_name: string;
    public static cli_file_common_picture_extension: string;
    public static cli_file_default_user_picture_no_gender: string;
    public static cli_file_default_user_picture_male: string;
    public static cli_file_default_user_picture_female: string;
    public static cli_file_default_source_picture: string;

    // Le nom de domaine pour les cookies
    public static cli_cookie_domain_name: string;

    // Le nom du siteweb
    public static cli_website_name: string;

    // L'activation de la timezone pour les clients
    public static cli_timezone_user_conversion: boolean;

    // L'environement d'exécution
    public static cli_environment_prod: boolean;

    // Les erreurs serveur
    public static cli_errors: {
      user: {
        not_found: number,
        incorrect_credentials: number,
        bad_roles: number,
        account_deleted: number,
        account_not_active: number,
        account_frozen: number,
        account_not_confirmed: number,
        form_invalid_email: number,
        form_invalid_username: number,
        form_invalid_password: number
      }
    };

    /**
     * Attribution des propriétés reçues du serveur aux propriétés du service de configuration.
     *
     * @param configurationObjectFromServer - objet reçu du serveur
     */
    public static setProperties(configurationObjectFromServer) {
        ConfigurationProperties.cli_url_root = configurationObjectFromServer.cli_url_root || "";
        ConfigurationProperties.cli_language_default = configurationObjectFromServer.cli_language_default;
        ConfigurationProperties.cli_error_type_fatal = configurationObjectFromServer.cli_error_type_fatal || ErrorType.FATAL;
        ConfigurationProperties.cli_error_type_notification = configurationObjectFromServer.cli_error_type_notification || ErrorType.NOTIFICATION;
        ConfigurationProperties.cli_creator_type_from_admin_ui = configurationObjectFromServer.cli_creator_type_from_admin_ui || 1;
        ConfigurationProperties.cli_user_username_min_length = configurationObjectFromServer.cli_user_username_min_length || 6;
        ConfigurationProperties.cli_source_name_min_length = configurationObjectFromServer.cli_source_name_min_length || 3;
        ConfigurationProperties.cli_user_password = {
            min_length: configurationObjectFromServer.cli_user_password.min_length || 6,
            min_upper: configurationObjectFromServer.cli_user_password.min_upper || 1,
            min_lower: configurationObjectFromServer.cli_user_password.min_lower || 1,
            min_nums: configurationObjectFromServer.cli_user_password.min_nums || 1,
            min_specials: configurationObjectFromServer.cli_user_password.min_specials || 0,
        };
        ConfigurationProperties.cli_file_common_picture_name = configurationObjectFromServer.cli_file_common_picture_name || "picture";
        ConfigurationProperties.cli_file_common_picture_sd_name = configurationObjectFromServer.cli_file_common_picture_sd_name || "picture_sd";
        ConfigurationProperties.cli_file_common_picture_default_name = configurationObjectFromServer.cli_file_common_picture_default_name || "defaultPicture";
        ConfigurationProperties.cli_file_common_picture_default_sd_name = configurationObjectFromServer.cli_file_common_picture_default_sd_name || "defaultPicture_sd";
        ConfigurationProperties.cli_file_common_picture_extension = configurationObjectFromServer.cli_file_common_picture_extension || "jpg";
        ConfigurationProperties.cli_file_default_user_picture_no_gender = configurationObjectFromServer.cli_file_default_user_picture_no_gender || "defaultNoGenderProfile.jpg";
        ConfigurationProperties.cli_file_default_user_picture_male = configurationObjectFromServer.cli_file_default_user_picture_male || "defaultMaleProfile.jpg";
        ConfigurationProperties.cli_file_default_user_picture_female = configurationObjectFromServer.cli_file_default_user_picture_female || "defaultFemaleProfile.jpg";
        ConfigurationProperties.cli_file_default_source_picture = configurationObjectFromServer.cli_file_default_source_picture || "defaultSourceProfile.svg";
        ConfigurationProperties.cli_cookie_domain_name = configurationObjectFromServer.cli_cookie_domain_name || UrlUtil.getDomainName();
        ConfigurationProperties.cli_website_name = configurationObjectFromServer.cli_website_name || "";
        ConfigurationProperties.cli_timezone_user_conversion = (ObjectUtil.isBoolean(configurationObjectFromServer.cli_timezone_user_conversion))
          ? configurationObjectFromServer.cli_timezone_user_conversion : false;
        ConfigurationProperties.cli_environment_prod = (ObjectUtil.isBoolean(configurationObjectFromServer.cli_environment_prod))
          ? configurationObjectFromServer.cli_environment_prod : true;
        ConfigurationProperties.cli_errors = {
            user: {
              not_found: configurationObjectFromServer.cli_errors.user.not_found,
              incorrect_credentials: configurationObjectFromServer.cli_errors.user.incorrect_credentials,
              bad_roles: configurationObjectFromServer.cli_errors.user.bad_roles,
              account_deleted: configurationObjectFromServer.cli_errors.user.account_deleted,
              account_not_active: configurationObjectFromServer.cli_errors.user.account_not_active,
              account_frozen: configurationObjectFromServer.cli_errors.user.account_frozen,
              account_not_confirmed: configurationObjectFromServer.cli_errors.user.account_not_confirmed,
              form_invalid_email: configurationObjectFromServer.cli_errors.user.form_invalid_email,
              form_invalid_username: configurationObjectFromServer.cli_errors.user.form_invalid_username,
              form_invalid_password: configurationObjectFromServer.cli_errors.user.form_invalid_password
            }
        };
    }

    /**
     * Attribution la langue du serveur au LangService.
     * TODO : Fonction a transférer dans le module ng-module-user
     */
    static setServerLngToManagerLangService(langService: LangService) {
      langService.serverLng = this.cli_language_default;
    }

}
