import { NgModule, ModuleWithProviders } from '@angular/core';
import { GlobalRef,
    // NodeGlobalRef,
    BrowserGlobalRef } from './global.ref';

/**
 * Module permettant de rendre accèssible les classes d'accès aux variables globales.
 */
@NgModule({})
export class GlobalModule {

    // On rend accèssible les classes d'accès aux variables globales pour les plate-formes de type navigateur web
    static forBrowser(): ModuleWithProviders<GlobalModule> {
        return {
            ngModule: GlobalModule,
            providers: [
                { provide: GlobalRef, useClass: BrowserGlobalRef }
            ]
        };
    }

    // On rend accèssible les classes d'accès aux variables globales pour les plate-formes de type node
    // static forNode(): ModuleWithProviders {
    //     return {
    //         ngModule: GlobalModule,
    //         providers: [
    //             { provide: GlobalRef, useClass: NodeGlobalRef }
    //         ]
    //     };
    // }
}
