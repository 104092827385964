import {Injectable} from '@angular/core';
import i18next from 'i18next';
import {CurrentUserService} from '../../../modules/ng-manager-user/services/current-user.service';
import {IIllustratedListItem} from '../../../data/IIllustratedListItem.interface';
import {ObjectUtil} from '../../../modules/ng-helpers-util/object.util';
import {appConfigurationProperties} from '../../../config/appConfigurationProperties';
import {environment} from '../../../../environments/environment';
import {UserDependenciesService} from '../../../modules/ng-module-login/services/user-dependencies.service';
import {applicationProperties} from '../../../modules/ng-properties-application/application.properties';
import {AnnouncementUtils} from "../../../utils/announcement.utils";

/**
 * Service pour la gestion des deadlines de l'utilisateur.
 */
@Injectable({
  providedIn: 'root',
})
export class DeadlinesService {

  // Les deadlines de tous les projets
  private _deadlines: IIllustratedListItem[] = [];
  get deadlines(): IIllustratedListItem[] {
    return this._deadlines;
  }

  // Les deadlines du projet ATEL
  private _deadlinesAtel: IIllustratedListItem[] = [];
  get deadlinesAtel(): IIllustratedListItem[] {
    return this._deadlinesAtel;
  }
  set deadlinesAtel(data: IIllustratedListItem[]) {
    this._deadlinesAtel = data;
    this.concatDeadlines();
  }

  // Les deadlines du projet ADOC
  private _deadlinesAdoc: IIllustratedListItem[] = [];
  get deadlinesAdoc(): IIllustratedListItem[] {
    return this._deadlinesAdoc;
  }
  set deadlinesAdoc(data: IIllustratedListItem[]) {
    this._deadlinesAdoc = data;
    this.concatDeadlines();
  }

  // Les deadlines du projet ATP
  private _deadlinesAtp: IIllustratedListItem[] = [];
  get deadlinesAtp(): IIllustratedListItem[] {
    return this._deadlinesAtp;
  }
  set deadlinesAtp(data: IIllustratedListItem[]) {
    this._deadlinesAtp = data;
    this.concatDeadlines();
  }

  /**
   *
   */
  constructor(
    private currentUserService: CurrentUserService,
    private userDependenciesService: UserDependenciesService) {
  }

  /**
   * Génération des échéances.
   */
  private concatDeadlines(): void {
    this._deadlines = []
      .concat(this._deadlinesAtel)
      .concat(this._deadlinesAdoc)
      .concat(this._deadlinesAtp)
      .sort((a, b) => {
        return b.date - a.date;
      });
  }

  /**
   * Génération des échéances.
   */
  public generateDeadlines(applicationName?: string): void {
    // Génération des deadlines pour le projet ATEL
    this.generateDeadlinesAtel(applicationName);
    // Génération des deadlines pour le projet ADOC
    this.generateDeadlinesAdoc(applicationName);
    // Génération des deadlines pour le projet ATP
    this.generateDeadlinesAtp(applicationName);

    this.concatDeadlines();
  }

  /**
   *
   */
  public generateDeadlinesAtel(applicationName?: string): void {
    if (!applicationName || applicationName === appConfigurationProperties.APPLICATION_NAME_AIP_ATEL) {
      // Génération des statistiques pour le nombre de teachings global
      const teachings = (
        ObjectUtil.hasProperty(this.currentUserService, 'user', 'atel', 'teachings') &&
        ObjectUtil.isArray(this.currentUserService.user.atel.teachings))
        ? this.currentUserService.user.atel.teachings.filter(elem => !elem.status.deleted)
        : [];
      this._deadlinesAtel = teachings
        .filter(elem => ObjectUtil.isDate(elem.status.expiryDate) && (new Date() < elem.status.expiryDate))
        .map(elem => {
          const learningContainerId = (ObjectUtil.isObject(elem.teaching_ref))
            ? elem.teaching_ref._id : elem.teaching_ref;
          const learningContainer = this.userDependenciesService.getElearningTeachingDependency(learningContainerId);
          const teachingType = learningContainer.type;
          return {
            element: {
              type: (teachingType === appConfigurationProperties.LEARNING_CONTAINER_TYPE_QUIZ)
                ? appConfigurationProperties.DEADLINE_ATEL_NEW_QUIZ
                : appConfigurationProperties.DEADLINE_ATEL_NEW_LESSON,
              date: elem.status.expiryDate,
              application: appConfigurationProperties.APPLICATION_NAME_AIP_ATEL,
              title: i18next.t(
                'page.dashboards.deadlines.atel.title',
                {
                  context: (teachingType === appConfigurationProperties.LEARNING_CONTAINER_TYPE_QUIZ) ? 'quiz' : 'lesson',
                  title: learningContainer.title
                }),
              message: learningContainer.title,
              imageUrl: `${appConfigurationProperties.URL_IMAGE_DEADLINE(applicationProperties.APPLICATION_NAME_AIP_COMMON)}`,
              data: {
                type: teachingType,
                _id: learningContainer._id
              },
              status: learningContainer.status
            },
            ...AnnouncementUtils.getBadge(appConfigurationProperties.APPLICATION_NAME_AIP_ATEL)
            /*
            application: appConfigurationProperties.APPLICATION_NAME_AIP_ATEL,
            applicationColorBackground: environment.applicationColors.default.background,
            // applicationColorBackground: environment.applicationColors.elearning.background,
            applicationColorText: environment.applicationColors.default.color
            // applicationColorText: environment.applicationColors.elearning.color
            */
          };
        });
    } else {
      this._deadlinesAtel = [];
    }
  }

  /**
   *
   */
  public generateDeadlinesAdoc(applicationName?: string): void {
    // TODO !!!
    if (!applicationName || applicationName === appConfigurationProperties.APPLICATION_NAME_AIP_ADOC) {
      this._deadlinesAdoc = [];
    } else {
      this._deadlinesAdoc = [];
    }
  }

  /**
   *
   */
  public generateDeadlinesAtp(applicationName?: string): void {
    // TODO !!!
    if (!applicationName || applicationName === appConfigurationProperties.APPLICATION_NAME_AIP_ATP) {
      this._deadlinesAtp = [];
    } else {
      this._deadlinesAtp = [];
    }
  }

}

