// @dynamic
export class ObjectUtil {

    /**
     * Fonction qui clone un objet.
     */
    static clone(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    };

    /**
     * Fonction qui retourne le nom du constructeur d'une instance.
     * @deprecated: Ne pas utiliser car lors de la compilation en mode prod de angular-cli le nom des classes changent.
     */
    static getInstanceConstructorName(instance: any): string {
        return instance.constructor.name;
    };

    /**
     * Fonction qui test si l'argument passé en paramètre est un entier.
     */
    static isInt(n): boolean {
        return Number(n) === n && n % 1 === 0;
    };

    /**
     * Fonction qui test si l'argument passé en paramètre est un nombre.
     */
    static isNumeric(n): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };

    /**
     * Fonction qui test si l'argument passé en paramètre est une chaine de caractères.
     */
    static isString(n): boolean {
        return (typeof n === 'string');
    };

    /**
     * Fonction qui test si l'argument passé en paramètre est un tableau.
     */
    static isArray(n): boolean {
        //return (n.prop && n.prop.constructor === Array);
        //return (n.constructor === Array);
        return (n instanceof Array);
    };

    /**
     * Fonction qui test si l'argument passé en paramètre est une date.
     * Gère le cas "new Date('random_string')"
     */
    static isDate(n): boolean {
        return (n instanceof Date && !isNaN(n.valueOf()));
    };

    /**
     * Fonction qui test si l'argument passé en paramètre est un boolean primitif.
     * Gère le cas "new Boolean()"
     */
    static isBoolean(n): boolean {
        // return (typeof n === 'boolean');
        return (typeof(n) === typeof(true));
    };

    /**
     * Fonction qui test si l'argument passé en paramètre est une fonction.
     */
    static isFunction(n): boolean {
        var getType = {};
        var type = getType.toString.call(n);
        return n && ((type === '[object Function]') || (type === '[object AsyncFunction]'));
    };

    /**
     * Fonction qui test si l'argument passé en paramètre est un objet.
     *
     * @param n
     * @param excludeArrays - ne considère pas le arrays comme des objets (default: false)
     * @param excludeFunctions - ne considère pas les fonction comme des objets (default: false)
     * @returns
     */
    static isObject(n, excludeArrays?, excludeFunctions?): boolean {
        if (n === null) {
            return false;
        }
        if(excludeArrays && this.isArray(n)) {
            return false;
        }
        if(excludeFunctions && this.isFunction(n)) {
            return false;
        }
        return (typeof n === 'object');
    };

    /**
     * Fonction qui test si un objet est vide.
     *
     * Because "Object.keys(new Date()).length === 0;" we have to do some additional check.
     *
     * @param obj
     * @returns
     */
    static isEmptyObject(obj: any): boolean {
        return (Object.keys(obj).length === 0) && (JSON.stringify(obj) === JSON.stringify({}));
    }

    /**
     * Fonction qui permet de récuprer les propriétés d'un objet à partir
     * de leur nom et ce même si elles sont imbriquées ou dans un tableau.
     * Retourne l'objet entier si le path est vide.
     * Retourne undefined si le path est undefined.
     * Retourne undefined si la propriété n'a pas pu être trouvée.
     *
     * Usage :
     *
     * var someObject = {
     *  'part1' : {
     *      'name': 'Part 1',
     *      'size': '20',
     *      'qty' : '50'
     *  },
     *  'part2' : {
     *      'name': 'Part 2',
     *      'size': '15',
     *      'qty' : '60'
     *  },
     *  'part3' : [
     *      {
     *          'name': 'Part 3A',
     *          'size': '10',
     *          'qty' : '20'
     *      }, {
     *          'name': 'Part 3B',
     *          'size': '5',
     *          'qty' : '20'
     *      }, {
     *          'name': 'Part 3C',
     *          'size': '7.5',
     *          'qty' : '20'
     *      }
     *  ]
     * };
     *
     * console.log(getValueByPath(someObject, 'part1.name'));     => 'Part 1'
     * console.log(getValueByPath(someObject, 'part2.qty'));      => '60'
     * console.log(getValueByPath(someObject, 'part3[0].name'));  => 'Part 3A'
     *
     * @param object
     * @param path
     * @returns
     */
    static getValueByPath(object: any, path: string): any {
        // Traitement des paramètres
        if(path === undefined) {
            // Le path est invalide => On retourne undefined
            return undefined;
        } else if(!path || (path.length === 0)) {   // isUndefinedOrEmpty(path)
            // Le path est vide => On retourne l'objet entier
            return object;
        } else if(ObjectUtil.isString(path)) {
            // On ne fait rien
        } else if(ObjectUtil.isArray(path)) {
            // On transforme le tableau en string
            // path = pathToString(path);   // FIXME : implémenter la fonction "pathToString"
            return undefined;   // On retourne undefined car la fonction "pathToString" n'est pas implémentée
        } else {
            // Le path est invalide => On retourne undefined
            return undefined;
        }

        // Début du traitement
        if((object !== undefined) && (path !== undefined)) {
            path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            path = path.replace(/^\./, '');           // strip a leading dot
            var a = path.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (k in object) {
                    object = object[k];
                } else {
                    return;
                }
            }
            return object;
        } else {
            return undefined;
        }
    };

    /**
     * Fonction qui compte le nombre de propriétés d'un objet.
     *
     * code pour tester la vitesse d'exécution de cette fonction :
     *      var tests = [10e3, 10e4, 10e5, 10e6]
     *      for(j in tests) {
     *         var obj = {};
     *         for(i = 0; i < tests[j]; i++)
     *             obj[i] = i;
     *         console.time(j + ' - test' + tests[j]);
     *         countProperties1(obj)
     *         console.timeEnd(j + ' - test' + tests[j]);
     *      }
     */
    static countProperties(obj: any): number {
        return (obj) ? Object.keys(obj).length : 0;
    };

    /**
     * Fonction qui teste l'existance d'une propriété dans un objet "obj" et le fait que cette propriété n'a pas une valeur null ou undefined.
     * La fonction peut également chercher à l'intérieur des tableaux si un paramétre level est un entier.
     * ATTENTION: Cette fonction test l'existance des propriétés dans l'objet avec la fonction hasOwnProperty(...) ET dans le prototype de
     *            l'objet avec l'opérateur "in".
     *
     * Ex:
     *      var test = {level1:{level2:{level3:0}} };
     *      var test1 = undefined;
     *      var prop0 = 'level0';
     *      var prop1 = 'level1';
     *      var prop2 = 'level2';
     *      var prop3 = 'level3';
     *      hasProperty(test, 'level1', 'level2', 'level3');  // true
     *      hasProperty(test, 'level1', prop2, 'level3');     // true
     *      hasProperty(test, prop1, prop2, prop3);           // true
     *      hasProperty(test, 'level1', 'level2', 'foo');     // false
     *      hasProperty(test, prop1, prop2, 'foo');           // false
     *      hasProperty(test, 'level0', 'level2', 'foo');     // false
     *      hasProperty(test, prop0, 'level1');               // false
     *      hasProperty(test, 'level1');                      // true
     *      hasProperty(test, prop1);                         // true
     *      hasProperty(test, 'level0');                      // false
     *      hasProperty(test1, 'level1');                     // false
     *
     * Autre exemple :
     *      hasProperty(test, ...['level0', 'level2', 'foo']);     // false
     *
     * Exemple de recherche dans un éléments d'un tableau :
     *      hasProperty(source, "groups", 0, "users");  // Cherche l'existance de la propriété : source.groups[0].users
     *
     * @param obj
     * @param properties
     * @returns
     */
    static hasProperty = function(obj, ...properties: (string|number)[] /*, level1, level2, ... levelN*/) {
        // var args = Array.prototype.slice.call(arguments, 1);
        for (let i = 0; i < properties.length; i++) {
            let isInObject = false;
            try {
                isInObject = properties[i] in obj;
            } catch (e) {
                // On recherche une propriété dans quelque chose qui n'est pas un objet
            }
            // console.warn("===> obj:", obj);
            if (!obj || !(obj.hasOwnProperty(properties[i]) || isInObject)) {    // On vérifie que la propriété existe dans l'objet ou le protoype de l'objet
                // console.warn(" X-> properties["+i+"]:", properties[i]);
                return false;
            }
            obj = obj[properties[i]];
            // console.warn("  -> obj:", obj);
        }
        // console.warn(" Y-> FIN");
        return true;
    };

    /**
     * Fonction qui applati un objet en un seul niveau.
     * Exemple:
     *      var obj1 = {toto: 1, titi: {a: 2, b:3}};
     *      flattenObject(obj1);
     * Resultat:
     *      {
     *          tata : 55
     *          titi.a : 22
     *          titi.c : 44
     *          toto : 11
     *      }
     *
     * @param ob
     * @returns
     */
    static flattenObject = function(ob) {
        var toReturn = {};

        for (var i in ob) {
            if (!ob.hasOwnProperty(i)) continue;

            if ((typeof ob[i] === 'object') &&
                !this.isDate(ob[i]) &&  // On ne rentre pas dans les dates même s'il s'agit d'objet
                !this.isArray(ob[i]) && // On ne rentre pas dans les tableaux même s'il s'agit d'objet
                (ob[i] !== null)) {
                var flatObject = this.flattenObject(ob[i]);
                for (var x in flatObject) {
                    if (!flatObject.hasOwnProperty(x)) continue;
                    toReturn[i + '.' + x] = flatObject[x];
                }
            } else {
                toReturn[i] = ob[i];
            }
        }
        return toReturn;
    };

    /**
     * Fonction qui permet de modifier les propriétés (même imbriquée) d'un objet à partir du nom de la propriété.
     * Si le path la propriété entré en pramamètre n'est pas trouvé dans l'objet, la propriété sera crée.
     * Exemple:
     *      var toto = {
     *          a: {
     *            b : {
     *                  c : 10
     *              }
     *          },
     *          d : 20
     *      };
     *      setNestedProperty(toto, 'a.b.c', 666);
     *      setNestedProperty(toto, 'd', 777);
     *
     * @param obj - objet à modifier
     * @param path - path de la propriété à modifier
     * @param value - valeur de la nouvelle propriété
     */
    static setNestedProperty = function(obj, path, value) {
        var schema = obj;  // a moving reference to internal objects within obj
        var pList = path.split('.');
        var len = pList.length;
        for(var i = 0; i < len-1; i++) {
            var elem = pList[i];
            if( !schema[elem] ) schema[elem] = {};
            schema = schema[elem];
        }
        schema[pList[len-1]] = value;
    };

    /**
     * Fonction qui compare si deux objets ont les mêmes propriétés.
     * La fonction rélise une comparaison des propriétés imbiquées.
     *
     * var objOne = {"a":"one","b":"two","c":{"f":"three_one"}};
     * var objTwo = {"a":"four","b":"five","c":{"f":"six_one"}};
     * var objThree = {"a":"four","b":"five","c":{"g":"six_one"}};
     * console.log("objOne, objTwo: " + deepSameKeys(objOne, objTwo));          => true
     * console.log("objTwo, objThree: " + deepSameKeys(objTwo, objThree));      => false
     *
     * @param o1
     * @param o2
     * @returns
     */
    static deepSameKeys = function(o1, o2) {
        // Get the keys of each object
        const o1keys = Object.keys(o1).sort();
        const o2keys = Object.keys(o2).sort();
        // Make sure they match
        // If you don't want a string check, you could do
        // if (o1keys.length !== o2keys.length || !o1keys.every((key, index) => o2keys[index] === key)) {
        if (o1keys.join() !== o2keys.join()) {
            // This level doesn't have the same keys
            return false;
        }
        // Check any objects
        return o1keys.every(key => {
            const v1 = o1[key];
            const v2 = o2[key];
            const t1 = typeof v1;
            const t2 = typeof v2;
            if (t1 !== t2) {
                return false;
            }
            return t1 === "object" ? ObjectUtil.deepSameKeys(v1, v2) : true;
        });
    };

    /**
     * Fonction qui fusionne les objets "objs" passés en paramètre dans l'objet "mainObj".
     *
     * @param mainObj
     * @param objs - tableau ou objets séparés par des virgules à fusionner avec "mainObj".
     * @returns
     */
    static merge = function(mainObj, ...objs): any {
        return Object.assign(mainObj, ...objs);
    }

    /**
     * Fonction qui aplati un objet ou un tableau en un seul niveau et retourne un tableau d'objet décrivant
     * toutes les propriétés et sous-propriétés de l'objet ou du tableau.
     * L'objet pasé en paramètre est parcouru récursivement et toutes les propriétés et sous-propriétés
     * sont enregistrés dans le tableau de retour. Ce dernier est au format suivant :
     *
     * Format de retour:
     *      [
     *          {
     *              path: <path de la proriété dans l'objet>,
     *              property: <clé de la proriété dans le sous-objet>,
     *              value: <value de la proriété dans le sous-objet>,
     *              obj: <l'objet/sous-objet contenant la proriété "property">,
     *          }, {
     *              ...
     *          }
     *      ]
     *
     * Remarques:
     *   - La fonction aplati toutes les prorpiétés et sous propriété des tableaux et des objets
     *     rencontrés en parcorant récursivement les objets et tableaux imbriqués trouvés.
     *     Les objets et tableau sont donc les noeuds de l'arbre et les autres proriétés sont
     *     les feuilles de l'arbre.
     *   - Seules les feuilles créées une entrée dans le tableau de retour de la fonction.
     *   - Les propriétés dans la valeur sont les suivantes sont considérés comme des feuilles :
     *      o objets vides
     *      o objets de type "Date"
     *      o "undefined"
     *   - La propriété "path" des éléments du tableau de retour est un tableau de nom de prorpiété.
     *     Si les éléments de ce tableau sont de type "int" cela signifie que cette propriété fait
     *     partie d'un tableau.
     *     Si les éléments de ce tableau sont de type "string" cela signifie que cette propriété fait
     *     partie d'un objet.
     *
     * Exemple:
     *      var obj = {
     *          ["1"]: "a",
     *          a: 11,
     *          c: [{ x: 'a', y: 'b' }, { xx: 'aa', yy: 'bb' }],
     *          e: {},
     *          f: undefined,
     *          g: function(toto) { return toto; },
     *          h: [ 'A', 79, new Date()],
     *          i: new Date(),
     *          j: null
     *      };
     *      flattenObjectAdvanced(obj, false);
     *
     * Résultat:
     *      [ { path: [ '1' ],
     *          property: '1',
     *          value: 'a',
     *          obj:
     *           {
     *              ...    // L'objet original passé en paramètre
     *           },
     *        { path: [ 'a' ],
     *          property: 'a',
     *          value: 11,
     *          obj:
     *           {
     *              ...    // L'objet original passé en paramètre
     *           },
     *        { path: [ 'c', 0, 'x' ],
     *          property: 'x',
     *          value: 'a',
     *          obj: { x: 'a', y: 'b' } },
     *        { path: [ 'c', 0, 'y' ],
     *          property: 'y',
     *          value: 'b',
     *          obj: { x: 'a', y: 'b' } },
     *        { path: [ 'c', 1, 'xx' ],
     *          property: 'xx',
     *          value: 'aa',
     *          obj: { xx: 'aa', yy: 'bb' } },
     *        { path: [ 'c', 1, 'yy' ],
     *          property: 'yy',
     *          value: 'bb',
     *          obj: { xx: 'aa', yy: 'bb' } },
     *        { path: [ 'e' ],
     *          property: 'e',
     *          value: {},
     *          obj:
     *           {
     *              ...    // L'objet original passé en paramètre
     *           } },
     *        { path: [ 'f' ],
     *          property: 'f',
     *          value: undefined,
     *          obj:
     *           {
     *              ...    // L'objet original passé en paramètre
     *           } },
     *        { path: [ 'g' ],
     *          property: 'g',
     *          value: [Function: g],
     *          obj:
     *           {
     *              ...    // L'objet original passé en paramètre
     *           } },
     *        { path: [ 'h', 0 ],
     *          property: 0,
     *          value: 'A',
     *          obj: [ 'A', 79, 2019-06-12T13:54:09.798Z ] },
     *        { path: [ 'h', 1 ],
     *          property: 1,
     *          value: 79,
     *          obj: [ 'A', 79, 2019-06-12T13:54:09.798Z ] },
     *        { path: [ 'h', 2 ],
     *          property: 2,
     *          value: 2019-06-12T13:54:09.798Z,
     *          obj: [ 'A', 79, 2019-06-12T13:54:09.798Z ] },
     *        { path: [ 'i' ],
     *          property: 'i',
     *          value: 2019-06-12T13:54:09.798Z,
     *          obj:
     *           {
     *              ...    // L'objet original passé en paramètre
     *           } } ]
     *
     * @param originalObj - L'objet à aplatir
     * @param compact - Si true, l'objet aplati ne contiendra pas la propriété "obj" pour chaque
     *                  element du tableau des propriétés de l'objet aplati. Ceci permet
     *                  d'avoir un objet aplati occupant moins d'espace mémoire.
     *                  Si false, la propriété "obj" sera rajoutée à chaque element du tableau
     *                  des propriétés de l'objet aplati. Cette propriété contient l'objet ou le
     *                  sous-objet dans lequel la prorpriété est issue.
     *                  Type: boolean, valeur par défaut : true.
     * @param sousObj - Le sous objet actuellement traité lors des appels récursifs
     * @param properties - La liste de toutes les propriétés parcourus lors des appels récursifs
     * @param path - Le path dans "originalObj" de la propriété actuellement traité lors des appels récursifs
     * @param res - Le résultat cumulé des appels récursifs
     * @returns
     */
    static flattenObjectAdvanced = function(originalObj, compact, sousObj?, properties?, path?, res?) {
        compact = (ObjectUtil.isBoolean(compact)) ? compact : true;  // La valeur par défaut de la propriété compact
        if(originalObj !== undefined) {
            sousObj = sousObj || originalObj;
            properties = properties || [];
            path = path || [];
            res = res || [];
            for (let strKey in sousObj) {
                if (!sousObj.hasOwnProperty(strKey)) continue;

                let typedKey: any = strKey;
                let currentValue;
                if (path.length === 0) {  // Niveau objet
                    currentValue = originalObj;
                } else {    // Niveau sous-objet
                    currentValue = path.reduce((accumulator, currentValue) => {
                        return accumulator[`${currentValue}`];
                    }, originalObj);
                }
                if (ObjectUtil.isArray(currentValue)) {
                    // On converti les indices de tableau en entier
                    typedKey = parseInt(typedKey, 10);
                }
                properties.push(typedKey);    // Le "push" permet de compléter le tableau à travers tous les appels récursif quelque soit les branches de l'arbre
                if (
                    (
                        (ObjectUtil.isObject(sousObj[strKey], true, false) && !ObjectUtil.isEmptyObject(sousObj[strKey])) || // Les objets vides sont considérés comme des feuilles
                        (ObjectUtil.isArray(sousObj[strKey]) && (sousObj[strKey].length > 0))                      // Les taleaux vides sont considérés comme des feuilles
                    ) &&
                    (sousObj[strKey] !== undefined) &&              // Les propriétés undefined sont considérés comme des feuilles
                    (sousObj[strKey] !== null) &&                   // Les propriétés null sont considérés comme des feuilles
                    !ObjectUtil.isDate(sousObj[strKey])) {                     // Les dates sont concidérées comme des feuilles
                    // Noeud
                    ObjectUtil.flattenObjectAdvanced(
                        originalObj,
                        compact,
                        sousObj[strKey],
                        properties,
                        path.concat([typedKey]),  // Le "concat" permet de compléter le tableau uniquement dans un sous-arbre d'appels récursif
                        res);
                } else {
                    // Feuille
                    let elem: any = {
                        path: path.concat(typedKey),  // Le "concat" permet de compléter le tableau uniquement dans un sous-arbre d'appels récursif
                        property: typedKey,
                        value: sousObj[strKey],
                        // obj: sousObj
                    }
                    if(!compact) {
                        elem.obj = sousObj
                    }
                    res.push(elem);
                }
            }
            return res;
        } else {
            return undefined;
        }
    }

    /**
     * Fonction qui supprime toutes les propriétés null ou undefined de l'objet "obj" à l'exception de celles
     * passées dans le paramètre "properties".
     * La fonction ne retourne rien mais modifie l'objet passé en paramètre.
     *
     * Exemple :
     *      let toto = {
     *          "title" : "aaa",
     *          "type" : "download",
     *          "code" : null,
     *          "resource" : null,
     *          "url" : null,
     *          "param" : null,
     *          "options" : {
     *              "width" : null,
     *              "height" : null,
     *              "directories" : null,
     *              "location" : null,
     *              "menubar" : null,
     *              "resizable" : null,
     *              "scrollbars" : null,
     *              "status" : null,
     *              "toolbar" : null,
     *              "titlebar" : null,
     *              "fullscreen" : null,
     *              "channelmode" : null,
     *              "dependent" : null,
     *              "top" : null,
     *              "left" : null
     *          };
     *      helpersUtils.deleteNullAndUndefinedProperties(toto, {recursive: true, properties: ["url", "location"]});
     *      console.log("toto:", toto);
     *
     * Resultat :
     *      toto: {
     *        title: 'aaa',
     *        type: 'download',
     *        url: null,
     *        options: { location: null }
     *      }
     *
     * @param obj
     * @param options - Options de la fonction, objet contenant les propriétés suivantes :
     *                     + recursive: si true la fonction supprime les porpriétés null ou undefined des sous-objets et
     *                       supprime les sous-objets s'ils sont vides. (défaut: false)
     *                     + removeEmptyStings - si true la fonction supprime les chaines vides. (défaut: false)
     *                     + removeEmptyArrays - si true la fonction supprime les tableaux vides. (défaut: false)
     *                     + properties - tableau de propriétées ignorées sous forme de chaine de caractères. (défaut: [])
     */
    static deleteNullAndUndefinedProperties = function(
        obj: any,
        options?: {
            recursive?: boolean,
            removeEmptyStings?: boolean,
            removeEmptyArrays?: boolean,
            properties?: string[]
         }) {

        let recursive = (ObjectUtil.isObject(options) && ObjectUtil.isBoolean(options.recursive)) ? options.recursive : false;
        let removeEmptyStings = (ObjectUtil.isObject(options) && ObjectUtil.isBoolean(options.removeEmptyStings)) ? options.removeEmptyStings : false;
        let removeEmptyArrays = (ObjectUtil.isObject(options) && ObjectUtil.isBoolean(options.removeEmptyArrays)) ? options.removeEmptyArrays : false;
        let properties = (ObjectUtil.isObject(options) && ObjectUtil.isArray(options.properties)) ? options.properties : [];

        if(ObjectUtil.isObject(obj)) {
            for(var propName in obj) {
                if(
                    (
                        (obj[propName] === null) ||         // La propriété est null
                        (obj[propName] === undefined) ||    // La propriété est undefined
                        (removeEmptyStings && ObjectUtil.isString(obj[propName]) && (obj[propName].length === 0)) ||    // La propriété est une string vide
                        (removeEmptyArrays && ObjectUtil.isArray(obj[propName]) && (obj[propName].length === 0))         // La propriété est un tableau vide
                    ) && (
                        !ObjectUtil.isArray(properties) || !properties.includes(propName)   // La propriété n'est pas exclue
                    )
                ) {
                    delete obj[propName];
                } else if(recursive && ObjectUtil.isObject(obj[propName], true, true)) {
                    if(!ObjectUtil.isArray(properties) || !properties.includes(propName)) {
                        ObjectUtil.deleteNullAndUndefinedProperties(obj[propName], options);
                        if(ObjectUtil.isEmptyObject(obj[propName])) {
                            // Toutes les propriétés de l'objet ont été supprimées
                            delete obj[propName];
                        }
                    }
                }
            }
        }
    };

    /**
     * Fonction qui fusionne deux objets, les champs de l'objet "otherObj" seront rajouté à ceux de "obj".
     * Propriétés de l'objet de retour :
     *  - les propriétés de "obj" et "otherObj" ayant le même path (propriété communes aux deux objets)
     *      => l'objet de retour contiendra les valeurs des propriétés de l'objet "otherObj" et non celles de "obj" (y compris si elles ont la valeur explicite "undefined" ou "null")
     *  - les propriétés présentes dans "obj" mais pas dans "otherObj"
     *      => l'objet de retour contiendra ces propriétés (y compris si elles ont la valeur explicite "undefined" ou "null")
     *  - les propriétés présentes dans "otherObj" mais pas dans "obj"
     *      => l'objet de retour contiendra ces propriétés (y compris si elles ont la valeur explicite "undefined" ou "null")
     * La fonction ne modifie pas les objets passés en paramètre mais retourne un nouvel objet.
     *
     * Exemple:
     *      let obj = {
     *          a: 1,
     *          b: 2,
     *          aa: {x: ["a", "b"], y: "toto"},
     *          bb: {p: "idriss", q: new Date()},
     *          cc: {r: true}
     *      };
     *      let otherObj = {
     *          a: 3,
     *          c: 4,
     *          aa: {x: ["aa", "bb"]},
     *          cc: undefined,
     *          dd: {t: false}
     *      };
     *      let mergeObj = helpersUtils.mergeObjects(obj, otherObj);
     * Résultat:
     *      mergeObj: {
     *          a: 3,                                   // otherObj (overwrite)
     *          b: 2,                                   // obj
     *          aa: {
     *              x: [ 'aa', 'bb' ],                  // otherObj (overwrite)
     *              y: 'toto'                           // obj
     *          },
     *          bb: {                                   // obj
     *              p: 'idriss',
     *              q: 2020-06-14T16:27:35.275Z
     *          },
     *          cc: undefined,                          // otherObj (overwrite)
     *          c: 4,                                   // otherObj
     *          dd: {
     *              t: false                            // otherObj
     *          }
     *      }
     *
     * @param obj
     * @param otherObj
     * @returns {{}}
     */
    static mergeObjects = function(obj: any, otherObj: any): any {
        let flatObj = ObjectUtil.flattenObject(obj);
        let flatOtherObj = ObjectUtil.flattenObject(otherObj);
        let flatMerge = Object.assign(flatObj, flatOtherObj);

        let retour = {};
        Object.keys(flatMerge).forEach(key => {
            ObjectUtil.setNestedProperty(retour, key, flatMerge[key]);
        });

        return retour;
    };

  /**
   * Fonction qui exécute une fonction sur chaque propriété d'un objet.
   * Si l'objet "obj" est undefined, la fonction ne réalise aucune action.
   * Pour accèder à la valeur de la propriété dans la fonction callback, utiliser une instruction de la forme :
   *      obj[property]
   *
   * @param obj
   * @param callback - fonction exécutée sur chaque propriété de l'objet.
   *                 - Cette fonction accepte trois paramètre:
   *                      o l'objet,
   *                      o la propriété,
   *                      o l'indice de la propriété dans l'objet.
   * @returns {Number}
   */
  static forEachProperty = function(obj, callback) {
    var index = 0;
    for (var property in obj) {  // Récupération des noms des clés de l'objet
      if (obj.hasOwnProperty(property)) {
        callback(obj, property, index);
        index++;
      }
    }
  };

}
