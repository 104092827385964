/**
 * Les différentes actions.
 */
export enum CatalogueActionName {
  ACTION_BUY = "buy",
  ACTIONS_UPDATE = "update"
}

/**
 * Les différents plans.
 */
export enum CataloguePlanName {
  PLAN_NONE = "planNone",
  PLAN_3_YEARS = "plan3years",
  PLAN_5_YEARS = "plan5years",
  PLAN_10_YEARS = "plan10years"
}

/**
 * Propiétés de configuration de la plate-forme.
 *      Proriétés :
 *      - JWT_TOKEN_NAME : nom du token jwt utilisé pour l'authentifiaction
 */
// @dynamic
export const adocProperties = {

  // *** Les noms des fichiers image pour les différents types de produit ***
  IMAGE_FILE_PRODUCT_COUNTRY: "flag.png?v1",
  IMAGE_FILE_PRODUCT_COMPANY: "logo.jpg?v1",

  // *** L'image des produits par défaut ***
  // CATALOGUE_DEFAULT_PRODUCT_IMAGE: "database/resources/images/share/default/zone/defaultZone.png",
  CATALOGUE_DEFAULT_PRODUCT_IMAGE: "resources/images/share/default/zone/defaultZone.png?v1",

  // *** Variable spécifiant que l'on doit charger le script pour l'utilisation de stripe ***
  // Si ce script n'est pas chargé, stripe ne pourra pas être utilisé pour les paiement en ligne.
  PAYMENT_LOAD_STRIPE_SCRIPT_ON_STARTUP: false,

  // *** Variable spécifiant que l'on doit charger le script pour l'utilisation de braintree ***
  // Si ce script n'est pas chargé, braintree ne pourra pas être utilisé pour les paiement en ligne.
  PAYMENT_LOAD_BRAINTREE_SCRIPT_ON_STARTUP: false,

  // *** Les différents types de produit ***
  /*PAYMENT_PRODUCT_COUNTRY: "country",
  PAYMENT_PRODUCT_COMPANY: "company",
  PAYMENT_PRODUCT_ZONE: "zone",*/
  PAYMENT_PRODUCT_COUNTRY   : "paysID",          // doit correspondre aux valeurs enregistrées dans la partie client
  PAYMENT_PRODUCT_COMPANY   : "societeID",             // doit correspondre aux valeurs enregistrées dans la partie client
  PAYMENT_PRODUCT_ORGANISM  : "organismeID",        // doit correspondre aux valeurs enregistrées dans la partie client
  PAYMENT_PRODUCT_ZONE      : "zoneID",             // doit correspondre aux valeurs enregistrées dans la partie client
  PAYMENT_PRODUCT_SUBZONE   : "subzoneID",          // doit correspondre aux valeurs enregistrées dans la partie client

  // *** Les différents options d'achat des produits ***
  PAYMENT_OPTIONS_PRODUCT_PLAN: "plan",
};
