/**
 * Fonction changeUserLanguage customisée pour le service CurrentUserService.
 * Elle sera exécutée dans le contexte du service CurrentUserService.
 */
import {StateProperties} from '../../../modules/ng-properties-state/state.properties';
import {SchedulerUtil} from '../../../modules/ng-helpers-util/scheduler.util';

export function changeUserLanguageFunction(): void {
    // On réinitilialise le tracker des news importer pour réimporter toutes les news dans la nouvelle langue.
    StateProperties.NEWS_LAST_IMPORT_DATE_RESET();
    // On redémarre la tâche de recherche des news
    SchedulerUtil.restartJobFetchNews();
}
