import {UserTeachingContainer} from './UserTeachingContainer';
import {ObjectUtil} from '../../../modules/ng-helpers-util/object.util';
import {appConfigurationProperties} from '../../../config/appConfigurationProperties';
import {Course} from '../../../modules/ng-models-atel3/models/db/Course';
import {Lesson} from '../../../modules/ng-models-atel3/models/db/Lesson';
import {CurrentUserService} from '../../../modules/ng-manager-user/services/current-user.service';
import {ElearningProductLibraryService} from '../../../views/app/elearning/elearning-product-library.service';
import {UserDependenciesService} from '../../../modules/ng-module-login/services/user-dependencies.service';
import {IElearningUserProduct} from './IElearningUserProduct';
import {UserTeachingDb} from '../../../modules/ng-models-atel3/models/db/userAtel/UserTeachingDb';
import {LibraryUtil} from '../../../modules/ng-manager-user/utils/atel3/library.util';

export class UserCourse extends UserTeachingContainer implements IElearningUserProduct {

  /**
   *
   * @param course Le cours
   * @param userTeachingsGetter Permet d'obtenir les userTeaching
   */
  constructor(
    public course: Course,
    userTeachingsGetter?: CurrentUserService | UserTeachingDb[]) {

    super();
    this._id = `###${this.course._id}###`; // Création d'un _id pour l'unicité dans les listes
    this.type = this.course.type;
    this.initUserTeachings(userTeachingsGetter);
  }


  // Le type d'objet
  public getType(): string {
    return appConfigurationProperties.LEARNING_CONTAINER_TYPE_COURSE;
  }

  // L'objet associé
  public getEmbeddedObject(): Course {
    return this.course;
  }

  // Fonction qui test si l'objet associé existe
  public hasEmbeddedObject(): boolean {
    return this.course !== undefined;
  }

  // Fonction qui test si l'objet est valide
  public isEmbeddedObjectValid(): boolean {
    return this.course.isValid();
  }

  // Fonction qui test si l'objet possède cette source
  public hasSource(sourceId: string): boolean {
    if (ObjectUtil.isArray(this.userTeachings)) {
      return this.userTeachings.every(usrTeach => {
        /*
        return (
          usrTeach.hasSource(sourceId) &&
          usrTeach.sources.some(
            src => (
              ObjectUtil.hasProperty(src, 'course_ref') &&
              ((src as any).course_ref === this.course._id))
          ));
        */
        // Le teaching à été souscrit via une source externe
        const isExternalSource = (
          usrTeach.hasSource(sourceId) &&
          usrTeach.sources.some(
            src => (
              ObjectUtil.hasProperty(src, 'course_ref') &&
              ((src as any).course_ref === this.course._id))
          ));
        // Le teaching a été sourcrit via la source personnel
        const isPersonalSource = (
          (sourceId === appConfigurationProperties.DEFAULT_SOURCE_ID) &&
          usrTeach.hasSource(sourceId) &&
          (
            (usrTeach.sources.length === 0) ||
            usrTeach.sources.some(
              src => (
                ObjectUtil.hasProperty(src, 'course_ref') &&
                ((src as any).course_ref === this.course._id) &&
                ((src as any).source_ref === null)
              )
            )
          )
        );
        return isExternalSource || isPersonalSource;

      });
    } else {
      return false;
    }
  }

  /**
   * Fonction qui initialise les userTeachings du UserCourse.
   */
  private initUserTeachings(
    userTeachingsGetter?: CurrentUserService | UserTeachingDb[],
    considerUserTeachingActiveFlag: boolean = false): void {
    if (this.course && ObjectUtil.isArray(this.course.lessons)) {
      // Initilisation du tableau des userTeaching avec des valeurs undefined
      this.userTeachings = Array(this.course.lessons.length).fill(undefined);
      // Remplissage du tableau des userTeaching avec les userTeachings trouvé dans l'utilisateur Atel
      this.course.lessons.forEach((lessonCourse, index) => {
        if (lessonCourse.active) {
          // On recherche les occurances de cette lesson dans les lessons du cours
          const lessonId: string = (ObjectUtil.isString(lessonCourse._ref))
            ? lessonCourse._ref as string
            : (lessonCourse._ref as Lesson)._id;
          const userTeaching = LibraryUtil.getUserTeachingFromGetter(
            userTeachingsGetter, lessonId, this.getUserTeachingFromTeachingId);
          if (userTeaching && userTeaching.isUserTeachingValid(considerUserTeachingActiveFlag)) {
            // On a trouvé la lesson correspondante dans le cours
            this.userTeachings[index] = userTeaching;
          }
        }
      });
    }
    // Génération des informations de progression
    this.generateProperties();
  }

  // L'objet est partiellement possédé par l'utilisateur
  public isPartiallyOwnByUser(
    userTeachingsGetter: CurrentUserService | UserTeachingDb[],
    userDependenciesService?: UserDependenciesService,
    considerUserTeachingActiveFlag: boolean = false): boolean {
    if (this.course && ObjectUtil.isArray(this.course.lessons)) {
      return this.course.lessons.some(lessonCourse => {
        if (lessonCourse.active) {
          // On recherche les occurances de cette lesson dans les lessons du cours
          const lessonId: string = (ObjectUtil.isString(lessonCourse._ref))
            ? lessonCourse._ref as string
            : (lessonCourse._ref as Lesson)._id;
          const userTeaching = LibraryUtil.getUserTeachingFromGetter(
            userTeachingsGetter, lessonId, this.getUserTeachingFromTeachingId);
          return (userTeaching && userTeaching.isUserTeachingValid(considerUserTeachingActiveFlag));
        } else {
          return false; // Traitement différent que pour la fontion isFullyOwnByUser
        }
      });
    } else {
      return false;
    }
  }

  // L'objet est entièrement possédé par l'utilisateur
  public isFullyOwnByUser(
    userTeachingsGetter: CurrentUserService | UserTeachingDb[],
    userDependenciesService?: UserDependenciesService,
    considerUserTeachingActiveFlag: boolean = false): boolean {
    if (this.course && ObjectUtil.isArray(this.course.lessons)) {
      return this.course.lessons.every(lessonCourse => {
        if (lessonCourse.active) {
          // On recherche les occurances de cette lesson dans les lessons du cours
          const lessonId: string = (ObjectUtil.isString(lessonCourse._ref))
            ? lessonCourse._ref as string
            : (lessonCourse._ref as Lesson)._id;
          const userTeaching = LibraryUtil.getUserTeachingFromGetter(
            userTeachingsGetter, lessonId, this.getUserTeachingFromTeachingId);
          return (userTeaching && userTeaching.isUserTeachingValid(considerUserTeachingActiveFlag));
        } else {
          return true; // Traitement différent que pour la fontion isPartiallyOwnByUser
        }
      });
    } else {
      return false;
    }
  }

  /**
   *
   */
  public hasInfoProgression({userTeachingsGetter, userDependenciesService}): boolean {
    return (
      this.isFullyOwnByUser(userTeachingsGetter, userDependenciesService, false) ||
      this.isPartiallyOwnByUser(userTeachingsGetter, userDependenciesService, false)
    );
  }

  // L'objet a été acheté par l'utilisateur
  public isPurchased(elearningProductLibraryService: ElearningProductLibraryService): boolean {
    return (elearningProductLibraryService.getUserCourseFromCourseId(this.course._id) !== undefined);
  }

  // L'objet peut être acheté par cet utilisateur
  public isPurchasable({userTeachingsGetter, userDependenciesService}): boolean {
    return (
      this.getEmbeddedObject().hasPrice() &&
      (
        !this.hasInfoProgression({userTeachingsGetter, userDependenciesService}) ||
        !this.isUserProductValid(true) ||
        !this.isFullyOwnByUser(userTeachingsGetter, userDependenciesService, false)
      )
    );
  }

}
