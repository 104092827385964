import {
  Injectable,
  Inject
} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import i18next from 'i18next';

import {ManagerUserConfigService} from '../models/ModuleLoginConfig';
import {UserDataServiceInt} from '../../ng-models-ats/interfaces/userDataService.interface';
import {Localization} from '../../ng-models-ui/models/Localization';
import {User} from '../../ng-models-ats/models/db/User';
import {ModuleLibraryConfigInt} from '../../ng-models-ui/interfaces/moduleLibraryConfig.interface';
import {AuthenticationService} from '../../ng-manager-authentication/authentication.service';
import {ObjectUtil} from '../../ng-helpers-util/object.util';
import {StringUtil} from '../../ng-helpers-util/string.util';
import {AipError} from '../../ng-models-ui/models/AipError';
import {ConfigurationProperties} from '../../ng-properties-configuration/configuration.properties';
import {errorProperties, ErrorStatusCode} from '../../ng-properties-error/error.properties';
import {ContentType} from '../../ng-models-ui/enums/ContentType';
import {PaginateOptions} from '../../ng-models-ui/models/Paginate';
import {Order} from '../../ng-models-ats/models/db/Order';
import {appConfigurationProperties} from "../../../config/appConfigurationProperties";
import {UserTeachingDb} from "../../ng-models-atel3/models/db/userAtel/UserTeachingDb";
import {UserUtil} from "../utils/user.utils";
import {UserDependenciesService} from "../../ng-module-login/services/user-dependencies.service";
import {ELEARNING_LIBRARY_TAB, PUBLISHING_LIBRARY_TAB} from "../../../constants/LibraryTabs";
import {LangService} from "../../../shared/lang.service";
import {CurrencyService} from "../../../shared/currency.service";
import {UrlUtil} from "../../ng-helpers-util/url.util";

/**
 * Service assurant l'accès aux information de l'utilisateur de la session.
 * Stocke les informations de l'utilisateur connecté.
 * Rq:  Les informations de connexion de l'utilisateur sont présente dans le service d'authentification
 *      (account.service.ts).
 */
@Injectable({
  providedIn: 'root'
})
export class CurrentUserService implements UserDataServiceInt {

  isCurrentUserServiceInstance = true;  // Remplace l'instruction "XXX.constructor.name === 'CurrentUserService'"
                                        // qui ne fonctionne pas en compilation prod car le nom des construncteur change.

  // La localisation de l'utilisateur
  _userLocalization: Localization;
  get userLocalization(): Localization {
    return this._userLocalization || new Localization(undefined, undefined, undefined, undefined, undefined);
  }

  set userLocalization(newValue: Localization) {
    this._userLocalization = newValue;
  }

  // Est-ce que l'utilisateur est connecté ?
  isUserConnected(): boolean {
    return (ObjectUtil.isObject(this.user) && !ObjectUtil.isEmptyObject(this.user));
  }

  // Est-ce que l'utilisateur est un compte corporate ?
  isUserCorporate(): boolean {
    return (
      this.isUserConnected() &&
      ObjectUtil.hasProperty(this.user, 'source') &&
      (
        (ObjectUtil.isObject(this.user.source) && !ObjectUtil.isEmptyObject(this.user.source)) ||
        (ObjectUtil.isString(this.user.source) && !StringUtil.isUndefinedOrEmpty(this.user.source))
      )
    );
  }

  // La devise suggérée en fonction de la localisation de l'utilisateur
  localCurrency: string;

  // La devise préféré de l'utilisateur
  preferredCurrency: string;

  // Flag indicant que l'utilisateur à changer sa devise préférée
  userChangedPreferredCurrency: boolean = false;

  // Objet stockant les préférences de l'utilisateur
  userPreferences = {
    lastElearningLibraryTab: ELEARNING_LIBRARY_TAB.LESSONS,
    lastPublishingLibraryTab: PUBLISHING_LIBRARY_TAB.PUBLICATIONS,
    scrollPageSize: appConfigurationProperties.SCROLL_PAGE_SIZE
  };

  // Utilisateur connecté initialisé par le service d'authentification (account.service.ts).
  private _user: User;
  get user(): any {
    return this._user;
  }

  set user(newValue: any) {
    // On modifie l'utilisateur
    this._user = new User(newValue);
    // On céclare le changement aux listener de l'observable "userEdited$"
    this.declareEdition();
  }

  // Observable pour la détection des modifications de l'utilisateur (propriété "_user"). Enregistre la date de dernière modification.
  private userEditedSource = new Subject<Date>();
  userEdited$ = this.userEditedSource.asObservable();

  // Observable pour la détection des modifications de la photo de l'utilisateur. Enregistre la date de dernière modification.
  private userPictureEditedSource = new Subject<Date>();
  userPictureEdited$ = this.userPictureEditedSource.asObservable();

  // Observable pour la détection du login de l'utilisateur.
  private userLoggedSource = new Subject<Date>();
  userLogged$ = this.userLoggedSource.asObservable();

  // Observable pour la détection de la langue choisie dans le combo (attention c'est un filtre pour les vignettes: ce n'est pas la langue du compte)
  private languageFilterSource = new Subject<string>();
  lngFilter$ = this.languageFilterSource.asObservable();

  // Fonction qui déclare une modification de l'utilisateur. Met à jour l'observable.
  declareEdition() {
    return this.userEditedSource.next(new Date());
  }

  // Fonction qui déclare une modification de la photo l'utilisateur. Met à jour l'observable.
  declarePictureEdition() {
    return this.userPictureEditedSource.next(new Date());
  }

  // Fonction qui déclare que l'utilisateur vient de se logger. Met à jour l'observable.
  declareUserLogged() {
    return this.userLoggedSource.next(new Date());
  }

  // Fonction qui déclare une modification de la langue dans le combo. Met à jour l'observable.
   declareLanguageFilter(lng: string) {
    this.languageFilterSource.next(lng);
   }

  /**
   *
   * @param config
   * @param http
   * @param langService
   * @param currencyService
   * @param authenticationService
   * @param userDependenciesService
   */
  constructor(
    @Inject(ManagerUserConfigService) private config: ModuleLibraryConfigInt,
    private http: HttpClient,
    private langService: LangService,
    private currencyService: CurrencyService,
    private authenticationService: AuthenticationService,
    private userDependenciesService: UserDependenciesService) {
  }


  /**
   * Fonction qui enregistre un utilisateur dans la service.
   */
  init(user: User) {
    // On sauve les informations sur l'utilisateur
    this.user = user;
    // console.log("¤¤¤ this.currentUser.user:", this.user);

    // La devise préférée
    if (this.hasPreferredCurrencyInProfile()) {
      this.preferredCurrency = this.user.info.preferredCurrency;
    }
    // console.log("¤¤¤ this.preferredCurrency:", this.preferredCurrency);

    // On change la langue du site en fonction de l'utilisateur
    this.langService.changeLanguage(this.getUserPreferredLanguage());
  }

  /**
   * Fonction qui réinitialise le contenu du service
   */
  reset() {
    this.user = new User().initUser();
  }

  /**
   * Fonction qui détruit le contenu du service
   */
  clear() {
    this.user = undefined;
    let lang = window.navigator.language || (<any>window.navigator).userLanguage;
    i18next.changeLanguage(lang, (err, t) => {
      // resources have been loaded
    });
  }

  /**
   * Fonction affiche le nom complet de l'utilisateur.
   *
   * @param firstNameFist - boolean permettant de choisir le format d'affichage:
   *                  si true=>prénom-nom (par défaut), si false=>nom-prénom.
   */
  getFullName(firstNameFist: boolean = true): string {
    if (this.user && this.user.info) {
      if (!StringUtil.isUndefinedOrEmpty(this.user.info.firstName) &&
        !StringUtil.isUndefinedOrEmpty(this.user.info.lastName)) {
        return (firstNameFist) ?
          this.user.info.firstName + " " + this.user.info.lastName :
          this.user.info.lastName + " " + this.user.info.firstName;
      } else if (!StringUtil.isUndefinedOrEmpty(this.user.info.firstName)) {
        return this.user.info.firstName;
      } else if (!StringUtil.isUndefinedOrEmpty(this.user.info.lastName)) {
        return this.user.info.lastName;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  /**
   *
   */
  private hasPreferredCurrencyInProfile(): boolean {
    return (
      ObjectUtil.hasProperty(this.user, "info", "preferredCurrency") &&
      !StringUtil.isUndefinedOrEmpty(this.user.info.preferredCurrency));
  }

  /**
   * Fonction qui initialise les informations liées à la localisation.
   *
   * @param localization
   */
  setLocalisation(localization: Localization): void {
    this.userLocalization = localization;
    this.localCurrency = this.currencyService.getCurrencyFromLocalization(localization);
    if (!this.userChangedPreferredCurrency && !this.hasPreferredCurrencyInProfile()) {
      this.preferredCurrency = this.localCurrency;
    }
  }

  /**
   * Fonction permettant de changer la devise préféré.
   *
   * // TODO : A déplacer dans le shoppingCart service ?
   *
   * @param currency
   */
  changePreferredCurrency(currency: string): void {
    if (currency) {
      this.userChangedPreferredCurrency = true;
      this.preferredCurrency = currency;
    }
  }

  /**
   * Fonction qui indique qu'une localisation a été initialisée.
   *
   * @returns
   */
  hasLocalisation(): boolean {
    return (this._userLocalization !== undefined);  // le _ est important car le getter a été modifié
  }

  /**
   * La fonction de logout par défaut si elle n'a pas été définie dans le paramètre "this.changeUserLanguageFunction".
   */
  defaultChangeUserLanguage() {
    // TODO ???
  }

  /**
   * Fonction qui effectue les opérations nécessaires au changement de langue
   */
  changeUserLanguage() {
    if (this.config && this.config.data && ObjectUtil.isFunction(this.config.data.changeUserLanguageFunction)) {
      this.config.data.changeUserLanguageFunction.apply(this, arguments);
    } else {
      this.defaultChangeUserLanguage();
    }
  }

  /**
   * ===> PRÉFÉRER LA FONCTION getUserPreferredLanguage() POUR LA MAJORITÉ DES CAS ! <===
   *
   * ATTENTION: ne pas utiliser directement cette fonction. Elle doit être utilisée
   * en argument de la fonction this.langService.getLanguage(preferredLanguage):
   *      this.langService.getLanguage(this.getUserLanguage())
   *
   * Fonction qui retourne la langue de l'utilisateur.
   * Retourne undefined si aucune langue n'est trouvée pour l'utilisateur.
   */
  private getUserLanguage(): string {
    let lang = (this.user && this.user.info && !StringUtil.isUndefinedOrEmpty(this.user.info.language))
      ? this.user.info.language
      : undefined;
    return lang;
  }

  /**
   * Fonction qui retourne la langue préférée de l'utilisateur.
   */
  getUserPreferredLanguage(): string {
    return this.langService.getLanguage(this.getUserLanguage());
  }

  /**
   * fonction qui retourne la devise préférée de l'utilisateur
   *
   * @returns
   */
  getUserCurrency(): string {
    if (this.preferredCurrency) {
      return this.preferredCurrency;
    } else if (this.localCurrency) {
      return this.localCurrency;
    } else {
      return this.currencyService.defaultServerCurrencyCode;
    }
  }

  /**
   * Fonction qui récupère le prix d'un objet selon la devise sélectionnée.
   *
   * @param itemPrice Contient le prix dans plusieurs devises. Format :
   *                    Objet : {EUR: 10, USD: 20, XAF: 30}
   *                      ou
   *                    Tableau : [{currency: "EUR", price: 10}, {currency: "USD", price: 20}, {currency: "XAF", price: 30}]
   * @returns
   * @throws AipError
   */
  getPriceInUserCurrency(itemPrice: any): number {
    // On affiche le prix dans la devise de l'utilisateur
    let price: number;
    if (ObjectUtil.isObject(itemPrice) && ObjectUtil.hasProperty(itemPrice, this.getUserCurrency())) {
      // Le prix est au format objet : {EUR: 10, USD: 20, XAF: 30}
      price = itemPrice[this.getUserCurrency()];
    } else if (ObjectUtil.isArray(itemPrice)) {
      // Le prix est au format tableau : [{currency: "EUR", price: 10}, {currency: "USD", price: 20}, {currency: "XAF", price: 30}]
      let priceObj = itemPrice.find(prix => prix.currency === this.getUserCurrency());
      if (priceObj) {
        price = priceObj.price;
      } else {
        // On ne fait rien
      }
    } else {
      // On ne fait rien
    }

    /*
    if (price === undefined) { // Modification par Ryad controlee par Idriss
      // On n'a pas trouvé le prix dans la devise de l'utilisateur => devise par défaut
      price = itemPrice[this.currencyService.defaultServerCurrencyCode];
    }
    */
    //if (price === undefined) { // Modification par Ryad controlee par Idriss
    if (!ObjectUtil.isNumeric(price)) {
      // On n'a pas trouvé le prix du tout !
      throw new AipError(
        ConfigurationProperties.cli_error_type_fatal,
        errorProperties.ERROR_PAYMENT_CURRENCY_NOT_FOUND,
        i18next.t('error.title'),
        i18next.t('error.paymentCurrencyNotFound.subTitle'),
        i18next.t('error.paymentCurrencyNotFound.message'),
        ErrorStatusCode.INTERNAL_ERROR);
    } else {
      return price;
    }
  }

  /**
   * Fonction qui retourne le nombre de point d'un utilisateur.
   *
   * @returns
   * @throws AipError
   */
  getPoints(): number {
    let points: number = 0;
    if (this.user && this.user.atel && this.user.atel.teachings) {
      this.user.atel.teachings.forEach(userTeaching => {
        if (userTeaching.status.active && !userTeaching.status.deleted && userTeaching.info &&
          userTeaching.info.points && ObjectUtil.isNumeric(userTeaching.info.points)) {
          points += userTeaching.info.points;
        }
      });
    }
    return points;
  }

  /**
   * Fonction qui retourne sous forme de string le prix dans la devise spécifiée.
   * Utilisé pour l'affichage.
   *
   * ATTENTION : PROPAGE l'exception lancée par "getUserCurrencySymbol()".
   *
   * @param price
   * @returns
   * @throws AipError
   */
  displayPriceInUserCurrency(price: number): string {
    try {
      return this.currencyService.displayPriceInSelectedCurrency(price, this.getUserCurrency());
    } catch (err) {  // Le throw est implicite mais c'est plus claire comme cela.
      throw err;
    }
  }

  /**
   * Fonction qui retourne le userTeaching de la librairie de l'utilisateur à partir de son _id.
   * Retourne undefined si l'élément n'a pas été trouvé.
   *
   * @returns
   * @throws AipError
   */
  getUserTeachingFromId(_id: string): UserTeachingDb {
    if (StringUtil.isUndefinedOrEmpty(_id)) {
      return undefined;
    } else {
      return this.user.atel.teachings.find(userTeaching => {
        return (userTeaching._id === _id);
      });
    }
  }

  /**
   * Fonction qui retourne le userTeaching de la librairie de l'utilisateur à partir de l'_id du teaching.
   * Retourne undefined si l'élément n'a pas été trouvé.
   *
   * @returns
   * @throws AipError
   */
  getUserTeachingFromTeachingId(_id: string): UserTeachingDb {
    if (
      !ObjectUtil.hasProperty(this.user, "atel", "teachings") ||
      !ObjectUtil.isArray(this.user.atel.teachings) ||
      StringUtil.isUndefinedOrEmpty(_id)
    ) {
      return undefined;
    } else {
      // On recherche l'élément
      return this.user.atel.teachings.find(userTeaching => (
        ObjectUtil.hasProperty(userTeaching, 'teaching_ref') &&
        (userTeaching.teaching_ref === _id) &&
        (userTeaching.isUserTeachingValid(false))
      ));
    }
  }

  /**
   * Fonction qui récupère les achats de l'utilisateur courant.
   *
   * @returns
   */
  public getOrders(paginateOptions?: PaginateOptions): Observable<{ collection: Array<Order>, totalNbElements: number }> {
    return this.http.post<{ collection: Array<Order>, totalNbElements: number }>(
      appConfigurationProperties.URL_SERVER_GET_ORDERS,
      {
        paginateOptions: paginateOptions
      },
      {
        headers: this.authenticationService.generateHeadersForProtectedRoutes(
          this.getUserPreferredLanguage())
      }
    )
    // .map(res => <any> res.json());
    // .catch(ErrorUtil.handleError);
    // .catch(err => Observable.throw(err));
  }

  /**
   * Fonction qui récupère les notifications de l'utilisateur courant.
   *
   * @param fromLastViewDate Valeurs possibles : true, false, undefined
   * @param notificationBell Valeurs possibles : true, false, undefined
   * @param paginateOptions
   */
  public getNotifications(
    fromLastViewDate: boolean, notificationBell: boolean, paginateOptions?: PaginateOptions
  ): Observable<{ collection: Array<Order>, totalNbElements: number }> {
    return this.http.post<{ collection: Array<Order>, totalNbElements: number }>(
      appConfigurationProperties.URL_SERVER_NOTIFICATIONS_GET,
      {
        fromLastViewDate,
        notificationBell,
        paginateOptions: paginateOptions
      },
      {
        headers: this.authenticationService.generateHeadersForProtectedRoutes(
          this.getUserPreferredLanguage())
      }
    )
    // .map(res => <any> res.json());
    // .catch(ErrorUtil.handleError);
    // .catch(err => Observable.throw(err));
  }

  /**
   *
   * @param lang
   * @param timezone
   * @param dst
   */
  changeUserPreferencesOnServer(
    lang: string,
    timezone?: string,
    dst?: boolean): Observable<{ language: string, timezone: string, dst: boolean }> {
    let url = `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${appConfigurationProperties.URL_SERVER_CHANGE_USER_PERFERENCES}/${lang}`;
    if (!StringUtil.isUndefinedOrEmpty(timezone)) {
      url += `/${timezone}`;
      if (ObjectUtil.isBoolean(dst)) {
        url += `/${dst.toString()}`;
      }
    }
    return this.http.get<{ language: string, timezone: string, dst: boolean }>(
      url,
      {
        headers: this.authenticationService.generateHeadersForUnprotectedRoutes(lang)
      })
  }

  /**
   *
   */
  notificationsBellSeen(): Observable<{ notificationsViewDate: Date }> {
    return this.http.get<{ notificationsViewDate: Date }>(
      `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${appConfigurationProperties.URL_SERVER_NOTIFICATIONS_BELL_SEEN}`,
      {
        headers: this.authenticationService.generateHeadersForUnprotectedRoutes()
      })
  }

  /**
   * Fonction qui fait une demande de rafraichissement des informations de l'utilisateur.
   * Retourne un observable un objet contenant le token utilisateur sous forme de string.
   *
   * @param rebuildDependencies - Si false, ne reconstruit pas les dépendances de l'utilisateur.
   *                              Si true, reconstruit les dépendances de l'utilisateur.
   *                              Default: true.
   * @returns
   */
  public reloadUser(rebuildDependencies: boolean = true): Observable<{ user_token: string }> {
    const url = (rebuildDependencies) ? this.config.data.urlReloadUser : `${this.config.data.urlReloadUser}?atelDeps=false&atpDeps=false`;
    return this.http.get<{ user_token: string }>(
      url,
      {
        headers: this.authenticationService.generateHeadersForProtectedRoutes(
          this.getUserPreferredLanguage())
      })
    // .map(res => <any> res.json());
    // .catch(ErrorUtil.handleError);
    // .catch(err => Observable.throw(err));
  }

  /**
   * Fonction génère l'entête pour les routes protégé par des droits d'accès (utilisateur connecté par exemple).
   * Cette fonction est équivalente à :
   *      this.authenticationService.generateHeadersForProtectedRoutes(
   *            this.langService.getLanguage(this.currentUser.getUserLanguage()))
   * où
   *      authenticationService, langService et currentUserService sont des services
   * @param contentType
   * @deprecated Le token d'authentification est ajouté directement dans l'intercepteur http "authentication.interceptor.ts"
   * @returns
   */
  public generateHeadersForProtectedRoutes(contentType: string | ContentType = ContentType.APPLICATION_JSON): HttpHeaders {
    return this.authenticationService.generateHeadersForProtectedRoutes(
      this.getUserPreferredLanguage(),
      contentType);
  }

  /**
   * Fonction génère l'entête pour les routes non protégées par des droits d'accès.
   * Cette fonction est équivalente à :
   *      this.authenticationService.generateHeadersForUnprotectedRoutes(
   *            this.getUserPreferredLanguage())
   * où
   *      authenticationService, langService et currentUserService sont des services
   *
   * @param contentType
   * @returns
   */
  public generateHeadersForUnprotectedRoutes(contentType: string | ContentType = ContentType.APPLICATION_JSON): HttpHeaders {
    return this.authenticationService.generateHeadersForUnprotectedRoutes(
      this.getUserPreferredLanguage(),
      contentType);
  }


  /**
   * Fonction génère l'entête pour les routes non protégées par des droits d'accès.
   * Cette fonction est équivalente à :
   *      this.authenticationService.generateHeadersForRoutes(
   *            this.languageService.getLanguage(this.currentUser.getUserLanguage()))
   * où
   *      authenticationService, languageService et currentUserService sont des services
   *
   * @param contentType
   * @returns
   */
  public generateHeadersForRoutes(contentType: string | ContentType = ContentType.APPLICATION_JSON): HttpHeaders {
    return this.authenticationService.generateHeadersForRoutes(
      this.getUserPreferredLanguage(),
      contentType);
  }

  /**
   * Fonction qui retourne l'url de l'image du profile de l'utilisateur.
   */
  public getPictureUrl(): string {
    const clearfix = UrlUtil.generateStaticUrlVersion(this.user);
    return UserUtil.getPictureUrl(this.user, clearfix);
  }

  /**
   * Fonction qui traduit les dropdowns.
   */
  public getTranslatedDropdown(
    dropdownElements: {code: string, name: string}[],
    dropdownType: string): {code: string, name: string}[] {

    return dropdownElements
      .map(
        elem => {
          // Traduction du nom
          elem.name = i18next.t(`aip-enumTypes:${dropdownType}.${elem.code}`);
          return elem;
        })
      .sort((a, b) => {
        const language = this.langService.language;
        // console.log('RRRRRRRRRRRRRRRRR language:', language);
        if (
          ObjectUtil.hasProperty(a, 'name') && ObjectUtil.isString(a.name) &&
          ObjectUtil.hasProperty(b, 'name') && ObjectUtil.isString(b.name)) {
          return a.name.localeCompare(b.name, language, {sensitivity: 'base'});
        } else if (
          ObjectUtil.hasProperty(a, 'code') && ObjectUtil.isString(a.code) &&
          ObjectUtil.hasProperty(b, 'code') && ObjectUtil.isString(b.code)) {
          return a.code.localeCompare(b.code, language, {sensitivity: 'base'});
        } else {
          return 0;
        }
      });
  }

}
