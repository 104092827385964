import {appConfigurationProperties} from '../../config/appConfigurationProperties';
import {CatalogueItemType} from '../../data/catalogueProduct/CatalogueItemType';

export class ElearningUtil {

  /**
   * Fonction qui retourne l'url du serveur par défaut des images des entités elearning.
   */
  public static getDefaultImageUrl(category: CatalogueItemType | string): string {
    if ((category === CatalogueItemType.LESSON) || (category === appConfigurationProperties.PAYMENT_PRODUCT_LESSON)) {
      return `${appConfigurationProperties.URL_SERVER_AIP_ATEL}/${appConfigurationProperties.URL_SERVER_ELEARNING_DEFAULT_IMAGE_LESSON()}`;
    } else if ((category === CatalogueItemType.QUIZ) || (category === appConfigurationProperties.PAYMENT_PRODUCT_QUIZ)) {
      return `${appConfigurationProperties.URL_SERVER_AIP_ATEL}/${appConfigurationProperties.URL_SERVER_ELEARNING_DEFAULT_IMAGE_QUIZ()}`;
    } else if ((category === CatalogueItemType.COURSE) || (category === appConfigurationProperties.PAYMENT_PRODUCT_COURSE)) {
      return `${appConfigurationProperties.URL_SERVER_AIP_ATEL}/${appConfigurationProperties.URL_SERVER_ELEARNING_DEFAULT_IMAGE_COURSE()}`;
    } else if ((category === CatalogueItemType.DEGREE) || (category === appConfigurationProperties.PAYMENT_PRODUCT_DEGREE)) {
      return `${appConfigurationProperties.URL_SERVER_AIP_ATEL}/${appConfigurationProperties.URL_SERVER_ELEARNING_DEFAULT_IMAGE_DEGREE()}`;
    } else if (category === CatalogueItemType.SOURCE) {
      return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${appConfigurationProperties.URL_SERVER_COMMON_DEFAULT_IMAGE_SOURCE()}`;
    } else {
      return `${appConfigurationProperties.URL_SERVER_AIP_ATEL}/${appConfigurationProperties.URL_SERVER_ELEARNING_DEFAULT_IMAGE_LESSON()}`;
    }
  }

  /**
   * Fonction qui retourne l'url de l'ui par défaut des détails des entités elearning.
   */
  public static getDetailUrl(category: CatalogueItemType | string): string {
    if ((category === CatalogueItemType.LESSON) || (category === appConfigurationProperties.PAYMENT_PRODUCT_LESSON)) {
      return appConfigurationProperties.URL_UI_ATEL_DETAIL_SKILL;
    } else if ((category === CatalogueItemType.QUIZ) || (category === appConfigurationProperties.PAYMENT_PRODUCT_QUIZ)) {
      return appConfigurationProperties.URL_UI_ATEL_DETAIL_QUIZ;
    } else if ((category === CatalogueItemType.COURSE) || (category === appConfigurationProperties.PAYMENT_PRODUCT_COURSE)) {
      return appConfigurationProperties.URL_UI_ATEL_DETAIL_COURSE;
    } else if ((category === CatalogueItemType.DEGREE) || (category === appConfigurationProperties.PAYMENT_PRODUCT_DEGREE)) {
      return appConfigurationProperties.URL_UI_ATEL_DETAIL_DEGREE;
    } else {
      return appConfigurationProperties.URL_UI_HOME;
    }
  }

  /**
   * Fonction qui transforme un type payment d'entité elearning vers un type catalogue.
   */
  public static paymentTypeToCatalogueType(type: string): CatalogueItemType {
    switch (type) {
      case appConfigurationProperties.PAYMENT_PRODUCT_LESSON:
        return CatalogueItemType.LESSON;
      case appConfigurationProperties.PAYMENT_PRODUCT_QUIZ:
        return CatalogueItemType.QUIZ;
      case appConfigurationProperties.PAYMENT_PRODUCT_COURSE:
        return CatalogueItemType.COURSE;
      case appConfigurationProperties.PAYMENT_PRODUCT_DEGREE:
        return CatalogueItemType.DEGREE;
      default:
        return undefined;
    }
  }

}
