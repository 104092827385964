import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {ObjectUtil} from "../../ng-helpers-util/object.util";
import {NotificationsService} from "angular2-notifications";
import {Router} from "@angular/router";
import {SessionService} from "../../ng-manager-user/services/user-session.service";
import {AipError} from "../../ng-models-ui";
import {ErrorService} from "../../ng-module-error/services/error.service";

/**
 * Etendre cette classe générique pour la affecter le paramètre "excludedUrls"
 * avec des valeurs de configuration spécique au projet.
 *
 * La liste des urls non traité par cet intercepteur se trouve dans le fichier :
 *    ngui/src/app/utils/interceptors/custom-errors.interceptor.ts
 */
@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  protected excludedUrls: string[] = [];  // Liste des urls non générées par cette intercepteur

  /**
   *
   * @param router
   * @param notifications
   * @param errorService
   * @param sessionService
   */
  constructor(
    protected router: Router,
    protected notifications: NotificationsService,
    protected errorService: ErrorService,
    protected sessionService: SessionService) {
  }

  /**
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      // retry(2),  // On ne réessait pas une deuxième fois en cas d'erreur
      catchError((err: HttpErrorResponse) => {
        if(ObjectUtil.isArray(this.excludedUrls) && !this.excludedUrls.includes(err.url)) {
          // Transformation des erreurs reçues du serveur en AipError
          let errors: AipError[] = [];
          if (ObjectUtil.isArray(err.error)) {
            // Erreur formattée par le serveur
            errors = err.error.map(item => {return AipError.convertToAipError(item)});
          } else {
            errors = [AipError.convertToAipError(err)]
          }
          // Traitement de l'erreur
          this.errorService.displayError(errors);
        }
        return throwError(err);
      })
    );
  }

  /*
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      console.log("RRRRRRRRRRRRRRR");
      return next.handle(req);
    }
  */

}
