import {Injectable} from "@angular/core";
import {ShoppingCart} from '../../../ng-models-ats/models/ShoppingCart';
import {StringUtil} from "../../../ng-helpers-util/string.util";
import {ObjectUtil} from "../../../ng-helpers-util/object.util";
import {ShoppingCartUtil} from "../../../ng-helpers-util/shoppingCart.util";
import {appConfigurationProperties} from "../../../../config/appConfigurationProperties";

/**
 * Classe contenant les données du cart partagée entre les services :
 *  - ShoppingCartAdocService
 *  - ShoppingCartAtelService
 */
@Injectable()
export class ShoppingCartServiceDataContent {

  private _shoppingCart: ShoppingCart;        // Attention doit normalement être récupéré à l'aide d'un observable !
  private _shoppingCartProducts: any[] = [];  // Attention doit normalement être récupéré à l'aide d'un observable !
  private _lastShoppingDate: Date;      // Date du dernier achat
  private _lastLibraryRealodDate: Date; // Date de la derniere rechargement de la librairie suite à un achat
  private _paymentSuccessMessage: string;  // Le message a afficher à l'utilisateur quand le paiement à eu lieu
  private _paymentErrorMessage: string;  // Le message a afficher à l'utilisateur quand une erreur de paiement à eu lieu
  // private _corporateUsersToAssignAfterPaymentByProduct: any[] = []; // Pour chaque produit utilisateurs a assigner apres le paiement lors des operations de renew de l'UI corporate

  get shoppingCart(): ShoppingCart {
    return this._shoppingCart;
  }

  set shoppingCart(data: ShoppingCart) {
    this._shoppingCart = data;
  }

  /*
  get corporateUsersToAssignAfterPaymentByProduct(): any[] {
    return this._corporateUsersToAssignAfterPaymentByProduct;
  }

  set corporateUsersToAssignAfterPaymentByProduct(data: any[]) {
    this._corporateUsersToAssignAfterPaymentByProduct = data;
  }
  */

  get shoppingCartProducts(): any[] {
    return this._shoppingCartProducts;
  }

  set shoppingCartProducts(data: any[]) {
    this._shoppingCartProducts = data;
  }

  set lastShoppingDate(date: Date) {
    this._lastShoppingDate = date;
  }

  get paymentSuccessMessage(): string {
    return this._paymentSuccessMessage;
  }

  set paymentSuccessMessage(data: string) {
    this._paymentSuccessMessage = data;
  }

  get paymentErrorMessage(): string {
    return this._paymentErrorMessage;
  }

  set paymentErrorMessage(data: string) {
    this._paymentErrorMessage = data;
  }

  /**
   * Constructeur.
   */
  constructor() {
  }

  /**
   * Fonction qui reset les messages de succès et d'erreur du service.
   */
  public resetMessages(): void {
    this.paymentSuccessMessage = undefined;
    this.paymentErrorMessage = undefined;
  }

  /**
   * Fonction qui retourne le nombre d'article de la copie local du panier.
   *
   * @returns
   */
  public getProductQuantity(): number {
    if (this.shoppingCart && this.shoppingCart.totalQuantity) {
      return this.shoppingCart.totalQuantity;
    } else {
      return 0;
    }
  }

  /**
   * Fonction qui retourne les devises applicables pour le total du panier.
   */
  public getCartCurrencies(): Array<string> {
    if (this.shoppingCart && this.shoppingCart.totalPrice) {
      return Object.keys(this.shoppingCart.totalPrice);
    } else {
      return [];
    }
  }

  /**
   * Fonction qui vérifie si un produit d'_id spécifié est dans la liste des produits.
   * La recherche se fait dans le panier en local et non sur le serveur.
   *
   * @param _id
   */
  public hasProduct(_id: string): boolean {
    if (
      !StringUtil.isUndefinedOrEmpty(_id) &&
      ObjectUtil.isArray(this.shoppingCartProducts)) {
      return (this.shoppingCartProducts.find(elem => elem.item._id === _id) !== undefined);
    } else {
      return false;
    }
  }

  /**
   * Fonction qui retourne true si le panier a des produits qui ne sont pas des produits database.
   */
  public hasNonDatabaseProducts(): boolean {
    if (this.shoppingCartProducts) {
      return this.shoppingCartProducts.some((product => {
          let productType;
          if (ObjectUtil.hasProperty(product, 'type') &&
            !StringUtil.isUndefinedOrEmpty(product.type)) {
            productType = product.type;
          } else if (ObjectUtil.hasProperty(product, 'key') &&
            !StringUtil.isUndefinedOrEmpty(product.key)) {
            productType = ShoppingCartUtil.parseShoppingCartKey(product.key).type;
          }
          switch (productType) {
            case appConfigurationProperties.PAYMENT_PRODUCT_COUNTRY:
            case appConfigurationProperties.PAYMENT_PRODUCT_COMPANY:
            case appConfigurationProperties.PAYMENT_PRODUCT_ZONE:
              return false;
            default:
              return true;
          }
        })
      );
    } else {
      return false;
    }
  }

  /**
   * Fonction qui retourne true si le panier a des produits database.
   */
  public hasDatabaseProducts(): boolean {
    if (this.shoppingCartProducts) {
      return this.shoppingCartProducts.some((product => {
          let productType;
          if (ObjectUtil.hasProperty(product, 'type') &&
            !StringUtil.isUndefinedOrEmpty(product.type)) {
            productType = product.type;
          } else if (ObjectUtil.hasProperty(product, 'key') &&
            !StringUtil.isUndefinedOrEmpty(product.key)) {
            productType = ShoppingCartUtil.parseShoppingCartKey(product.key).type;
          }
          switch (productType) {
            case appConfigurationProperties.PAYMENT_PRODUCT_COUNTRY:
            case appConfigurationProperties.PAYMENT_PRODUCT_COMPANY:
            case appConfigurationProperties.PAYMENT_PRODUCT_ZONE:
              return true;
            default:
              return false;
          }
        })
      );
    } else {
      return false;
    }
  }

  /**
   * Fonction qui retourne true si le panier a des produits qui ne sont pas des produits elearning.
   */
  public hasNonElearningProducts(): boolean {
    if (this.shoppingCartProducts) {
      return this.shoppingCartProducts.some((product => {
          let productType;
          if (ObjectUtil.hasProperty(product, 'type') &&
            !StringUtil.isUndefinedOrEmpty(product.type)) {
            productType = product.type;
          } else if (ObjectUtil.hasProperty(product, 'key') &&
            !StringUtil.isUndefinedOrEmpty(product.key)) {
            productType = ShoppingCartUtil.parseShoppingCartKey(product.key).type;
          }
          switch (productType) {
            case appConfigurationProperties.PAYMENT_PRODUCT_DEGREE:
            case appConfigurationProperties.PAYMENT_PRODUCT_COURSE:
            case appConfigurationProperties.PAYMENT_PRODUCT_LESSON:
            case appConfigurationProperties.PAYMENT_PRODUCT_QUIZ:
              return false;
            default:
              return true;
          }
        })
      );
    } else {
      return false;
    }
  }

  /**
   * Fonction qui retourne true si le panier a des produits elearning.
   */
  public hasElearningProducts(): boolean {
    if (this.shoppingCartProducts) {
      return this.shoppingCartProducts.some((product => {
          let productType;
          if (ObjectUtil.hasProperty(product, 'type') &&
            !StringUtil.isUndefinedOrEmpty(product.type)) {
            productType = product.type;
          } else if (ObjectUtil.hasProperty(product, 'key') &&
            !StringUtil.isUndefinedOrEmpty(product.key)) {
            productType = ShoppingCartUtil.parseShoppingCartKey(product.key).type;
          }
          switch (productType) {
            case appConfigurationProperties.PAYMENT_PRODUCT_DEGREE:
            case appConfigurationProperties.PAYMENT_PRODUCT_COURSE:
            case appConfigurationProperties.PAYMENT_PRODUCT_LESSON:
            case appConfigurationProperties.PAYMENT_PRODUCT_QUIZ:
              return true;
            default:
              return false;
          }
        })
      );
    } else {
      return false;
    }
  }

  /**
   * Fonction qui retourne true si le panier a des produits qui ne sont pas des produits publishing.
   */
  public hasNonPublishingProducts(): boolean {
    if (this.shoppingCartProducts) {
      return this.shoppingCartProducts.some((product => {
          let productType;
          if (ObjectUtil.hasProperty(product, 'type') &&
            !StringUtil.isUndefinedOrEmpty(product.type)) {
            productType = product.type;
          } else if (ObjectUtil.hasProperty(product, 'key') &&
            !StringUtil.isUndefinedOrEmpty(product.key)) {
            productType = ShoppingCartUtil.parseShoppingCartKey(product.key).type;
          }
          switch (productType) {
            case appConfigurationProperties.PAYMENT_PRODUCT_PUBLICATION:
              return false;
            default:
              return true;
          }
        })
      );
    } else {
      return false;
    }
  }

  /**
   * Fonction qui retourne true si le panier a des produits publishing.
   */
  public hasPublishingProducts(): boolean {
    if (this.shoppingCartProducts) {
      return this.shoppingCartProducts.some((product => {
          let productType;
          if (ObjectUtil.hasProperty(product, 'type') &&
            !StringUtil.isUndefinedOrEmpty(product.type)) {
            productType = product.type;
          } else if (ObjectUtil.hasProperty(product, 'key') &&
            !StringUtil.isUndefinedOrEmpty(product.key)) {
            productType = ShoppingCartUtil.parseShoppingCartKey(product.key).type;
          }
          switch (productType) {
            case appConfigurationProperties.PAYMENT_PRODUCT_PUBLICATION:
              return true;
            default:
              return false;
          }
        })
      );
    } else {
      return false;
    }
  }

  /**
   * Fonction retourne la productKey d'un item du panier à partir de sont _id.
   * Retourne undefined si le produit n'a pas été trouvé dans le panier.
   *
   * @param _id
   */
  public getProductKeyFromId(_id: string): string {
    if (
      !StringUtil.isUndefinedOrEmpty(_id) &&
      ObjectUtil.isArray(this.shoppingCartProducts)) {
      let product = this.shoppingCartProducts.find(elem => elem.item._id === _id);
      return (product) ? product.key : undefined;
    } else {
      return undefined;
    }
  }

  /**
   * Fonction qui indique que l'on doit recharge la librairie suite à un achat.
   */
  public reloadLibrary(): boolean {
    const reload = (
      (this._lastShoppingDate !== undefined) &&
      ((this._lastLibraryRealodDate === undefined) ||
        (this._lastLibraryRealodDate < this._lastShoppingDate)));
    if (reload) {
      this._lastLibraryRealodDate = new Date();
    }
    return reload;
  }
}
