/**
 * Les status d'erreur de la plate-forme.
 */
export enum ErrorStatusCode {   // L'attribution de valeur aux enums n'est pas obligatoire
  ACCESS_DENIED = 401,
  PAGE_NOT_FOUND = 404,
  EXPECTATION_FAILED = 417,   // Erreurs non classifiées
  TEAPOT = 418,               // Erreurs non classifiées
  INTERNAL_ERROR = 500
}

export enum ErrorType {   // L'attribution de valeur aux enums n'est pas obligatoire
  FATAL = 1,
  NOTIFICATION = 2
}

/**
 * Les codes d'erreur de la plate-forme.
 * Les code pour l'interface angular sont précédés par un "UI-"
 */
// @dynamic
export const errorProperties = {

  // Pour les erreurs inconnues
  ERROR_UNKNOWN: "UI-100",

  // Pour les paramètres
  ERROR_PARAMETERS_INVALID: "UI-20",

  // Pour les erreurs de session
  ERROR_SESSION_INVALID: "UI-300",
  ERROR_SESSION_LOGOUT_REQUESTED: "UI-301",

  // Pour les images non trouvées
  ERROR_IMAGE_NOT_FOUND: "UI-400",

  // Pour les erreurs du panier
  ERROR_PAYMENT_EMPTY_SHOPPING_CART: "UI-500",
  ERROR_PAYMENT_CURRENCY_NOT_FOUND: "UI-501",
  ERROR_PAYMENT_SHOPPING_CART_INVALID_ENTITIES: "UI-502",
  ERROR_PAYMENT_UNKNOWN_WIZARD_STEP: "UI-503",
  ERROR_PAYMENT_NO_ACTION: "UI-504",
  ERROR_PAYMENT_NO_PLAN: "UI-505",
  ERROR_PAYMENT_NO_PAYMENT: "UI-506",

  // Pour l'authentification
  ERROR_AUTHENTICATE_NO_CREDENTIALS: "UI-2001",
  ERROR_ACCESS_DENY: "UI-2002",

};
