import {ObjectUtil} from './object.util';
import {StringUtil} from './string.util';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";

export class UrlUtil {

  /**
   * Fonction qui ouvre un nouvel onglet avec une url spécifique.
   *
   * @param url Peut être un string ou un objet précisant des urls différentes si urls doivent être
   *            diffentes si un nouvel onglet est ouvert.
   * @param openInCurrentTabIfBlocked Automatiquement sur true si la propriété 'url' est un objet.
   */
  public static windowOpenBlank(
    url: string | {sameTabUrl: string, newTabUrl: string},
    openInCurrentTabIfBlocked = true) {
    // Traitement des paramètres
    let _url: {sameTabUrl: string, newTabUrl: string};
    if (ObjectUtil.isString(url)) {
      _url = {
        sameTabUrl: url as string,
        newTabUrl: url as string
      }
    } else {
      _url = url as {sameTabUrl: string, newTabUrl: string};
      openInCurrentTabIfBlocked = true;
    }
    // Ouverture de l'url
    const newWin = window.open(_url.newTabUrl, '_blank');
    if (openInCurrentTabIfBlocked &&
        (!newWin || newWin.closed || typeof newWin.closed === 'undefined')) {
      // Le popup sont bloquée => on ouvre dans la fenêtre actuelle
      window.location.href = _url.sameTabUrl;
    }
  }

  /**
   * Fonction qui ouvre une url spécifique dans l'onglet courant.
   */
  public static windowOpenSelf(url: string) {
    window.open(url, '_self');
  }

  /**
   * Fonction qui retourne l'origine l'url (http://www.example.com:8080).
   *
   * @returns
   */
  public static getOrigin(): string {
    return (
      window.location.origin ||   // falldown si window.location.origin n'est pas reconnu (ie)
      window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : ''));
  }

  /**
   * Fonction qui retourne le base name de l'url.
   *
   * @returns
   */
  public static getBasename(): string {
    return window.location.pathname.split('/')[1] || '';
  }

  /**
   * Fonction qui retourne le domaine name.
   *
   * @returns
   */
  public static getDomainName(): string {
    return window.location.hostname;
  }

  /**
   * Fonction qui retourne l'url passée en paramètre sans le caractère '/' en début de chaîne s'il est présent.
   * S'il y a plusieurs '/', ils seront tous supprimés (récursion).
   *
   * @param url
   * @returns
   */
  public static removeFirstCharacterIfSlash(url: string): string {
    if (ObjectUtil.isString(url) && !StringUtil.isUndefinedOrEmpty(url) && (url[0] === '/')) {
      return this.removeFirstCharacterIfSlash(url.substr(1));
    } else {
      return url;
    }
  }

  /**
   * Fonction qui change l'url du navigateur sans recharger la page.
   *
   * @param router
   * @param location
   * @param commands - Les parties de l'url. Exemples :
   *      ['/team', 33, 'user', 11] => /team/33/user/11
   *      ['/team', 33, {expand: true}, 'user', 11] => /team/33;expand=true/user/11
   *      [{segmentPath: '/one/two'}]
   *      ['/team', 33, {outlets: {primary: 'user/11', right: 'chat'}}]  => /team/33/(user/11//right:chat)
   *      ['/team', 33, {outlets: {primary: 'user/11', right: null}}]
   * @param relativeTo - Permet de rendre l'url relative. Exemples si l'url courante est `/team/33/user/11` et route pointe vers `user/11`
   *      (le premier tableau dans les exmples suivants est l'argument 'commands')
   *      ['details'], {relativeTo: route}  => /team/33/user/11/details
   *      ['../22'], {relativeTo: route} => /team/33/user/22
   *      ['../../team/44/user/22'], {relativeTo: route} => /team/44/user/22
   * @param queryParams
   */
  public static changeUrl(
    router: Router,
    location: Location,
    commands: any[],
    relativeTo?: ActivatedRoute,
    queryParams?: any): void {
    const url = router.createUrlTree(
      commands,
      {relativeTo: relativeTo, queryParams: queryParams}).toString();
    // console.log("CCCCCCCCCCCCCCCC url:", url);
    // On change l'url dans le navigateur si elle est différente de l'url actuelle
    const fullUrl = `${UrlUtil.getOrigin()}/${UrlUtil.getBasename()}${url}`;
    if (fullUrl !== window.location.href) {
      location.go(url);
    } else {
      location.replaceState(url);
    }
  }

  /**
   *
   * @param location
   * @param path
   * @param query
   * @param state
   */
  public static replaceUrl(
    location: Location, path: string, query?: string, state?: any): void {
    // window.history.replaceState({}, '', path);
    location.replaceState(path, query, state);
  }

  /**
   *
   * @param url
   */
  public static hasUrlParameters(url: string): boolean {
    var arr = url.split('?');
    return ((arr.length > 1) && (arr[1] !== ''));
  }

  /**
   *
   * @param url
   * @param parameterName
   * @param parameterValue
   */
  public static addOReplaceParameterInUrl(
    url: string, parameterName, parameterValue): string {
    if (StringUtil.isUndefinedOrEmpty(url) || StringUtil.isUndefinedOrEmpty(parameterName)) {
      return url;
    } else {
      if (parameterValue == null) {
        parameterValue = '';
      }
      var pattern = new RegExp('\\b('+parameterName+'=).*?(&|#|$)');
      if (url.search(pattern)>=0) {
        return url.replace(pattern,'$1' + parameterValue + '$2');
      }
      url = url.replace(/[?#]$/,'');
      return url + (url.indexOf('?')>0 ? '&' : '?') + parameterName + '=' + parameterValue;
    }
  }

  /**
   * Fonction qui retourne le numéro de version d'un élément.
   * Par défaut la fonction utilise la propriété 'propertyModificationDate' de l'objet pour déterminer le numéro de version.
   * Si la propriété n'est pas trouvée, la fonction utilise la propriété 'propertyCreationDate'.
   *
   * Le paramètre 'defaultVersion' permet de fixer le comportement à adopter si aucun numéro de version ne peut être détermnié :
   *    - pour générer un numéro de version permettant de recharger systèmatiqement le fichier static du serveur,
   *      mettre le paramètre 'defaultVersion' à undefined.
   *    - pour ne jamais recharger le fichier static du serveur, utiliser une chaine vide (''), le fichier sera
   *      toujours pris du cache du navigateur.
   *    - pour fixé un numéro de version spécifique saisir une chaine autre qu'une chaine vide ou undefined.
   *
   * @param element
   * @param defaultVersion fixe le numéro version par défaut (la date actuelle si undefined)
   * @param propertyModificationDate
   * @param propertyCreationDate
   */
  public static generateStaticUrlVersion(
    element,
    defaultVersion: string = `${new Date().getTime()}`,
    propertyModificationDate: string = 'status.modificationDate',
    propertyCreationDate: string = 'status.creationDate'): string {
    // On détermine le numéro de version
    let version = '';
    if (!StringUtil.isUndefinedOrEmpty(propertyModificationDate) && !StringUtil.isUndefinedOrEmpty(propertyCreationDate)) {
      if (ObjectUtil.hasProperty(element, ...(propertyModificationDate.split("."))) &&
        ObjectUtil.isDate(new Date(ObjectUtil.getValueByPath(element, propertyModificationDate)))) {
        version = `${new Date(ObjectUtil.getValueByPath(element, propertyModificationDate)).getTime()}`;
      } else if (ObjectUtil.hasProperty(element, ...(propertyCreationDate.split("."))) &&
        ObjectUtil.isDate(new Date(ObjectUtil.getValueByPath(element, propertyCreationDate)))) {
        version = `${new Date(ObjectUtil.getValueByPath(element, propertyCreationDate)).getTime()}`;
      }
    }
    // On retourne le résultat
    if (StringUtil.isUndefinedOrEmpty(version)) {
      return (StringUtil.isUndefinedOrEmpty(defaultVersion)) ? '' : `?v${defaultVersion}`;
    } else {
      return `?v${version}`;
    }
  }

}
