// Ce fichier ne doit jamais être importer.
// Il faut importer le fichier environment.ts !

import {appConfigurationProperties} from '../app/config/appConfigurationProperties';
// import { UserRoleType } from '../app/shared/auth.roles';

export const env = {
  ...appConfigurationProperties,  // Ajout des contantes de l'application

  // buyUrl : 'https://1.envato.market/6NV1b', // FIXME A MODIFIER !!!
  SCARF_ANALYTICS : false,
  adminRoot: appConfigurationProperties.APP_BASE_URL,
  // apiUrl: 'https://api.coloredstrategies.com', // FIXME A MODIFIER !!!
  defaultMenuType: 'menu-default',
  // subHiddenBreakpoint: 1440, // Valeur original du thème
  subHiddenBreakpoint: 1024,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  themeColorCorporateStorageKey: 'forColThe', // La clé pour la couleur du theme corporate forcée
  isMultiColorActive: true,
  /*
  Color Options:
  'light.blueyale', 'light.blueolympic', 'light.bluenavy', 'light.greenmoss', 'light.greenlime', 'light.yellowgranola', 'light.greysteel', 'light.orangecarrot', 'light.redruby', 'light.purplemonster'
  'dark.blueyale', 'dark.blueolympic', 'dark.bluenavy', 'dark.greenmoss', 'dark.greenlime', 'dark.yellowgranola', 'dark.greysteel', 'dark.orangecarrot', 'dark.redruby', 'dark.purplemonster'
  */
  defaultColor: 'light.blueyale',
  elearningColor: 'light.purplemonster',
  databaseColor: 'light.orangecarrot',
  publishingColor: 'light.redruby',
  corporateColor: 'light.greysteel',
  disableColorChange: ['light.purplemonster', 'light.orangecarrot', 'light.redruby'],  // Desactive le changement de couleur pour les couleurs suivantes (permet de ne pas faire de rechargement de pages entre certains modules)
  applicationColors: { // Les couleurs des badges des annoucements ne peuvent pas être prises dans les thèmes. On les rentre manuellement selon les thèmes.
    default: {
      background: '#145388',  // La couleur du background
      color: '#fff',          // La couleur du texte
    },
    elearning: {
      background: '#922c88', // La couleur du background
      color: '#fff',      // La couleur du texte
    },
    database: {
      background: '#ed7117', // La couleur du background
      color: '#fff',      // La couleur du texte
    },
    publishing: {
      background: '#913a47', // La couleur du background
      color: '#fff',      // La couleur du texte
    },
    corporate: {
      background: '#48494b', // La couleur du background
      color: '#fff',      // La couleur du texte
    }
  },
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: false,
  // defaultRole: UserRoleType.Admin,
  defaultRole: undefined,
};
