import {CurrentUserService} from '../../../modules/ng-manager-user/services/current-user.service';
import {ObjectUtil} from '../../../modules/ng-helpers-util/object.util';
import {appConfigurationProperties} from '../../../config/appConfigurationProperties';
import {UserDependenciesService} from '../../../modules/ng-module-login/services/user-dependencies.service';
import {Publication} from '../../../modules/ng-models-atp/models/db/Publication';
import {PublishingProductLibraryService} from '../../../views/app/publishing/publishing-product-library.service';
import {IPublishingUserProduct} from './IPublishingUserProduct';
import {UserPublicationDb} from '../../../modules/ng-models-atp/models/db/userAtp/UserPublicationDb';
import {UserTeachingDb} from "../../../modules/ng-models-atel3/models/db/userAtel/UserTeachingDb";

export class UserPublication extends UserPublicationDb implements IPublishingUserProduct {

  public publication: Publication;

  /**
   *
   */
  constructor(
    publication: Publication,
    currentUserService: CurrentUserService,
    userPublicationData?: any) {
    if (
      !userPublicationData &&
      ObjectUtil.hasProperty(currentUserService, 'user', 'atp', 'publications') &&
      ObjectUtil.isArray(currentUserService.user.atp.publications)) {
      // Récupération des données du userPublication dans les publications de l'utilisateur
      userPublicationData = currentUserService.user.atp.publications.find(elem => (
        ObjectUtil.hasProperty(elem, 'publication_ref') && (elem.publication_ref === publication._id) &&
        ObjectUtil.hasProperty(elem, 'status', 'deleted') && !elem.status.deleted
      ));
    }
    super(userPublicationData);
    this.publication = publication;
  }

  // Le type d'objet
  public getType(): string {
    return appConfigurationProperties.PUBLISHING_CONTAINER_TYPE_PUBLICATION;
  }

  // L'objet associé
  public getEmbeddedObject(): Publication {
    return this.publication;
  }

  // Fonction qui test si l'objet associé existe
  public hasEmbeddedObject(): boolean {
    return this.publication !== undefined;
  }

  // Fonction qui test si l'objet est valide
  public isEmbeddedObjectValid(): boolean {
    return this.publication.isValid();
  }

  // L'objet est partiellement possédé par l'utilisateur
  public isPartiallyOwnByUser(
    currentUserService?: CurrentUserService | UserTeachingDb[], // UserTeachingDb[] pour la compatibilité avec les fonctions de l'interface
    userDependenciesService?: UserDependenciesService,
    considerUserPublicationActiveFlag: boolean = false): boolean {
    return this.isUserPublicationValid(considerUserPublicationActiveFlag);
  }

  // L'objet est entièrement possédé par l'utilisateur
  public isFullyOwnByUser(
    currentUserService?: CurrentUserService | UserTeachingDb[], // UserTeachingDb[] pour la compatibilité avec les fonctions de l'interface
    userDependenciesService?: UserDependenciesService,
    considerUserPublicationActiveFlag: boolean = false): boolean {
    return this.isUserPublicationValid(considerUserPublicationActiveFlag);
  }

  /**
   *
   */
  public hasInfoProgression({publishingProductLibraryService}): boolean {
    return (
      this.isUserPublicationValid(false) &&
      this.isPurchased(publishingProductLibraryService));
  }

  // L'objet a été acheté par l'utilisateur
  isPurchased(publishingProductLibraryService: PublishingProductLibraryService): boolean {
    return (publishingProductLibraryService.getUserPublicationFromPublicationId(this.publication._id) !== undefined);
  }

  // L'objet peut être acheté par cet utilisateur
  isPurchasable({publishingProductLibraryService}): boolean {
    return (
      this.getEmbeddedObject().hasPrice() &&
      (
        !this.isUserPublicationValid(true) ||
        !this.hasInfoProgression({publishingProductLibraryService}) ||
        !this.isFullyOwnByUser()
      )
    );
  }

}
