/**
 * Les informations pour les UserPublication.
 */
export class UserProductInfoPublishing {
  nbConn: number;
  firstConn: Date;
  lastConn: Date;
  notes: string;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
    // Gestion des dates
    this.firstConn = (_data.firstConn) ? new Date(_data.firstConn) : undefined;
    this.lastConn = (_data.lastConn) ? new Date(_data.lastConn) : undefined;
  }

  /**
   *
   * @param fallbackZero Si true et si pas de nbConn retourne 0. Retourne undefined sinon.
   */
  public displayNbConn(fallbackZero: boolean = true): number {
    return ((this.nbConn === undefined) && !fallbackZero) ? undefined : this.nbConn || 0;
  }

}
