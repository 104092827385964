import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorComponent} from '../views/error/error.component';
import {RouterModule} from '@angular/router';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {UnauthorizedComponent} from '../views/unauthorized/unauthorized.component';
import {ImageFallbackDirective} from '../directives/image/image-fallback.directive';
import {I18nextPipeModule} from '../modules/ng-manager-lang/i18next.pipe.module';
import {HelpersUtilsPipesModule} from '../modules/ng-helpers-util/pipes/helpers-utils.pipe.module';
import {appConfigurationProperties} from '../config/appConfigurationProperties';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {BlankForReloadComponent} from '../views/blankForReload/blankForReload.component';
import {EmailListValidatorDirective} from '../directives/validator/email-list-validator.directive';

@NgModule({
    declarations: [
        BlankForReloadComponent,
        ErrorComponent,
        UnauthorizedComponent,
        ImageFallbackDirective,
        EmailListValidatorDirective
    ],
    imports: [
        RouterModule,
        CommonModule,
        I18nextPipeModule,
        PerfectScrollbarModule,
        RecaptchaFormsModule,
        RecaptchaModule,
    ],
    exports: [
        PerfectScrollbarModule,
        RouterModule,
        BlankForReloadComponent,
        ErrorComponent,
        UnauthorizedComponent,
        ImageFallbackDirective,
        EmailListValidatorDirective,
        I18nextPipeModule,
        HelpersUtilsPipesModule,
        CommonModule,
        RecaptchaFormsModule,
        RecaptchaModule,
    ],
    providers: [
        {   // Les valeur globales pour le module recaptcha (la siteKey peut être overridée dans les balises html dans les fichiers jade)
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: appConfigurationProperties.RECAPTCHA_SITE_KEY,
                size: appConfigurationProperties.RECAPTCHA_SIZE
            } as RecaptchaSettings,
        }
    ],
})
export class SharedModule {
}
