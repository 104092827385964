import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {timeout} from 'rxjs/operators';

import {CurrentUserService} from './current-user.service';
import {Localization} from '../../ng-models-ui/models/Localization';
import {applicationProperties} from '../../ng-properties-application/application.properties';


/**
 * Classe permettant de recupérer les données de localisation de l'utilisateur.
 */
@Injectable({
    providedIn: 'root'
})
export class LocalizationService {

    /**
     *
     * @param http
     * @param currentUserService
     */
    constructor(private http: HttpClient,
                private currentUserService: CurrentUserService) {
    }

    /**
     * Fonction qui retourne la localisation et l'enregistre dans currentUserService.
     *
     * @returns
     */
    getLocalization(): Promise<Localization>|Localization {
        return new Promise((resolve, reject) => {
            this._IPaddressResolver(applicationProperties.LOCALISATION_API_URL().requireIp)
                .then(
                    ip => {
                        this._localizationResolver(ip).subscribe(
                            res => {
                                // console.log("LOCALIZATION res+:", res);
                                this.currentUserService.setLocalisation(res);
                                resolve(res);
                            },
                            err => {    // err est aussi de type Localization
                                // console.log("LOCALIZATION ERROR 1 err+:", err);
                                this.currentUserService.setLocalisation(err);
                                resolve(err);
                            }//,
                            //() => console.log('Request competed')
                        );
                    })
                .catch(
                    err => {
                        // console.log("LOCALIZATION ERROR 2 err:", err);
                        this._localizationResolver().subscribe(
                            res => {
                                // console.log("LOCALIZATION res++:", res);
                                this.currentUserService.setLocalisation(res);
                                resolve(res);
                            },
                            err => {    // err est aussi de type Localization
                                // console.log("LOCALIZATION ERROR 1++ err:", err);
                                this.currentUserService.setLocalisation(err);
                                resolve(err);
                            }//,
                            //() => console.log('Request competed')
                        );
                    }
                )
        })
    }

    /**
     * Fonction qui résout la localisation du client.
     *
     * @param ip
     */
    private _localizationResolver(ip: string = ""): Observable<Localization> {
        // console.log("LOCALIZATION localization url:", applicationProperties.LOCALISATION_API_URL(ip).url);
        return Observable.create(observer => {
            let localization: Localization;
          if ((applicationProperties.LOCALISATION_API_URL(ip).api) === undefined) {
            // La géolocalisation est désactivée
            // On resout l'observable pour continuer le chainage
            observer.next(localization);  // Valeur retournée en cas de succès
            observer.complete();    // Fermeture du stream : on résout l'observable (comme une promesse)
          } else {
            // La géolocalisation est activée
            this.http.get(applicationProperties.LOCALISATION_API_URL(ip).url).pipe(
              timeout(applicationProperties.LOCALISATION_API_TIMEOUT)) // On fixe un timeout si le serveur ne répond pas sufissement vite
              .subscribe(
                data => {
                  if (data) {
                    localization = this._getLocalization(data);
                  }
                  // console.log("LOCALIZATION localization:", localization);
                  // On resout l'observable pour continuer le chainage
                  observer.next(localization);  // Valeur retournée en cas de succès
                  observer.complete();    // Fermeture du stream : on résout l'observable (comme une promesse)
                },
                err => {
                  // console.log("LOCALIZATION ERROR 1 err:", err);
                  observer.next(localization);  // Valeur retournée en cas d'échec
                  observer.complete();    // Fermeture du stream : on résout l'observable (comme une promesse)
                })
          }
        })
    }


  /**
   * Fonction qui résout l'adresse ip du client.
     *
     * @param requireIp - indique que l'on aller chercher l'adresse ip du client.
     * @returns
     */
    private _IPaddressResolver(requireIp: boolean): Promise<string> {
        return new Promise((resolve, reject) => {
            let ip: string;
            if (!requireIp) {
                resolve(ip);
                return;
            }
            //noinspection TypeScriptUnresolvedFunction
            this.http.get(applicationProperties.IP_ADDRESS_API_URL.url).pipe(
                timeout(applicationProperties.IP_ADDRESS_API_TIMEOUT)) // On fixe un timeout si le serveur ne répond pas sufissement vite
                .subscribe(
                    data => {
                        // console.log("LOCALIZATION SUCCESS data:", data);
                        if (data) {
                            ip = this._getIpAddress(data);
                        }
                        resolve(ip);
                    },
                    err => {
                        // console.log("LOCALIZATION ERROR err:", err);
                        resolve(ip);
                    })
        })
    }

    /**
     * Fonction qui retourne la localisation en fonction du type d'api.
     *
     * @param data
     * @returns
     */
    private _getLocalization(data: any): Localization {
        // console.log("LLLLLLLLLLLLL data:", data);
        if (!data) {
            return undefined;
        }
        switch (applicationProperties.LOCALISATION_API_URL().api) {
            case "geoplugin": {
                // console.log("LLLLLLLLLLLLL loc:", loc);
                return new Localization(
                    data.geoplugin_request,
                    data.geoplugin_countryCode,
                    data.geoplugin_countryName,
                    data.geoplugin_continentCode,
                    data.geoplugin_currencyCode);
            }
            case "freegeoip": {
                return new Localization(data.ip, data.country_code, data.country_name, "", "");
            }
            default:
                return undefined;
        }
    }

    /**
     * Fonction qui retourne l'adresse ip en fonction du type d'api.
     *
     * @param data
     * @returns
     */
    private _getIpAddress(data: any): string {
        if (!data) {
            return undefined;
        }
        switch (applicationProperties.IP_ADDRESS_API_URL.api) {
            case "jsonip":
                return data.ip;
            case "ipify":
                return data.ip;
            default:
                return undefined;
        }
    }
}
