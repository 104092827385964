/**
 * Classe permettant de stocker les informations sur les jobs schedulés.
 *
 * Ces informations ne sont pas stockées dans les services correspondant à chaque tâche
 * car ils est souvant nécessaire de les interrompre lors de la déconnection de l'utilisateur.
 * La déconnexion se fait dans le service "authentificationService" et donc il faudrait importer
 * ces services dans "authentificationService" ce qui rendrait impossible de faire du lazy loading,
 * "authentificationService" etant chargé au démarrage (tous les services auxquels il fait
 * appel seraient donc aussi chargés au démarrage).
 */
import {StringUtil} from './string.util';
import {ObjectUtil} from './object.util';
import {ArrayUtil} from './array.util';
// import {unescape} from 'querystring';

class Job {
    jobId: number;
    jobName: string;
    delay: number;
    action: Function;
    myThis: any;
    parameters: any[];
}

// @dynamic
export class SchedulerUtil {

    /*
     * La tâche de recherche des news
     */

    // L'id de la tâche de recherche des news
    private static _jobFetchNewsId: number;
    public static get JOB_FETCH_NEWS_ID(): number { return this._jobFetchNewsId; }
    public static set JOB_FETCH_NEWS_ID(id: number) { this._jobFetchNewsId = id; }
    // La récurence en milliseconde de la répetition de la tâche
    // public static get JOB_FETCH_NEWS_DELAY(): number { return (1000 * 20); }    // 20 secondes pour les tests
    public static get JOB_FETCH_NEWS_DELAY(): number { return (1000 * 60 * 15); }  // 15 minutes
    // Fonction qui démarre la tache de recherche des news.
    public static startJobFetchNews(func: Function, myThis: any) {
        this.startJob("JOB_FETCH_NEWS_ID", this.JOB_FETCH_NEWS_DELAY, func, myThis);
    }
    // Fonction qui annule la tache de recherche des news.
    public static cancelJobFetchNews() {
        this.stopJob("JOB_FETCH_NEWS_ID");
    }
    // Fonction qui redémarrer la tache de recherche des news.
    public static restartJobFetchNews() {
        this.restartJob("JOB_FETCH_NEWS_ID");
    }

    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    /*
     * La liste des tâches du scheduler.
     * Permet de mémoriser les paramêtres des tâches pour les redémarrer avec la fonction "restartJob".
     */
    private static _jobList: Job[] = [];

    /*
     * Les fonctions de démarrage et d'arrêt des tâches
     */

    /**
     * Fonction qui permet de démarrer une tâche du scheduler.
     * @param jobName - ne nom de la propiété stockant l'id de la tâche dans la classe SchedulerUtil
     * // FIXME: Trouver un moyen de ne plus faire passer le nom de la propriété "jobName" dans cette fonction.
     * @param func - la fonction a exécuter en boucle
     * @param repeatDelayInMillisecond - le délait en milliseconde entre deux exécutions
     * @param myThis - (optionel) le context this de l'appelant si la tache fait appel à "this".
     * @param parameters - (optionel) les paramètres passé à la tache
     */
    public static startJob(jobName: string, repeatDelayInMillisecond: number, func: Function, myThis?: any, parameters: any[] = []) {
        if (func && !StringUtil.isUndefinedOrEmpty(jobName) && (repeatDelayInMillisecond !== undefined)) {
            if (ObjectUtil.isArray(myThis)) {
                parameters = myThis;
                myThis = undefined;
            }
            let action = (() => {
                if (myThis) {
                    func.apply(myThis, parameters);
                } else {
                    func(...parameters)
                }
                if (ArrayUtil.indexOfEqual(this._jobList, (a, b) => {
                        return a.jobName === b.jobName
                    }, {jobName: jobName}) !== -1) {    // La tâche existait déjà
                    // On la supprime
                    this.stopJob(jobName);
                }
                // On rajoute la tâche à la liste des tâches
                this._jobList.push({
                    jobId: this[jobName],
                    jobName: jobName,
                    delay: repeatDelayInMillisecond,
                    action: func,
                    myThis: myThis,
                    parameters: parameters
                });
                this[jobName] = setTimeout(action, repeatDelayInMillisecond);

            });
            setTimeout(action, 0);   // Pour que la première instance du job démarre dès que l'on lance l'appel à la fonction d'ordonnancement
        }
    }

    /**
     * Fonction qui permet d'arrêter une tâche du scheduler.
     * // FIXME: Trouver un moyen de ne plus faire passer le nom de la propriété "jobName" dans cette fonction.
     * @param jobName
     */
    public static stopJob(jobName: string) {
        clearTimeout(this[jobName]);    // this[jobName] --> jobId
        ArrayUtil.removeElementsEqual(this._jobList, (a, b) => {return a.jobName === b.jobName}, {jobName: jobName});
    }

    /**
     * Fonction qui permet de redémarrer une tâche du scheduler.
     * // FIXME: Trouver un moyen de ne plus faire passer le nom de la propriété "jobName" dans cette fonction.
     * @param jobName
     */
    public static restartJob(jobName: string) {
        let job: Job = this._jobList.find(task => { return(task.jobName === jobName) });
        if(job) {
            this.stopJob(jobName);
            this.startJob(jobName, job.delay, job.action, job.myThis, job.parameters);
        }
    }

}
