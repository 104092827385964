import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {NotificationType} from 'angular2-notifications/lib/enums/notification-type.enum';

/**
 * Service permettant de demander l'affichage de notification sur l'écran d'accueil.
 */
@Injectable({
  providedIn: 'root',
})
export class MessageService {

  // Observable pour la détection de l'affichage des notifications.
  private displayNotificationSource = new Subject<NotificationMessage>();
  displayNotification$ = this.displayNotificationSource.asObservable();

  // Observable pour la détection de l'affichage des modals (prend en paramètre le nom de la fenêtre modal a lancer).
  private displayModalSource = new Subject<string>();
  displayModal$ = this.displayModalSource.asObservable();

  /**
   *
   */
  displayNotification(notification: NotificationMessage) {
    return this.displayNotificationSource.next(notification);
  }

  /**
   *
   */
  displayModal(modalName: string) {
    return this.displayModalSource.next(modalName);
  }
}

/**
 *
 */
export class NotificationMessage {
  constructor(
    public title: any,
    public content: any,
    public type: NotificationType,
    public override?: any,
    public context?: any) {
  }
}
