import {InjectionToken} from '@angular/core';
import {ModuleLibraryConfig, ModuleLibraryConfigInt} from "../../ng-models-ui";

// On définit un token pour l'injection des paramètres de configuration du module
export const ModuleErrorConfigService = new InjectionToken<ModuleLibraryConfigInt>(
    'ModuleErrorConfigInt'
);

export class ModuleErrorConfig extends ModuleLibraryConfig {

    /**
     * 
     * @param data 
     */
    constructor(public data: any) {     // Objet comprenant les propriétés de configuration du module
        super(data);
    }
}
