import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  // selector: 'app-blank-for-reload',
  templateUrl: './blankForReload.component.html'
})
export class BlankForReloadComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
