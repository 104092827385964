import {ObjectUtil} from "../../../../ng-helpers-util/object.util";
import {UserNotification} from "../../../../../shared/notificationBell.service";
import {appConfigurationProperties} from "../../../../../config/appConfigurationProperties";
import {UserProductStatus} from "../../../../ng-models-shared/models/db/UserProduct";
import {UserProductInfoPublishing} from "./UserProductPublishing";

// Déclaration de la variable $ (il faut que cette variable soit reconnue au niveau de l'application en faisant par exemple un "npm install --save-dev @type/jquery")
// declare var $: any;

/**
 * Encapsule les éléments du tableau userAtp.publications
 */
export class UserPublicationDb {

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
    if (_data && _data.sources && ObjectUtil.isArray(_data.sources)) {
      this.sources = _data.sources.map(elem => {
        return new UserPublicationSource(elem);
      });
    }
    if (_data && _data.info) {
      this.info = new UserProductInfoPublishing(_data.info);
    }
    if (_data && _data.status) {
      this.status = new UserProductStatus(_data.status);
    }
    if (_data && _data.actions && ObjectUtil.isArray(_data.actions)) {
      this.actions = _data.actions.map(elem => {
        return new UserNotification(elem);
      });
    }
  }

  public _id: string;
  public publication_ref: string;

  public publishedItem: {
    action?: string,
    date?: Date,
    viewingMethods?: string[]
  };
  public sources: UserPublicationSource[];

  public info: UserProductInfoPublishing;
  public status: UserProductStatus;
  public actions: UserNotification[];

  /**
   * Fonction qui test si l'objet est valide
   */
  public canPlay(): boolean {
    if (this.status) {
      return this.isUserPublicationValid(true) && !this.status.frozen;
    } else {
      return false;
    }
  }

  /**
   * Fonction qui test si l'objet est valide
   */
  public isUserPublicationValid(considerUserPublicationActiveFlag: boolean = false): boolean {
    if (this.status) {
      return (considerUserPublicationActiveFlag)
        ? this.status.active && !this.status.expired && !this.status.deleted
        : !this.status.deleted;
    } else {
      return false;
    }
  }

  /**
   * Le userProduct (userPublication, userSource) est valide.
   */
  public isUserProductValid(considerUserPublicationActiveFlag: boolean = false): boolean {
    return this.isUserPublicationValid(considerUserPublicationActiveFlag);
  }

  /**
   *
   */
  public hasSource(sourceId: string): boolean {
    return (
      // Cas de la source personnelle
      (
        (sourceId === appConfigurationProperties.DEFAULT_SOURCE_ID) &&
        (
          !ObjectUtil.isArray(this.sources) ||
          (this.sources.length === 0) ||
          this.sources.some(src => (
            !ObjectUtil.hasProperty(src, 'source_ref') ||
            src.source_ref === null) ||
            (src.source_ref === appConfigurationProperties.DEFAULT_SOURCE_ID)
          )
        )
      ) ||
      // Cas d'une source autre que la source personnelle
      (
        (sourceId !== appConfigurationProperties.DEFAULT_SOURCE_ID) &&
        this.sources.some(src => (src.source_ref === sourceId))
      )
    );
    /*
    if (this.sources) {
      return this.sources.some(
        src => ObjectUtil.hasProperty(src, 'source_ref', '_id') && (sourceId === src.source_ref._id));
    } else {
      return false;
    }
    */
  }

  public getExpiryDate(): Date {
    return this.status.expiryDate;
  }

}

/**
 *
 */
class UserPublicationSource {

  public source_ref: string;
  public sourceLibrary_ref: string;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   */
  constructor(_data?: any) {
    $.extend(this, _data);
  }

}
