/**
 * Propiétés de configuration de la plate-forme.
 *      Proriétés :
 *      - JWT_TOKEN_NAME : nom du token jwt utilisé pour l'authentifiaction
 *
 * Cette classe override la classe ApplicationProperties afin de modifier ou
 * d'ajouter de nouvelles propriétés spécifiques.
 */
import {applicationProperties} from '../modules/ng-properties-application/application.properties';
import {atelProperties} from '../modules/ng-properties-application/atel.properties';
import {atpProperties} from '../modules/ng-properties-application/atp.properties';
import {adocProperties} from '../modules/ng-properties-application/adoc.properties';
import UiJsonConfig from '../../config/current/aip-ui.json';
import {StringUtil} from '../modules/ng-helpers-util/string.util';
import {ConfigurationUtil} from './configuration.util';
import {ObjectUtil} from '../modules/ng-helpers-util/object.util';
import {ConfigurationProperties} from '../modules/ng-properties-configuration/configuration.properties';
import {IAnnouncement} from '../modules/ng-models-ats/interfaces/announcement.interface';

const appConfiguration = {
  ...applicationProperties,  // Ajout des contantes héritées
  ...atelProperties,  // Ajout des contantes héritées
  ...atpProperties,   // Ajout des contantes héritées
  ...adocProperties,  // Ajout des contantes héritées

  // *** L'authentification se fait par email ou username pour cette application ***
  AUTHENTICATION_IS_EMAIL_LOGIN: true,

  // *** Clé et valeur de la signature dans le header des requetes pour permettre au serveur d'identifier la provenance de requetes ***
  REQUEST_SIGNATURE_APPLICATION_VALUE: 'aip-ui',  // La valeur de la clé stockant le nom de l'application aip ayant généré la requête
  REQUEST_SIGNATURE_SOURCE_VALUE: 'ng2usrui',     // La valeur de la clé stockant le nom de source interne à l'application ayant généré la requête

  /*****************************************
   **** Les données de google analytics ****
   *****************************************/
  GOOGLE_ANALYTICS_MEASUREMENT_ID: UiJsonConfig.GOOGLE_ANALYTICS_MEASUREMENT_ID,

  /***********************************
   **** Les domaines des serveurs ****
   ***********************************/
  DOMAIN_AIP_COMMON: UiJsonConfig.DOMAIN_AIP_COMMON,
  DOMAIN_AIP_ATEL: UiJsonConfig.DOMAIN_AIP_ATEL,
  DOMAIN_AIP_ATP: UiJsonConfig.DOMAIN_AIP_ATP,
  DOMAIN_AIP_ADOC: UiJsonConfig.DOMAIN_AIP_ADOC,
  DOMAIN_AIP_CORPORATE: UiJsonConfig.DOMAIN_AIP_CORPORATE,

  /*****************************************
   **** Les paths des urls des serveurs ****
   *****************************************/
  SERVER_PATH_AIP_COMMON_ADOC: '/database',

  /***********************************
   **** Les domaines des serveurs ****
   ***********************************/
  APP_BASE_HREF: '/aip',  // Doit correspondre à <base href="/aip"> dans le fichier index.html

  /*********************************************
   **** Le prefix des urls de l'application ****
   *********************************************/
  APP_BASE_URL: '/app',
  APP_BASE_URL_ELEARNING: '/elearning',
  APP_BASE_URL_ELEARNING_CORPORATE: '/elearning-corporate',
  APP_BASE_URL_DATABASE: '/database',
  APP_BASE_URL_PUBLISHING: '/publishing',
  APP_BASE_URL_PUBLISHING_CORPORATE: '/publishing-corporate',

  /**********************************
   **** Les urls vers le serveur ****
   **********************************/
  URL_SERVER_ERROR_NOTIFICATION: './system/error/ui',          // Pour la notification des erreurs
  URL_SERVER_ERROR_TRANSLATION: './system/error/translation',  // Pour la notification des clés de translation manquantes
  URL_SERVER_GET_ENUM_TYPE: './enumType/get/',                 // Pour la récupération des enum types
  URL_SERVER_SEARCH: 'aip/search',                 // Pour la récupération des enum types
  URL_SERVER_UPDATE_USER_COMMON: 'user/updateCommon',
  URL_SERVER_RESEND_CONFIRMATION_UPDATE_USER_EMAIL: 'user/resendConfirmationUpdateUserEmail',
  URL_SERVER_RELOAD_USER: './user/reload',  // Utilisé dans la configuration du service ManagerUserConfigService
  URL_SERVER_GET_ORDERS: './user/getOrders',
  URL_SERVER_GET_ORDERS_BY_PAYMENT_ID: './user/getOrdersByPaymentId',
  URL_SERVER_CHANGE_USER_PERFERENCES: `./user/updateUserPreferences`,    // Pour la modification de la langue et la timezone
  URL_SERVER_CHANGE_USER_SETTINGS: `./user/updateUserSettings`,    // Pour la modification des settings de l'utilisateur
  URL_SERVER_NOTIFICATIONS_BELL_SEEN: `./user/notificationsBellSeen`,
  URL_SERVER_NOTIFICATIONS_GET: `./user/getUserNotifications`,
  URL_SERVER_ANNOUNCEMENTS_LIST: `./user/listAnnouncements`,
  URL_SERVER_CONTACT_US_SEND_EMAIL: `./user/contactUsSendEmail`,
  URL_SERVER_PROXY_ADOC: '/aip-adoc',

  URL_SERVER_ELEARNING_GET_THEMES: 'catalogue/getThemeTree',
  URL_SERVER_ELEARNING_GET_THEME_CONTENT: 'catalogue/themeContent',
  URL_SERVER_ELEARNING_GET_THEMES_COUNT: 'catalogue/countTeachingsPerTheme',
  URL_SERVER_ELEARNING_SEARCH: 'catalogue/search',
  URL_SERVER_ELEARNING_GET_LESSON: 'catalogue/getLesson',
  URL_SERVER_ELEARNING_GET_QUIZ: 'catalogue/getQuiz',
  URL_SERVER_ELEARNING_GET_COURSE: 'catalogue/getCourse',
  URL_SERVER_ELEARNING_GET_DEGREE: 'catalogue/getDegree',
  URL_SERVER_COMMON_DEFAULT_IMAGE_SOURCE: () => `${UiJsonConfig.URL_SERVER_COMMON_DEFAULT_IMAGE_SOURCE}/${ConfigurationProperties.cli_file_default_source_picture}?v${UiJsonConfig.VERSION_COMMON_DEFAULT_IMAGE_SOURCE}`,
  URL_SERVER_ELEARNING_DEFAULT_IMAGE_LESSON: () => `${UiJsonConfig.URL_SERVER_ELEARNING_DEFAULT_IMAGE_LESSON}/${ConfigurationProperties.cli_file_common_picture_default_name}.${ConfigurationUtil.cli_file_common_picture_extension}?v${UiJsonConfig.VERSION_COMMON_DEFAULT_IMAGE}`,
  URL_SERVER_ELEARNING_DEFAULT_IMAGE_QUIZ: () => `${UiJsonConfig.URL_SERVER_ELEARNING_DEFAULT_IMAGE_QUIZ}/${ConfigurationProperties.cli_file_common_picture_default_name}.${ConfigurationUtil.cli_file_common_picture_extension}?v${UiJsonConfig.VERSION_COMMON_DEFAULT_IMAGE}`,
  URL_SERVER_ELEARNING_DEFAULT_IMAGE_COURSE: () => `${UiJsonConfig.URL_SERVER_ELEARNING_DEFAULT_IMAGE_COURSE}/${ConfigurationProperties.cli_file_common_picture_default_name}.${ConfigurationUtil.cli_file_common_picture_extension}?v${UiJsonConfig.VERSION_COMMON_DEFAULT_IMAGE}`,
  URL_SERVER_ELEARNING_DEFAULT_IMAGE_DEGREE: () => `${UiJsonConfig.URL_SERVER_ELEARNING_DEFAULT_IMAGE_DEGREE}/${ConfigurationProperties.cli_file_common_picture_default_name}.${ConfigurationUtil.cli_file_common_picture_extension}?v${UiJsonConfig.VERSION_COMMON_DEFAULT_IMAGE}`,
  URL_SERVER_ELEARNING_LAUNCH_LESSON: 'protocol/startLesson',
  URL_SERVER_ELEARNING_LAUNCH_QUIZ: 'protocol/startQuiz',
  URL_SERVER_ELEARNING_REMOVE_BOOKMARKS: 'protocol/removeBookmarks',

  URL_SERVER_PUBLISHING_GET_PUBLICATION: 'catalogue/getPublication',
  URL_SERVER_PUBLISHING_SEARCH: 'catalogue/search',
  URL_SERVER_PUBLISHING_DEFAULT_IMAGE_PUBLICATION: () => `${UiJsonConfig.URL_SERVER_PUBLISHING_DEFAULT_IMAGE_PUBLICATION}/${ConfigurationProperties.cli_file_common_picture_default_name}.${ConfigurationUtil.cli_file_common_picture_extension}?v${UiJsonConfig.VERSION_COMMON_DEFAULT_IMAGE}`,
  URL_SERVER_PUBLISHING_LAUNCH_PUBLICATION: 'protocol/startPublication',

  URL_SERVER_SHOPPING_GET_SHOPPING_CART: 'shopping/getShoppingCart',
  URL_SERVER_SHOPPING_VIEW_SHOPPING_CART: 'shopping/viewShoppingCart',
  URL_SERVER_SHOPPING_ADD_ONE_TO_CART: 'shopping/addOneToCart',
  URL_SERVER_SHOPPING_ADD_MANY_TO_CART: 'shopping/addManyToCart',
  URL_SERVER_SHOPPING_CHANGE_QUANTITY: 'shopping/changeQuantity',
  URL_SERVER_SHOPPING_REDUCE_BY_ONE: 'shopping/reduceByOne',
  URL_SERVER_SHOPPING_REMOVE_ITEM: 'shopping/removeItem',
  URL_SERVER_SHOPPING_CHANGE_OPTION: 'shopping/changeOption',
  URL_SERVER_SHOPPING_EMPTY_CART: 'shopping/emptyCart',
  URL_SERVER_SHOPPING_SET_CART_CURRENCY: 'shopping/setCartCurrency',

  URL_SERVER_PAYMENT_PAYMENT_METHOD: './shopping/paymentMethod',
  URL_SERVER_PAYMENT_CHECKOUT: './shopping/checkout',
  URL_SERVER_PAYMENT_PAYPAL_PAYNOW: './payment/paypal/paynow',
  URL_SERVER_PAYMENT_CLICK_TO_PAY_AUTORISATION: './payment/clicToPay/register',
  URL_SERVER_PAYMENT_BRAINTREE_CHECKOUTS_NEW: './payment/braintree/checkouts/new',
  URL_SERVER_PAYMENT_BRAINTREE_CHECKOUTS: './payment/braintree/checkouts',

  URL_SERVER_CORPORATE_USERS_LIBRARY_LIST: 'users/library/list',
  URL_SERVER_CORPORATE_USERS_LIBRARY_VIEW: 'users/library/view',
  URL_SERVER_CORPORATE_USERS_LIBRARY_ASSIGN_TO_USERS: 'corporate/library/assignToUsers',
  URL_SERVER_CORPORATE_USERS_GROUP_LIST: 'users/group/list', // URL ajouter par oussama (add)
  URL_SERVER_CORPORATE_USERS_REPORT_TEACHING_LIST: 'users/report/getUserLearningContainersList',
  URL_SERVER_CORPORATE_USERS_REPORT_GET_TRACKING_INFO: 'users/report/getUserTrackingReportInfo',
  URL_SERVER_CORPORATE_USERS_REPORT_GET_THEMES_LIST: 'users/report/getUserThemesList',
  URL_SERVER_CORPORATE_GET_SOURCE: 'sources/get',
  URL_SERVER_CORPORATE_SOURCES_GROUP_GET_INFO: 'sources/group/getGroupActivityReportInfo',
  URL_SERVER_CORPORATE_DASHBOARD_ELEARNING_STATISTICS: `sources/dashboard/statisticsElearning`,
  URL_SERVER_CORPORATE_DASHBOARD_PUBLISHING_STATISTICS: `sources/dashboard/statisticsPublishing`,

  URL_SERVER_CORPORATE_SOURCE_UPDATE: 'sources/update',
  URL_SERVER_CORPORATE_SOURCE_VALIDATE_EXISTING_PROPERTY: 'sources/validateSourceExistingProperty',
  URL_SERVER_CORPORATE_SOURCES_GROUP_GET_STATISTICS: 'sources/group/statisticsActivtiyReport',
  URL_SERVER_CORPORATE_SOURCES_GROUP_UPDATE: 'sources/group/update',
  URL_SERVER_CORPORATE_SOURCES_GROUP_GET_BY_ID: 'sources/group/get',
  URL_SERVER_CORPORATE_SOURCES_GROUP_NAME_UNIQUE: 'sources/group/validate',

  LIBRARY_QUERY_PARAMETER_STATUS_OPEN: 'open',
  LIBRARY_QUERY_PARAMETER_STATUS_CLOSE: 'close',

  // *** Pour les images des announcements ***
  URL_IMAGE_ANNOUNCEMENT: (announcement?: IAnnouncement) => {
    const application = (ObjectUtil.hasProperty(announcement, 'application'))
      ? announcement.application
      : undefined;
    switch (application) {
      case applicationProperties.APPLICATION_NAME_AIP_COMMON:
        // return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_ANNOUNCEMENT}/logo-black-mobile-AIP-blueyale.svg?v${UiJsonConfig.VERSION_ANNOUNCEMENT_IMAGE_AIP_COMMON}`;
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_DATA_ANNOUNCEMENT()}${ConfigurationUtil.cli_file_common_picture_default_name}.${ConfigurationUtil.cli_file_common_picture_extension}?v${UiJsonConfig.VERSION_ANNOUNCEMENT_IMAGE_AIP_COMMON}`;
      case applicationProperties.APPLICATION_NAME_AIP_ATEL:
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_ANNOUNCEMENT}/logo-black-mobile-AIP-purplemonster.svg?v${UiJsonConfig.VERSION_ANNOUNCEMENT_IMAGE_AIP_ATEL}`;
      case applicationProperties.APPLICATION_NAME_AIP_ATP:
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_ANNOUNCEMENT}/logo-black-mobile-AIP-redruby.svg?v${UiJsonConfig.VERSION_ANNOUNCEMENT_IMAGE_AIP_ATP}`;
      case applicationProperties.APPLICATION_NAME_AIP_ADOC:
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_ANNOUNCEMENT}/logo-black-mobile-AIP-orangecarrot.svg?v${UiJsonConfig.VERSION_ANNOUNCEMENT_IMAGE_AIP_ADOC}`;
      case applicationProperties.APPLICATION_NAME_AIP_CORPORATE:
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_ANNOUNCEMENT}/logo-black-mobile-AIP-greysteel.svg?v${UiJsonConfig.VERSION_ANNOUNCEMENT_IMAGE_AIP_CORPORATE}`;
      case applicationProperties.APPLICATION_NAME_ATM:
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_ANNOUNCEMENT}/logo-black-mobile-AIP-redruby.svg?v${UiJsonConfig.VERSION_ANNOUNCEMENT_IMAGE_AIP_ATM}`;
      default:
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_ANNOUNCEMENT}/logo-black-mobile-AIP-blueyale.svg?v${UiJsonConfig.VERSION_ANNOUNCEMENT_IMAGE_DEFAULT}`;
    }
  },

  // *** Pour les images des deadlines ***
  URL_IMAGE_DEADLINE: (application?: string) => {
    switch (application) {
      case applicationProperties.APPLICATION_NAME_AIP_COMMON:
        // return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_DEADLINE}/logo-black-mobile-AIP-blueyale.svg?v${UiJsonConfig.VERSION_DEADLINE_IMAGE_AIP_COMMON}`;
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_DATA_DEADLINE}/${ConfigurationUtil.cli_file_common_picture_default_name}.${ConfigurationUtil.cli_file_common_picture_extension}?v${UiJsonConfig.VERSION_DEADLINE_IMAGE_AIP_COMMON}`;
      case applicationProperties.APPLICATION_NAME_AIP_ATEL:
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_DEADLINE}/logo-black-mobile-AIP-purplemonster.svg?v${UiJsonConfig.VERSION_DEADLINE_IMAGE_AIP_ATEL}`;
      case applicationProperties.APPLICATION_NAME_AIP_ATP:
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_DEADLINE}/logo-black-mobile-AIP-redruby.svg?v${UiJsonConfig.VERSION_DEADLINE_IMAGE_AIP_ATP}`;
      case applicationProperties.APPLICATION_NAME_AIP_ADOC:
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_DEADLINE}/logo-black-mobile-AIP-orangecarrot.svg?v${UiJsonConfig.VERSION_DEADLINE_IMAGE_AIP_ADOC}`;
      case applicationProperties.APPLICATION_NAME_AIP_CORPORATE:
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_DEADLINE}/logo-black-mobile-AIP-greysteel.svg?v${UiJsonConfig.VERSION_DEADLINE_IMAGE_AIP_CORPORATE}`;
      case applicationProperties.APPLICATION_NAME_ATM:
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_DEADLINE}/logo-black-mobile-AIP-redruby.svg?v${UiJsonConfig.VERSION_DEADLINE_IMAGE_AIP_ATM}`;
      default:
        return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${applicationProperties.URL_LOGOS_DEADLINE}/logo-black-mobile-AIP-blueyale.svg?v${UiJsonConfig.VERSION_DEADLINE_IMAGE_DEFAULT}`;
    }
  },

  // *** Pour les images des produits ***
  /**
   * @param addClearfix - ajout d'un clearfix à l'url pour forcer le rechargement de la photo.
   */
  URL_IMAGE_PRODUCT: (type: string, _id: string, addClearfix?: number) => {
    if (!StringUtil.isUndefinedOrEmpty(type) && !StringUtil.isUndefinedOrEmpty(_id)) {
      const clearfix = (ObjectUtil.isNumeric(addClearfix)) ? `?v${addClearfix}` : '';
      switch (type) {
        case atelProperties.LEARNING_CONTAINER_TYPE_LESSON:
        case atelProperties.LEARNING_CONTAINER_TYPE_QUIZ:
          return `${appConfigurationProperties.URL_SERVER_AIP_ATEL}/${appConfigurationProperties.URL_DATA_TEACHING(_id)}${ConfigurationUtil.cli_file_common_picture_name}.${ConfigurationUtil.cli_file_common_picture_extension}${clearfix}`;
        case atelProperties.LEARNING_CONTAINER_TYPE_COURSE:
          return `${appConfigurationProperties.URL_SERVER_AIP_ATEL}/${appConfigurationProperties.URL_DATA_COURSE(_id)}${ConfigurationUtil.cli_file_common_picture_name}.${ConfigurationUtil.cli_file_common_picture_extension}${clearfix}`;
        case atelProperties.LEARNING_CONTAINER_TYPE_DEGREE:
          return `${appConfigurationProperties.URL_SERVER_AIP_ATEL}/${appConfigurationProperties.URL_DATA_DEGREE(_id)}${ConfigurationUtil.cli_file_common_picture_name}.${ConfigurationUtil.cli_file_common_picture_extension}${clearfix}`;
        case atpProperties.PUBLISHING_CONTAINER_TYPE_PUBLICATION:
          return `${appConfigurationProperties.URL_SERVER_AIP_ATP}/${appConfigurationProperties.URL_DATA_PUBLICATION(_id)}${ConfigurationUtil.cli_file_common_picture_name}.${ConfigurationUtil.cli_file_common_picture_extension}${clearfix}`;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  },

  // *** Pour les params recaptcha ***
  // Clé publique de RECAPTCHAv2 invisible générée sur le site https://www.google.com/recaptcha/admin pour aip.dev
  RECAPTCHA_SITE_KEY: UiJsonConfig.RECAPTCHA_SITE_KEY,
  RECAPTCHA_SIZE: UiJsonConfig.RECAPTCHA_SIZE,
  RECAPTCHA_ENABLED: UiJsonConfig.RECAPTCHA_ENABLED,

  // *** Les actions de l'update du source - Gestion des groupes -   ***
  SOURCE_GROUP_ADD_ACTION: '1',
  SOURCE_GROUP_UPDATE_ACTION: '2',
  SOURCE_GROUP_DELETE_ACTION: '3',

  // *** Les statuts de terminaison ***
  TEACHING_STATUS_NOT_ATTEMPTED: '1',
  TEACHING_STATUS_INCOMPLETE: '2',
  TEACHING_STATUS_COMPLETE: '3',

  // *** Les statuts de succes ou d'échec ***
  TEACHING_SUCCESS_FAILED: '11',
  TEACHING_SUCCESS_PASSED: '12',

  // *** Les informations sur les news ***
  // La source des news atlas-mag
  // DASHBOARD_NEWS_SOURCE_ATLAS_MAG: 'atlas-mag',
  // Les sources des news a afficher
  // DASHBOARD_NEWS_SOURCES_TO_DISPLAY: ['atlas-mag'], // [DASHBOARD_NEWS_SOURCE_ATLAS_MAG]
  // Le nombre de news a afficher dans le dashboard
  // DASHBOARD_NEWS_NUMBER_OF_NEWS_TO_DISPLAY: 12, // SCROLL_PAGE_SIZE

  // *** Les formats de lessons ***
  TEACHING_FORMAT_ATEL3_V0: 'atel3_v0',

  // *** Les noms des fenêtres modales du service MessageService ***
  MESSAGE_SERVICE_MODAL_NAME_WELCOME: 'welcome',

  // *** Les options par défaut des messages de notification ***
  NOTIFICATIONS_OPTIONS: {
    timeOut: 15000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    // Si on met maxStack a 1, le nombre total d'elements identiques est tjrs 1, mais le progressbar recommence dés le debut
    // Si on met preventDuplicates : true, la progressbar continue sans recommencer
    // preventDuplicates : true
    //preventLastDuplicates: "visible"
    // Dans la pratique maxStack:3 est le plus intutif pour l'internaute car
    // - seul maxStack parmi toutes les options garantit la RAZ de la progressbar au clic
    // - 3 est un ememoire suffisameent grande
    maxStack: 3,
    // fromBottom est le plus intituif dans le cas d'une stack: les nvs notifications arrivent depuis le bas
    // malheureusement cela semble demander la derniere version de ce module et celle ci ne semble pas marcher
    // et le faire fonctionner (cf https://www.npmjs.com/package/angular2-notifications)
    // animate: "fromBottom"
  },

  // *** Les types d'objet dans la recherche ***
  USER_SEARCH_TYPE_LESSON: atelProperties.LEARNING_CONTAINER_TYPE_LESSON,
  USER_SEARCH_TYPE_QUIZ: atelProperties.LEARNING_CONTAINER_TYPE_QUIZ,
  USER_SEARCH_TYPE_COURSE: atelProperties.LEARNING_CONTAINER_TYPE_COURSE,
  USER_SEARCH_TYPE_DEGREE: atelProperties.LEARNING_CONTAINER_TYPE_DEGREE,
  USER_SEARCH_TYPE_PUBLICATION: atpProperties.PUBLISHING_CONTAINER_TYPE_PUBLICATION,

  // *** Les valeurs par défauts de la source personnelle (pour les fiches n'ayants pas de source) ***
  DEFAULT_SOURCE_ID: '-1',
  DEFAULT_SOURCE_NAME: (firstName, lastName): string => {
    return `${firstName} ${lastName}`;
  },

};

/**
 * Surcharge pour pouvoir exporter des proprtiétés utilisant d'autres propriétés.
 */
export const appConfigurationProperties = {
  ...appConfiguration,

  /*******************************
   **** La liste des domaines ****
   *******************************/
  // Liste des domaines pour lesquels les tokens d'identifications (notamment les cookies)
  // sont ajoutés aux requetes.
  // Pour les domaines non présents dans cette liste, une nouvelle session sera créé.
  // Pour les domaines présents dans cette liste, la session actuelle sera réutilisée
  // y compris côté serveur permettant d'accéder aux informations stockées dans la session :
  // utilisateur, cart, ...
  LIST_DOMAIN: [
    appConfiguration.DOMAIN_AIP_COMMON,
    appConfiguration.DOMAIN_AIP_ATEL,
    appConfiguration.DOMAIN_AIP_ATP,
    appConfiguration.DOMAIN_AIP_ADOC,
    appConfiguration.DOMAIN_AIP_CORPORATE,
  ],

  /***********************************************************
   **** Ajout des urls des serveurs à partir des domaines ****
   ***********************************************************/
  // URL_SERVER_AIP_COMMON: 'http://localhost:2020',
  URL_SERVER_AIP_COMMON: `https://${appConfiguration.DOMAIN_AIP_COMMON}`,
  URL_SERVER_AIP_ATEL: `https://${appConfiguration.DOMAIN_AIP_ATEL}`,
  URL_SERVER_AIP_ATP: `https://${appConfiguration.DOMAIN_AIP_ATP}`,
  URL_SERVER_AIP_ADOC: `https://${appConfiguration.DOMAIN_AIP_ADOC}`,
  URL_SERVER_AIP_CORPORATE: `https://${appConfiguration.DOMAIN_AIP_CORPORATE}`,

  // Url de récupération des informations de configuration du serveur
  URL_SERVER_GET_CONFIGURATION: `https://${appConfiguration.DOMAIN_AIP_COMMON}/system/configuration`,

  // Pour le contenu statique dans le répertoire 'public'
  // URL_CONTENT: '/content',

  /******************************************************
   **** Les urls de routage dans l'interface angular ****
   ******************************************************/
  URL_UI_ERROR: '/error', // L'écran d'erreur
  URL_UI_HOME: '/',
  URL_UI_ATEL_HOME: '/',
  URL_UI_ADOC_HOME: '/',
  URL_UI_ATM_HOME: '/',
  URL_UI_LOGIN: `${appConfiguration.APP_BASE_URL}/user/login`,  // Différent de '/user/login'
  URL_UI_FORGOT_PASSWORD: `${appConfiguration.APP_BASE_URL}/user/forgot-password`,
  URL_UI_REGISTER: `${appConfiguration.APP_BASE_URL}/user/register`,
  URL_UI_RESEND_CONFIRMATION_EMAIL: (email: string) => `${appConfiguration.APP_BASE_URL}/user/resend-confirmation-email/${email}`,
  URL_UI_HOME_FAQ: (section: string, questionIndex: string) => `${appConfiguration.APP_BASE_URL}/help/faq/${section}/${questionIndex}`,
  URL_UI_HOME_USER: `/home`,
  URL_UI_HOME_USER_START: `/home/start`,
  URL_UI_HOME_CONTACT_US: `${appConfiguration.APP_BASE_URL}/home/contact-us`,
  URL_UI_HOME_ENTERPRISE_SOLUTIONS: `${appConfiguration.APP_BASE_URL}/home/enterprise-solutions`,
  URL_UI_HOME_NOTIFICATIONS: `${appConfiguration.APP_BASE_URL}/notifications`, // Url des notifications
  URL_UI_HOME_ANNOUNCEMENTS: `${appConfiguration.APP_BASE_URL}/announcements`, // Url des announcements
  URL_UI_HOME_CORPORATE: `/corporate`,

  URL_UI_HOME_CORPORATE_START: `${appConfiguration.APP_BASE_URL}/corporate/`,
  URL_UI_START_PAGE: `${appConfiguration.APP_BASE_URL}/home/start`,
  URL_UI_START_PAGE_ELEARNING: `${appConfiguration.APP_BASE_URL}/home/elearning`,
  URL_UI_START_PAGE_PUBLISHING: `${appConfiguration.APP_BASE_URL}/home/publishing`,
  URL_UI_PROFILE: `${appConfiguration.APP_BASE_URL}/profile`,
  URL_UI_PROFILE_CORPORATE: `${appConfiguration.APP_BASE_URL}/profile/account-corporate`,
  URL_UI_PROFILE_UPDATE: `${appConfiguration.APP_BASE_URL}/profile/account`,
  URL_UI_PROFILE_ORDERS: `${appConfiguration.APP_BASE_URL}/profile/orders`,
  URL_UI_PROFILE_INVOICE: `${appConfiguration.APP_BASE_URL}/profile/invoice`,

  URL_UI_ATEL: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}`,
  URL_UI_ATEL_LIBRARY: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/library`,
  URL_UI_ATEL_LIBRARY_SKILLS: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/library-skills`,
  URL_UI_ATEL_LIBRARY_SKILLS_DEADLINES: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/library-skills-deadlines`,
  URL_UI_ATEL_LIBRARY_QUIZ: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/library-quiz`,
  URL_UI_ATEL_LIBRARY_COURSES: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/library-courses`,
  URL_UI_ATEL_LIBRARY_DEGREES: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/library-degrees`,
  URL_UI_ATEL_LIBRARY_SOURCES: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/library-sources`,
  URL_UI_ATEL_DETAIL_SKILL: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/skill`,
  URL_UI_ATEL_DETAIL_QUIZ: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/quiz`,
  URL_UI_ATEL_DETAIL_COURSE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/course`,
  URL_UI_ATEL_DETAIL_DEGREE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/degree`,
  URL_UI_ATEL_DETAIL_SOURCE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/source`,
  URL_UI_ATEL_CATALOGUE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/catalogue`,
  URL_UI_ATEL_SEARCH: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/search`,
  URL_UI_ATEL_CART: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/cart`,
  URL_UI_ATEL_BOOKMARKS: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING}/bookmarks`,
  URL_UI_ATEL_CORPORATE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING_CORPORATE}`,
  URL_UI_ATEL_CORPORATE_DASHBOARD: `${appConfiguration.APP_BASE_URL}/corporate/elearning-corporate-dashboard`,
  URL_UI_ATEL_CORPORATE_LIBRARY: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING_CORPORATE}/library`,
  URL_UI_ATEL_CORPORATE_LIBRARY_SKILLS: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING_CORPORATE}/library-skills`,
  URL_UI_ATEL_CORPORATE_LIBRARY_QUIZ: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING_CORPORATE}/library-quiz`,
  URL_UI_ATEL_CORPORATE_LIBRARY_COURSES: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING_CORPORATE}/library-courses`,
  URL_UI_ATEL_CORPORATE_LIBRARY_DEGREES: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING_CORPORATE}/library-degrees`,
  URL_UI_ATEL_CORPORATE_CATALOGUE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING_CORPORATE}/catalogue`,
  URL_UI_ATEL_CORPORATE_USERS: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING_CORPORATE}/corporate-users`,
  URL_UI_ATEL_CORPORATE_GROUPS: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_ELEARNING_CORPORATE}/corporate-groups`,

  URL_UI_ADOC_DATABASE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}`,
  // URL_UI_ADOC: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}`,
  URL_UI_ADOC: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}/cChartbox`,
  // URL_UI_ADOC_SEARCH: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}`,
  // URL_UI_ADOC_CATALOGUE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}/catalogue`,
  URL_UI_ADOC_DESKTOP_SAVE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}/cChartbox/save`,
  URL_UI_ADOC_DESKTOP_RESTORE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}/cChartbox/restore`,
  URL_UI_ADOC_APP_SEARCH: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}/cChartbox/search`,
  URL_UI_ADOC_APP_HISTORY: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}/cChartbox/history`,
  /*
  URL_UI_ADOC_DESKTOP_SAVE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}/cChartbox/save/0`,
  URL_UI_ADOC_DESKTOP_RESTORE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}/cChartbox/restore/0`,
  URL_UI_ADOC_APP_SEARCH: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}/cChartbox/search/0`,
  URL_UI_ADOC_APP_HISTORY: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}/cChartbox/history/0`,
  */
  URL_UI_ADOC_LIBRARY: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_DATABASE}/`,

  URL_UI_ATP: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_PUBLISHING}`,
  URL_UI_ATP_LIBRARY: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_PUBLISHING}/library`,
  URL_UI_ATP_LIBRARY_PUBLICATIONS: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_PUBLISHING}/library-publications`,
  URL_UI_ATP_LIBRARY_SOURCES: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_PUBLISHING}/library-sources`,
  URL_UI_ATP_DETAIL_PUBLICATION: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_PUBLISHING}/publication`,
  URL_UI_ATP_SEARCH: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_PUBLISHING}/search`,
  URL_UI_ATP_CART: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_PUBLISHING}/cart`,
  URL_UI_ATP_CORPORATE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_PUBLISHING_CORPORATE}`,
  URL_UI_ATP_CORPORATE_DASHBOARD: `${appConfiguration.APP_BASE_URL}/corporate/publishing-corporate-dashboard`,
  URL_UI_ATP_CORPORATE_LIBRARY: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_PUBLISHING_CORPORATE}/library`,
  URL_UI_ATP_CORPORATE_LIBRARY_PUBLICATIONS: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_PUBLISHING_CORPORATE}/library-publications`,
  URL_UI_ATP_CORPORATE_CATALOGUE: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_PUBLISHING_CORPORATE}/catalogue`,
  URL_UI_ATP_CORPORATE_USERS: `${appConfiguration.APP_BASE_URL}${appConfiguration.APP_BASE_URL_PUBLISHING_CORPORATE}/corporate-users`,

  // Emplacement des locales pour i18next
  URL_UI_LOCALES: `/locales`,

  // Pour les rapports
  URL_REPORT_FILES: '/data/reports/',
  URL_REPORT_FONTS: '/data/reports/fonts',

  /* Criteria pour rapport activity du groupe */
  // Status Utilisateurs
  REPORTS_GROUPS_USER_STATUS_INACTIVE_VALUE: 0,
  REPORTS_GROUPS_USER_STATUS_ACTIVE_VALUE: 1,
  REPORTS_GROUPS_USER_STATUS_ACTIVE_AND_INACTIVE_VALUE: 2,

  // Status incription
  REPORTS_GROUPS_INSCRIPTION_STATUS_INACTIVE_VALUE: 0,
  REPORTS_GROUPS_INSCRIPTION_STATUS_ACTIVE_VALUE: 1,
  REPORTS_GROUPS_INSCRIPTION_STATUS_ACTIVE_AND_INACTIVE_VALUE: 2,

  // Deleted inscription
  REPORTS_GROUPS_INSCRIPTION_STATUS_DELETED_VALUE: 0,
  REPORTS_GROUPS_INSCRIPTION_STATUS_NOT_DELETED_VALUE: 1,
  REPORTS_GROUPS_INSCRIPTION_STATUS_DELETED_AND_NOT_DELETED_VALUE: 2,

  /* Chart reports */
  // Color chart
  REPORT_CHART_DEFAULT_COOLOR : '#595156',
  REPORT_CHART_DEFAULT_BACKROUND_COLOR:  '#0e79bf', // "#BF005B",

  // Les buttons du corporate
  BUTTONS_TITLE: (type) => {
    switch (type) {
      case ACTION_BUTTON_HEADER_TYPE.ADD:
        return 'common.form.buttons.add';
      case ACTION_BUTTON_HEADER_TYPE.REMOVE:
        return 'common.form.buttons.remove';
      case ACTION_BUTTON_HEADER_TYPE.EDIT:
        return 'common.form.buttons.edit';
      case ACTION_BUTTON_HEADER_TYPE.DOWNLOAD:
        return 'common.form.buttons.download';
      case ACTION_BUTTON_HEADER_TYPE.REPORT_ACTIVITY_GROUP:
        return 'reports.button.dropdown.activityReport';
      case ACTION_BUTTON_HEADER_TYPE.REPORT_TRACKING:
        return 'reports.button.dropdown.trackingReport';
      case ACTION_BUTTON_HEADER_TYPE.GENERATE_USER_CERTIFICATE:
        return 'reports.button.dropdown.certificate';
    }
  },

};

export enum ACTION_BUTTON_HEADER_TYPE {
  ADD,
  REMOVE,
  EDIT,
  DOWNLOAD,
  GENERATE_USER_CERTIFICATE,
  REPORT_TRACKING,
  REPORT_ACTIVITY_GROUP
}

/**
 * Les les noms des types
 * Cf. egalement : appConfigurationProperties.PAYMENT_PRODUCT_XXX
 */
export enum CatalogueRequestTypeName {
  LESSON = 'lesson',
  COURSE = 'course',
  DEGREE = 'degree'
}


