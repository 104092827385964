import {ObjectUtil} from './object.util';

export class ArrayUtil {

  /**
   * Fonction qui initialise un tableau à deux dimensions avec une valeur spécifique.
   *
   * @param nbRows - nombre de lignes du tableau à initialiser
   * @param nbColumns  - nombre de colonnes du tableau à initialiser
   * @param initValue - valeur d'initialisation pour chaque cellule du tableau
   */
  static initTwoDimensionalArray(nbRows: number, nbColumns: number, initValue: any): any[][] {
    return Array(nbRows).fill(initValue).map(x => Array(nbColumns).fill(initValue));
  };

  /**
   * Fonction qui supprime les doublons dans un tableau.
   * Fonctionne avec les tableaux d'objets et de type simple (optionalProperty est undefined).
   *
   * @param array
   * @param optionalProperty - propriété optionel utilisé pour la comparaison des objets.
   *        Si la valeur de cette propriété est partagée par plusieurs objets, seul le premier trouvé sera conservé.
   *        Le paramètre "optionalProperty" est un objet de la forme {property: string, ignoreMissing: boolean}.
   *        Le champs "property" est le path de la propriété rechechée dans l'objet pour la comparaison.
   *        Si "ignoreMissing" (defaut: false) est sur true, les objets dont la propriété spécifiée est manquante ou
   *        undefined ne seront pas conservés.
   * @returns
   */
  static arrayUnique(array: any[], optionalProperty?: { property?: string, ignoreMissing?: boolean }): any[] {
    var tab = array.concat();   // Dupliquer le tableau pour ne pas modifier l'original
    if (optionalProperty && optionalProperty.ignoreMissing) {
      tab = tab.filter(function (elem) {
        return (ObjectUtil.getValueByPath(elem, optionalProperty.property) !== undefined);
      });
    }
    for (var i = 0; i < tab.length; ++i) {
      for (var j = i + 1; j < tab.length; ++j) {
        if (typeof optionalProperty === 'undefined') {
          if (tab[i] === tab[j]) {
            tab.splice(j--, 1);
          }
        } else {
          var tabI = ObjectUtil.getValueByPath(tab[i], optionalProperty.property);
          var tabJ = ObjectUtil.getValueByPath(tab[j], optionalProperty.property);
          if (tabI === tabJ) {
            tab.splice(j--, 1);
          }
        }
      }

    }
    return tab;
  };

  /**
   * Fonction qui supprime les doublons dans un tableau à l'aide d'une fonction d'égalité.
   * La fonction ne modifie pas le tableau original. Elle retourne un nouveau tableau.
   *
   * Remarque:
   *  - Cette fonction peut être combinée avec les fonctions "compareObjectsDataDriven" et "compareObjectsStructureDriven"
   *    pour supprimer les doublons dans des objets complexes :
   *          helpersUtils.arrayUniqueFct(array, (a, b) => helpersUtils.compareObjectsStructureDriven(a, b).isEqual);
   *
   * @param array
   * @param {(a, b) => boolean} equalFunction Fonction de test d'égalité des éléments, si undefined utilisation de === .
   * @returns {*}
   */
  static arrayUniqueFct(array: any[], equalFunction: Function): any[] {
    var tab = array.concat();   // Dupliquer le tableau pour ne pas modifier l'original
    for (var i = 0; i < tab.length; ++i) {
      for (var j = i + 1; j < tab.length; ++j) {
        //if (typeof equalFunction === 'function') {
          if (equalFunction(tab[i], tab[j])) {
            tab.splice(j--, 1);
          }
          /*
        } else {
          if (tab[i] === tab[j]) {
            tab.splice(j--, 1);
          }
        }
        */
      }
    }
    return tab;
  };

  /**
   * Fonction qui compte les doublons dans un tableau.
   *
   * Exemples:
   *      countDuplicateElements(["dog", "dog", "cat", "buffalo", "wolf", "cat", "tiger", "cat"])     => { dog: 2, cat: 3, buffalo: 1, wolf: 1, tiger: 1 }
   *      countDuplicateElements([
   *          {id: 1, degreeId: 1, name: "idriss", info: {nb: 10, score: 5}},
   *          {id: 2, degreeId: 2, name: "nina", info: {nb: 20, score: 25}},
   *          {id: 3, degreeId: 1, name: "bek", info: {nb: 30, score: 25}},
   *          {id: 4, degreeId: 3, name: "grizzly", info: {nb: 40, score: 20}},
   *          {id: 3, degreeId: 1, name: "ivan", info: {nb: 50, score: 25}}],
   *      "info.score")   => { 5: 1, 20: 1, 25: 3 }
   *
   * Remarque : Utilisation de cette fonction pour détecter si un tableau a des doublons :
   *            Javascript :
   *                  Object.values(countDuplicateElements(["dog", "dog", "cat", "buffalo", "wolf", "cat", "tiger", "cat"])).some((element) => element > 1);
   *            Typescript :
   *                  let data = countDuplicateElements(["dog", "dog", "cat", "buffalo", "wolf", "cat", "tiger", "cat"]);
   *                  Object.keys(data).map(key => data[key]).some((element) => element > 1);
   *
   * @param array
   * @param stringProperty - paramètre optionel servant à servir de clé pour chercher les doublons dans une propriété donnée des objets (string)
   * @returns
   */
  static countDuplicateElements(array: any[], stringProperty?: string): any {
    var counts = {};
    array.forEach(function (x) {
      if (stringProperty) {
        x = ObjectUtil.getValueByPath(x, stringProperty);
      }
      if (x) {
        counts[x] = (counts[x] || 0) + 1;
      }
    });
    return counts;
  }

  /**
   * Fonction qui retire de "referenceArray" les éléments présents dans "elementsToSubstactFormReference".
   * Exemple :
   *      A = [1, 2, 3, 4];
   *      B = [1, 3, 4, 7];
   *      arrayDifference(A, B);  --> 2
   *      arrayDifference(B, A);  --> 7
   *
   * @param referenceArray
   * @param elementsToSubstactFormReference
   * @returns
   */
  static arrayDifference(referenceArray: any[], elementsToSubstactFormReference: any[]): any[] {
    return referenceArray.filter(function (x) {
      return elementsToSubstactFormReference.indexOf(x) < 0
    });
  }

  /**
   * Fonction qui extrait, en fonction de la valeur du boolean "max", la valeur minimum ou le maximum de la propriété "property"
   * des objets contenus dans le tableau "array".
   *
   * @param array - le tableau
   * @param property - la propriété des objets sur le quel se base la comparaison (string)
   * @param max - boolean indiquant qui l'on veut la valeur maximum (true) ou minimum (false) - defaut: max.
   * @returns
   */
  static getExtremePropertyFromArray(array: any[], property: string, max: boolean): any {
    if (!array || !property) {
      return undefined;
    }
    let tmp, res;
    max = (max === undefined) ? true : max;
    for (let i = array.length - 1; i >= 0; i--) {
      tmp = ObjectUtil.getValueByPath(array[i], property);
      if (res) {
        if (max) {
          res = (tmp > res) ? tmp : res;
        } else {
          res = (tmp < res) ? tmp : res;
        }
      } else {
        res = tmp;
      }
    }
    return res;
  }

  /**
   * Fonction qui supprime du tableau "array" les éléments passés en paramètre.
   * Cette fonction modifie le tableau passé en paramètre.
   * Exemple :
   *      var arr = [{x: 1, y: 2}, {x: 3, y: 4}, {x: 5, y: 6}, {x: 7, y: 8}, {x: 9, y: 0}];
   *      function comparator(a, b) {
   *         return a.x === b.x && a.y === b.y
   *      }
   *      removeElementsEqual(arr, comparator, {x: 1, y: 2}, {x: 9, y: 0});       --> [{x: 3, y: 4}, {x: 5, y: 6}, {x: 7, y: 8}]
   *      removeElementsEqual(arr, comparator, ...[{x: 1, y: 2}, {x: 9, y: 0}]);  --> [{x: 3, y: 4}, {x: 5, y: 6}, {x: 7, y: 8}] (l'argument "values" est passé sous forme de tableau)
   *      removeElementsEqual(arr, comparator, {x: 3, y: 4});                     --> [{x: 1, y: 2}, {x: 5, y: 6}, {x: 7, y: 8}, {x: 9, y: 0}]
   *      removeElementsEqual(arr, comparator, {x: 3, y: 7});                     --> [{x: 1, y: 2}, {x: 3, y: 4}, {x: 5, y: 6}, {x: 7, y: 8}, {x: 9, y: 0}]
   *
   * Remarque:
   *   - Dans le comparateur le premier paramètre est un des éléments contenu dans le tableau "array"
   *     et le deuxième paramètre représente le paramètre "value" de la fonction.
   *
   * @param array - le tableau
   * @param comparator
   * @param values
   * @returns
   */
  static removeElementsEqual(array, comparator, ...values) {
    let what, L = values.length, ax;
    while (L > 0 && array.length) {
      what = values[--L];
      while ((ax = this.indexOfEqual(array, comparator, what)) !== -1) {
        array.splice(ax, 1);
      }
    }
    return array;
  };

  /**
   * Fonction qui retourne l'indice d'un element du tableau "array" en utilisant la fonction
   * "comparator" pour déterminer l'égalité entre les éléments.
   * Retourne -1 si l'élément n'a pas pu être trouvé.
   * Exemple :
   *      var arr = [{x: 1, y: 2}, {x: 3, y: 4}];
   *      indexOfEqual(arr, (a, b) => {return a.x === b.x && a.y === b.y}, {x: 3, y: 4});   --> 1
   *      indexOfEqual(arr, (a, b) => (a.x === b.x && a.y === b.y), {x: 3, y: 4});   --> 1
   *      indexOfEqual(arr, (a, b) => {return a.x === b.x && a.y === b.y}, {x: 3, y: 7});   --> -1
   *      indexOfEqual(arr, (a, b) => (a.x === b.x && a.y === b.y), {x: 3, y: 7});   --> -1
   *
   * Remarque:
   *   - Dans le comparateur le premier paramètre est un des éléments contenu dans le tableau "array"
   *     et le deuxième paramètre représente le paramètre "value" de la fonction.
   *   - On peut également utiliser la fonction "Array.prototype.findIndex()" à la place de cette fonction
   *     pour les tableaux simples.
   *
   * @param array
   * @param comparator
   * @param value - la valeur à rechercher dans le tableau
   * @returns
   */
  static indexOfEqual(array, comparator, value) {
    for (let i = 0; i < array.length; i++) {
      if (comparator(array[i], value)) {
        return i;
      }
    }
    return -1;
  };

  /**
   * Fonction qui retourne l'intersection de deux tableaux.
   * Pour fonctionner les tableaux doivent être ordonnés, la
   * fonction procède au trie des éléments des tableaux mais,
   * si les tableaux sont déjà triés, cette étape peut être
   * ignorée grâce au paramètre "arraysAreSorted". Ceci permet
   * d'optimiser la fonction en ne triant pas des tableaux dèjà
   * triés.
   *
   * NOTES
   *  Should have O(n) operations, where n is
   *    n = MIN(a.length(), b.length())
   *
   * @param a - first array
   * @param b - second array
   * @param arraysAreSorted - true si les tableaux sont déjà ordonnés (évite à la fonction de le faire pour l'optimisation)
   * @returns
   */
  static intersect(a: any[], b: any[], arraysAreSorted: boolean = false): any[] {
    let ai = 0, bi = 0;
    let result = [];

    let _a = a;
    let _b = b;
    if (!arraysAreSorted) {
      _a = JSON.parse(JSON.stringify(a)).sort();
      _b = JSON.parse(JSON.stringify(b)).sort();
    }

    while (ai < _a.length && bi < _b.length) {
      if (_a[ai] < _b[bi]) {
        ai++;
      } else if (_a[ai] > _b[bi]) {
        bi++;
      } else /* they're equal */
      {
        result.push(_a[ai]);
        ai++;
        bi++;
      }
    }

    return result;
  }

  /**
   * Fonction qui groupe les éléments de type objet d'un tableau selon une clé.
   * Tous les objets du tableau dont la valeur de la clé sont identiques seront regroupé ensemble.
   * Retourne undefined si le tableau d'entré est invalide.
   *
   * Les objets ne possédant pas la clé sont tous regroupés dans un élément du tableau de retour dont la proprété "key" est égale à undefined.
   *
   * Le paramètre "key" peut être :
   *      - une string contenant le path de la propriété servant à grouper les éléments (ex: 'Phase', 'part3[0].name', 'x.y', ...).
   *      - une fonction, dans ce cas la valeur de la clé sera déterminée en appliquant cette fonction à chaque objet du tableau.
   *
   * Exemple :
   *      var tab = [
   *          { Phase: "Phase 1", Step: "Step 1", Task: "Task 1", Value: "5" },
   *          { Phase: "Phase 1", Step: "Step 1", Task: "Task 2", Value: "10" },
   *          { Phase: "Phase 1", Step: "Step 2", Task: "Task 1", Value: "15" },
   *          { Phase: "Phase 1", Step: "Step 2", Task: "Task 2", Value: "20" },
   *          { Phase: "Phase 2", Step: "Step 1", Task: "Task 1", Value: "25" },
   *          { Phase: "Phase 2", Step: "Step 1", Task: "Task 2", Value: "30" },
   *          { Phase: "Phase 2", Step: "Step 2", Task: "Task 1", Value: "35" },
   *          { Phase: "Phase 2", Step: "Step 2", Task: "Task 2", Value: "40" },
   *          { Step: "Step 3", Task: "Task 1", Value: "10" }     // Ne contient pas de propriété 'Phase'
   *      ];
   *      groupBy(tab, "Phase"); -->
   *
   *      [
   *        {
   *          key: "Phase 1",
   *          values: [
   *              {Phase: "Phase 1", Step: "Step 1", Task: "Task 1", Value: "5"},
   *              {Phase: "Phase 1", Step: "Step 1", Task: "Task 2", Value: "10"},
   *              {Phase: "Phase 1", Step: "Step 2", Task: "Task 1", Value: "15"},
   *              {Phase: "Phase 1", Step: "Step 2", Task: "Task 2", Value: "20"}
   *          ]
   *        },
   *        {
   *          key: "Phase 2",
   *          values: [
   *              {Phase: "Phase 2", Step: "Step 1", Task: "Task 1", Value: "25"},
   *              {Phase: "Phase 2", Step: "Step 1", Task: "Task 2", Value: "30"},
   *              {Phase: "Phase 2", Step: "Step 2", Task: "Task 1", Value: "35"},
   *              {Phase: "Phase 2", Step: "Step 2", Task: "Task 2", Value: "40"}
   *          ]
   *        },
   *        {
   *          key: undefined,
   *          values: [
   *              {Step: "Step 3", Task: "Task 1", Value: "10"}
   *          ]
   *        }
   *      ]
   *
   * @param array
   * @param key
   * @returns
   */
  static groupBy(array: any[], key: string | Function) {
    if (array && ObjectUtil.isArray(array)) {
      return array.reduce(function (rv, x) {
        // let v = key instanceof Function ? key(x) : x[key];
        let v = key instanceof Function ? key(x) : ObjectUtil.getValueByPath(x, key);
        let el = rv.find((r) => {
          return (r && (
              ((r.key === undefined) && (v === undefined)) ||
              ((r.key !== undefined) && (v !== undefined) && (r.key.toString() === v.toString()))
            )
          );
        });
        if (el) {
          el.values.push(x);
        } else {
          rv.push({key: v, values: [x]});
        }
        return rv;
      }, []);
    } else {
      return undefined;
    }
  };

  /**
   * Fonction qui retourne un nouveau tableau dont les éléments sont transposés selon
   * les paramètres de transposition spécifié.
   *
   * @param array - Le tableau dont les éléments doivent être transposés.
   * @param indicesToTranspose - Tableau d'entiers.
   * @param direction - Boolean (true = up, false = down).
   * @param offset - Entier positif indiquant l'offset de transposition (nombre positions de déplacement).
   *                 Si 0 ou undefined, tous les éléments sont déplacer en haut ou en bas du tableau en fonction de la direction.
   *                 Lorsque les éléments transposés montent ou descendent jusqu'a "buter" sur les limites du tableau. Les
   *                 éléments à transposer peuvent dépasser des éléments ne devant pas être transposer mais ne peuvent pas
   *                 dépasser d'autres éléments à transposer qui étaient devant ou derrière eux en fonction de la direction.
   */
  static transposeElements(array: any[], indicesToTranspose: number[], direction: boolean, offset: number) {
    if (ObjectUtil.isArray(array) && (array.length > 0) &&
      ObjectUtil.isArray(indicesToTranspose) && (indicesToTranspose.length > 0)) {
      // Suppression des doublons dans les indices à transposer
      indicesToTranspose = this.arrayUnique(indicesToTranspose);
      // On clone le tableau pour ne pas le modifier
      let arrayCopy = [];
      array.forEach(elem => {
        arrayCopy.push(elem);
      });
      // Si l'offset est 0 ou undefined on met un offest égale à la taille du tableau pour forcer tous les
      // éléments à se déplacer aux extrémités.
      if ((offset === undefined) || (offset === 0)) {
        offset = array.length;
      }
      // Création du tableau de transposition des indices
      let transposeIndices = [];
      for (let i = 0; i < arrayCopy.length; i++) {
        transposeIndices.push({
          transpose: indicesToTranspose.includes(i),  // Doit on transposer cet élément
          transposed: false,                          // L'élément a été transposé (traitement déjà effectué pour cet élément)
          oldIndice: i,                               // L'indice de l'élément dans le tableau d'origine
          newIndice: i                                // L'indice de l'élément dans le nouveau tableau
        });
      }
      // On détermine les nouveaux indices des éléments à transposer
      if (direction) { // Déplacement des éléments vers le haut
        // Pour le bon fonctionnement de l'algorithme, on trie en ordre croissant les indices des éléments à transposer
        indicesToTranspose.sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        });
        // On parcours le tableau de transposition des indices
        for (let i = 0; i < transposeIndices.length; i++) {
          let elem = transposeIndices[i];
          if (elem.transpose) {   // L'élément doit être transposer
            // On calcule son nouvel indice idéal (en veillant à ce qu'il reste dans les limites du tableau)
            let newIndice = ((elem.oldIndice - offset) < 0) ? 0 : (elem.oldIndice - offset);
            let currentIndice, finalIndice;
            let found = false;  // Indique que l'on a trouver la nouvelle place de l'élément
            if (elem.oldIndice === newIndice) { // Le nouvel indice est le même que sa position actuelle
              finalIndice = elem.oldIndice;   // On ne change pas son indice
              found = true;                   // On précise que l'on a trouvé l'élément
            } else {
              // On cherche la nouvelle position de l'élément
              // en parcourant tous les éléments qui sont au dessus de lui
              // on s'arrête à la position qu'il devrait normalement avoir avec son nouvel indice
              for (let j = elem.oldIndice; j > newIndice; j--) {
                currentIndice = j - 1;  // L'indice en cours
                if (transposeIndices[currentIndice].transposed) {
                  // On s'arrête au premier élément qui a été déjà transposé lors d'une itération précédente
                  // car un élément transposé ne peut pas dépasser un élément qui a été transposé avant lui
                  // (il faut conserver l'ordre des éléments)
                  finalIndice = currentIndice + 1;    // Le nouvel indice (qui est inférieur à l'indice idéal calculé)
                  found = true;   // On précise que l'on a trouvé l'élément
                  break;          // On arrête les itérations
                }
                if ((currentIndice === 0) || (currentIndice === newIndice)) {
                  // On est remonté jusqu'au nouvel indice idéal calculé car aucun élément
                  // déjà transposé n'a été trouvé pour bloquer sa remonté.
                  finalIndice = currentIndice;    // Le nouvel indice est l'indice idéal calculé
                  found = true;   // On précise que l'on a trouvé l'élément
                  break;          // On arrête les itérations
                }
              }
            }
            if (found && (finalIndice !== undefined)) { // Test probablement inutile
              // L'élément a été trouvé
              elem.newIndice = finalIndice;   // On enegistre son nouvel indice
              elem.transposed = true;         // On indique que l'élément a été transposé dans cette itération.
                                              // Permet d'éventuellement bloquer d'autres éléments pour qu'ils ne le dépassent pas dans leur transposition
              // On modifie les indices des éléments situés entre l'ancienne position de l'élément et sa nouvelle position
              for (let j = finalIndice; j < elem.oldIndice; j++) {
                transposeIndices[j].newIndice++;
              }
              // On trie le tableau de transposition des indices
              transposeIndices.sort((a, b) => {
                if (a.newIndice < b.newIndice) {
                  return -1;
                }
                if (a.newIndice > b.newIndice) {
                  return 1;
                }
                return 0;
              });
            }
          }
        }
      } else {    // Déplacement des éléments vers le bas
        // Pour le bon fonctionnement de l'algorithme, on trie en ordre décroissant les indices des éléments à transposer
        indicesToTranspose.sort((a, b) => {
          if (a < b) {
            return 1;
          }
          if (a > b) {
            return -1;
          }
          return 0;
        });
        // On parcours le tableau de transposition des indices
        for (let i = transposeIndices.length - 1; i >= 0; i--) {
          let elem = transposeIndices[i];
          if (elem.transpose) {   // L'élément doit être transposer
            // On calcule son nouvel indice idéal (en veillant à ce qu'il reste dans les limites du tableau)
            let newIndice = ((elem.oldIndice + offset) > (transposeIndices.length - 1)) ? (transposeIndices.length - 1) : (elem.oldIndice + offset);
            let currentIndice, finalIndice;
            let found = false;  // Indique que l'on a trouver la nouvelle place de l'élément
            if (elem.oldIndice === newIndice) { // Le nouvel indice est le même que sa position actuelle
              finalIndice = elem.oldIndice;   // On ne change pas son indice
              found = true;                   // On précise que l'on a trouvé l'élément
            } else {
              // On cherche la nouvelle position de l'élément
              // en parcourant tous les éléments qui sont au dessous de lui
              // on s'arrête à la position qu'il devrait normalement avoir avec son nouvel indice
              for (let j = elem.oldIndice; j < newIndice; j++) {
                currentIndice = j + 1;  // L'indice en cours
                if (transposeIndices[currentIndice].transposed) {
                  // On s'arrête au premier élément qui a été déjà transposé lors d'une itération précédente
                  // car un élément transposé ne peut pas dépasser un élément qui a été transposé avant lui
                  // (il faut conserver l'ordre des éléments)
                  finalIndice = currentIndice - 1;    // Le nouvel indice (qui est supérieur à l'indice idéal calculé)
                  found = true;   // On précise que l'on a trouvé l'élément
                  break;          // On arrête les itérations
                }
                if ((currentIndice === (transposeIndices.length - 1)) || (currentIndice === newIndice)) {
                  // On est descendu jusqu'au nouvel indice idéal calculé car aucun élément
                  // déjà transposé n'a été trouvé pour bloquer sa descente.
                  finalIndice = currentIndice;    // Le nouvel indice est l'indice idéal calculé
                  found = true;   // On précise que l'on a trouvé l'élément
                  break;          // On arrête les itérations
                }
              }
            }
            if (found && (finalIndice !== undefined)) { // Test probablement inutile
              // L'élément a été trouvé
              elem.newIndice = finalIndice;   // On enegistre son nouvel indice
              elem.transposed = true;         // On indique que l'élément a été transposé dans cette itération.
                                              // Permet d'éventuellement bloquer d'autres éléments pour qu'ils ne le dépassent pas dans leur transposition
              // On modifie les indices des éléments situés entre l'ancienne position de l'élément et sa nouvelle position
              for (let j = finalIndice; j > elem.oldIndice; j--) {
                transposeIndices[j].newIndice--;
              }
              // On trie le tableau de transposition des indices
              transposeIndices.sort((a, b) => {
                if (a.newIndice < b.newIndice) {
                  return -1;
                }
                if (a.newIndice > b.newIndice) {
                  return 1;
                }
                return 0;
              });
            }
          }
        }
      }
      // On déplace les éléments selon leur nouvel ordre
      transposeIndices.forEach((elem, i) => {
        arrayCopy[i] = array[elem.oldIndice];
      });
      return arrayCopy;
    } else {
      return array;
    }
  };

  /**
   * Fonction qui retourne un sous ensemble d'un tableau selon les options de pagination passés en paramètre.
   * Cette fonction utilise l'algorithme de pagination du fichier "mongoose-utils/mongoose-paginate.js".
   *
   * Retourne un tableau vide si l'offset des options de pagination est hors limite.
   * Si le paramètre "pagination" est spécifié mais ne contient pas de limit, la valeur par défaut (DEFAULT_LIMIT = 10) sera utilisé.
   * Si le paramètre "pagination" n'est pas spécifié la limit par défaut sera utilisé (DEFAULT_LIMIT = 10) et l'offset sera 0.
   *
   * Format de retour :
   *      {
   *          list: <tableau sous-ensemble du tableau "array" selon les options de pagination>,
   *          count: <nombre total d'élément du tableau "array">
   *      }
   *
   * @param array
   * @param pagination - format: {offset: <nombre>, limit: <nombre>, page: <nombre>}
   */
  static paginate(array: any[], pagination: { offset?: number, limit?: number, page?: number }): { list: any[], count: number } {
    let DEFAULT_LIMIT = 10;
    let offset, limit;

    if (ObjectUtil.isArray(array)) {
      // Définition des options de pagination
      if (!pagination) pagination = {};
      limit = (typeof pagination.limit === 'number') ? pagination.limit : DEFAULT_LIMIT;
      if (typeof pagination.offset === 'number') {
        offset = pagination.offset;
      } else if (typeof pagination.page === 'number') {
        offset = (pagination.page * limit) - limit;
      } else {
        offset = 0;
      }
      // Cas des valeurs négatives
      if (offset < 0) {
        offset = 0;
      }
      if (limit < 0) {
        limit = 0;
      }
      // Le nombre total d'éléménts
      let count = array.length;
      // Traitement du tableau
      return {
        list: array.slice(offset, offset + limit) || [],
        count: count
      }
    } else {
      return {
        list: [],
        count: 0
      };
    }
  }

  /**
   * Fonction qui retourne la valeur la plus proche dans un tableau de valeurs numériques.
   * Retourne 'undefined en car de problème.
   *
   * Exemple :
   *      const list = [1, 10, 7, 2, 4];
   *      const value = 7;
   *      closestValue(value, list);  => 7
   *
   * @param value
   * @param list
   */
  static closestValue(value: number, list: number[]): number {
    try {
      return list.reduce((a, b) => {
        let aDiff = Math.abs(a - value);
        let bDiff = Math.abs(b - value);

        if (aDiff == bDiff) {
          return a > b ? a : b;
        } else {
          return bDiff < aDiff ? b : a;
        }
      });
    } catch (err) {
      return undefined;
    }
  }

  /**
   * Fonction qui fusionne deux tabeaux en supprimant les éléments dupliqués.
   *
   * Exemples :
   *  mergeWithoutDuplicates(['a', 'b', 'c'], ['c', 'x', 'd']);
   *      // => ['a', 'b', 'c', 'x', 'd']
   *  mergeWithoutDuplicates([{id: 1}, {id: 2}], [{id: 2}, {id: 3}], (a, b) => a.id === b.id);
   *      // => [{id: 1}, {id: 2}, {id: 3}]
   *
   * @param {array} arr1
   * @param {array} arr2
   * @param {function} isEqual La valeur par défaut est le teste d'égalité ===
   */
  static mergeWithoutDuplicates(arr1: any[], arr2: any[], isEqual = (a: any, b: any) => a === b): any[] {
    const arr3 = [...arr1]; // copy to avoid side effects
    // add all items from arr2 to copy arr3 if they're not already present
    arr2.forEach((bItem) => (arr3.some((cItem) => isEqual(bItem, cItem)) ? null : arr3.push(bItem)))
    return arr3;
  };
}
