import {UserTeachingDb} from '../../../modules/ng-models-atel3/models/db/userAtel/UserTeachingDb';
import {UserProductInfoElearning} from '../../../modules/ng-models-atel3/models/db/userAtel/UserProductElearning';
import {UserNotification} from '../../../shared/notificationBell.service';
import {ObjectUtil} from '../../../modules/ng-helpers-util/object.util';
import {ArrayUtil} from '../../../modules/ng-helpers-util/array.util';
import {atelProperties} from '../../../modules/ng-properties-application/atel.properties';
import {CommonUtil} from '../../../modules/ng-helpers-util/common.util';
import {UserProductStatus} from '../../../modules/ng-models-shared/models/db/UserProduct';
import {CurrentUserService} from '../../../modules/ng-manager-user/services/current-user.service';
import {StringUtil} from '../../../modules/ng-helpers-util/string.util';
import {LearningContainer} from '../../../modules/ng-models-atel3/models/db/LearningContainer';

export abstract class UserTeachingContainer {

  public _id: string; // extrait de l'_id du degree, du course ou de la source pour identifier de manière unique les éléments
                      // graphiques quand ils sont dans des listes
  public type: string; // extrait type du degree ou du course ou de la source pour identifier de manière  les éléments
                      // graphiques quand ils sont dans des listes

  // Les teaching du cours ou de degree de l'utilisateur (classés selon l'ordre des teachings du cours établit lors de
  // sa création, certains éléments du tableau peuvent être undefined).
  // (peuvent être différentes de la liste des lessons du cours si ce dernier à changé depuis la souscription du cours par l'utilisateur)
  public userTeachings: UserTeachingDb[] = [];

  public info: UserProductInfoElearning;
  public status: UserProductStatus;
  public actions: UserNotification[];

  /**
   * Fonction qui retourne le userTeaching de la librairie de l'utilisateur à partir de l'_id du teaching.
   * Retourne undefined si l'élément n'a pas été trouvé.
   *
   * @throws AipError
   */
  getUserTeachingFromTeachingId(teachingId: string): UserTeachingDb {
    if (StringUtil.isUndefinedOrEmpty(teachingId)) {
      return undefined;
    } else {
      return this.userTeachings.find(userTeaching => {
        return (
          (
            ((userTeaching.teaching_ref as string) === teachingId) ||
            ((ObjectUtil.hasProperty(userTeaching, 'teaching_ref', '_id') &&
              (userTeaching.teaching_ref as LearningContainer)._id === teachingId))
          ) &&
          userTeaching.isUserTeachingValid(false));
      });
    }
  }

  /**
   * Générations des popropiétés "status" et "info" à partir des userTeachings.
   *
   * ATTENTION: Le paramètre "userTeachings" est optionnel donc toutes les fonctions
   *            appellée dans cette fonction doivent fonctionner avec la valeur
   *            undefined du paramètre "userTeachings".
   *
   * FIXME : Voir si on peut le mettre dans le constructeur
   */
  generateProperties(userTeachings?: UserTeachingDb[]): void {
    this.info = new UserProductInfoElearning({
      nbConn: this.getNbConn(userTeachings),
      firstConn: this.getFirstConn(userTeachings),
      lastConn: this.getLastConn(userTeachings),
      totalTime: this.getTotalTime(userTeachings),
      success: this.getSuccess(userTeachings),
      status: this.getStatus(userTeachings),
      scoreTemporary: this.getScoreTemporary(userTeachings),
      scoreAuto: this.getScoreAuto(userTeachings),
      score: this.getScore(userTeachings),
      progress: this.getProgression(userTeachings),
      points: this.getPoints(userTeachings),
      nbAttempt: this.getNbAttempt(userTeachings),
    });
    this.status = new UserProductStatus({
      frozen: this.isFrozen(userTeachings),
      active: this.isActive(userTeachings),
      expired: this.isExpired(userTeachings),
      duration: this.getDuration(userTeachings),
      activationDate: this.getActivationDate(userTeachings),
      expiryDate: this.getExpiryDate(userTeachings),
      creationDate: this.getCreationDate(userTeachings),
      modificationDate: this.getModificationDate(userTeachings),
      deleted: this.isDeleted(userTeachings),
      deletionDate: this.getDeletionDate(userTeachings),
    });
    this.actions = this.getActions(userTeachings);
  }

  /**
   * Fonction qui retourne true si le teachingId est présent dans la liste des userTeachings.
   */
  public hasUserTeaching(teachingId): boolean {
    return this.userTeachings.findIndex(elem => elem.teaching_ref === teachingId) !== -1;
  }

  /**
   * Le userProduct (userLesson, userQuiz, userCourse, userDegree, userSource) est valide.
   */
  public isUserProductValid(considerUserTeachingActiveFlag: boolean = false): boolean {
    if (this.status) {
      return (considerUserTeachingActiveFlag)
        ? this.status.active && !this.status.expired && !this.status.deleted
        : !this.status.deleted;
    } else {
      return false;
    }
  }

  /**
   * Fonction qui retourne true si au moins un teaching est frozen.
   * Retourne false sinon.
   */
  public isFrozen(userTeachings: UserTeachingDb[] = this.userTeachings): boolean {
    return userTeachings.some(
      elem => ObjectUtil.hasProperty(elem, 'status', 'frozen') && elem.status.frozen);
  }

  /**
   * Fonction qui retourne true si :
   *    - les userTeachings ne sont pas valides
   *    - il n'y a pas de userTeaching (pas de lesson dans le cours/degree)
   *    - les userTeachings sont tous unedefined (cours/degree non possédé par l'utilisateur)
   *    - au moins un teaching est active
   * Retourne false sinon.
   */
  public isActive(userTeachings: UserTeachingDb[] = this.userTeachings): boolean {
    if (
      !ObjectUtil.isArray(userTeachings) ||
      (userTeachings.length === 0) ||
      userTeachings.every(elem => elem === undefined)
    ) {
      return true;
    } else {
      return userTeachings.some(
        elem => ObjectUtil.hasProperty(elem, 'status', 'active') && elem.status.active);
    }
  }

  /**
   * Fonction qui retourne true si au moins un teaching est expired.
   * Retourne false sinon.
   */
  public isExpired(userTeachings: UserTeachingDb[] = this.userTeachings): boolean {
    return userTeachings.some(
      elem => ObjectUtil.hasProperty(elem, 'status', 'expired') && elem.status.expired);
  }

  /**
   * Fonction qui retourne true si tous les teachings sont deleted.
   * Retourne false sinon.
   */
  public isDeleted(userTeachings: UserTeachingDb[] = this.userTeachings): boolean {
    // .every rend true sur les tableaux vides donc un degree sans userDependencies
    // (ce qui est le cas qd on se connecte la premiere fois a AIP et qu'on affiche le catalogue: comme on a fait aucune operation d'achat les userDependancies sont vides )
    // est considere deleted :  on evite ca avec le test (userTeachings.length > 0)
    return (userTeachings.length > 0) && userTeachings.every(
      // return userTeachings.some(
      elem => ObjectUtil.hasProperty(elem, 'status', 'deleted') && elem.status.deleted);
  }

  /**
   * Fonction qui retourne une duration si elle existante est identique pour tous les teachings.
   * Retourne undefined sinon.
   */
  public getDuration(userTeachings: UserTeachingDb[] = this.userTeachings): number {
    if ((userTeachings.length > 0) &&
      userTeachings.every(elem =>
        ObjectUtil.hasProperty(elem, 'status', 'duration') && // Toutes les durations sont existantes
        ObjectUtil.isNumeric(elem.status.duration) &&                 // Toutes les durations sont des nombres
        ObjectUtil.hasProperty(userTeachings[0], 'status', 'duration') &&
        (userTeachings[0].status.duration === elem.status.duration))  // Toutes les durations sont identiques
    ) {
      return userTeachings[0].status.duration;
    } else {
      return undefined;
    }
  }

  /**
   * Fonction qui retourne l'activationDate la plus récente parmis toutes les userTeachings.
   * Retourne undefined si aucune activationDate n'a été trouvée dans les userTeachings.
   */
  public getActivationDate(userTeachings: UserTeachingDb[] = this.userTeachings): Date {
    return ArrayUtil.getExtremePropertyFromArray(
      userTeachings, 'status.activationDate', false);
  }

  /**
   * Fonction qui retourne l'expiryDate la plus récente parmis toutes les userTeachings.
   * Retourne undefined si aucune expiryDate n'a été trouvée dans les userTeachings.
   */
  public getExpiryDate(userTeachings: UserTeachingDb[] = this.userTeachings): Date {
    return ArrayUtil.getExtremePropertyFromArray(
      userTeachings, 'status.expiryDate', false);
  }

  /**
   * Fonction qui retourne la creationDate la plus ancienne parmis toutes les userTeachings.
   * Retourne undefined si aucune creationDate n'a été trouvée dans les userTeachings.
   */
  public getCreationDate(userTeachings: UserTeachingDb[] = this.userTeachings): Date {
    return ArrayUtil.getExtremePropertyFromArray(
      userTeachings, 'status.creationDate', true);
  }

  /**
   * Fonction qui retourne la modificationDate la plus récente parmis toutes les userTeachings.
   * Retourne undefined si aucune modificationDate n'a été trouvée dans les userTeachings.
   */
  public getModificationDate(userTeachings: UserTeachingDb[] = this.userTeachings): Date {
    return ArrayUtil.getExtremePropertyFromArray(
      userTeachings, 'status.modificationDate', false);
  }

  /**
   * Fonction qui retourne la deletionDate la plus récente parmis toutes les userTeachings.
   * Retourne undefined si aucune deletionDate n'a été trouvée dans les userTeachings.
   */
  public getDeletionDate(userTeachings: UserTeachingDb[] = this.userTeachings): Date {
    return (this.isDeleted())
      ? ArrayUtil.getExtremePropertyFromArray(userTeachings, 'status.deletionDate', false)
      : undefined;
  }

  /**
   * Fonction qui retourne le nombre de connxions de l'utilisateur à patir des userTeachings.
   */
  public getNbConn(userTeachings: UserTeachingDb[] = this.userTeachings): number {
    let res = 0;
    userTeachings.forEach(userTeaching => {
      if (
        userTeaching &&
        userTeaching.isUserTeachingValid(false) &&
        ObjectUtil.hasProperty(userTeaching, 'info', 'nbConn') &&
        ObjectUtil.isInt(userTeaching.info.nbConn)) {
        res += userTeaching.info.nbConn;
      }
    });
    return res;
  }

  /**
   * Fonction qui retourne la firstConn la plus récente parmis toutes les userTeachings.
   * Retourne undefined si aucune firstConn n'a été trouvée dans les userTeachings.
   */
  public getFirstConn(userTeachings: UserTeachingDb[] = this.userTeachings): Date {
    return ArrayUtil.getExtremePropertyFromArray(
      userTeachings, 'info.firstConn', false);
  }

  /**
   * Fonction qui retourne la lastConn la plus ancienne parmis toutes les userTeachings.
   * Retourne undefined si aucune lastConn n'a été trouvée dans les userTeachings.
   */
  public getLastConn(userTeachings: UserTeachingDb[] = this.userTeachings): Date {
    return ArrayUtil.getExtremePropertyFromArray(
      userTeachings, 'info.lastConn', true);
  }

  /**
   * Fonction qui retourne le "success" de l'utilisateur à patir des userTeachings.
   * Retourne dans l'ordre :
   *    - LESSON_SUCCESS_STATUS_UNKNOWN si au moins element est LESSON_SUCCESS_STATUS_UNKNOWN
   *    - LESSON_SUCCESS_STATUS_FAILED si au moins element est LESSON_SUCCESS_STATUS_FAILED
   *    - LESSON_SUCCESS_STATUS_PASSED si tous les elements sont LESSON_SUCCESS_STATUS_PASSED
   *    - LESSON_SUCCESS_STATUS_UNKNOWN sinon
   */
  public getSuccess(userTeachings: UserTeachingDb[] = this.userTeachings): string {
    if (userTeachings.some(elem => (
      !elem ||  // Un element est undefined
      (elem &&
        elem.isUserTeachingValid(false) &&
        ObjectUtil.hasProperty(elem, 'info', 'success') &&
        (elem.info.success === atelProperties.LESSON_SUCCESS_STATUS_UNKNOWN))))) {
      // On a détecté au moins un teaching avec le "success" LESSON_SUCCESS_STATUS_UNKNOWN
      return atelProperties.LESSON_SUCCESS_STATUS_UNKNOWN;
    } else if (userTeachings.some(elem => (
      elem &&
      elem.isUserTeachingValid(false) &&
      ObjectUtil.hasProperty(elem, 'info', 'success') &&
      (elem.info.success === atelProperties.LESSON_SUCCESS_STATUS_FAILED)))) {
      // On a détecté au moins un teaching avec le "success" LESSON_SUCCESS_STATUS_FAILED
      return atelProperties.LESSON_SUCCESS_STATUS_FAILED;
    } else if (userTeachings.every(elem => (
      elem &&
      elem.isUserTeachingValid(false) &&
      ObjectUtil.hasProperty(elem, 'info', 'success') &&
      (elem.info.success === atelProperties.LESSON_SUCCESS_STATUS_PASSED)))) {
      // Tous les teaching sont LESSON_SUCCESS_STATUS_PASSED
      return atelProperties.LESSON_SUCCESS_STATUS_PASSED;
    } else {
      return atelProperties.LESSON_SUCCESS_STATUS_UNKNOWN;
    }
  }

  /**
   * Fonction qui retourne le status de l'utilisateur à patir des userTeachings.
   * Retourne dans l'ordre :
   *    - LESSON_COMPLETION_STATUS_UNKNOWN s'il y aucun élément
   *    - LESSON_COMPLETION_STATUS_NOT_ATTEMPTED s'il y a plusieurs éléments et tous ont un nombre de connexions égal à 0
   *    - LESSON_COMPLETION_STATUS_UNKNOWN si au moins element est LESSON_COMPLETION_STATUS_UNKNOWN
   *    - LESSON_COMPLETION_STATUS_INCOMPLETE si au moins element est LESSON_COMPLETION_STATUS_INCOMPLETE
   *    - LESSON_COMPLETION_STATUS_COMPLETED si tous les elements sont LESSON_COMPLETION_STATUS_COMPLETED
   *    - LESSON_COMPLETION_STATUS_INCOMPLETE si au moins element est LESSON_COMPLETION_STATUS_COMPLETED
   *    - LESSON_COMPLETION_STATUS_NOT_ATTEMPTED si au moins element est LESSON_COMPLETION_STATUS_NOT_ATTEMPTED
   *    - LESSON_COMPLETION_STATUS_BROWSED si au moins element est LESSON_COMPLETION_STATUS_BROWSED
   *    - LESSON_COMPLETION_STATUS_UNKNOWN sinon
   */
  public getStatus(userTeachings: UserTeachingDb[] = this.userTeachings): string {
    if (userTeachings.length === 0) {
      // Il n'y a aucun élément
      return atelProperties.LESSON_COMPLETION_STATUS_NOT_ATTEMPTED;
    } else if ((userTeachings.length > 0) && userTeachings.every(elem => (
        ObjectUtil.hasProperty(elem, 'info', 'nbConn') &&
        (elem.info.nbConn === 0)))) {
        // Il y a des teachings et ils sont tous non ouvert (nbConn === 0)
        return atelProperties.LESSON_COMPLETION_STATUS_NOT_ATTEMPTED;
    } else if (userTeachings.some(elem => (
      !elem ||  // Un element est undefined
      (elem &&
        elem.isUserTeachingValid(false) &&
        ObjectUtil.hasProperty(elem, 'info', 'status') &&
        (elem.info.status === atelProperties.LESSON_COMPLETION_STATUS_UNKNOWN))))) {
      // On a détecté au moins un teaching avec le status LESSON_COMPLETION_STATUS_UNKNOWN
      return atelProperties.LESSON_COMPLETION_STATUS_UNKNOWN;
    } else if (userTeachings.some(elem => (
      elem &&
      elem.isUserTeachingValid(false) &&
      ObjectUtil.hasProperty(elem, 'info', 'status') &&
      (elem.info.status === atelProperties.LESSON_COMPLETION_STATUS_INCOMPLETE)))) {
      // On a détecté au moins un teaching avec le status LESSON_COMPLETION_STATUS_INCOMPLETE
      return atelProperties.LESSON_COMPLETION_STATUS_INCOMPLETE;
    } else if (userTeachings.every(elem => (
      elem &&
      elem.isUserTeachingValid(false) &&
      ObjectUtil.hasProperty(elem, 'info', 'status') &&
      (elem.info.status === atelProperties.LESSON_COMPLETION_STATUS_COMPLETED)))) {
      // Tous les teaching sont LESSON_COMPLETION_STATUS_COMPLETED
      return atelProperties.LESSON_COMPLETION_STATUS_COMPLETED;
    } else if (userTeachings.some(elem => (
      elem &&
      elem.isUserTeachingValid(false) &&
      ObjectUtil.hasProperty(elem, 'info', 'status') &&
      (elem.info.status === atelProperties.LESSON_COMPLETION_STATUS_COMPLETED)))) {
      // On a détecté au moins un teaching avec le status LESSON_COMPLETION_STATUS_COMPLETED
      return atelProperties.LESSON_COMPLETION_STATUS_INCOMPLETE;
    } else if (userTeachings.some(elem => (
      elem &&
      elem.isUserTeachingValid(false) &&
      ObjectUtil.hasProperty(elem, 'info', 'status') &&
      (elem.info.status === atelProperties.LESSON_COMPLETION_STATUS_NOT_ATTEMPTED)))) {
      // On a détecté au moins un teaching avec le status LESSON_COMPLETION_STATUS_NOT_ATTEMPTED
      return atelProperties.LESSON_COMPLETION_STATUS_NOT_ATTEMPTED;
    } else if (userTeachings.some(elem => (
      elem &&
      elem.isUserTeachingValid(false) &&
      ObjectUtil.hasProperty(elem, 'info', 'status') &&
      (elem.info.status === atelProperties.LESSON_COMPLETION_STATUS_BROWSED)))) {
      // On a détecté au moins un teaching avec le status LESSON_COMPLETION_STATUS_BROWSED
      return atelProperties.LESSON_COMPLETION_STATUS_BROWSED;
    } else {
      return atelProperties.LESSON_COMPLETION_STATUS_UNKNOWN;
    }
  }

  /**
   * Fonction qui retourne le temps total de l'utilisateur à patir des userTeachings.
   */
  public getTotalTime(userTeachings: UserTeachingDb[] = this.userTeachings): number {
    let res = 0;
    userTeachings.forEach(userTeaching => {
      if (
        userTeaching &&
        userTeaching.isUserTeachingValid(false) &&
        ObjectUtil.hasProperty(userTeaching, 'info', 'totalTime') &&
        ObjectUtil.isNumeric(userTeaching.info.totalTime)) {
        res += userTeaching.info.totalTime;
      }
    });
    return res;
  }

  /**
   * Fonction qui retourne le nombre de points de l'utilisateur à patir des userTeachings.
   */
  public getPoints(userTeachings: UserTeachingDb[] = this.userTeachings): number {
    let res = 0;
    userTeachings.forEach(userTeaching => {
      if (
        userTeaching &&
        userTeaching.isUserTeachingValid(false) &&
        ObjectUtil.hasProperty(userTeaching, 'info', 'points') &&
        ObjectUtil.isNumeric(userTeaching.info.points)) {
        res += userTeaching.info.points;
      }
    });
    return res;
  }

  /**
   * Fonction qui retourne le score de l'utilisateur à patir des userTeachings.
   * Retourne undefined si le score n'a pas pu être déterminé à patir des userTeachings.
   *
   * ATTENTION : Fonction overriddée pour les degrees.
   */
  public getScore(userTeachings: UserTeachingDb[] | UserTeachingDb[][] = this.userTeachings, base: number = 100): number {
    let accumulateur = 0;
    if (userTeachings.length > 0) {
      userTeachings.forEach(userTeaching => {
        if (
          userTeaching &&
          userTeaching.isUserTeachingValid(false) &&
          ObjectUtil.hasProperty(userTeaching, 'info', 'score') &&
          ObjectUtil.isNumeric(userTeaching.info.score)) {
          accumulateur += userTeaching.info.score;
        }
      });
      const res = (accumulateur / userTeachings.length);
      return CommonUtil.round(res * base, 2) / base;
    } else {
      return undefined;
    }
  }

  /**
   * Fonction qui retourne le score temporaire de l'utilisateur à patir des userTeachings.
   * Retourne undefined si le score temporaire n'a pas pu être déterminé à patir des userTeachings.
   *
   * ATTENTION : Fonction overriddée pour les degrees.
   */
  public getScoreTemporary(userTeachings: UserTeachingDb[] | UserTeachingDb[][] = this.userTeachings, base: number = 100): number {
    let accumulateur = 0;
    if (userTeachings.length > 0) {
      userTeachings.forEach(userTeaching => {
        if (
          userTeaching &&
          userTeaching.isUserTeachingValid(false) &&
          ObjectUtil.hasProperty(userTeaching, 'info', 'scoreTemporary') &&
          ObjectUtil.isNumeric(userTeaching.info.scoreTemporary)) {
          accumulateur += userTeaching.info.scoreTemporary;
        }
      });
      const res = (accumulateur / userTeachings.length);
      return CommonUtil.round(res * base, 2) / base;
    } else {
      return undefined;
    }
  }

  /**
   * Fonction qui retourne le score auto de l'utilisateur à patir des userTeachings.
   * Retourne undefined si le score auto n'a pas pu être déterminé à patir des userTeachings.
   *
   * ATTENTION : Fonction overriddée pour les degrees.
   */
  public getScoreAuto(userTeachings: UserTeachingDb[] | UserTeachingDb[][] = this.userTeachings, base: number = 100): number {
    let accumulateur = 0;
    if (userTeachings.length > 0) {
      userTeachings.forEach(userTeaching => {
        if (
          userTeaching &&
          userTeaching.isUserTeachingValid(false) &&
          ObjectUtil.hasProperty(userTeaching, 'info', 'scoreAuto') &&
          ObjectUtil.isNumeric(userTeaching.info.scoreAuto)) {
          accumulateur += userTeaching.info.scoreAuto;
        }
      });
      const res = (accumulateur / userTeachings.length);
      return CommonUtil.round(res * base, 2) / base;
    } else {
      return undefined;
    }
  }

  /**
   * Fonction qui retourne la progression de l'utilisateur à patir des userTeachings..
   * Retourne undefined si la progression n'a pas pu être déterminé à patir des userTeachings.
   *
   * ATTENTION : Fonction overriddée pour les degrees.
   */
  public getProgression(userTeachings: UserTeachingDb[] | UserTeachingDb[][] = this.userTeachings, base: number = 100): number {
    let accumulateur = 0;
    if (userTeachings.length > 0) {
      userTeachings.forEach(userTeaching => {
        if (
          userTeaching &&
          userTeaching.isUserTeachingValid(false) &&
          ObjectUtil.hasProperty(userTeaching, 'info', 'progress') &&
          ObjectUtil.isNumeric(userTeaching.info.progress)) {
          accumulateur += userTeaching.info.progress;
        }
      });
      const res = accumulateur / userTeachings.length;
      return CommonUtil.round(res * base, 2) / base;
    } else {
      return undefined;
    }
  }

  /**
   * Fonction qui retourne le nombre de tentatives de l'utilisateur à patir des userTeachings.
   */
  public getNbAttempt(userTeachings: UserTeachingDb[] = this.userTeachings): number {
    let res = 0;
    userTeachings.forEach(userTeaching => {
      if (
        userTeaching &&
        userTeaching.isUserTeachingValid(false) &&
        ObjectUtil.hasProperty(userTeaching, 'info', 'nbAttempt', 'current') &&
        ObjectUtil.isInt(userTeaching.info.nbAttempt.current)) {
        res += userTeaching.info.nbAttempt.current;
      }
    });
    return res;
  }

  /**
   * Fonction qui retourne les actions à patir des userTeachings.
   */
  public getActions(userTeachings: UserTeachingDb[] = this.userTeachings): UserNotification[] {
    return userTeachings
      .reduce(
        (accumulator, currentValue) => {
          return (ObjectUtil.hasProperty(currentValue, 'actions') && ObjectUtil.isArray(currentValue.actions))
            // ? accumulator.concat([currentValue.actions])
            ? accumulator.concat(currentValue.actions)
            : accumulator;
        },
        []);
    // .filter(elem => ObjectUtil.isArray(elem) && (elem.length > 0));
  }
}
