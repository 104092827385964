/**
 * Modèle pour la paginaltion avec le portal
 */
export class PaginateOptions {
    constructor(
      public page?: number,
      public limit?: number,
      public offset?: number) {
    }
}

/**
 * Modèle pour la paginaltion avec le portal
 */
export class PaginateData {
    constructor(
      public data: any[] = [],
      public recordsTotal: number = 0,
      public recordsFiltered: number = 0) {
    }
}

