import {Injectable, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ErrorDataService} from './error-data.service';
import {ModuleErrorConfigService} from '../models/ModuleErrorConfig';
import {ErrorUtil} from '../utils/error.util';
import {ModuleLibraryConfigInt} from '../../ng-models-ui/interfaces/moduleLibraryConfig.interface';
import {SessionService} from '../../ng-manager-user/services/user-session.service';
import {CurrentUserService} from '../../ng-manager-user/services/current-user.service';
import {ConfigurationProperties} from '../../ng-properties-configuration/configuration.properties';
import {AipError} from '../../ng-models-ui/models/AipError';
import i18next from 'i18next';
import {NotificationsService, NotificationType} from 'angular2-notifications';
import {ObjectUtil} from "../../ng-helpers-util/object.util";

/**
 * Service servant à enregistrer les informations sur l'erreur avant de l'afficher
 * avec le composant error.component.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  // private moduleLibraryConfig: ModuleLibraryConfigInt;

  /**
   *
   * @param config
   * @param sessionService
   * @param currentUserService
   * @param router
   * @param http
   * @param errorDataService
   * @param notifications
   */
  constructor(
    @Inject(ModuleErrorConfigService) private config: ModuleLibraryConfigInt,
    private sessionService: SessionService,
    private currentUserService: CurrentUserService,
    private router: Router,
    private http: HttpClient,
    private errorDataService: ErrorDataService,
    private notifications: NotificationsService) {
    // console.log("EEEEEEEEEEEEE ErrorService config:", config);
    // this.moduleLibraryConfig = config;
  }

  /**
   * Récupération de tous les paramètres de configuration du module.
   */
  getConfig(): Observable<ModuleLibraryConfigInt> {
    // return of(this.config.data);
    return of(this.config);
  }

  /**
   * Récupération des données de l'erreur.
   */
  getErrorData(): AipError[] {
    return this.errorDataService.appErrors;
  }

  /**
   * Affichage d'une erreur.
   *
   * L'erreur est aux formats :
   *      - string : chaine JSON généralement envoyée par le serveur. Elle sera parssée et convertie en objet AipError.
   *      - string[] : une liste de message d'erreur de type string. Une erreur par défaut en mode affichage fatale sera crée.
   *      - un object contenant les champs :
   *          { type: number,
   *            code: string|number,
   *            title: string,
   *            subTitle: string,
   *            msg: string[],
   *            status: number,
   *          }
   *
   * @param err
   */
  displayError(err: AipError | AipError[]) {
    // console.log("LLLLLLLLLLLLLLLL 111 err:", err);
    if (err) {
      let errors: AipError[] = (!ObjectUtil.isArray(err)) ? [err as AipError] : err as AipError[];

      // Enregistrement de l'erreur
      this.errorDataService.appErrors = errors.map(item => {
        return AipError.convertToAipError(item);
      });

      // On délogue l'utilisateur si nécessaire
      if (errors.some(item => item.hasOwnProperty("logoutUser") && item.logoutUser)) {
        this.sessionService.resetSession();
      }

      // Traitement de l'erreur
      if (errors.some(item => item.type !== ConfigurationProperties.cli_error_type_notification)) {
        // On redirige vers une page d'erreur si nécessaire
        this.router.navigate([this.config.data.urlUiError]);
      } else {
        // Affichage d'une notification
        errors.forEach(item => {
          this.notifications.create(
            i18next.t('error.title'),
            ErrorUtil.displayToastrErrorMessages(item.msg),
            NotificationType.Error);
        });
      }
    }
  }

  /**
   * Fonction qui notifie au serveur une erreur afin qu'il la log.
   *
   * @param error
   * @returns
   */
  notifyErrorToServer(error: AipError) {

    // Enregistrement de l'url ayant provoqué l'erreur
    error.url = window.location.href;

    // Envoi des informations au serveur
    //noinspection TypeScriptUnresolvedFunction
    this.http.post(
      this.config.data.urlServerErrorNotification,
      {error: error},
    ).subscribe(() => {
      },
      err => {
      }
    )
  }

}
