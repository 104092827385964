import {Renderer2} from "@angular/core";
import {appConfigurationProperties} from "../../config/appConfigurationProperties";
import {StringUtil} from "./string.util";
import {Language} from "../../shared/lang.service";

export class UiUtil {

  /**
   * Fonction qui surligne avec une ombre un élément identifier dans l'event.
   * Pour fonctionner l'élément à surligner doit avoir la classe css ".highlight-shadow".
   *
   * @param renderer Classe de rendu Angular
   * @param target L'élément html (est en général 'event.target')
   * @param duration La durée de l'animation
   * @param delay Le délay avant le déclanchement de l'animation
   */
  public static animateShadow(renderer: Renderer2, target: any, duration: number = 500, delay: number = 0) {
    setTimeout(() => {
      renderer.addClass(target, 'ani-state');
      setTimeout(() => {
        renderer.removeClass(target, 'ani-state');
      }, duration);
    }, delay)
  }

  /**
   * Fonction qui retourne la langue du navigateur.
   */
  public static getBrowserLanguage(): string {
    const browserLanguage = window.navigator.language || (window.navigator as any).userLanguage;
    const lang = UiUtil.findLanguage(browserLanguage);
    return (lang) ? lang.code : appConfigurationProperties.LANGUAGE_DEFAULT;
  }

  /**
   * Fonction qui retourne la langue du code spécifié si celui-ci est trouvé
   * dans le code ou les alias des langues de la plate-forme.
   * Si "defaultLanguageCode" existe, la fonction retourne la langue identifiée
   * par ce code si la langue n'a pas été trouvée, sinon retourne "undefined".
   */
  public static findLanguage(code: string, defaultLanguageCode?: string): Language {
    const language = appConfigurationProperties.SUPPORTED_LANGUAGES.find(
      (item) => (
        item.code.toLowerCase() === code.toLowerCase()) ||
        (item.alias.map(elem => elem.toLowerCase()) || []).includes(code.toLowerCase())
    );
    if (language) {
      return language;
    } else {
      // On compare les première parties des locales
      const firstPart = appConfigurationProperties.SUPPORTED_LANGUAGES.find(
        (item) => (
          item.code.slice(0, 2).toLowerCase() === code.slice(0, 2).toLowerCase()) ||
          (item.alias.map(elem => elem.slice(0, 2).toLowerCase()) || []).includes(code.slice(0, 2).toLowerCase())
      );
      if (firstPart) {
        return firstPart;
      } else {
        return (StringUtil.isUndefinedOrEmpty(defaultLanguageCode))
          ? undefined : UiUtil.findLanguage(defaultLanguageCode);
      }
    }
  }


}
