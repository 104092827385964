import { Injectable } from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {AuthenticationServiceCommon} from './authentication-common.service';
import {applicationProperties} from '../ng-properties-application/application.properties';
import {Credentials} from '../ng-models-ui/models/Credentials';
import {CookieUtil} from '../ng-helpers-cookies/cookie.util';
import {ConfigurationProperties} from '../ng-properties-configuration/configuration.properties';
import {ContentType} from '../ng-models-ui/enums/ContentType';
import {StringUtil} from '../ng-helpers-util/string.util';
import {AipDbService} from "../../shared/aip-db.service";
import {appConfigurationProperties} from "../../config/appConfigurationProperties";
import {LangService} from "../../shared/lang.service";

/**
 * Classe permettant de controler l'accès aux composants.
 * Fonctions :
 *      - userHasLocalCredentials(): boolean
 *          Fonction qui vérifie si l'utilisateur a des crédentials.
 *          Retourne true si l'utilisateur a des crédentials, false sinon.
 *      - userHasRole(roles:Array<string>): boolean
 *          Fonction qui vérifie si l'utilisateur est connecté et s'il possède les roles passés en paramètre.
 *          Retourne true si l'utilisateur est connecté et s'il possède les roles passés en paramètre
 *          retourne false sinon.
 *      - checkAccess(roles:Array<string>): boolean
 *          Fonction qui vérifie les rôles pour l'accès à une route.
 *      - getLocalCreds() :
 *          Recupère du localStorage le token jwt envoyées par le serveur lors de la phase d'authentification.
 */

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends AuthenticationServiceCommon {
    // Proprieté stockant la derrière erreur de type accès refusée levée par une fonction @CanActivate.
    // L'accès aux composants est accèptée ou refusée selon les rôles de l'utilisateur.
    // Si l'accès est accéptée la valeur de cette propriété est false.
    // Si l'accès est refusée la valeur de cette propriété est true.
    //private isAccessDenied: boolean;

    // Message affiché en alerte en cas d'access refusé
    private ERROR_MESSAGE_ACCESS_DENIED = "Access Denied";

    private requestSignatureSourceValue: string;   // Variable intialisée dans la fonction init()
                                                   // Initialisée avec la valeur de l'application AppConfigurationProperties.REQUEST_SIGNATURE_SOURCE_VALUE
    private requestSignatureApplicationKey: string;   // Variable intialisée dans la fonction init()
                                                      // Initialisée avec la valeur de l'application AppConfigurationProperties.REQUEST_SIGNATURE_APPLICATION_VALUE

    /**
     *
     * @param aipDbService
     * @param langService
     */
    constructor(
      private aipDbService: AipDbService,
      private langService: LangService) {
        super();
    }

    /**
     * Fonction d'initialisation du manager
     *
     * @param requestSignatureApplicationKey
     * @param requestSignatureSourceValue
     */
    public init(requestSignatureApplicationKey: string, requestSignatureSourceValue: string) {
        this.requestSignatureApplicationKey = requestSignatureApplicationKey;
        this.requestSignatureSourceValue = requestSignatureSourceValue;
    }


    /**
     * Fonction qui sauve les crédentials dans le token remember-me si "rememberMe" est sur true,
     * ou dans la session locale si "rememberMe" est sur false.
     *
     * @param creds
     * @param rememberMe
     */
    public async saveJwtCreds(creds: Credentials, rememberMe: boolean) {
        if(creds) {
            //let s = AppConfigurationProperties.JWT_CREDS_SECRET + window.clientInformation.platform;
            let s = applicationProperties.JWT_CREDS_SECRET;
            if (rememberMe) {
                if(applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_LOCAL_STORAGE) {
                    localStorage.setItem(
                        applicationProperties.JWT_CREDS_REMEMBER_ME_KEY, StringUtil.e(creds.toString(), s));
                } else if(applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_INDEXED_DB) {
                  await this.aipDbService.setRememberMe(StringUtil.e(creds.toString(), s));
                } else {
                    CookieUtil.setCookie(applicationProperties.JWT_CREDS_REMEMBER_ME_KEY, StringUtil.e(creds.toString(), s),
                        {path: "/", domain: ConfigurationProperties.cli_cookie_domain_name});
                }
            } else {
                sessionStorage.setItem(
                    applicationProperties.JWT_CREDS_KEY,
                    StringUtil.e(creds.toString(), s));
            }
          if(applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_INDEXED_DB) {
            await this.aipDbService.setCreds(StringUtil.e(creds.toString(), s))
          }
        }
    }

  /**
   * Fonction qui enregristre dans la session les inforamtions d'authentification du token jwt.
   *
   * @param authToken
   */
  public async saveJwtAuthToken(authToken) {
    if (authToken) {
      sessionStorage.setItem(applicationProperties.JWT_AUTH_TOKEN_KEY, authToken);
      if (applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_INDEXED_DB) {
        await this.aipDbService.setAuthToken(authToken);
      }
    }
  }

  /**
     * Fonction qui met à jour les crédentials dans le token remember-me "rememberMe" s'il existe
     * et dans le session locale.
     *
     * @param creds
     */
    public async updateJwtCreds(creds: Credentials) {
        if(creds) {
            let c: string;
            if(applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_LOCAL_STORAGE) {
                c = localStorage.getItem(applicationProperties.JWT_CREDS_REMEMBER_ME_KEY);
            } else if(applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_INDEXED_DB) {
              c = await this.aipDbService.getRememberMe();
            } else {
                c = CookieUtil.getCookie(applicationProperties.JWT_CREDS_REMEMBER_ME_KEY);
            }
            if(c) {
                await this.saveJwtCreds(creds, true);
            }
            await this.saveJwtCreds(creds, false);
            // On indique que les credentials on été modifiés dans cet onglet
            sessionStorage.setItem(applicationProperties.JWT_CREDS_UPDATED_IN_THIS_TAB_KEY, "true");
            // On déclare aux autres onglets que les creds ont été modifiés
            this.triggerJwtLogout();
        }
    }

    /**
     * Fonction qui recherche les crédentials dans le sessionStorage.
     *
     * @returns
     * @deprecated Utiliser getLocalCreds()
     */
    public getSessionCreds(): Credentials {
        let c: string = sessionStorage.getItem(applicationProperties.JWT_CREDS_KEY);
        if(c) {
            //let s = AppConfigurationProperties.JWT_CREDS_SECRET + window.clientInformation.platform;
            let s = applicationProperties.JWT_CREDS_SECRET;
            return Credentials.convertToCredentials(
              StringUtil.d(c, s), appConfigurationProperties.AUTHENTICATION_IS_EMAIL_LOGIN);
        } else {
            return undefined;
        }
    }

    /**
     * Fonction qui recherche les crédentials dans le localStorage ou le cookie (token "rememberMe")
     * ou à partir de la sessionStorage s'il n'y a rien dans le localStorage ou le cookie.
     *
     * @returns
     */
    public async getLocalCreds(): Promise<Credentials> {
        let c: string;
        if(applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_LOCAL_STORAGE) {
            c = localStorage.getItem(applicationProperties.JWT_CREDS_REMEMBER_ME_KEY);
        } else if(applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_INDEXED_DB) {
          c = await this.aipDbService.getCreds();
        } else {
            c = CookieUtil.getCookie(applicationProperties.JWT_CREDS_REMEMBER_ME_KEY);
        }
        if(!c) {
            c = sessionStorage.getItem(applicationProperties.JWT_CREDS_KEY);
        }
        if(c) {
            //let s = AppConfigurationProperties.JWT_CREDS_SECRET + window.clientInformation.platform;
            let s = applicationProperties.JWT_CREDS_SECRET;
            return Credentials.convertToCredentials(
              StringUtil.d(c, s), appConfigurationProperties.AUTHENTICATION_IS_EMAIL_LOGIN);
        } else {
            return undefined;
        }
    }

    /**
     * Fonction qui vérifie si l'utilisateur a des crédentials.
     * Retourne true si l'utilisateur a des crédentials, false sinon.
     *
     * @returns
     */
    public async userHasLocalCredentials(): Promise<boolean> {
        return (await this.getLocalCreds() !== undefined);
        /*
         let jwtHelper = new JwtHelperService();
         let rememberMeToken: string = this.getRememberMe();
         if(rememberMeToken && jwtHelper.tokenNotExpired(rememberMeToken)) {    // Recherche dans le localStorage (si le flag rememberMe est sur true)
         return true;
         } else {    // Recherche dans le sessionStorage (si le flag rememberMe est sur false) - implementation inspiré de la fonction "tokenNotExpired" du fichier "ng2-jwt.ts"
         var token: string = sessionStorage.getItem(AppConfigurationProperties.JWT_AUTH_TOKEN_KEY);
         return (!!token && !jwtHelper.isTokenExpired(token, null))
         }
         */
    }

    /**
     * Fonction génère l'entête pour les routes protégé par des droits d'accès (utilisateur connecté par exemple).
     *
     * @param userLanguage
     * @param contentType
     * @returns
     */
    public generateHeadersForProtectedRoutes(
      userLanguage: string,
      contentType: string | ContentType = ContentType.APPLICATION_JSON): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/json'); // Permet au client de délcarer au serveur le format accepté (par le client)
        if (contentType !== ContentType.REMOVE_CONTENT_TYPE_FROM_HEADER) {
          headers = headers.append('Content-Type', contentType);      // Permet au client de décrire pour le serveur le format des données contenu dans la requête (envoyée au serveur)
        }
        if (appConfigurationProperties.ADD_JWT_CREDS_SECRET_TO_REQUESTS_HEADERS) {
          // headers = headers.append('Authorization', 'Bearer ' + this.getTokenFromLocalStorage()); // To authenticate the user in this API call, we send the JWT we got from localStorage
          headers = headers.append('Authorization', 'Bearer ' + this.getAuthToken());
        }
        // headers = headers.append('Access-Control-Allow-Origin', 'http://localhost:2020');
        return this.updateHeadersBeforeSendingRequest(userLanguage, headers);
    }

    /**
     * Fonction génère l'entête pour les routes non protégées par des droits d'accès.
     *
     * @param userLanguage
     * @param contentType
     * @returns
     */
    public generateHeadersForUnprotectedRoutes(
      userLanguage?: string,
      contentType: string | ContentType = ContentType.APPLICATION_JSON): HttpHeaders {
      let headers = new HttpHeaders();
      headers = headers.append('Accept', 'application/json');  // Permet au client de délcarer au serveur le format accepté (par le client)
      if (contentType !== ContentType.REMOVE_CONTENT_TYPE_FROM_HEADER) {  // Pour que le contentType soit disponible au niveau de l'AuthenticationInterceptor
        headers = headers.append('Content-Type', contentType);      // Permet au client de décrire pour le serveur le format des données contenu dans la requête (envoyée au serveur)
      }
      return this.updateHeadersBeforeSendingRequest(userLanguage, headers);
    }

  /**
   * Fonction génère l'entête pour les routes.
   *
   * @param userLanguage
   * @param contentType
   * @returns
   */
  public generateHeadersForRoutes(
    userLanguage?: string,
    contentType: string | ContentType = ContentType.APPLICATION_JSON): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');   // Permet au client de délcarer au serveur le format accepté (par le client)
    headers = headers.append('Content-Type', contentType);          // Permet au client de décrire pour le serveur le format des données contenu dans la requête (envoyée au serveur)
    return this.updateHeadersBeforeSendingRequest(userLanguage, headers);
  }

  /**
     * Fonction qui rajoute la langue et une signature au header pour permettre au serveur d'identifier la source de la requete.
     *
     * @param userLanguage
     * @param headers
     */
    private updateHeadersBeforeSendingRequest(userLanguage: string, headers?: HttpHeaders): HttpHeaders {
        if(!headers) {
            headers = new HttpHeaders();
            headers = headers.append('Content-Type', 'application/json');
        }
        headers = this.appendSignatureToHeaders(headers);
        let lang = userLanguage;    // La langue passée en paramètre
        if(StringUtil.isUndefinedOrEmpty(lang)) {
            // lang = navigator.language || navigator.userLanguage;
            // lang = lang.toString();
            lang = this.langService.getDefaultLanguage();
        }
        if (!StringUtil.isUndefinedOrEmpty(lang)) {
            headers = this.appendLanguageToHeaders(lang, headers);
        }
        return headers;
    }


    /**
     * Fonction qui rajoute une signature au header pour permettre au serveur d'identifier la source de la requete.
     *
     * @param headers
     */
    public appendSignatureToHeaders(headers: HttpHeaders): HttpHeaders {
        headers = headers.append(applicationProperties.REQUEST_SIGNATURE_APPLICATION_KEY, this.requestSignatureApplicationKey);
        headers = headers.append(applicationProperties.REQUEST_SIGNATURE_SOURCE_KEY, this.requestSignatureSourceValue);
        return headers;
    }

    /**
     * Fonction qui rajoute une langue au header pour permettre au serveur de retourner les messages dans la bonne langue.
     *
     * @param userLanguage
     * @param headers
     */
    public appendLanguageToHeaders(userLanguage: string, headers: HttpHeaders): HttpHeaders {
        headers = headers.append('Accept-Language', userLanguage + ", en;q=0.8, fr;q=0.7");
        return headers;
    }

}
