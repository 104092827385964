import {ObjectUtil} from './object.util';
import {CurrentUserService} from "../ng-manager-user/services/current-user.service";
import {applicationProperties} from "../ng-properties-application/application.properties";
import {MathUtil} from "./math.util";
import {appConfigurationProperties} from "../../config/appConfigurationProperties";

export class ShoppingCartUtil {

    /**
     * Fonction qui génère une clé pour le panier en fonction du type et de l'id du produit.
     * Retourne la clé générée en fonction des paramètre ou "undefined" si les paramètres ne sont
     * pas corrects.
     *
     * @param productType
     * @param productId
     * @returns
     */
    public static generateShoppingCartKey(productType: string, productId: string): string {
        if (productId && productType) {
            return productType + "#" + productId;
        } else {
            return undefined;
        }
    };

    /**
     * Fonction qui génère un objet de la forme { type: productType, id: productId }.
     * retourne undefined si la clé n'est pas correcte.
     *
     * @param key
     * @returns
     */
    public static parseShoppingCartKey(key: string): { type: string, _id: string } {
        if (key) {
            let tab = key.split("#");
            if (tab && (tab.length === 2)) {
                return {
                    type: tab[0],
                    _id: tab[1]
                }
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

  /**
   * Fonction qui retourne le nom de l'application associé au produit de la
   * productKey passé en paramètre.
   * Retourne undefined si aucune application n'a été trouvée.
   *
   * @param productKey
   * @returns
   */
  public static getApplicationNameFormShoppingCartKey(productKey: string): string {
    const parsedKey = ShoppingCartUtil.parseShoppingCartKey(productKey);
    if (parsedKey) {
      switch (parsedKey.type) {
        case appConfigurationProperties.PAYMENT_PRODUCT_LESSON:
        case appConfigurationProperties.PAYMENT_PRODUCT_QUIZ:
        case appConfigurationProperties.PAYMENT_PRODUCT_COURSE:
        case appConfigurationProperties.PAYMENT_PRODUCT_DEGREE:
          return appConfigurationProperties.APPLICATION_NAME_AIP_ATEL;
        case appConfigurationProperties.PAYMENT_PRODUCT_PUBLICATION:
          return appConfigurationProperties.APPLICATION_NAME_AIP_ATP;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  };

  /**
   * Fonction qui retourne l'url du cart associé au produit de la
   * productKey passé en paramètre.
   * Retourne undefined si aucune url n'a été trouvée.
   *
   * @param productKey
   * @returns
   */
  public static getUrlToCartFormShoppingCartKey(productKey: string): string {
    const applicationName = ShoppingCartUtil.getApplicationNameFormShoppingCartKey(productKey);
    if (applicationName) {
      switch (applicationName) {
        case appConfigurationProperties.APPLICATION_NAME_AIP_ATEL:
          return appConfigurationProperties.URL_UI_ATEL_CART;
        case appConfigurationProperties.APPLICATION_NAME_AIP_ATP:
          return appConfigurationProperties.URL_UI_ATP_CART;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  };

  /**
     * Fonction qui détermine si un prix est valide.
     * Il s'agit d'une fonction très limitée qui ne teste pas la validité des devises ni leur format.
     *
     * @param price
     * @returns
     */
    public static isPriceValid(price: any): boolean {
        return (price && ObjectUtil.isObject(price, true, true) && !ObjectUtil.isEmptyObject(price));
    };

    /**
     * Fonction qui somme des prix devise par devise.
     * Retourne undefined si aucune somme n'a pu être déterminée.
     *
     * Format de retour:
     *      {
     *       totalPrice: {USD: 10, EUR: 12},    // Somme devise par devise (peut être un objet vide si au moins une devise est manquante dans chaque element sommé)
     *       invalidPricesDetected: false,      // Des prix invalides (undefined ou objets vides) ont été détectés dans la liste des prix passé en paramétre. Ces prix sont ignorés dans le total.
     *       missingCurrenciesDetected: false   // Au moins une devise n'est pas présente dans la liste des prix passé en paramétre. Si une devise n'est pas trouvée dans au moins un prix, cette devise est supprimée du total.
     *      }
     *
     * @param prices
     * @returns
     */
    public static sumPrices(prices: Array<any>): {totalPrice: any, invalidPricesDetected: boolean, missingCurrenciesDetected: boolean} {
        if (ObjectUtil.isArray(prices) && (prices.length > 0)) {
            // La valeur de retour
            let retour = {
                totalPrice: undefined,
                invalidPricesDetected: false,
                missingCurrenciesDetected: false
            };

            // On regarde si tous les éléments ont un prix
            retour.invalidPricesDetected = !prices.every(element => {
                return ShoppingCartUtil.isPriceValid(element);    // On regarde si au moins un élément est invalid
            });

            // On supprime les prix invalides
            prices = prices.filter(element => {
                return ShoppingCartUtil.isPriceValid(element);
            });

            // Tous les éléments on un prix => On somme les prix par devise
            if(prices.length > 0) {
                retour.totalPrice = prices.reduce((accumulateur, valeurCourante, index) => {
                    if (index === 0) {
                        return valeurCourante;
                    } else {
                        let newVal = {};
                        Object.keys(accumulateur).forEach(key => {
                            // On regarde si un prix n'a pas toutes les devises de l'accumulateur
                            if (!retour.missingCurrenciesDetected && !ObjectUtil.deepSameKeys(accumulateur, valeurCourante)) {
                                retour.missingCurrenciesDetected = true;
                            }

                            // On somme le prix avec l'accumulateur
                            if (valeurCourante.hasOwnProperty(key)) {
                                // On a trouvé le prix dans la devise pour la valeur courante, on l'additionne et on l'ajoute à l'accumulateur
                                // Si une devise n'est pas trouvée l'élément elle ne sera pas ajoutée à l'accumulateur
                                newVal[key] = accumulateur[key] + valeurCourante[key];
                            } else {
                                retour.missingCurrenciesDetected = true;
                            }
                        });
                        return newVal;
                    }
                });
                return retour;
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    };

  /**
   * Fonction ajoutee par Ryad
   * Fonction de bliotheque recopiee telquelle de LearningContainer.ts) qui retourne le prix total soldé au format
   * string du cours dans la devise spécifiée pour l'affichage.
   * On fait essentielleemnt des checks sur le prix initia et final et on ne rend le prix discounté que si il y a
   * une difference sinon on rend undefined.
   */
  public static computePriceDiscounted(currentUserService: CurrentUserService, discounts, priceDiscounted): string {
    try {
      if (!ObjectUtil.isArray(discounts)) {
        return undefined;
      }
      if (discounts.length > 1) {
        // Il y a un discount
        const initialPrice = discounts[0] && discounts[0].price;
        const finalPrice = discounts[discounts.length - 1] && discounts[discounts.length - 1].price;
        if (!finalPrice || !initialPrice) {
          // Cas impossible
          return undefined;
        }
        const priceInitial = currentUserService.getPriceInUserCurrency(initialPrice);
        const priceFinal = currentUserService.getPriceInUserCurrency(finalPrice);
        if (priceInitial === priceFinal) { // Si le prix est le meme inutile d'afficher le priceDiscounted
          return undefined;
        }

        return currentUserService.displayPriceInUserCurrency(
          currentUserService.getPriceInUserCurrency(priceDiscounted));
      } else {
        // if (discounts.length === 1 && discounts[0] && discounts[0].type === "magic") {
        if (discounts.length === 1 && discounts[0] && discounts[0].type === applicationProperties.ALGO_DISCOUNT_TYPE_MAGIC) {
          // Il n'y a que le discount magic qui est un fake discount trackant l'operation de prix magique et representant le prix
          // en input du pipe d'operation.
          // => correspond au cas ou il n'y a pas de veritable discount => le prix d'entree represente le prix en sortie
          return undefined;
        } else {
          // Ce cas est theoriquemnt impossible : on doit tjs avoir au minima le magic discount traquant/decrivant l'operation magique
          return undefined;
        }
      }
    } catch (err) {
      return undefined;
    }
  }

  /**
   * Fonction ajoutee par Ryad
   * Fonction de bibliotheque (recopiee telquelle de LearningContainer.ts) qui retourne la discount au format string de l'objet.
   * Nouveau code de Ryad: maintenant les discounts sont plus touffus, il faut selectionner les bons types
   * Par exemple dans les vignettes il ne faut prendre que les elemnts inclus dans le VAT
   * (en principe il n'y en a pas d'element non inlcus mais on prend une precaution qd meme)
   * PS : les elements non inclus dans le VAT ne sont interessants que pour le cart et les factures
   * De plus il peut y avoir des disocunts en forfait (Ex 5euros) ou en pourcentage (Ex 5%)
   * On n'affiche un % si tous les disounts sont en % sinon on affiche une chaine plus complexe
   * L'operation de prix magique (ie le prix sans discount a partir duquel on applique les discount pour trouver le prix avec discount)
   * est maintenat aussi inclus a la tete du tableau de discounts afin de mieux tracker les operations effectue sur les prix
   * (l'objet repreentant l'operation magique contient des information supplementaire sur comment a ete calcule le prix maagique)
   * Il faut evidemement l'exclure car il ne s'agit pas du prix discounté mais du prx original
   * A noter qu'ici on peut etre plus restricif pour l'affichage que pour displayPriceDiscounted
   * car displayDiscount n'affiche pas une information tres importante
   * c'est juste une aide visuelle pour guider l'utilisateur alors qu'il faut vraiment etre tres rigoureux avec displayPriceDiscounted
   * Par exemple si il y a un prix qui passe de 0.50 euro a 10 euros ca fait une grosse augmentation bizarre
   * donc inutile de l'afficher : par contre il faut absolument afficher le priceDiscounted final = 10 euros
   *
   */
  public static computeDiscount(currentUserService: CurrentUserService, discounts): string {
    try {

      if (!ObjectUtil.isArray(discounts)) {
        return undefined;
      }
      if (discounts.length > 1) {
        const initialPrice = discounts[0] && discounts[0].price;
        const finalPrice = discounts[discounts.length - 1] && discounts[discounts.length - 1].price;
        if (!finalPrice || !initialPrice) {
          // Cas impossible
          return undefined;
        }
        const priceInitial = currentUserService.getPriceInUserCurrency(initialPrice);
        if (!priceInitial) { // Si le denominateur est 0 : mathemathiquement non défini donc ne rien afficher
          return undefined;
        }

        const priceFinal = currentUserService.getPriceInUserCurrency(finalPrice);
        const totalDiscountPercent = MathUtil.round(100 * (priceFinal - priceInitial) / priceInitial , 1);
        if (!totalDiscountPercent) {
          // Le prix n'a pas changé : ne rien afficher
          return undefined;
        }
        const totalIsMalus = priceFinal > priceInitial;
        if (totalDiscountPercent > 200 && totalIsMalus) {
          // Ne pas faire paniquer l'utilisiateur pour les gros malus : il s'agit probablement d'une augmentation sur un petit prix de depart
          return undefined;
        }
        if  (totalIsMalus) {
          return '+' + totalDiscountPercent + '%';
        } else {
          return totalDiscountPercent + '%';
        }
      } else {
        // if (discounts.length === 1 && discounts[0] && discounts[0].type === "magic") {
        if (discounts.length === 1 && discounts[0] && discounts[0].type === applicationProperties.ALGO_DISCOUNT_TYPE_MAGIC) {
          // Il n'y a que le discount magic qui est un fake discount trackant l'operation de prix magique et representant
          // le prix en input du pipe d'operation.
          // => correspond au cas ou il n'y a pas de veritable discount => le prix d'entree represente le prix en sortie
          return undefined;
        } else {
          // Ce cas est theoriquemnt impossible : on doit tjs avoir au minima le magic discount traquant/decrivant l'operation magique
          return undefined;
        }
      }
    } catch (err) {
      return undefined;
    }
  }

}

