import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ObjectUtil} from "../../ng-helpers-util/object.util";
import {CurrentUserService} from "../../ng-manager-user/services/current-user.service";
import {ErrorService} from "../../ng-module-error/services/error.service";
import {ContentType} from "../../ng-models-ui";


export enum HTTP_ACTIONS {
  GET,
  POST,
  PUT,
  DELETE
}

@Injectable()
export class HttpService {

  /**
   *
   */
  get httpClient(): HttpClient {
    return this.http;
  }

  /**
   *
   * @param http
   * @param currentUserService
   * @param errorService
   */
  constructor(private http: HttpClient,
              private currentUserService: CurrentUserService,
              private errorService: ErrorService) {
  }


  /**
   *
   * @param url
   * @param onSuccess
   * @param onError
   * @param headers
   */
  public sendGetDataToServer(
    url: string,
    onSuccess?: Function,
    onError?: Function,
    headers?: { params?: HttpParams, headers?: HttpHeaders }
  ): void {
    this.sendDataToServer(undefined, url, HTTP_ACTIONS.GET, onSuccess, onError, headers)
  }

  /**
   *
   * @param data - Les données à envoyer
   * @param url
   * @param onSuccess
   * @param onError
   * @param options
   */
  public sendPostDataToServer(
    data: any | FormData,
    url: string,
    onSuccess?: Function,
    onError?: Function,
    options?: { params?: HttpParams, headers?: HttpHeaders }
  ): void {
    this.sendDataToServer(data, url, HTTP_ACTIONS.POST, onSuccess, onError, options)
  }

  /**
   * Fonction send qui renvoi un observable.
   *
   * @param data - Les données à envoyer
   * @param url
   * @param options
   */
  public sendCustomPostDataToServer<T>(
    data: any | FormData,
    url: string,
    options?: { params?: HttpParams, headers?: HttpHeaders }
  ): Observable<T> {
    let contentType: ContentType;
    let dataToSend: any;
    if (data && (<FormData>data).append) {
      // contentType = ContentType.MULTIPART_FORM_DATA;
      contentType = ContentType.REMOVE_CONTENT_TYPE_FROM_HEADER;
      // dataToSend = data;
    } else {    // On ne trouve pas la fonction append => data n'est de type FormData
      contentType = ContentType.APPLICATION_JSON;
      // dataToSend = {data: data};
    }
    if (!options) {
      options = {};
    }
    if (!options.headers) {
      options.headers = this.currentUserService.generateHeadersForProtectedRoutes(contentType)
    }
    return this.http.post<any>(url, data, options);
  }

  /**
   *
   * @param data - Les données à envoyer
   * @param url
   * @param onSuccess
   * @param onError
   * @param headers
   */
  public sendPutDataToServer(
    data: any | FormData,
    url: string,
    onSuccess?: Function,
    onError?: Function,
    headers?: { params?: HttpParams, headers?: HttpHeaders }
  ): void {
    this.sendDataToServer(data, url, HTTP_ACTIONS.PUT, onSuccess, onError, headers)
  }

  /**
   *
   * @param data
   * @param url
   * @param onSuccess
   * @param onError
   * @param headers
   */
  public sendDeleteDataToServer(
    data: any | FormData,
    url: string,
    onSuccess?: Function,
    onError?: Function,
    headers?: { params?: HttpParams, headers?: HttpHeaders }
  ): void {
    this.sendDataToServer(data, url, HTTP_ACTIONS.POST, onSuccess, onError, headers)
  }

  /**
   * Fonction qui envoie des données au serveur.
   *
   * @param data - Les données à envoyer
   * @param url - L'url
   * @param action - Le type d'envoi : get, post, put (par défaut: post)
   * @param onSuccess
   * @param onError
   * @param options
   */
  private sendDataToServer(
    data: any | FormData,
    url: string,
    action: HTTP_ACTIONS = HTTP_ACTIONS.POST,
    onSuccess?: Function,
    onError?: Function,
    options?: { params?: HttpParams, headers?: HttpHeaders }
  ): void {
    this.requestDataToServer(data, url, action, onSuccess, onError, options)
      .subscribe(res => {
        if (ObjectUtil.isFunction(onSuccess)) {
          onSuccess(res);
        }
      }, err => {
        if (ObjectUtil.isFunction(onError)) {
          onError(err);
        } else {
          // this.errorService.displayError(err.error);   // FIXME - uniformiser le format des erreurs sur tous les projets et toutes les requêtes
          this.errorService.displayError(err);            // FIXME - uniformiser le format des erreurs sur tous les projets et toutes les requêtes
        }
      });
    // .map(res => <any> res.json());
    // .catch(ErrorUtil.handleError);
    // .catch(err => Observable.throw(err));
  }

  /**
   * Fonction qui envoie des données au serveur.
   * Retourne un observable.
   *
   * @param data - Les données à envoyer
   * @param url - L'url
   * @param action - Le type d'envoi : get, post, put (par défaut: post)
   * @param onSuccess
   * @param onError
   * @param options
   */
  public requestDataToServer<T>(
    data: any | FormData,
    url: string,
    action: HTTP_ACTIONS = HTTP_ACTIONS.POST,
    onSuccess?: Function,
    onError?: Function,
    options?: { params?: HttpParams, headers?: HttpHeaders }
  ): Observable<T> {
    let contentType: ContentType;
    let dataToSend: any;
    if (data && (<FormData>data).append) {
      contentType = ContentType.MULTIPART_FORM_DATA;
      dataToSend = data;
    } else {    // On ne trouve pas la fonction append => data n'est de type FormData
      contentType = ContentType.APPLICATION_JSON;
      dataToSend = {data: data};
    }
    if (!options) {
      options = {};
    }
    if (!options.headers) {
      options.headers = this.currentUserService.generateHeadersForProtectedRoutes(contentType)
    }
    let actionFunc: Function;
    let args;
    switch (action) {
      case HTTP_ACTIONS.GET:
        actionFunc = this.http.get.bind(this.http); // On fait un bind pour réattacher le context de la fonction quand elle sera applée (il est perdu lorqu'on enregistre la fonction dans une variable)
        args = [url, options];
        break;
      case HTTP_ACTIONS.PUT:
        actionFunc = this.http.put.bind(this.http); // On fait un bind pour réattacher le context de la fonction quand elle sera applée (il est perdu lorqu'on enregistre la fonction dans une variable)
        args = [url, dataToSend, options];
        break;
      case HTTP_ACTIONS.POST:
      default:
        actionFunc = this.http.post.bind(this.http); // On fait un bind pour réattacher le context de la fonction quand elle sera applée (il est perdu lorqu'on enregistre la fonction dans une variable)
        args = [url, dataToSend, options];
        break;
    }
    return actionFunc(...args);
  }

}

