import {AccountService} from "../services/account.service";
import {appConfigurationProperties} from "../../../config/appConfigurationProperties";
import {CurrentUserService} from "../../ng-manager-user/services/current-user.service";
import {Router} from "@angular/router";
import i18next from "i18next";
import {AipError} from "../../ng-models-ui";
import {ConfigurationProperties} from "../../ng-properties-configuration/configuration.properties";
import {appErrorProperties} from "../../../config/appErrorProperties";
import {ErrorStatusCode} from "../../ng-properties-error/error.properties";
import {ErrorService} from "../../ng-module-error/services/error.service";

export class LoginUtil {

  /**
   * Fonction de connexion de l'utilisateur au serveur.
   * Récupère les credentials à partir du troken remember-me ou d'autres onglets déjà ouverts.
   */
  public static initUserConnexion(accountService: AccountService): Promise<boolean> {
    return new Promise((resolve, reject) => {
      accountService.getCreds()
        .then(
          (creds) => {
            // On lance le processus d'authentification.
            // Si on n'a pas de credentials ou s'ils ne sont pas correctes, le processus d'authentification nous rejetera
            accountService.authenticateCreds(creds, false)
              .then(
                (data) => {
                  // Le processus d'authentification à fonctionné
                  if (data && data.confirmedSignup) {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                },
                (error) => {
                  // Le processus d'authentification à échoué
                  //  => On est redirigé vers la page d'acceuil
                  reject(error);
                })
          },
          (error) => {
            // On n'a pas trouvé de crendentials
            reject(undefined);
          });
    })
  }

  /**
   * Fonction de connexion de l'utilisateur au serveur.
   * Récupère les credentials à partir du troken remember-me ou d'autres onglets déjà ouverts.
   */
  public static denyAccessIfNotCorporateUser(
    router: Router,
    currentUserService: CurrentUserService,
    errorService: ErrorService): void {
    if (!currentUserService || !currentUserService.isUserCorporate()) {
      errorService.displayError(new AipError(
        ConfigurationProperties.cli_error_type_fatal,
        appErrorProperties.ERROR_ACCESS_DENY,
        i18next.t('error.title'),
        i18next.t('error.accessDeny.subTitle'),
        i18next.t('error.accessDeny.message'),
        ErrorStatusCode.ACCESS_DENIED));
      /*
      ErrorUtil.displayErrorsNotifications(
        {
          error: [{
            subTitle: i18next.t('error.accessDeny.subTitle'),
            msg: i18next.t('error.accessDeny.message')
          }]
        }, notificationsService);
      */
      router.navigate([appConfigurationProperties.URL_UI_ERROR]);
    }
  }
}
