import {
  Injectable,
  // Optional,
  Inject
} from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot
} from '@angular/router';
import {
  merge as observableMerge,
  Observable
} from 'rxjs';
import {EnumTypeService} from './enumType.service';
import {ErrorService} from '../../ng-module-error/services/error.service';
import {ObjectUtil} from '../../ng-helpers-util/object.util';
import {EnumTypeUtil} from './enumType.util';
import {CurrentUserService} from "../../ng-manager-user/services/current-user.service";

/**
 * Resolver permettant de recupérer les données des menus déroulants avant de naviguer vers une route.
 */
@Injectable({
  providedIn: 'root'
})
export class DropDownResolver implements Resolve<any> {

  /**
   *
   * @param enumTypeService
   * @param errorService
   * @param currentUserService
   * @param enumTypeNamespace
   */
  constructor(
    private enumTypeService: EnumTypeService,
    private errorService: ErrorService,
    private currentUserService: CurrentUserService,
    @Inject('enumTypeNamespace') public enumTypeNamespace: string,
    // @Inject('urlGetEnumType') @Optional() public urlGetEnumType?: string
  ) {
    // this.urlGetEnumType  = urlGetEnumType || "blahblahblahblahblahblahblahblah=";
  }

  /**
   *
   * @param route
   * @returns
   */
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    // La liste des requetes au serveur (liste d'observables)
    let dropdownObservables: Observable<any>[] = [];
    // Récupération des dropdown a télécharger à partir des paramètres du résolver dans le path de la route
    if (route.data['dropDowns'] && ObjectUtil.isArray(route.data['dropDowns'])) {
      dropdownObservables.push(this.enumTypeService.getEnumType(route.data['dropDowns']));
      /*
      for (let dropdown of route.data['dropDowns']) {
        dropdownObservables.push(this.enumTypeService.getEnumType(dropdown));
      }
      */
    }
    let allDropDownRequests = observableMerge(...dropdownObservables); // ... permet de tranformer le tableau en paramètre pour la fonction

    // Création de l'observable de retour du resolver
    return Observable.create(observer => {
      allDropDownRequests.subscribe(
        enumTypes => {  // Fonction exécutée pour chaque observable de la liste des requêtes
          // L'observable contentenant la liste des observables des requêtes est terminé
          for (let type in enumTypes) {   // On internationalise les dropdowns
            let additionalProperties;
            switch (type) {
              case "timezone":
                additionalProperties = ['options.offset', 'options.gmt'];
                break;
              case "currency":
                additionalProperties = ['options.abbreviation', 'options.symbol', 'options.decimals'];
                break;
              case "document":
                additionalProperties = ['options.url', 'options.lang', 'options.type'];
                break;
            }
            enumTypes[type] = EnumTypeUtil.getEnumTypesForDropDown(
              enumTypes[type],
              `${this.enumTypeNamespace}:${type}.`,
              additionalProperties,
              this.currentUserService.getUserPreferredLanguage());
          }
          observer.next(enumTypes); // On associe le résultat au retour de l'observable (sera transmis au prochain observable)
          observer.complete();      // On finalise l'observable pour le libérer
        },
        err => {
          this.errorService.displayError(err);
          observer.complete();    // On finalise l'observable pour le libérer
        },
        () => {
        }
      );
    })
  }

}
