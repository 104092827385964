import { Injectable } from '@angular/core';
import { Router, Resolve,
    ActivatedRouteSnapshot } from '@angular/router';
import {AccountService} from '../services/account.service';
import {CurrentUserService} from '../../ng-manager-user/services/current-user.service';
import {LoginUtil} from "../utils/login.util";
import {appConfigurationProperties} from "../../../config/appConfigurationProperties";
import {ObjectUtil} from "../../ng-helpers-util/object.util";
import {NavigationService} from "../../../shared/navigation.service";

/**
 * Resolver qui logue automatiquement un utilisateur avant de naviguer vers une route.
 */
@Injectable({
  providedIn: 'root'
})
export class LoginResolver implements Resolve<boolean> {

    /**
     *
     * @param router
     * @param currentUserService
     * @param accountService
     * @param navigationService
     */
    constructor(
        private router: Router,
        private currentUserService: CurrentUserService,
        private accountService: AccountService,
        private navigationService: NavigationService) {
    }

    /**
     * Le resolver redirige vers automatiquement vers la page d'accueil si l'argument "redirectToHomePageIfNotConnected"
     * passé au résolver est absent, de type non boolean ou égale à true. Si ce paramètre est égale à false, l'utilisateur
     * poursuivera sa navigation sans être automatiquement connecté.
     *
     * Le résolver est résolu avec true si l'utilisateur est automatiquement connecté ou false sinon.
     *
     * @param route
     * @returns
     */
    resolve(route: ActivatedRouteSnapshot): Promise<boolean>|boolean {
        // console.log("####### RESOLVE LoginResolve !!!!");
        const resolverData: any = route.data['redirectToHomePageIfNotConnected'];
        const loginUrl: any = route.data['loginUrl'] || appConfigurationProperties.URL_UI_LOGIN;
        const redirectToHomePageIfNotConnected = ((resolverData !== undefined) && (ObjectUtil.isBoolean(resolverData))) ?
            !!(route.data['redirectToHomePageIfNotConnected']) : true; // Par défaut : true
        if (!this.currentUserService || !this.currentUserService.user) {
            return LoginUtil.initUserConnexion(this.accountService).then(autorized => {
                if (autorized) {
                    this.navigationService.setForcedTheme();
                    return autorized;
                } else {
                    if (redirectToHomePageIfNotConnected) {
                        this.router.navigate([loginUrl]);
                    }
                    return false;
                }
            },
            (err) => {  // Le reject de "initUserConnexion()"
              if(err && (err.status === 508)) {
                // Boucle infinie (cas d'un utilisateur supprimé par exemple)
                return false;
              }
              if (redirectToHomePageIfNotConnected) {
                this.router.navigate([loginUrl]);
              } else {
                return false;
              }
            });
        } else {
            this.navigationService.setForcedTheme();
            return Promise.resolve(true);
        }
    }
}
