import {
  Injectable,
  Inject
} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {AuthenticationService} from '../../ng-manager-authentication/authentication.service';
import {EnumType} from '../../ng-models-ui/models/EnumType';
import {ObjectUtil} from "../../ng-helpers-util/object.util";

@Injectable({
  providedIn: 'root'
})
export class EnumTypeService {

  // Les types de données pouvant être enregistrées dans le cache "cachedData"
  cachedEnumTypeList = [
    "activity",
    "audience",
    "country",
    "zones",
    "currency",
    "department",
    "difficultyLevel",
    "document",
    "elearningCourseRegion",
    "contentField",
    "elearningResourceType",
    "language",
    "position",
    "priceCode",
    "role",
    "service",
    "timezone",
    "title"
  ];
  // Les données enregistées dans le cache client pour ne pas les récupérer du serveur si elles existent déjà
  cachedEnumType: any = {};

  /**
   *
   * @param http
   * @param authenticationService
   * @param urlGetEnumType
   */
  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService,
              @Inject('urlGetEnumType') public urlGetEnumType: string) {  // Variable à injecter dans le provider
    // console.log("################## urlGetEnumType:", urlGetEnumType);
  }

  /**
   * Fonction qui retourne la liste des enumType d'un type défini en paramètre.
   *
   * @param type
   * @returns
   */
  private doGetEnumTypeGet(type: string): Observable<EnumType[]> {
    return this.http.get<EnumType[]>(this.urlGetEnumType + type,
      {headers: this.authenticationService.generateHeadersForUnprotectedRoutes()});
    //.pipe(
    //.map(res => <EnumType[]> res.json()));
    // .map(res => {   // Suppression de l'_id qui est inutil
    //     res.forEach(item => delete item._id);
    //     return res;
    // });
    //.catch(ErrorUtil.handleError);
  }

  /**
   * Fonction qui retourne la liste des enumType d'un type défini en paramètre.
   *
   * @param types
   * @returns
   */
  private doGetEnumTypePost(types: string[]): Observable<EnumType[]> {
    return this.http.post<EnumType[]>(
      this.urlGetEnumType,
      {types, displayType: true},
      {headers: this.authenticationService.generateHeadersForUnprotectedRoutes()});
    //.pipe(
    //.map(res => <EnumType[]> res.json()));
    // .map(res => {   // Suppression de l'_id qui est inutil
    //     res.forEach(item => delete item._id);
    //     return res;
    // });
    //.catch(ErrorUtil.handleError);
  }

  /**
   * Fonction qui retourne un observer qui récupére les données "value" et les
   * stocke dans un objet dont la seule propriété est "property".
   */
  private setTypeEnumToObserver(value: any, property: string): Observable<any> {
    return Observable.create(observer => {
      // observer.next({language: value});
      // setTimeout(() => {   // Pour les tests
      observer.next({[property]: value});
      observer.complete();
      // Any cleanup logic might go here
      // return () => console.log('disposed')
      // }, 5000)
    });
  }

  /**
   * Fonction qui retourne un observer avec la propriété "value".
   */
  private setTypeEnumsToObserver(value: any): Observable<any> {
    return Observable.create(observer => {
      observer.next(value);
      observer.complete();
    });
  }

  /**
   * Fonction qui retourne un observer récupèrant depuis le serveur l'enumType de type "typeOfEnum"
   * et met le résultat dans un objet ne contenant qu'une propriété nommée "typeOfEnum".
   * Cette fonction chaine deux observers: celui effectuant la requête au serveur et celui encpsulant
   * le résultat dans l'objet.
   */
  public getEnumType(typeOfEnum: string | string[]): Observable<any> {
    const types = (ObjectUtil.isArray(typeOfEnum))
      ? typeOfEnum
      : (ObjectUtil.isString(typeOfEnum) ? [typeOfEnum] : []);
    const cacheInfo = this.getInfoCache(types as string[]);
    // Récupération des données
    if ((cacheInfo.notManagedByCache.length > 0) || (cacheInfo.notInCache.length > 0)) {
      const typesToGetFromServer = cacheInfo.notManagedByCache.concat(cacheInfo.notInCache);
      return this.doGetEnumTypeObserver(typesToGetFromServer)
        .pipe(mergeMap(responseFromServer => {
          let formattedResponseFromServer = {};
          responseFromServer.forEach(elem => {
            const type = elem.type;
            delete elem.type;
            if (ObjectUtil.hasProperty(formattedResponseFromServer, type)) {
              formattedResponseFromServer[type].push(elem);
            } else {
              formattedResponseFromServer[type] = [elem];
            }
          });
          // Mise à jour du cache
          this.updateCache(cacheInfo.notInCache, formattedResponseFromServer);
          // Retour du résultat
          return this.setTypeEnumsToObserver(
            Object.assign(formattedResponseFromServer, cacheInfo.enumTypesFromCache));
        }));
    } else {
      // Retour du résultat
      return this.setTypeEnumsToObserver(cacheInfo.enumTypesFromCache);
    }
  }

  /**
   * Fonction qui retourne un observer récupèrant depuis le serveur l'enumType de type "typeOfEnum"
   * et met le résultat dans un objet ne contenant qu'une propriété nommée "typeOfEnum".
   * Cette fonction chaine deux observers: celui effectuant la requête au serveur et celui encpsulant
   * le résultat dans l'objet.
   */
  private doGetEnumTypeObserver(typeOfEnum: string[]): Observable<any> {
    return this.doGetEnumTypePost(typeOfEnum);
  }

  /**
   * Fonction qui retourne true si l'enumType est dans le cache.
   */
  private isInCachedEnumType(typeOfEnum: string): boolean {
    return (
      this.isInCachedEnumTypeList(typeOfEnum) &&
      ObjectUtil.hasProperty(this.cachedEnumType, typeOfEnum));
  }

  /**
   * Fonction qui retourne true si l'enumType est géré par le cache.
   */
  private isInCachedEnumTypeList(typeOfEnum: string): boolean {
    return this.cachedEnumTypeList.includes(typeOfEnum);
  }

  /**
   * Fonction qui retourne les enumTypes du cache et d'autres informations.
   * Format de retour :
   *    {
   *      managedByCache: string[],     // types gérés par le cache
   *      notManagedByCache: string[],  // types non gérés par le cache
   *      inCache: string[],            // types gérés par le cache et déjà présents dans le cache
   *      notInCache: string[],         // types gérés par le cache mais non présents dans le cache
   *      enumTypesFromCache: {         // types issus du cache
   *        type1: [<any>],
   *        type2: [<any>],
   *        ...
   *      }
   *    }
   */
  private getInfoCache(typeOfEnum: string[]): any {
    let result = {
      managedByCache: [],
      notManagedByCache: [],
      inCache: [],
      notInCache: [],
      enumTypesFromCache: {}
    };
    typeOfEnum.forEach(type => {
      if (this.isInCachedEnumTypeList(type)) {
        result.managedByCache.push(type);
      } else {
        result.notManagedByCache.push(type);
      }
      if (this.isInCachedEnumType(type)) {
        result.inCache.push(type);
        result.enumTypesFromCache[type] = this.cachedEnumType[type]
      } else if (this.isInCachedEnumTypeList(type)) {
        result.notInCache.push(type);
      }
    });
    return result;
  }

  /**
   * Fonction qui retourne met à jour le cache avec les données reçues du serveur.
   */
  private updateCache(notInCache: string[], enumTypesFromServer: any): void {
    notInCache.forEach(type => {
      this.cachedEnumType[type] = enumTypesFromServer[type];
    });
  }

}
