import {ModuleLibraryConfigInt} from '../interfaces/moduleLibraryConfig.interface';
import {ObjectUtil} from '../../ng-helpers-util/object.util';

export class ModuleLibraryConfig implements ModuleLibraryConfigInt {

    /**
     *
     * @param data
     */
    constructor(public data: any) {     // Objet comprenant les propriétés de configuration du module
    }

    /**
     *
     * @param key Fonction de récupération de la valeur d'une propriété à partir de sa clé.
     */
    public getDataValue(key: string): string {
        return (ObjectUtil.isObject(this.data) && !ObjectUtil.isEmptyObject(this.data)) ? this.data[key] : undefined;
    }
}
