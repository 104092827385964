import {Injectable, Inject} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {CurrentUserService} from './current-user.service';
import { ManagerUserConfigService } from '../models/ModuleLoginConfig';
import {AuthenticationService} from "../../ng-manager-authentication/authentication.service";
import {ModuleLibraryConfigInt} from "../../ng-models-ui";
import {StateProperties} from "../../ng-properties-state/state.properties";
import {User} from "../../ng-models-ats/models/db/User";
import {UserDependenciesService} from "../../ng-module-login/services/user-dependencies.service";
import {ElearningProductLibraryService} from "../../../views/app/elearning/elearning-product-library.service";
import {PublishingProductLibraryService} from "../../../views/app/publishing/publishing-product-library.service";

/**
 * Service assurant l'authentication des utilisateurs.
 * Stocke les informations d'identification de l'utilisateur.
 *      Proriétés :
 *      - authToken jwt
 *
 *      Fonctions :
 *      - isTokenPresent()
 *      - decodeToken() :   decode le authToken jwt du composant pour obtenir les informations sur l'utilisateur envoyées par
 *                          le serveur lors de la phase d'authentification.
 *      - getTokenExpirationDate()
 *      - isTokenExpired()
 *
 */
@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private isInitialised = false;

    private jwtHelper: JwtHelperService = new JwtHelperService();

    private _authToken: string;       // Le token reçu du serveur contenant les informations d'authentification de l'utilisateur
    public get authToken(): string {
        return this._authToken;
    }
    public set authToken(newValue: string) {
        this._authToken = newValue;
    }

    /**
     *
     * @param config
     * @param currentUserService
     * @param authenticationService
     * @param userDependenciesService
     * @param elearningProductLibraryService
     * @param publishingProductLibraryService
     */
    constructor(
        @Inject(ManagerUserConfigService) private config: ModuleLibraryConfigInt,
        private currentUserService: CurrentUserService,
        private authenticationService: AuthenticationService,
        private userDependenciesService: UserDependenciesService,
        private elearningProductLibraryService: ElearningProductLibraryService,
        private publishingProductLibraryService: PublishingProductLibraryService) {
    }

    /**
     * Fonction qui initialise la session en cours.
     */
    public initSession(user: User = this.currentUserService.user, reload = false) {
        //console.log("SSSSSSSSSSSSSSSS initSession");
      if (!this.isInitialised || reload) {
        this.currentUserService.init(user);   // Doit être effectué en premier
        // On fixe le singleton
        this.isInitialised = true;
      } else {
      }
    }

    /**
     * Fonction qui réinitialise la session en cours.
     */
    public resetSession() {
        //console.log("SSSSSSSSSSSSSSSS resetSession");
        // Réinitialisation du statut
        this.isInitialised = false;
        StateProperties.IS_USER_CONNECTED = false;
        // Réinitialisation des tokens
        this.authenticationService.clearTokens();
        this.authToken = null;
        // Réinitialisation des services
        this.currentUserService.reset();   // Doit être effectué en premier
        this.userDependenciesService.reset();
        this.elearningProductLibraryService.reset();
        this.publishingProductLibraryService.reset();
        this.isInitialised = false;
    }

    /**
     * Fonction qui detruit la session en cours.
     */
    public clearSession() {
        //console.log("SSSSSSSSSSSSSSSS clearSession");
        // Destruction des services
        this.currentUserService.clear();   // Doit être effectué en premier
        this.isInitialised = false;
    }

    /**
     * Fonction qui test si un utilisateur est authentifié en
     * regardant la présence du authToken.
     *
     * @returns
     */
    public isTokenPresent(_token?: string): boolean {
        let toDecocde: string = this.authToken;
        if(_token !== undefined) {
            toDecocde = _token;
        }
        return !!toDecocde;
    }

    /**
     * Fonction qui décode le token crypté passé en service.
     *
     * @param token
     * @returns
     */
    public decodeToken(token: string): any {
        if (token) {
            return this.jwtHelper.decodeToken(token);
        } else {
            return null;
        }
    }

    /**
     * Fonction qui récupère la date d'expiration d'un token.
     * Si aucun token est passé en paramètre la fonction utilise le authToken.
     * Si un token est passé en paramètre, la fonction décode le token passé en paramètre.
     *
     * @returns
     */
    private getTokenExpirationDate(_token?: string): Date | null {
        let toDecocde: string = this.authToken;
        if(_token !== undefined) {
            toDecocde = _token;
        }
        if (this.isTokenPresent(toDecocde)) {
            return this.jwtHelper.getTokenExpirationDate(toDecocde);
        } else {
            return null;
        }
    }

    /**
     * Fonction qui détecte si un token a est expiré.
     * Si aucun token est passé en paramètre la fonction utilise le authToken.
     * Si un token est passé en paramètre, la fonction décode le token passé en paramètre.
     *
     * @returns
     */
    private isTokenExpired(_token?: string): boolean | null {
        let toDecocde: string = this.authToken;
        if(_token !== undefined) {
            toDecocde = _token;
        }
        if (this.isTokenPresent(toDecocde)) {
            return this.jwtHelper.isTokenExpired(toDecocde);
        } else {
            return null;
        }
    }

}
