import {appConfigurationProperties} from '../../config/appConfigurationProperties';
import {CatalogueItemType} from '../../data/catalogueProduct/CatalogueItemType';
import {IPublishingCatalogueItem} from '../../data/catalogueProduct/publishing/IPublishingCatalogueItem';
import {ObjectUtil} from '../../modules/ng-helpers-util/object.util';
import {Publication} from '../../modules/ng-models-atp/models/db/Publication';
import UiJsonConfig from '../../../config/current/aip-ui.json';

export class PublishingUtil {

  /**
   * Fonction qui retourne true si le bouton Publishing est masqué dans le menu et false sinon.
   */
  public static isMenuButtonPublishingHidden(): boolean {
    return (
      (UiJsonConfig.MENU_BUTTONS_HIDDEN || []).includes('MENU_BUTTON_HOME_START_PAGE_PUBLISHING_PUBLISHING') ||
      (UiJsonConfig.MENU_BUTTONS_HIDDEN || []).includes('MENU_BUTTON_PUBLISHING'));
  }

  /**
   * Fonction qui retourne l'url du serveur par défaut des images des entités publishing.
   */
  public static getDefaultImageUrl(category: CatalogueItemType | string): string {
    if ((category === CatalogueItemType.PUBLICATION) || (category === appConfigurationProperties.PAYMENT_PRODUCT_PUBLICATION)) {
      return `${appConfigurationProperties.URL_SERVER_AIP_ATP}/${appConfigurationProperties.URL_SERVER_PUBLISHING_DEFAULT_IMAGE_PUBLICATION()}`;
    } else if (category === CatalogueItemType.SOURCE) {
      return `${appConfigurationProperties.URL_SERVER_AIP_COMMON}/${appConfigurationProperties.URL_SERVER_COMMON_DEFAULT_IMAGE_SOURCE()}`;
    } else {
      return `${appConfigurationProperties.URL_SERVER_AIP_ATP}/${appConfigurationProperties.URL_SERVER_PUBLISHING_DEFAULT_IMAGE_PUBLICATION()}`;
    }
  }

  /**
   * Fonction qui retourne l'url de l'ui par défaut des détails des entités publishing.
   */
  public static getDetailUrl(category: CatalogueItemType | string): string {
    if ((category === CatalogueItemType.PUBLICATION) || (category === appConfigurationProperties.PAYMENT_PRODUCT_PUBLICATION)) {
      return appConfigurationProperties.URL_UI_ATP_DETAIL_PUBLICATION;
    } else {
      return appConfigurationProperties.URL_UI_HOME;
    }
  }

  /**
   * Fonction qui transforme un type payment d'entité publishing vers un type catalogue.
   */
  public static paymentTypeToCatalogueType(type: string): CatalogueItemType {
    switch (type) {
      case appConfigurationProperties.PAYMENT_PRODUCT_PUBLICATION:
        return CatalogueItemType.PUBLICATION;
      default:
        return undefined;
    }
  }

  /**
   * Fonction qui retourne la classe des badge selon la catégorie de publication.
   */
  public static classProductCategory(product: IPublishingCatalogueItem): string {
    if (ObjectUtil.hasProperty(product, 'catalogueProduct', 'category')) {
      if (product.catalogueProduct.category === CatalogueItemType.SOURCE) {
        return 'category-color-source';
      } else {
        const cat = (ObjectUtil.hasProperty(product, 'catalogueProduct', 'product', 'details', 'category'))
          ? (product.catalogueProduct.product as Publication).details.category
          : '';
        switch (cat) {
          case appConfigurationProperties.PUBLICATION_ENUM_TYPE_DIRECTORY_CONTACT:
            return 'category-color-publication-directoryContact';
          case appConfigurationProperties.PUBLICATION_ENUM_TYPE_INSURANCE_MARKET:
            return 'category-color-publication-insuranceMarket';
          case appConfigurationProperties.PUBLICATION_ENUM_TYPE_INSURANCE_STATISTICS:
            return 'category-color-publication-insuranceStatistics';
          case appConfigurationProperties.PUBLICATION_ENUM_TYPE_INSURANCE_STUDIES:
            return 'category-color-publication-insuranceStudies';
          default:
            return 'category-color-publication-other';
        }
      }
    } else {
      return '';
    }
  }

}
