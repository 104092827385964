import { Pipe, PipeTransform } from '@angular/core';
import i18next from 'i18next';

/*
 * Traduit une clé avec éventuellement des options de traduction
 *
 * Usages :
 *   key | i18next
 *   key | i18next:options
 *   key | i18next:options:triggerReload
 *
 * Examples :
 *   {{ 'publishedContainers.type.nbLesson_interval' | i18next: {postProcess: 'interval', count: 0} }}
 *   p.mb-0(*ngIf="userEmail") {{ 'page.profile.info.personal.email' | i18next }}
 *   p.mb-0([innerHTML]="'page.profile.account.info.displayUsername' | i18next:{'username': userUsername}")
 *   p.mb-0(*ngIf="userTimezone && userDst ", [innerHTML]="'page.profile.account.info.displayTimezone' | i18next:{timezone: '$t(aip-enumTypes:timezone.' + userTimezone + ')', context: userDst}")
 * La balise [innerHTML] permet de fomater l'affichage en html des balises contenues dans la traduction dans le fichier de locale ("yyy<strong>xxx</strong>")
 *
 * Remarques :
 *  - Le paramètre "triggerReload" permet de forcer la réévaluation du pipe si cette valeur a été modifiée dans le composant.
 *    Si le paramètre 'options' est vide et 'triggerReload' doit être utilisé, mettre 'undefined' pour le paramètre 'options'.
 *    Exemple
 *      => jade :
 *            h6.mb-4 {{ 'page.resetPassword.title' | i18next:undefined:triggerReload }}
 *      => ts :
 *          i18next.changeLanguage("fr");  // On change la langue
 *          this.triggerReload = new Date().getTime();  // On force la réévaluation des pipe i18next
 */
@Pipe({name: 'i18next'})
export class I18nextPipe implements PipeTransform {
  transform(key: string, options?: any, triggerReload?: any): string {
    return i18next.t(key, options);
  }
}
