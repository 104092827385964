import {UserTeachingDb} from '../../../modules/ng-models-atel3/models/db/userAtel/UserTeachingDb';
import {IElearningUserProduct} from './IElearningUserProduct';
import {ElearningProductLibraryService} from '../../../views/app/elearning/elearning-product-library.service';
import {Lesson} from '../../../modules/ng-models-atel3/models/db/Lesson';
import {CurrentUserService} from '../../../modules/ng-manager-user/services/current-user.service';
import {appConfigurationProperties} from '../../../config/appConfigurationProperties';
import {UserDependenciesService} from '../../../modules/ng-module-login/services/user-dependencies.service';
import {LibraryUtil} from '../../../modules/ng-manager-user/utils/atel3/library.util';

export class UserLesson extends UserTeachingDb implements IElearningUserProduct {

  public lesson: Lesson;

  /**
   *
   */
  constructor(
    lesson: Lesson,
    userTeachingsGetter?: CurrentUserService | UserTeachingDb[],
    userTeachingData?: any) {
    /*
    if (
      currentUserService &&
      !userTeachingData &&
      ObjectUtil.hasProperty(currentUserService, 'user', 'atel', 'teachings') &&
      ObjectUtil.isArray(currentUserService.user.atel.teachings)) {
      // Récupération des données du userTeaching dans les teachings de l'utilisateur
      userTeachingData = currentUserService.user.atel.teachings.find(elem => (
        ObjectUtil.hasProperty(elem, 'teaching_ref') && (elem.teaching_ref === lesson._id) &&
        ObjectUtil.hasProperty(elem, 'status', 'deleted') && !elem.status.deleted
      ));
    }
    */
    if (!userTeachingData) {
      userTeachingData = LibraryUtil.getUserTeachingFromGetter(userTeachingsGetter, lesson._id);
    }
    super(userTeachingData);
    this.lesson = lesson;
  }

  // Le type d'objet
  public getType(): string {
    return appConfigurationProperties.LEARNING_CONTAINER_TYPE_LESSON;
  }

  // L'objet associé
  public getEmbeddedObject(): Lesson {
    return this.lesson;
  }

  // Fonction qui test si l'objet associé existe
  public hasEmbeddedObject(): boolean {
    return this.lesson !== undefined;
  }

  // Fonction qui test si l'objet est valide
  public isEmbeddedObjectValid(): boolean {
    return this.lesson.isValid();
  }

  // L'objet est partiellement possédé par l'utilisateur
  public isPartiallyOwnByUser(
    userTeachingsGetter?: CurrentUserService | UserTeachingDb[],
    userDependenciesService?: UserDependenciesService,
    considerUserTeachingActiveFlag: boolean = false): boolean {
    return this.isUserTeachingValid(considerUserTeachingActiveFlag);
  }

  // L'objet est entièrement possédé par l'utilisateur
  public isFullyOwnByUser(
    userTeachingsGetter?: CurrentUserService | UserTeachingDb[],
    userDependenciesService?: UserDependenciesService,
    considerUserTeachingActiveFlag: boolean = false): boolean {
    return this.isUserTeachingValid(considerUserTeachingActiveFlag);
  }

  /**
   *
   */
  public hasInfoProgression({elearningProductLibraryService}): boolean {
    return (
      this.isUserTeachingValid(false) &&
      this.isPurchased(elearningProductLibraryService));
  }

  // L'objet a été acheté par l'utilisateur
  isPurchased(elearningProductLibraryService: ElearningProductLibraryService): boolean {
    return (elearningProductLibraryService.getUserLessonFromTeachingId(this.lesson._id) !== undefined);
  }

  // L'objet peut être acheté par cet utilisateur
  isPurchasable({elearningProductLibraryService}): boolean {
    return (
      this.getEmbeddedObject().hasPrice() &&
      (
        !this.isUserTeachingValid(true) ||
        !this.hasInfoProgression({elearningProductLibraryService}) ||
        !this.isFullyOwnByUser()
      )
    );
  }

}
