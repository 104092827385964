import {ObjectUtil} from "../../../ng-helpers-util/object.util";
import {UserTeachingDb} from "../../../ng-models-atel3/models/db/userAtel/UserTeachingDb";

export class UserAtel {

    _id?: string;
    // roles?: string[];
    statistics?: UserAtelStatistics;
    teachings?: UserTeachingDb[];
    settings?: UserAtelSettings;

    /**
     * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
     * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
     * ainsi que les fonctions définies dans la classe.
     *
     * @param _data
     */
    constructor(
      // userDependenciesService: UserDependenciesService,
      _data: any = undefined) {
      $.extend(this, _data);
      if (_data && _data.teachings && ObjectUtil.isArray(_data.teachings)) {
        // this.teachings = _data.teachings;
        // this.teachings = LibraryUtil.buildTeachingsList(userDependenciesService, _data.teachings);
        this.teachings = _data.teachings
          .map(elem => new UserTeachingDb(elem))
          .filter(elem => elem !== undefined);
      } else {
        this.teachings = [];
      }
    }

    /**
     * Fonction qui intialise une nouvel utilisateur Atel.
     *
     * @param reset - Si false, n'initialise que les propriétés undefined.
     */
    public init(reset: boolean = true): UserAtel {
        this.initUserAtel();
        if (reset || !this.statistics) {
            this.initUserAtelStatistics();
        }
        return this;
    }

    public initUserAtel(): void {
        // this.roles = [];
        this.teachings = [];
    }

    public initUserAtelStatistics(): void {
        this.statistics = new UserAtelStatistics();
        this.statistics.totalTime = 0;
        this.statistics.totalTimeEffective = 0;
    }

}

class UserAtelSettings {
    lastLibraryTab: number;
}

class UserAtelStatistics {
    totalTime: number;
    totalTimeEffective: number;
}
