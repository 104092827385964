import {LearningContainer} from "./LearningContainer";
import {TeachingData} from "./Teaching";
import {Theme} from "./Theme";
import {IElearningProduct} from "../../../../data/catalogueProduct/elearning/IElearningProduct";

export class TeachingQuiz {
  _id: string;
  _ref: string;
  success: TeachingQuizSuccess;
}

export class TeachingQuizSuccess {
  score: number;
  progress: number;
}

export class Quiz extends LearningContainer implements IElearningProduct {

  public theme: Theme;
  public url: string;
  public data: TeachingData;
  public quizType: string;
  public quizLessons: Array<TeachingQuiz> = [];
  public quizCourses: Array<TeachingQuiz> = [];
  public quizDegrees: Array<TeachingQuiz> = [];

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    super(_data);
    $.extend(this, _data);
  }

  /**
   *
   */
  isPopulated(): boolean {
    return true;
  }

  /**
   *
   */
  populate(): void {}

}

