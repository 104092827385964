import {User} from "./User";
import {atelProperties} from "../../../ng-properties-application/atel.properties";
import {applicationProperties} from "../../../ng-properties-application/application.properties";
import {AddressSchema} from "../schemas/AddressSchema";
import {IElearningProduct} from "../../../../data/catalogueProduct/elearning/IElearningProduct";

class SourceStatus {
  active: boolean;
  expired: boolean;
  activationDate: Date;
  expiryDate: Date;
  modificationDate: Date;
  creationDate: Date;
  deleted: boolean;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
  }
}

class SourceGift {
  given: number;
  remaining: number;
  player: Array<SourcePlayer>;
  message: SourceMessage;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
  }
}

class SourceMessage {
  picture : string ;
  displayForEver : boolean;
  displayNb : number ;
}

class SourcePlayer {
  version: string;
  picture: string;
  _id: string;

}

export class SourceGroup {
  _id: string;
  name: string;
  active: boolean;
  users: User[] = [];
}

class sourceCompany {
  name: string;
  activity: string;
  email: string;
  phone: string;
  website: string;
  address: AddressSchema;
}

export class Source implements IElearningProduct {
  _id: string;
  name: string;
  code: string;
  company: sourceCompany;
  picture: string;
  status: SourceStatus;
  gift: SourceGift;
  groups: SourceGroup[] = [];

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
    if (_data && _data.status) {
      this.status = new SourceStatus(_data.status);
    }
    if (_data && _data.gift) {
      this.gift = new SourceGift(_data.gift);
    }
  }

  /**
   * Fonction qui test si l'objet est valide
   */
  public isValid(): boolean {
    return (
      !this.status.deleted &&
      this.status.active
    )
  }

  /**
   * Fonction qui retourne le prix total au format string de l'objet dans la devise spécifiée pour l'affichage.
   */
  displayPrice(): string {
    return undefined;
  }

  /**
   * Fonction qui retourne le prix total soldé au format string de l'objet dans la devise spécifiée pour l'affichage.
   */
  displayPriceDiscounted(): string {
    return undefined;
  }

  /**
   * Fonction qui retourne la discount au format string de l'objet.
   */
  displayDiscount(): string {
    return undefined;
  }

  /**
   * Fonction qui retourne true si le learningContainer a un prix.
   */
  public hasPrice(): boolean {
    return false;
  }

  /**
   * Fonction qui met à jour les urls des images de la source avec les paths du système.
   */
  public updatePictureUrls() {
    this.picture = Source.formatSourceForDisplay(this).picture;
  }

  /**
   * Fonction qui retounre une source formatée pour l'affichage :
   *    - mise à jour des urls des images
   *
   * @returns
   */
  public static formatSourceForDisplay(source): Source {
    let newSource: Source = JSON.parse(JSON.stringify(source));
    if(!newSource.picture) {
      newSource.picture = applicationProperties.URL_DATA_SOURCE() + applicationProperties.FOLDER_PICTURES + atelProperties.DEFAULT_FILE_PICTURE_SOURCE();
    } else {
      newSource.picture = applicationProperties.URL_DATA_SOURCE(newSource._id) + applicationProperties.FOLDER_PICTURES + newSource.picture;
    }
    return newSource;
  }

  /**
   *
   */
  isPopulated(): boolean {
    return true;
  }

  /**
   *
   */
  populate(): void {}

}
