import {
  Injectable,
  Inject
} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ModuleLoginConfigService} from '../models/ModuleLoginConfig';
import {LoginProperties} from '../login.properties';
import {ErrorService} from '../../ng-module-error/services/error.service';
import {SessionService} from '../../ng-manager-user/services/user-session.service';
import {CurrentUserService} from '../../ng-manager-user/services/current-user.service';
import {AuthenticationService} from '../../ng-manager-authentication/authentication.service';
import {ModuleLibraryConfigInt} from '../../ng-models-ui/interfaces/moduleLibraryConfig.interface';
import {Credentials} from '../../ng-models-ui/models/Credentials';
import {StateProperties} from '../../ng-properties-state/state.properties';
import {AipError} from '../../ng-models-ui/models/AipError';
import {ConfigurationProperties} from '../../ng-properties-configuration/configuration.properties';
import {errorProperties, ErrorStatusCode} from '../../ng-properties-error/error.properties';
import {User} from '../../ng-models-ats/models/db/User';
import {ObjectUtil} from '../../ng-helpers-util/object.util';
import {applicationProperties} from '../../ng-properties-application/application.properties';
import {CookieUtil} from '../../ng-helpers-cookies/cookie.util';
import {StringUtil} from '../../ng-helpers-util/string.util';
import i18next from 'i18next';
import {AipDbService} from '../../../shared/aip-db.service';
import {UserNotification, NotificationBellService} from '../../../shared/notificationBell.service';
import {UserDependenciesService} from "./user-dependencies.service";
import {ElearningProductLibraryService} from "../../../views/app/elearning/elearning-product-library.service";
import {PublishingProductLibraryService} from "../../../views/app/publishing/publishing-product-library.service";


/**
 * Service assurant l'authentication des utilisateurs.
 *      - authenticate(username:string, password:string)
 *      - logout()
 */
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  /**
   *
   * @param http
   * @param errorService
   * @param sessionService
   * @param aipDbService
   * @param currentUserService
   * @param elearningProductLibraryService
   * @param publishingProductLibraryService
   * @param authenticationService
   * @param notificationBellService
   * @param userDependenciesService
   * @param config
   */
  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private sessionService: SessionService,
    private aipDbService: AipDbService,
    private currentUserService: CurrentUserService,
    private elearningProductLibraryService: ElearningProductLibraryService,
    private publishingProductLibraryService: PublishingProductLibraryService,
    private authenticationService: AuthenticationService,
    private notificationBellService: NotificationBellService,
    private userDependenciesService: UserDependenciesService,
    @Inject(ModuleLoginConfigService) private config: ModuleLibraryConfigInt,
  ) {
  }

  /**
   * Fonction qui gère l'authentification des utilisateurs à partir de leur credentials.
   * Si l'utilisateur est trouvé, la promesse est résolue avec l'id de l'utilisateur "userId" et
   * un paramètre "confirmedSignup" qui indique si la confirmation de l'utilisateur est en règle.
   * Si l'utilisateur n'est pas trouvé la promesse sera rejetée.
   *
   * @param creds
   * @param rememberMe - si true, demande à sauver les  crédentials dans le token jwt remember-me.
   * @param userLanguage
   * @param captcha
   */
  authenticateCreds(
    creds: Credentials,
    rememberMe: boolean,
    captcha?: string,
    userLanguage?: string): Promise<{ confirmedSignup: boolean, userId: string }> {

    //console.log("////// creds:", creds);
    StateProperties.IS_USER_CONNECTED = false;
    return new Promise((resolve, reject) => {

      if (!creds) {
        // Si on n'a pas de credentials, on rejette la promesse
        //console.log("authenticateCreds => REJECT!!!");
        reject(new AipError(
          ConfigurationProperties.cli_error_type_fatal,
          errorProperties.ERROR_AUTHENTICATE_NO_CREDENTIALS,
          i18next.t('error.title'),
          i18next.t('error.authenticationFailed.subTitle'),
          i18next.t('error.authenticationFailed.message'),
          ErrorStatusCode.ACCESS_DENIED));

      } else {
        // On a des credentials
        //console.log("authenticateCreds => NOT YET REJECTED!!!");
        //setTimeout(function() {   // Pour simuler un temps de latence du réseau

        // console.log('LoginProperties:', LoginProperties);
        // console.log('LoginProperties.URL_SERVER_CREATE_USER_SESSION:', LoginProperties.URL_SERVER_CREATE_USER_SESSION);

        // On lance la requête pour créer une session et authoriser les routes protégées par droit d'accès.
        let headers = this.authenticationService.generateHeadersForUnprotectedRoutes(userLanguage);
        if (!StringUtil.isUndefinedOrEmpty(captcha)) {
          headers = headers.append(
            applicationProperties.REQUEST_SIGNATURE_CAPTCHA_REQUESTED,
            userLanguage + ", en;q=0.8, fr;q=0.7"); // La valeur est bidon
        }
        this.http.post(
          LoginProperties.URL_SERVER_CREATE_USER_SESSION,
          {...creds, captcha},
          {
            headers
          })
          .subscribe(
            async data => {
              // let formatedData = data.json();
              let userToken = (<any>data).user_token;    // Le userToken comprenant les informations sur l'utilisateur
              let user: User = this.sessionService.decodeToken(userToken);
              // Récupération des dépendences de l'utilisateur
              this.userDependenciesService.init((data as any).userDependencies);
              // On enregistre l'utilisateur dans la session
              this.sessionService.initSession(user, true);  // param 'reload' sur true pour mettre à jour le 'topnav.component'
              // Le serveur nous a retourné les informations sur l'utilisateur pour lequel on n'a fait la demande de connexion
              if ((<any>data).confirmed_signup) {   // Les informations de confirmation du signin sont en règle (l'utilisateur a activé son compte)
                // On sauve les credentials
                // ** TODO : faire une fonction updateJwtCreds qui met à jour le username si on le modifie dans l écran du profil utilisateur
                await this.authenticationService.saveJwtCreds(creds, rememberMe);
                //console.log("data.json().user_token=" + JSON.stringify(data));
                // console.log("data=", data);
                this.sessionService.authToken = (<any>data).auth_token;   // Le authToken comprenant les informations d'authentification de l'utilisateur
                // console.log("this.sessionService.authToken=", this.sessionService.authToken);
                // On supprime l'eventuel token qui aurai pu être rajouté si on avait cliqué sur logout car on n'est maintenant connecté
                this.authenticationService.clearUserRequestLogoutToken();
                // On sauve le token utilisateur dans le store
                this.authenticationService.saveJwtAuthToken(this.sessionService.authToken);
                // console.log('$$$$$$$$$$$$$$ token db:', await this.aipDbService.getAuthToken());
                // console.log('$$$$$$$$$$$$$$ LocalCreds:', await this.authenticationService.getLocalCreds());
                // On regénère la librairie
                if (this.elearningProductLibraryService) {
                  this.elearningProductLibraryService.init();
                }
                if (this.publishingProductLibraryService) {
                  this.publishingProductLibraryService.init();
                }
                // On lance la détection des changements dans les informations de session des autres onglets
                this.initSessionListener();
                // On fixe l'indicateur de connexion de l'utilisateur
                StateProperties.IS_USER_CONNECTED = true;
                // On récupère les notifications
                let notifications: UserNotification[] =
                  <UserNotification[]>(<any>data).notifications.map(elem => new UserNotification(elem));
                this.notificationBellService.notifications = notifications.filter(elem => elem.notificationBell);
                // On resout la promesse
                resolve({confirmedSignup: true, userId: user._id});
              } else {   // Les informations de confirmation du signin ne sont pas en règle
                resolve({confirmedSignup: false, userId: user._id});
              }
            },
            err => {
              // La demande d'authentification à échoué
              // console.log("Error", err);
              // On efface les informations de la session
              this.sessionService.clearSession();
              // On se déconnect et on efface les tokens
              // this.logout();
              reject(err);
            },
            () => {
              //console.log('Authentication Complete');
            }
          );
        //}.bind(this), 5000);  // fin de la fonction : setTimeout
      }
    });
  }

  /**
   *
   * @param email
   * @param captcha
   * @param userLanguage
   */
  requestResetPassword(email: string, captcha: string, userLanguage?: string): Promise<{ email?: string, emailNotFound?: boolean }> {
    return new Promise((resolve, reject) => {
      if (!email) {
        // Si on n'a pas d'email, on rejette la promesse
        //console.log("authenticateCreds => REJECT!!!");
        reject(new AipError(
          ConfigurationProperties.cli_error_type_fatal,
          errorProperties.ERROR_AUTHENTICATE_NO_CREDENTIALS,
          i18next.t('error.title'),
          i18next.t('error.authenticationFailed.subTitle'),
          i18next.t('error.authenticationFailed.message'),
          ErrorStatusCode.ACCESS_DENIED));
      } else {
        // On lance la requête pour créer une session et authoriser les routes protégées par droit d'accès.
        let headers = this.authenticationService.generateHeadersForUnprotectedRoutes(userLanguage);
        if (!StringUtil.isUndefinedOrEmpty(captcha)) {
          headers = headers.append(
            applicationProperties.REQUEST_SIGNATURE_CAPTCHA_REQUESTED,
            userLanguage + ", en;q=0.8, fr;q=0.7"); // La valeur est bidon
        }
        // On lance la requête pour créer une session et authoriser les routes protégées par droit d'accès.
        this.http.post(
          LoginProperties.URL_SERVER_REQUEST_RESET_PASSWORD,
          {email, captcha},
          {
            headers
          })
          .subscribe({
            next: data => {
              if ((<any>data).email) {  // Le serveur nous a retourné l'email masqué de l'utilisateur
                let serverData = {email: (<any>data).email};
                if ((<any>data).nef) {  // Le serveur n'a pas trouvé l'email dans la base de données (nef = emailNotFound)
                  (<any>serverData).emailNotFound = (<any>data).nef;
                }
                resolve(serverData);
              } else {   // Les informations de confirmation du signin ne sont pas en règle
                reject();
              }
            },
            error: err => {
              // La demande a échoué
              reject(err);
            }
          });
      }
    });
  }

  /**
   *
   * @param user
   * @param password
   * @param captcha
   * @param userLanguage
   * @param options
   */
  register(
    user: User,
    password: string,
    captcha: string,
    userLanguage?: string,
    {registrationByCorporateUser}: any = {}): Promise<{ email: string }> {
    return new Promise((resolve, reject) => {
      if (!user || !password) {
        // Si on n'a pas d'email, on rejette la promesse
        //console.log("authenticateCreds => REJECT!!!");
        reject(
          new AipError(
            ConfigurationProperties.cli_error_type_fatal,
            errorProperties.ERROR_AUTHENTICATE_NO_CREDENTIALS,
            i18next.t('error.title'),
            i18next.t('error.authenticationFailed.subTitle'),
            i18next.t('error.authenticationFailed.message'),
            ErrorStatusCode.EXPECTATION_FAILED));
      } else {
        // On lance la requête pour créer une session et authoriser les routes protégées par droit d'accès.
        let headers = this.authenticationService.generateHeadersForUnprotectedRoutes(userLanguage);
        if (!StringUtil.isUndefinedOrEmpty(captcha)) {
          headers = headers.append(
            applicationProperties.REQUEST_SIGNATURE_CAPTCHA_REQUESTED,
            userLanguage + ", en;q=0.8, fr;q=0.7"); // La valeur est bidon
        }
        this.http.post(
          LoginProperties.URL_SERVER_REGISTER,
          {user, password, captcha, registrationByCorporateUser},
          {
            headers
          })
          .subscribe({
            next: data => {
              if ((<any>data).email) {  // Le serveur nous a retourné l'email masqué de l'utilisateur
                resolve({email: (<any>data).email});
              } else {   // Les informations de confirmation du signin ne sont pas en règle
                reject();
              }
            },
            error: err => {
              // La demande a échoué
              reject(err);
            }
          });
      }
    });
  }

  /**
   *
   * @param userId
   * @param userLanguage
   */
  registerConfirmation(userId: string, userLanguage?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!userId) {
        // Si on n'a pas d'email, on rejette la promesse
        //console.log("authenticateCreds => REJECT!!!");
        reject(new AipError(
          ConfigurationProperties.cli_error_type_fatal,
          errorProperties.ERROR_AUTHENTICATE_NO_CREDENTIALS,
          i18next.t('error.title'),
          i18next.t('error.authenticationFailed.subTitle'),
          i18next.t('error.authenticationFailed.message'),
          ErrorStatusCode.ACCESS_DENIED));
      } else {
        this.http.get(
          `${LoginProperties.URL_SERVER_REGISTER_CONFIRMATION}/${userId}`,
          {
            headers: this.authenticationService.generateHeadersForUnprotectedRoutes(userLanguage)
          })
          .subscribe({
            next: data => {
              if (data) {
                resolve((<any>data));
              } else {
                reject();
              }
            },
            error: err => {
              // La demande a échoué
              reject(err);
            }
          });
      }
    });
  }

  /**
   *
   * @param email
   * @param userLanguage
   */
  resendEmailConfirmation(email: string, userLanguage?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!email) {
        // Si on n'a pas d'email, on rejette la promesse
        //console.log("authenticateCreds => REJECT!!!");
        reject(new AipError(
          ConfigurationProperties.cli_error_type_fatal,
          errorProperties.ERROR_AUTHENTICATE_NO_CREDENTIALS,
          i18next.t('error.title'),
          i18next.t('error.authenticationFailed.subTitle'),
          i18next.t('error.authenticationFailed.message'),
          ErrorStatusCode.ACCESS_DENIED));
      } else {
        // On lance la requête pour créer une session et authoriser les routes protégées par droit d'accès.
        this.http.post(
          LoginProperties.URL_SERVER_RESEND_CONFIRMATION_EMAIL,
          {email},
          {
            headers: this.authenticationService.generateHeadersForUnprotectedRoutes(userLanguage)
          })
          .subscribe({
            next: data => {
              if (data) {
                resolve((<any>data));
              } else {
                reject();
              }
            },
            error: err => {
              // La demande a échoué
              reject(err);
            }
          });
      }
    });
  }

  /**
   *
   * @param data
   * @param captcha
   * @param userLanguage
   */
  resetPassword(data: any, captcha: string, userLanguage?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!data) {
        // Si on n'a pas d'email, on rejette la promesse
        //console.log("authenticateCreds => REJECT!!!");
        reject(new AipError(
          ConfigurationProperties.cli_error_type_fatal,
          errorProperties.ERROR_AUTHENTICATE_NO_CREDENTIALS,
          i18next.t('error.title'),
          i18next.t('error.authenticationFailed.subTitle'),
          i18next.t('error.authenticationFailed.message'),
          ErrorStatusCode.ACCESS_DENIED));
      } else {
        if (!StringUtil.isUndefinedOrEmpty(data.email)) {
          data.email = data.email.trim();
        }
        if (!StringUtil.isUndefinedOrEmpty(data.username)) {
          data.username = data.username.trim();
        }
        // On lance la requête pour créer une session et authoriser les routes protégées par droit d'accès.
        let headers = this.authenticationService.generateHeadersForUnprotectedRoutes(userLanguage);
        if (!StringUtil.isUndefinedOrEmpty(captcha)) {
          headers = headers.append(
            applicationProperties.REQUEST_SIGNATURE_CAPTCHA_REQUESTED,
            userLanguage + ", en;q=0.8, fr;q=0.7"); // La valeur est bidon
        }
        this.http.post(
          LoginProperties.URL_SERVER_RESET_PASSWORD,
          {...data, captcha},
          {
            headers
          })
          .subscribe({
            next: data => {
              if (data) {
                resolve(data);
              } else {
                reject();
              }
            },
            error: err => {
              // La demande a échoué
              reject(err);
            }
          });
      }
    });
  }

  /**
   *
   * @param userId
   * @param userLanguage
   */
  changeEmail(userId: string, userLanguage?: string): Observable<Object> {
    if (!userId) {
      // Si on n'a pas d'email, on rejette la promesse
      //console.log("authenticateCreds => REJECT!!!");
      throw new AipError(
        ConfigurationProperties.cli_error_type_fatal,
        errorProperties.ERROR_AUTHENTICATE_NO_CREDENTIALS,
        i18next.t('error.title'),
        i18next.t('error.authenticationFailed.subTitle'),
        i18next.t('error.authenticationFailed.message'),
        ErrorStatusCode.ACCESS_DENIED);
    } else {
      return this.http.get(
        `${LoginProperties.URL_SERVER_CHANGE_EMAIL_CONFIRMATION}/${userId}`,
        {
          headers: this.authenticationService.generateHeadersForUnprotectedRoutes(userLanguage)
        })
    }
  }

  /**
   *
   * @param userLanguage
   */
  changeEmailCancel(userLanguage?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.get(
        `${LoginProperties.URL_SERVER_CHANGE_EMAIL_CANCEL}`,
        {
          headers: this.authenticationService.generateHeadersForUnprotectedRoutes(userLanguage)
        })
        .subscribe({
          next: data => {
            if (data) {
              resolve((<any>data));
            } else {
              reject();
            }
          },
          error: err => {
            // La demande a échoué
            reject(err);
          }
        });
    });
  }

  /**
   * Fonction qui effectue une mise à jour des informations de l'utilisateur et qui déclare
   * le changement afin que les composants ayant souscrit a ces évènements puissent mettre
   * à jour leur affichage.
   *
   * @param rebuildDependencies
   * @param elearningProductLibraryService Si présent reconstruit la bibliothèque elearning
   * @param publishingProductLibraryService
   */
  reloadUser(
    rebuildDependencies = true,
    elearningProductLibraryService?: ElearningProductLibraryService,
    publishingProductLibraryService?: PublishingProductLibraryService): Observable<User> {

    // console.log('===> reloadUser');
    return new Observable(observer => {
      this.currentUserService.reloadUser(rebuildDependencies).subscribe(
        res => {
          // console.log('reload user -> res:', res);
          let userToken = res.user_token;    // Le userToken comprenant les informations sur l'utilisateur
          let user: User = this.sessionService.decodeToken(userToken);
          // console.log('reload user -> user:', user);
          // Récupération des dépendences de l'utilisateur
          this.userDependenciesService.init((res as any).userDependencies);
          // On enregistre l'utilisateur dans la session
          this.sessionService.initSession(user, true);
          // console.log('reload user -> this.currentUserService.user.atel.teachings:', this.currentUserService.user.atel.teachings);
          this.currentUserService.declareEdition();
          this.currentUserService.declarePictureEdition();   // Au cas où la photo de profil de l'utilisateur a été modifiée aussi
          // On regénère la librairie
          if (elearningProductLibraryService) {
            elearningProductLibraryService.init();
          }
          if (publishingProductLibraryService) {
            publishingProductLibraryService.init();
          }
          // La valeur de retour
          observer.next(user);        // Valeur retournée en cas de succès
          observer.complete();    // Permet de lancer la fonction callback complete (troisième argument de la fonction subscribe) qui clos le stream
        },
        err => {
          // console.log('Aie Aie Aie il y a eut une erreur !!! err:', err);
          console.error(err);
          // this.errorService.displayError(err.error);
          // La valeur de retour
          observer.error(err);        // Valeur retournée en cas d'erreur
          observer.complete();    // Permet de lancer la fonction callback complete (troisième argument de la fonction subscribe) qui clos le stream
        })
    })
  }

  /**
   * Fonction qui fait un logout et efface toutes les informations du localStorage et du sessionStorage,
   * sauf le user token trigger pour ne pas lancer la copie des credentials si d'autres onglets sont ouverts.
   *
   * @returns
   */
  serverLogout(): Observable<any> {
    return this.http.post(LoginProperties.URL_SERVER_LOGOUT,
      {headers: this.authenticationService.generateHeadersForUnprotectedRoutes()});
  }

  /**
   * La fonction de logout par défaut si elle n'a pas été définie dans le paramètre "this.config.data.logoutFunction".
   *
   * Fonction dupliquée dans C:\home\logiciels\AtlasInsurancePlace\aip-ui\dev\trunk\src\ngui\src\app\config\packages\ng-module-login\account.service.ts - logoutFunction()
   */
  defaultLogoutFunction(): Observable<any> {
    // On procède au logout
    return Observable.create(observer => {
      this.serverLogout()  // On procède à la déconnexion au niveau du seveur
        .subscribe(() => {
          // Nettoyage de la session locale
          if (this.sessionService) {
            this.sessionService.resetSession();
          }
          // Demande aux autres onglets de se déconnecter
          this.authenticationService.triggerJwtLogout();
          observer.next();
          observer.complete();
        });
    });
  }

  /**
   * Fonction qui assure le logout d'un utilisateur.
   */
  logout(): Observable<any> {
    if (this.config && this.config.data && ObjectUtil.isFunction(this.config.data.logoutFunction)) {
      return this.config.data.logoutFunction.apply(this, arguments);
    } else {
      return this.defaultLogoutFunction();
    }
  }


  /**
   * Fonction qui effectue une déconnexion automatique suite à une demande système.
   */
  logoutSystem(): void {
    // On délogue automatiquement l'utilisateur
    let error = new AipError(
      ConfigurationProperties.cli_error_type_fatal,
      errorProperties.ERROR_SESSION_LOGOUT_REQUESTED,
      i18next.t('error.title'),
      i18next.t('error.logoutRequested.subTitle'),
      i18next.t('error.logoutRequested.message'),
      ErrorStatusCode.TEAPOT);
    error.logoutUser = true;
    this.errorService.displayError(error);
  }

  /**
   * Fonction qui gère le passage des credentials des onglets aux autres en utilisant le localStorage ou les cookies (ne permet pas de transférer des informations entre des onglets de différents sous-domaines).
   * Le principe est d'utilier les propriétés des localStorages ou des cookies et sessionStorage pour transférer des informations des onglets aux autres.
   * Le sessionStorage contient des informations qui sont visible que par l'onglet et sont effacés lorsque l'onglet ou le navigateur sont fermés.
   * Les informations contenues dans le localStorage sont visibles par tous les onglets et conservées même si l'onglet ou le navigateur sont fermés.
   * Donc pour passer des informations d'un onglet à un autre on les copient dans le localStorage puis ont les tranfert dans le sessionStorage.
   * Le passage dans le localStorage ou les cookies est très bref car les informations sont effacées après leur copie dans le sessionStorage.
   */
  private checkForCredsInOtherSession(): void {
    let creds = sessionStorage.getItem(applicationProperties.JWT_CREDS_KEY);
    let logout = sessionStorage.getItem(applicationProperties.JWT_USER_REQUEST_LOGOUT_KEY);
    // console.log("--*--> creds=", creds);
    // console.log("--*--> logout=", logout);
    if (!creds && !logout) { // Si on n'a pas de credentials stocké dans la session et que l'on n'a cliqué sur logout (quand on se délogue, on revient sur l'écran d'accueil mais il ne faut pas initier une procedure de recherche de credentials sinon on se reloguera),
      //  => on modifie le token de trigger pour demander aux autre onglets de sauver leur credentials dans le localStorage.
      this.authenticationService.triggerJwtCreds();
    }
  }

  /**
   * Fonction qui recherche les credentials à partir de la session locale ou des autres sessions ouvertes.
   *
   * @returns
   */
  getCreds(): Promise<Credentials> {
    return new Promise(async function (resolve, reject) {
      let creds = await this.authenticationService.getLocalCreds();
      if (Credentials.isValidCredentials(creds)) {
        // On a trouver des credentials dans la session locale
        return resolve(creds);
      } else {
        // Il n'y a pas de credentials dans la session local => on va chercher dans les autres sessions
        this.checkForCredsInOtherSession();   // On cherche les credentials à partir d'autres ongles ouverts
        setTimeout(async function () {    // On attend un délai prédéfini pour obtenir les credentials à partir des autres onglets.
          creds = await this.authenticationService.getLocalCreds(); // Récupération des credentials issues d'un autre onglet et nouvellement copier dans la sessionLocal
          // console.log("YYYYYYYYYYYYYYYY creds:", creds);
          // On ne peut pas utiliser de promesse car il on est le premier et seul onglet, personne nous répondra et on sera bloqué.
          if (Credentials.isValidCredentials(creds)) {
            resolve(creds);
          } else {
            reject('No credential found!');
          }
        }.bind(this), applicationProperties.GET_CREDENTIALS_FROM_OTHER_TABS_TIMEOUT);  // fin de la fonction : setTimeout
      }
    }.bind(this));
  }

  /**
   * Fonction qui gère la détection automatique des changements dans les cookies ou les local storage
   * des autres onglets (utile pour détecter une déconnexion par exemple).
   */
  initSessionListener(): void {
    if (applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_LOCAL_STORAGE) {
      this.initLocalStorageListenerFromOtherSession();
    } else {
      this.initCookieListenerFromOtherSessions();
    }
  }

  /**
   * Listener gérant l'ouverture et la fermeture des onglets (connexion et deconnexion automatique des utilisateurs).
   * Utilise le browser storage.
   */
  private initLocalStorageListenerFromOtherSession(): void {
    // console.log("====> initCheckForCredsInOtherSessionFromBrowserStorage: window.addEventListener");
    // On ajoute un listener pour détecter les changements dans la localStorage et la sessionStorage
    window.addEventListener(
      //"keydown",        // Catch toutes les pressions de touche du clavier
      'storage',     // Catch toute les modifications des valeurs stockées dans la localStorage et la sessionStorage
      (event) => {
        if (event.key === applicationProperties.JWT_LOGOUT_TRIGGER_KEY) {
          // console.log("====> 444");
          // On spécifie que l'utilisateur doit être déconnecté dans le cas ou la déconnexion automatique n'est pas possible (cas du player).
          this.authenticationService.setSystemRequestLogoutToken();
          // On déconnecte automatiquement l'utilisateur
          this.logoutSystem();
        }
      });
  }

  /**
   * Listener gérant l'ouverture et la fermeture des onglets (connexion et deconnexion automatique des utilisateurs).
   * Utilise le browser storage.
   * @deprecated Plus utilisé. Remplacé par authenticationService.getLocalCreds()
   */
  initCheckForCredsInOtherSessionFromLocalStorage(): void {
    // console.log("====> initCheckForCredsInOtherSessionFromBrowserStorage: window.addEventListener");
    // On ajoute un listener pour détecter les changements dans la localStorage et la sessionStorage
    window.addEventListener(
      //"keydown",        // Catch toutes les pressions de touche du clavier
      'storage',     // Catch toute les modifications des valeurs stockées dans la localStorage et la sessionStorage
      (event) => {
        let _creds = sessionStorage.getItem(applicationProperties.JWT_CREDS_KEY);
        // console.log("====> event.key=", event.key);
        // console.log("====> _creds=", _creds);
        // console.log("====> JWT_CREDS_UPDATED_IN_THIS_TAB_KEY=", sessionStorage.getItem(applicationProperties.JWT_CREDS_UPDATED_IN_THIS_TAB_KEY));
        // console.log("====> JWT_SYSTEM_REQUEST_LOGOUT_KEY=", sessionStorage.getItem(applicationProperties.JWT_SYSTEM_REQUEST_LOGOUT_KEY));
        if ((event.key === applicationProperties.JWT_CREDS_SESSION_TRIGGER_KEY) &&                          // On détecte une demande de credentials d'un autre onglet
          !!_creds &&                                                                                       // On a des credentials
          (sessionStorage.getItem(applicationProperties.JWT_CREDS_UPDATED_IN_THIS_TAB_KEY) !== 'true') && // On n'a pas modifié les credentials
          (sessionStorage.getItem(applicationProperties.JWT_SYSTEM_REQUEST_LOGOUT_KEY) !== 'true')        // Aucun logout système est en attente
        ) {
          // console.log("====> 111");
          // Un autre onglet (a été ouvert et) a modifié le token trigger et on possède des credentials dans notre session
          //  => on copie les credentials de notre session dans le localStorage pour que l'autre onglet puisse les lire
          localStorage.setItem(applicationProperties.JWT_CREDS_LOCAL_TMP_KEY, _creds);
        } else if ((event.key === applicationProperties.JWT_CREDS_LOCAL_TMP_KEY) && !_creds) {
          // console.log("====> 222");
          // On n'a pas de credentials et on détecte qu'un autre onglet à répondu à notre trigger et a copier les credentials dans le localStorage
          let _logout = sessionStorage.getItem(applicationProperties.JWT_USER_REQUEST_LOGOUT_KEY);
          if (!_logout) {
            // console.log("====> 333");
            // On n'a n'a pas cliqué sur logout donc on peut copier les crédentials (placé par un l'onglet qui a repondu à notre trigger) dans notre sessionStorage
            sessionStorage.setItem(applicationProperties.JWT_CREDS_KEY, localStorage.getItem(applicationProperties.JWT_CREDS_LOCAL_TMP_KEY));
          }
          // On supprime les credentials du localStorage car on n'en n'a plus besoin (pour la sécurité)
          localStorage.removeItem(applicationProperties.JWT_CREDS_LOCAL_TMP_KEY);
        } else if (event.key === applicationProperties.JWT_LOGOUT_TRIGGER_KEY) {
          // console.log("====> 444");
          // On spécifie que l'utilisateur doit être déconnecté dans le cas ou la déconnexion automatique n'est pas possible (cas du player).
          this.authenticationService.setSystemRequestLogoutToken();
          // On déconnecte automatiquement l'utilisateur
          this.logoutSystem();
        }
        /*
        // Effectué désormais par le service "localStorageListener.service"
        else if (event.key === applicationProperties.JWT_USER_TRIGGER_KEY) {
          // console.log("====> 555");
          // On spécifie que l'affichage doit être rafraîchi car des changements ont été opérés sur l'utilisateur (notamment dans le cas ou le player met à jour les informations de progression des lessons).
          this.reloadUser();
        }
        */
      });
  }

  /**
   * Listener gérant l'ouverture et la fermeture des onglets (connexion et deconnexion automatique des utilisateurs).
   * Utilise les cookies.
   */
  private initCookieListenerFromOtherSessions(): void {
    // console.log("====> initCheckForCredsInOtherSessionFromCookie");
    // On spécifie que l'utilisateur doit être déconnecté dans le cas ou la déconnexion automatique n'est pas possible (cas du player).
    CookieUtil.checkCookie.addAction(
      {
        actionName: applicationProperties.JWT_LOGOUT_TRIGGER_KEY,
        action: () => {
          // console.log("====> 444");
          // On ne délogue pas si on est pas connecté ou si on est l'investigateur de l'action de déloguage
          if (StateProperties.IS_USER_CONNECTED &&    // On ne délogue pas si on est pas connecté
            (CookieUtil.getCookie(applicationProperties.JWT_LOGOUT_TRIGGER_IN_THIS_TAB_KEY) !== StateProperties.APP_INSTANCE_NAME) && // On ne délogue pas si on est l'investigateur de l'action de déloguage
            !StringUtil.isUndefinedOrEmpty(CookieUtil.getCookie(applicationProperties.JWT_LOGOUT_TRIGGER_KEY)) // On ne délogue pas s'il s'agit pas d'une expiration de cookie
          ) {
            // console.log("====> 444+");
            // On spécifie que l'utilisateur doit être déconnecté dans le cas ou la déconnexion automatique n'est pas possible (cas du player).
            this.authenticationService.setSystemRequestLogoutToken();
            // On déconnecte automatiquement l'utilisateur
            this.logoutSystem();
          }
        },
        cookieListerner: applicationProperties.JWT_LOGOUT_TRIGGER_KEY
      });
    // On initialise le cookie checker pour détecter les changements dans les cookies
    CookieUtil.checkCookie.start(applicationProperties.COOKIE_CHECKER_DELAY_IN_MILLISECONDS);
  }


  /**
   * Listener gérant l'ouverture et la fermeture des onglets (connexion et deconnexion automatique des utilisateurs).
   * Utilise les cookies.
   * @deprecated Plus utilisé. Remplacé par authenticationService.getLocalCreds()
   */
  initCheckForCredsInOtherSessionFromCookie(): void {
    // console.log("====> initCheckForCredsInOtherSessionFromCookie");
    // Un autre onglet (a été ouvert et) a modifié le token trigger et on possède des credentials dans notre session
    //  => on copie les credentials de notre session dans un cookie pour que l'autre onglet puisse les lire
    CookieUtil.checkCookie.addAction(
      {
        actionName: applicationProperties.JWT_CREDS_SESSION_TRIGGER_KEY,
        action: () => {
          let _creds = sessionStorage.getItem(applicationProperties.JWT_CREDS_KEY);
          if (!!_creds &&                                                                                        // On a des credentials
            (sessionStorage.getItem(applicationProperties.JWT_CREDS_UPDATED_IN_THIS_TAB_KEY) !== 'true') && // On n'a pas modifié les credentials
            (sessionStorage.getItem(applicationProperties.JWT_SYSTEM_REQUEST_LOGOUT_KEY) !== 'true') &&     // Aucun logout système est en attente)
            !StringUtil.isUndefinedOrEmpty(CookieUtil.getCookie(applicationProperties.JWT_CREDS_SESSION_TRIGGER_KEY))   // Il ne s'agit pas d'une expiration de cookie
          ) {
            // console.log("====> 111");
            // CookieUtil.deleteCookie(applicationProperties.JWT_CREDS_LOCAL_TMP_KEY, {domain: ConfigurationProperties.cli_cookie_domain_name});
            // Un autre onglet (a été ouvert et) a modifié le token trigger et on possède des credentials dans notre session
            //  => on copie les credentials de notre session dans un cookie pour que l'autre onglet puisse les lire
            CookieUtil.setCookie(applicationProperties.JWT_CREDS_LOCAL_TMP_KEY, _creds,
              {
                expirySeconds: applicationProperties.AUTHENTICATION_METHOD_COOKIE_TRIGGER_TIME_OUT_IN_SECONDS,
                path: '/',
                domain: ConfigurationProperties.cli_cookie_domain_name
              });
          }
        },
        cookieListerner: applicationProperties.JWT_CREDS_SESSION_TRIGGER_KEY  // On détecte une demande de credentials d'un autre onglet
      });
    // On n'a pas de credentials et on détecte qu'un autre onglet à répondu à notre trigger et a copier les credentials dans le cookie
    CookieUtil.checkCookie.addAction(
      {
        actionName: applicationProperties.JWT_CREDS_LOCAL_TMP_KEY,
        action: () => {
          let _creds = sessionStorage.getItem(applicationProperties.JWT_CREDS_KEY);
          if (!_creds) {        // On n'a pas de credentials
            // console.log("====> 222");
            // On n'a pas de credentials et on détecte qu'un autre onglet à répondu à notre trigger et a copier les credentials dans le cookie
            let _logout = sessionStorage.getItem(applicationProperties.JWT_USER_REQUEST_LOGOUT_KEY);
            if (!_logout) {
              // console.log("====> 222+");
              // On n'a n'a pas cliqué sur logout donc on peut copier les crédentials (placé par un l'onglet qui a repondu à notre trigger) dans notre sessionStorage
              sessionStorage.setItem(applicationProperties.JWT_CREDS_KEY, CookieUtil.getCookie(applicationProperties.JWT_CREDS_LOCAL_TMP_KEY));
            }
            // On supprime les credentials du cookie car on n'en n'a plus besoin (pour la sécurité)
            // console.log("====> 222++");
            CookieUtil.deleteCookie(applicationProperties.JWT_CREDS_LOCAL_TMP_KEY, {domain: ConfigurationProperties.cli_cookie_domain_name});
          }
        },
        cookieListerner: applicationProperties.JWT_CREDS_LOCAL_TMP_KEY  // On détecte qu'un autre onglet à répondu à notre trigger et a copier les credentials dans le cookie
      });
    // On spécifie que l'utilisateur doit être déconnecté dans le cas ou la déconnexion automatique n'est pas possible (cas du player).
    CookieUtil.checkCookie.addAction(
      {
        actionName: applicationProperties.JWT_LOGOUT_TRIGGER_KEY,
        action: () => {
          // console.log("====> 444");
          // On ne délogue pas si on est pas connecté ou si on est l'investigateur de l'action de déloguage
          if (StateProperties.IS_USER_CONNECTED &&    // On ne délogue pas si on est pas connecté
            (CookieUtil.getCookie(applicationProperties.JWT_LOGOUT_TRIGGER_IN_THIS_TAB_KEY) !== StateProperties.APP_INSTANCE_NAME) && // On ne délogue pas si on est l'investigateur de l'action de déloguage
            !StringUtil.isUndefinedOrEmpty(CookieUtil.getCookie(applicationProperties.JWT_LOGOUT_TRIGGER_KEY)) // On ne délogue pas s'il s'agit pas d'une expiration de cookie
          ) {
            // console.log("====> 444+");
            // On spécifie que l'utilisateur doit être déconnecté dans le cas ou la déconnexion automatique n'est pas possible (cas du player).
            this.authenticationService.setSystemRequestLogoutToken();
            // On déconnecte automatiquement l'utilisateur
            this.logoutSystem();
          }
        },
        cookieListerner: applicationProperties.JWT_LOGOUT_TRIGGER_KEY
      });
    /*
    // On spécifie que l'affichage doit être rafraîchi car des changements ont été opérés sur l'utilisateur (notamment dans le cas ou le player met à jour les informations de progression des lessons).
    // Effectué désormais par le service "localStorageListener.service"
    CookieUtil.checkCookie.addAction(
      {
        actionName: applicationProperties.JWT_USER_TRIGGER_KEY,
        action: () => {
          // console.log("====> 555");
          // On spécifie que l'affichage doit être rafraîchi car des changements ont été opérés sur l'utilisateur (notamment dans le cas ou le player met à jour les informations de progression des lessons).
          if (!StringUtil.isUndefinedOrEmpty(CookieUtil.getCookie(applicationProperties.JWT_USER_TRIGGER_KEY))) {   // Il ne s'agit pas d'une expiration de cookie
            this.reloadUser();
          }
        },
        cookieListerner: applicationProperties.JWT_USER_TRIGGER_KEY
      });
    */
    // On initialise le cookie checker pour détecter les changements dans les cookies
    CookieUtil.checkCookie.start(applicationProperties.COOKIE_CHECKER_DELAY_IN_MILLISECONDS);
  }

}
