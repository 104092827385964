import {Injectable} from '@angular/core';
import {NotificationsService} from 'angular2-notifications';
import {ErrorsInterceptor} from '../../modules/ng-manager-server/interceptors/errors.interceptor';
import {LoginProperties} from '../../modules/ng-module-login/login.properties';
import {Router} from '@angular/router';
import {SessionService} from '../../modules/ng-manager-user/services/user-session.service';
import {ErrorService} from '../../modules/ng-module-error/services/error.service';
import {appConfigurationProperties} from '../../config/appConfigurationProperties';

/**
 * La liste des urls exlus de l'intercepteur d'erreur
 */
const EXCLUDED_URLS = [
  LoginProperties.URL_SERVER_CREATE_USER_SESSION,
  LoginProperties.URL_SERVER_REQUEST_RESET_PASSWORD,
  LoginProperties.URL_SERVER_RESET_PASSWORD,
  // Url de statistiques de l'écran d'accueil ne doit pas généré d'erreur car sinon la page d'accueil tout entière ne s'affiche pas
  `${appConfigurationProperties.URL_SERVER_AIP_ATEL}/${appConfigurationProperties.URL_SERVER_ELEARNING_GET_THEMES_COUNT}`
];

@Injectable()
export class CustomErrorsInterceptor extends ErrorsInterceptor {

  /**
   *
   */
  constructor(
    protected router: Router,
    protected notifications: NotificationsService,
    protected errorService: ErrorService,
    protected sessionService: SessionService) {
    super(router, notifications, errorService, sessionService);
    this.excludedUrls = EXCLUDED_URLS;
  }

}
