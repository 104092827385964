import {HttpHeaders} from '@angular/common/http';
import {applicationProperties} from '../ng-properties-application/application.properties';
import {CookieUtil} from '../ng-helpers-cookies/cookie.util';
import {StateProperties} from '../ng-properties-state/state.properties';
import {ConfigurationProperties} from '../ng-properties-configuration/configuration.properties';

/**
 * Classe de fonctions utilitaires n'utilisant pas la variable "this" et donc pouvant être
 * sorties de la classe du service "AuthenticationService" afin d'en alléger son code.
 * La classe "AuthenticationService" étend cette classe.
 */
export class AuthenticationServiceCommon {

    /**
     *
     * @returns
     */
    protected getAuthToken(): any {
        return sessionStorage.getItem(applicationProperties.JWT_AUTH_TOKEN_KEY);
    }

    /**
     * Fonction qui modifie le token de trigger des creds avec la date en milliseconde actuelle
     * pour que les autres onglets copie leurs credentials dans le localStorage ou un cookie.
     */
    public triggerJwtCreds(): void {
        if(applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_LOCAL_STORAGE) {
            localStorage.setItem(applicationProperties.JWT_CREDS_SESSION_TRIGGER_KEY, Date.now().toString());
        } else {
            CookieUtil.setCookie(applicationProperties.JWT_CREDS_SESSION_TRIGGER_KEY,
                StateProperties.APP_INSTANCE_NAME + Date.now().toString(),
                {
                    expirySeconds: applicationProperties.AUTHENTICATION_METHOD_COOKIE_TRIGGER_TIME_OUT_IN_SECONDS,
                    path: "/",
                    domain: ConfigurationProperties.cli_cookie_domain_name
                });
        }
    }

    /**
     * Fonction qui modifie le token de trigger d'obsolecence des creds avec la date en milliseconde actuelle
     * pour que les autres onglets copie leurs credentials dans le localStorage ou un cookie.
     */
    public triggerJwtLogout(): void {
        // On indique que le logout a été demandé par cet onglet
        if(applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_LOCAL_STORAGE) {
            localStorage.setItem(applicationProperties.JWT_LOGOUT_TRIGGER_KEY, Date.now().toString());
        } else {
            // On utilise qq soit la méthode d'authentification le local storage pour la détection des déconnexions
            localStorage.setItem(applicationProperties.JWT_LOGOUT_TRIGGER_KEY, Date.now().toString());
            CookieUtil.setCookie(applicationProperties.JWT_LOGOUT_TRIGGER_IN_THIS_TAB_KEY,
                StateProperties.APP_INSTANCE_NAME,
                {expirySeconds: applicationProperties.AUTHENTICATION_METHOD_COOKIE_TRIGGER_TIME_OUT_IN_SECONDS});
            CookieUtil.setCookie(applicationProperties.JWT_LOGOUT_TRIGGER_KEY, Date.now().toString(),
                {
                    expirySeconds: applicationProperties.AUTHENTICATION_METHOD_COOKIE_TRIGGER_TIME_OUT_IN_SECONDS,
                    path: "/",
                    domain: ConfigurationProperties.cli_cookie_domain_name
                });
        }
    }

    /**
     * Fonction qui modifie le token de trigger d'obsolecence des données de l'utilisateur avec la date en milliseconde actuelle
     * pour que tous les onglets rafraichissent leur affichage.
     */
    public triggerUserReload(): void {
        localStorage.setItem(applicationProperties.JWT_USER_TRIGGER_KEY, Date.now().toString());
        /*
        if(applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_LOCAL_STORAGE) {
            localStorage.setItem(applicationProperties.JWT_USER_TRIGGER_KEY, Date.now().toString());
        } else {
            CookieUtil.setCookie(applicationProperties.JWT_USER_TRIGGER_KEY, Date.now().toString(),
                {
                    expirySeconds: applicationProperties.AUTHENTICATION_METHOD_COOKIE_TRIGGER_TIME_OUT_IN_SECONDS,
                    path: "/",
                    domain: ConfigurationProperties.cli_cookie_domain_name
                });
        }
        */
    }

    /**
     * Fonction qui fixe sur true le token de logout si il est initié par l'utilisateur.
     */
    public setUserRequestLogoutToken(): void {
        sessionStorage.setItem(applicationProperties.JWT_USER_REQUEST_LOGOUT_KEY, "true");
    }

    /**
     * Fonction qui fixe sur true le token de logout si il est initié par le system.
     */
    public setSystemRequestLogoutToken(): void {
        sessionStorage.setItem(applicationProperties.JWT_SYSTEM_REQUEST_LOGOUT_KEY, "true");
    }

    /**
     * Fonction qui fixe sur true le token de logout si il est initié par le system.
     */
    public hasPendingSystemRequestLogout(): boolean {
        return (sessionStorage.getItem(applicationProperties.JWT_SYSTEM_REQUEST_LOGOUT_KEY) === "true");
    }

    /**
     * Fonction qui supprime le token de logout si il est initié par l'utilisateur.
     */
    public clearUserRequestLogoutToken(): void {
        sessionStorage.removeItem(applicationProperties.JWT_USER_REQUEST_LOGOUT_KEY);
    }

    /**
     * Fonction qui converti un entête au format ajax.
     *
     * @param headers
     * @returns
     */
    public convertToAjaxHeaders(headers: HttpHeaders): Object {
        let retour: Object = {};
        headers
            .keys()
            .forEach((name: string) => {
                retour[name] = headers.get(name);
                //console.log("name=" + name);
                //console.log("value=" + headers.get(name);
                //console.log("headers=" + headers);
            });
        return retour;
    }

    /**
     * Fonction qui fait un logout et efface toutes les informations du localStorage ou des cookies et du sessionStorage,
     * sauf les tokens de trigger en autre pour ne pas lancer la copie des credentials si d'autres onglets sont ouverts.
     */
    public clearTokens() {
        sessionStorage.removeItem(applicationProperties.JWT_CREDS_UPDATED_IN_THIS_TAB_KEY);
        sessionStorage.removeItem(applicationProperties.JWT_SYSTEM_REQUEST_LOGOUT_KEY);

        sessionStorage.removeItem(applicationProperties.JWT_CREDS_KEY);
        sessionStorage.removeItem(applicationProperties.JWT_AUTH_TOKEN_KEY);

        if(applicationProperties.AUTHENTICATION_METHOD === applicationProperties.AUTHENTICATION_METHOD_LOCAL_STORAGE) {
            localStorage.removeItem(applicationProperties.JWT_CREDS_REMEMBER_ME_KEY);
            //localStorage.removeItem(AppConfigurationProperties.JWT_LOGOUT_TRIGGER_KEY);          // Déclenche un logout
            localStorage.removeItem(applicationProperties.JWT_CREDS_LOCAL_TMP_KEY);
            //localStorage.removeItem(AppConfigurationProperties.JWT_CREDS_SESSION_TRIGGER_KEY);   // Déclenche une copie des credentials qui ne sera pas consomée par un onglet et donc pas effacée
        } else {
            CookieUtil.deleteCookie(
              applicationProperties.JWT_CREDS_REMEMBER_ME_KEY,
              {domain: ConfigurationProperties.cli_cookie_domain_name});
            // CookieUtil.deleteCookie(AppConfigurationProperties.JWT_LOGOUT_TRIGGER_KEY);         // Déclenche un logout
            CookieUtil.deleteCookie(
              applicationProperties.JWT_CREDS_LOCAL_TMP_KEY,
              {domain: ConfigurationProperties.cli_cookie_domain_name});
            // CookieUtil.deleteCookie(AppConfigurationProperties.JWT_CREDS_SESSION_TRIGGER_KEY);  // Déclenche une copie des credentials qui ne sera pas consomée par un onglet et donc pas effacée
        }
    }
}
