export class CommonUtil {

    /**
     * Fonction qui arrondi a n décimales.
     * Retourne undefined si une erreur s'est produite lors de l'opération.
     *
     * @param number - le nombre à arrondir
     * @param decimals - le nombre de décimals
     * @returns
     */
    public static round(number: number, decimals: number): number {
        try {
            return parseFloat(number.toFixed(decimals));
        } catch(e) {
            return undefined;
        }
    }

    /**
     * Fonction qui fait un sleep d'une durée en ms.
     *
     * Usage avec une promesse :
     *    CommonUtil.delay(500)
     *      .then(
     *        () => {
     *          ...
     *          resolve(true);
     *        },
     *        (error) => {
     *          ...
     *          reject(false);
     *        });
     *
     * Usage avec un await :
     *    ...
     *    await CommonUtil.delay(500);
     *    ...
     */
    public static delay(ms: number): Promise<void> {
      return new Promise( resolve => setTimeout(resolve, ms) );
    }

}

