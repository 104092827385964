<div class="h-100">
  <!--<app-color-switcher *ngIf="isMultiColorActive"></app-color-switcher>-->
  <router-outlet></router-outlet>
</div>
<simple-notifications></simple-notifications>

<ng-template #templateWelcome>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Hello</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalWelcomeRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Hello :-)</p>
  </div>
</ng-template>


