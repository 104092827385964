import i18next from 'i18next';
import {Language} from "../../shared/lang.service";
import {StringUtil} from "../ng-helpers-util/string.util";
import UiJsonConfig from '../../../config/current/aip-ui.json';

/**
 * Propiétés de configuration de la plate-forme.
 *      Proriétés :
 *      - JWT_TOKEN_NAME : nom du token jwt utilisé pour l'authentifiaction
 */
// @dynamic
const application = {

  // *** Les urls des projets du groupe (pour le header) ***
  URL_ATLAS_DATABASE: (_lng: string = ""): string => {
    return `http://adoc.ats.localhost`;
  },
  URL_ATLAS_MAGAZINE: (_lng: string = ""): string => {
    if (_lng === "fr") {
      return `http://www.atlas-mag.net`
    } else {
      return `http://www.atlas-mag.com`
    }
  },
  URL_ATLAS_ELEARNING: (_lng: string = ""): string => {
    return `http://atel.ats.localhost`;
  },

  // *** Les urls des ressources ***
  URL_DATA_USER: (_id: string = ""): string => { // On ne met pas le common pour le urls avec _id car c'est le routage d'expressJs qui le fera
    return (StringUtil.isUndefinedOrEmpty(_id)) ? `data/common/users/local/` : `data/users/local/${_id}/`;
  },
  URL_DATA_SOURCE: (_id: string = ""): string => { // On ne met pas le common pour le urls avec _id car c'est le routage d'expressJs qui le fera
    return (StringUtil.isUndefinedOrEmpty(_id)) ? `data/common/sources/local/` : `data/sources/local/${_id}/`;
  },
  URL_DATA_ANNOUNCEMENT: (_id: string = ""): string => { // On ne met pas le common pour le urls avec _id car c'est le routage d'expressJs qui le fera
    return (StringUtil.isUndefinedOrEmpty(_id)) ? `data/common/announcements/local/` : `data/announcements/local/${_id}/`;
  },
  URL_LOGOS_ANNOUNCEMENT: "assets/img/announcements/logos",
  URL_DATA_DEADLINE: "assets/img/deadlines",
  URL_LOGOS_DEADLINE: "assets/img/deadlines/logos",

  // *** Les urls des invoices ***
  URL_LOGO_INVOICE_DEFAULT: "../../assets/logos/logo-mobile.svg?v1",
  URL_LOGO_INVOICE_ATS: "../../assets/logos/logo-black-ATS-purplemonster.svg?v1",
  URL_LOGO_INVOICE_ATST: "../../assets/logos/logo-black-ATST-purplemonster.svg?v1",

  // *** Les informations pour la page "Contact" ***
  CONTACT_EMAIL: UiJsonConfig.CONTACT_EMAIL,
  CONTACT_PHONE: UiJsonConfig.CONTACT_PHONE,
  CONTACT_CATEGORY_PAGE: 'Contact us page', // Accès direct à partir de la page contactez nous
  CONTACT_CATEGORY_HOME_PRESENTATION: 'Contact us - Home - Presentation', // Accès à partir de la page home -> presentation
  CONTACT_CATEGORY_HOME_FIND_TRAINING: 'Contact us - Home - Find training', // Accès à partir de la page home -> find-training
  CONTACT_CATEGORY_HOME_FIND_PUBLICATION: 'Contact us - Home - Find publication', // Accès à partir de la page home -> find-publication
  CONTACT_CATEGORY_ENTERPRISE_OFFER: 'Contact us- Enterprise - Offer', // Accès à partir de la page entreprise-solutions -> offer
  CONTACT_CATEGORY_ENTERPRISE_ENGINEERING: 'Contact us - Enterprise - Engineering', // Accès à partir de la page entreprise-solutions -> engineering

  // *** Le nom des applications pour la reconnaissance des types d'objets achetés ***
  APPLICATION_NAME_AIP_COMMON: "aipCommon",       // Application commune
  APPLICATION_NAME_AIP_ATEL: "aipAtel",           // Application atel3 pour le elearning
  APPLICATION_NAME_AIP_ATP: "aipAtp",           // Application atp pour le publishing
  APPLICATION_NAME_AIP_ADOC: "aipAdoc",           // Application adoc pour la base de données assurance
  APPLICATION_NAME_AIP_CORPORATE: "aipCorporate", // Application corporate
  APPLICATION_NAME_ATM: "atm",                    // Application atm pour atlas magazine

  // *** Les namespaces pour des fichiers de traduction i18next ***
  I18NEXT_NAMESPACE_UI: "ui",                       // L'interface
  I18NEXT_NAMESPACE_ENUM_TYPES: "aip-enumTypes",    // Les enumTypes

  // *** Les versions des namespaces pour des fichiers de traduction i18next (pour recharger le cache du navigateur) ***
  I18NEXT_NAMESPACE_VERSION: "20240208",

  // *** Les formats des données affichées ***
  // TODO : FAIRE PASSER DANS LES PREFERENCES UTILISATEUR !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  FORMAT_DISPLAY_DATE: "dd/mm/yyyy",                  // Le format des date stocké dans la base de donnée
  FORMAT_DISPLAY_DATE_DETAIL: "dd/mm/yyyy HH:MM:ss",  // Le format des date pour l'affichage

  // *** Les répertoires par défaut ***
  FOLDER_PICTURES: "pictures/",           // Le nom du répertoire image

  // *** Clé et valeur de la signature dans le header des requetes pour permettre au serveur d'identifier la provenance de requetes ***
  REQUEST_SIGNATURE_APPLICATION_KEY: "aipapp",  // La clé stockant le nom de l'application aip ayant généré la requête
  REQUEST_SIGNATURE_SOURCE_KEY: "aipappuisrc",  // La clé stockant le nom de source interne à l'application ayant généré la requête (ex: ui, player, ...)

  // *** Clé et valeur de la signature dans le header des requetes pour permettre au serveur d'identifier les requetes de login nécessitant un vérification du captcha ***
  REQUEST_SIGNATURE_CAPTCHA_REQUESTED: "cap",   // Information indiquant que la vérification du captcha est nécessaire pour le login

  // *** Token pour l'authentification ***
  JWT_AUTH_TOKEN_KEY: "A",                  // Token stockant les informations pour l'authentification de l'utilisateur - stocké dans sessionStorage
  JWT_CREDS_KEY: "C",                       // Token stockant les credentials dans la session - stocké dans sessionStorage
  JWT_CREDS_UPDATED_IN_THIS_TAB_KEY: "U",   // Token servant à spécifier que les crédentials ont été modifiés par cet onglet (plus d'exportation des credentials) - stocké dans sessionStorage
  JWT_LOGOUT_TRIGGER_IN_THIS_TAB_KEY: "G",  // Token servant à spécifier que le logout a été demandé par cet onglet (pas de logout automatique) - stocké dans un cookie - utilisé que pour la methode de récuperation des credentials par cookie
  JWT_USER_TRIGGER_KEY: "T",                // Token permettant de déclancher la procédure de rafraichissement des informations de l'utilisateur dans les autres sessions - stocké dans localStorage
  JWT_LOGOUT_TRIGGER_KEY: "D",              // Token permettant de déclancher la procédure de logout dans les autres sessions - stocké dans localStorage ou dans un cookie (selon la methode de récuperation des credentials)
  JWT_RELOAD_TABS_TRIGGER_KEY: "R",         // Token permettant de déclancher la procédure de rechargement des autres onglets - stocké dans localStorage ou dans un cookie (selon la methode de récuperation des credentials)
  JWT_CREDS_SESSION_TRIGGER_KEY: "I",       // Token permettant de déclancher la recherche des credentials jwt dans les autres sessions - stocké dans localStorage ou dans un cookie (selon la methode de récuperation des credentials)
  JWT_CREDS_LOCAL_TMP_KEY: "L",             // Token permettant de faire la copie temporaire des credentials d'une session à une autre via le localStorage - stocké dans localStorage ou dans un cookie (selon la methode de récuperation des credentials)
  JWT_CREDS_REMEMBER_ME_KEY: "M",           // Token stockant les crédentials pour la création de session sur le serveur, utilisé pour la fonction remember-me - stocké dans localStorage ou dans un cookie (selon la methode de récuperation des credentials)
  JWT_SYSTEM_REQUEST_LOGOUT_KEY: "O",       // Token servant à spécifier qu'un logout a été demandé par le système - stocké dans sessionStorage
  JWT_USER_REQUEST_LOGOUT_KEY: "E",         // Token servant à spécifié qu'un logout a été demandé par l'utilisateur - stocké dans sessionStorage
  JWT_CREDS_SECRET: UiJsonConfig.JWT_CREDS_SECRET,   // Clé privée pour le cryptage des crédentials pour la fonction remember-me

  ADD_JWT_CREDS_SECRET_TO_REQUESTS_HEADERS: false,  // Doit-on ajouter le token JWT au headers des requêtes
                                                    // Utile que s'il est utilisé pour sécuriser les requêtes niveau client avec le middleware express
                                                    // "app.use(userManager.services.expressJwt)" ou par url avec "app.get('/api/protected/userTeachings', jwtCheck, lessons.getLessons)")

  GET_CREDENTIALS_FROM_OTHER_TABS_TIMEOUT: 1000,  // Délai d'attente en milliseconde au démarrage de la plate-forme pour obtenir les credentials des autres onglets
                                                  // Augmenter ce délai si les nouveaux onglets ouverts n'ont pas le temps d'interroger les autres onglets pour obtenir les credentials et loguer automatiquement l'utilisateur.
  // Le délai en millisecondes entre deux vérification des modifications des cookies par le Cookie Checker
  COOKIE_CHECKER_DELAY_IN_MILLISECONDS: 75,      // Doit être bien inférieur à GET_CREDENTIALS_FROM_OTHER_TABS_TIMEOUT/2 sinon le système n'aura pas le temps de chercher les crédentials dans les autres onglets
  DEFAULT_SIGNIN_REMEMBER_ME_FLAG: false,        // La valeur par défaut du flag remember dans l'écran de login

  // *** Pour la méthode de récupération des crédentials à partir des autres onglets ***
  AUTHENTICATION_METHOD_LOCAL_STORAGE: "s",   // Utilise le localStorage pour transmettre les informations entre les onglets (ne permet pas de transférer des informations d'un sous-domaine à un autre)
  AUTHENTICATION_METHOD_COOKIE: "c",          // Utilise les cookies pour transmettre les informations entre les onglets (permet de transférer des informations d'un sous-domaine à un autre)
  AUTHENTICATION_METHOD_INDEXED_DB: "i",      // Utilise la base de données IndexedDB pour transmettre les informations entre les onglets (ne permet pas de transférer des informations d'un sous-domaine à un autre)
  // Le délai en secondes d'expiration de certains cookie servant au trigger des actions (ne pas mettre un délai trop cours sinon les informations ne sont pas passées d'un onglet à l'autre)
  AUTHENTICATION_METHOD_COOKIE_TRIGGER_TIME_OUT_IN_SECONDS: 20,

  // *** Pour les params recaptcha ***
  // RECAPTCHA_SITE_KEY: "6Ld0UjcUAAAAAMd8LWEPXbeVuqjs2H_TE3uzy_DH",
  // RECAPTCHA_SIZE: "invisible",

  // *** Les langues suportées ***
  SUPPORTED_LANGUAGES: [
    {code: 'en', alias: ['en-US'], direction: 'ltr', label: 'English', shorthand: 'en'},
    {code: 'fr', alias: ['fr-FR'], direction: 'ltr', label: 'Français', shorthand: 'fr'},
    // { code: 'en-EN', direction: 'rtl', label: 'English - RTL', shorthand: 'enrtl' },
  ] as Language[],

  // *** Tous les langues (on peut ajouter WILDCARD_LANGUAGES a SUPPORTED_LANGUAGES pour alimenter un combo avec les langues supportées + une entree pour toutes les langues ***
  // WILDCARD_LANGUAGE : {code: '', alias: ['Tous'], direction: 'ltr', label: 'Tous', shorthand: 'Tous'} as Language,
  WILDCARD_LANGUAGE: (): Language => {
    return {
      code: '',
      alias: ['Tous'],
      direction: 'ltr',
      label: i18next.t('common.form.list.all'),
      shorthand: i18next.t('common.form.list.all')} as Language;
  },

  // *** La chaine de caractères de remplacement en cas de clé non trouvée ***
  LANGUAGE_MISSING_KEY_REPLACEMENT_VALUE: "-",

  // *** Le nombre d'éléments par défaut par page pour le paging ***
  SCROLL_PAGE_SIZE: 30,
  // public static get SCROLL_PAGE_SIZE(): number { return 50; }
  // SCROLL_PAGE_SIZE_CHOICES: [5, 10, 20, 30, 40, 50],
  SCROLL_PAGE_SIZE_CHOICES: [5, 10, 20, 30],  // On limite à 30 pour ne par trop charger le serveur

  // *** Le temps de latence entre les enfoncement des touches du clavier avant l'envoie d'une requête de recheche au serveur ***
  INPUT_TYPING_SEARCH_DELAY_DURATION: 1000,

  // *** Le createurId par défaut pour les utilisateurs ***
  USER_DEFAULT_CREATOR_ID: "-1",

  // *** La page d'accueil par défaut pour les utilisateurs connectés (si vide accès à la page demandée dans l'url) ***
  ROUTE_DEFAULT_HOME_PAGE: "",

  // *** L'activation du shopping ***
  SHOPPING_ENABLED: UiJsonConfig.SHOPPING_ENABLED,

  // *** Le niveau de difficulty "Approprié pour tous" ***
  // Les niveaux de difficulté sont dans les enumTypes
  DIFFICULTY_LEVELS_ALL_LEVELS: 0,

  // *** Variable spécifiant que l'on doit charger le script pour l'utilisation de stripe ***
  // Si ce script n'est pas chargé, stripe ne pourra pas être utilisé pour les paiement en ligne.
  PAYMENT_LOAD_STRIPE_SCRIPT_ON_STARTUP: false,

  // *** Variable spécifiant que l'on doit charger le script pour l'utilisation de braintree ***
  // Si ce script n'est pas chargé, braintree ne pourra pas être utilisé pour les paiement en ligne.
  PAYMENT_LOAD_BRAINTREE_SCRIPT_ON_STARTUP: false,

  // *** Les différents types de produit ***
  PAYMENT_PRODUCT_LESSON: "lesson",
  PAYMENT_PRODUCT_QUIZ: "quiz",
  PAYMENT_PRODUCT_COURSE: "course",
  PAYMENT_PRODUCT_DEGREE: "degree",
  PAYMENT_PRODUCT_PUBLICATION: "publication",

  // *** Les différentes devises ***
  PAYMENT_CURRENCY_EUR: "EUR",
  PAYMENT_CURRENCY_USD: "USD",
  PAYMENT_CURRENCY_XAF: "XAF",
  PAYMENT_CURRENCY_TND: "TND",

  // *** Le paramètre pour le checkout automatique du panier suite à un retour au panier après la redirection en provenance de l'écran de login pour les utilisateurs qui n'étaient pas connectés ***
  PAYMENT_CART_AUTO_CHECKOUT_PARAMETER: "ckeckout",    // Ce n'est pas un getter pour pouvoir l'utiliser dans un décorateur "@NgModule"

  // *** Les plate-forme de paiement en ligne (paymentMethod) ***
  PAYMENT_PLATFORM_MAINTENANCE: "maintenanceMode",
  PAYMENT_PLATFORM_FREE: "free",
  PAYMENT_PLATFORM_OFFLINE: "offline",
  PAYMENT_PLATFORM_BRAINTREE: "braintree",
  PAYMENT_PLATFORM_PAYPAL: "paypal",
  PAYMENT_PLATFORM_STRIPE: "stripe",
  PAYMENT_PLATFORM_CLIC_TO_PAY: "clicToPay",

  PAYMENT_STATUS_UNKNOWN: "unknown",
  PAYMENT_STATUS_SUCCESS: "success",
  PAYMENT_STATUS_FAILED: "failed",

// *** Un proxy pour éviter les erreurs Access-Control-Allow-Origin - utilisation du cors-anywhere free service ***
  LOCALISATION_API_PROXY: "https://cors-anywhere.herokuapp.com/",

  // *** L'api de localisation du client à partir de son adresse IP ***
  // LOCALISATION_API_URL: (ip: string=""): { api: "geoplugin", url: this.LOCALISATION_API_PROXY+"http://www.geoplugin.net/json.gp?ip="+ip, requireIp: true };},  // http://www.geoplugin.com - 120 requetes/minute - reponse plus détaillé (code devise, code continent mais lent et génère souvent des erreurs de connexion)
  // LOCALISATION_API_URL: (ip: string=""): { api: string, url: string, requireIp: boolean} => {return { api: "freegeoip", url: "//freegeoip.net/json/", requireIp: false };},  // http://freegeoip.net     - 15 000 requetes/jour - NE FONCTIONNE PLUS :-(
  // LOCALISATION_API_URL: (ip: string=""): { api: string, url: string, requireIp: boolean} => {return { api: "geobytes", url: "http://gd.geobytes.com/GetCityDetails", requireIp: false };},  // http://gd.geobytes.com   - 16 000 requetes/heure - leur db peut contenir des erreurs
  LOCALISATION_API_URL: (ip: string = ""): { api: string, url: string, requireIp: boolean } => {
    return {api: undefined, url: "", requireIp: false};
  },  // Géolocalisation désactivée

  // *** Le timeout pour l'api de localisation ***
  LOCALISATION_API_TIMEOUT: 2000,    // En millisecondes

  // *** L'api d'adresse IP ***
  IP_ADDRESS_API_URL: {api: "jsonip", url: "https://jsonip.com/"},
  // IP_ADDRESS_API_URL(): { api: "ipify", url: "http://api.ipify.org/?format=json" };}

  // *** Le timeout pour l'api d'adresse IP ***
  IP_ADDRESS_API_TIMEOUT: 2000,    // En millisecondes

  // *** Les clés dans le fichier local pour les autres choix ***
  ENUM_TYPES_OTHERS_KEY: "other",

  // *** Les types d'ordre des listes pour les requêtes vers le serveur ***
  LIST_SORT_DIRECTION_ASC: "ASC",
  LIST_SORT_DIRECTION_DESC: "DESC",

  // *** Les types d'action utilisateur ***
  USER_ACTION_DISPLAY_TEXT: "displayText",
  USER_ACTION_DISPLAY_TEXT_I18N: "displayTextI18n",
  // Les actions spécifiques à la plate-forme atel
  USER_ACTION_ATEL_MARK_AS_NEW_LESSON: "atelMarkAsNewLesson",
  USER_ACTION_ATEL_MARK_AS_NEW_QUIZ: "atelMarkAsNewQuiz",
  USER_ACTION_ATEL_MARK_AS_NEW_COURSE: "atelMarkAsNewCourse",
  USER_ACTION_ATEL_MARK_AS_NEW_DEGREE: "atelMarkAsNewDegree",
  USER_ACTION_ATEL_RESET_REQUESTED: "atelResetRequested",
  USER_ACTION_ATEL_RESET_PENDING: "atelResetPending",
  USER_ACTION_ATEL_RESET_REFUSED: "atelResetRefused",
  USER_ACTION_ATEL_RESET_ACCEPTED: "atelResetAccepted",
/// Les actions spécifiques à la plate-forme atp
  USER_ACTION_ATP_MARK_AS_NEW_PUBLICATION: "atelMarkAsNewPublication",
  // Les actions spécifiques à la plate-forme adoc
  USER_ACTION_ADOC_MARK_AS_NEW: "adocMarkAsNew",

  // *** Le paramétrage des tailles maximales des listes ***
  ANNOUNCEMENT_MAX_SIZE_ITEMS: 6,
  DEADLINE_MAX_SIZE_ITEMS: 6,

  // *** Les types d'annoncement pour les utilisateurs ***
  // Les announcements généraux
  ANNOUNCEMENT_AIP_SIMPLE_MESSAGE: "aipSimpleMessage",
  ANNOUNCEMENT_AIP_I18NEXT_MESSAGE: "aipI18nextMessage",
  // Les announcements pour l'application atel
  ANNOUNCEMENT_ATEL_NEW_LESSON: "atelNewLesson",
  ANNOUNCEMENT_ATEL_NEW_QUIZ: "atelNewQuiz",
  ANNOUNCEMENT_ATEL_NEW_COURSE: "atelNewCourse",
  ANNOUNCEMENT_ATEL_NEW_DEGREE: "atelNewDegree",
  ANNOUNCEMENT_ATEL_NEW_LEARNING_CONTAINER: 'atelNewLearningContainer',   // remplace les autres constantes ANNOUNCEMENT_ATEL_NEW_XXX
  // Les announcements pour l'application atp
  ANNOUNCEMENT_ATP_NEW_REPORT: 'atpNewReport',
  ANNOUNCEMENT_ATP_NEW_PUBLICATION: 'atpNewPublication', // remplace les autres constantes ANNOUNCEMENT_ATP_NEW_XXX
  // Les announcements pour l'application adoc

  // *** Les types d'échéance (uniquement pour l'ui) ***
  // Les échéances généraux
  // Les échéances pour l'application atel
  DEADLINE_ATEL_NEW_LESSON: "atelDeadlineLesson",
  DEADLINE_ATEL_NEW_QUIZ: "atelDeadlineQuiz",
  // Les échéances pour l'application adoc

  // *** Constantes utilisées pour remplir la collection de base de donnes (algoPromoCampaignRule) ***
  ALGO_DISCOUNT_NATURE_BONUS_PLUS: "bonus+",
  ALGO_DISCOUNT_NATURE_BONUS_MULTIPLY: "bonus*",
  ALGO_DISCOUNT_NATURE_MALUS_PLUS: "malus+",
  ALGO_DISCOUNT_NATURE_MALUS_MULTIPLY: "malus*",
  ALGO_DISCOUNT_NATURE_TAX_PLUS: "tax+",
  ALGO_DISCOUNT_NATURE_TAX_MULTIPLY: "tax*",
  ALGO_DISCOUNT_TYPE_CODE: "code",
  ALGO_DISCOUNT_TYPE_USER: "user",
  ALGO_DISCOUNT_TYPE_CART: "cart",
  ALGO_DISCOUNT_TYPE_ENTITY: "entity",
  ALGO_DISCOUNT_TYPE_PACK: "pack",
  ALGO_DISCOUNT_TYPE_DETAIL: "detail",
  ALGO_DISCOUNT_TYPE_MAGIC: "magic",
  ALGO_DISCOUNT_SUBCODE_MAGIC: "magic",
  ALGO_DISCOUNT_SUBCODE_VAT: "VAT",          // Correspond à la valeur donnée dans la collection "algoPromoCampaignPolicy"
  ALGO_DISCOUNT_SUBCODE_STAMP: "taxStamp",   // Correspond à la valeur donnée dans la collection "algoPromoCampaignPolicy"
};

/**
 * Surcharge pour pouvoir exporter des proprtiétés utilisant d'autres propriétés.
 */
export const applicationProperties = {
  ...application,

  // *** La langue par défaut ***
  LANGUAGE_DEFAULT: "fr", // Doit correspondre à l'attribut "shorthand" d'une SUPPORTED_LANGUAGES

  // *** La méthode d'authentification par défaut ***
  AUTHENTICATION_METHOD: application.AUTHENTICATION_METHOD_LOCAL_STORAGE,

  // *** La devise par défaut si la localisation de l'utilisateur n'est pas détermniée ***
  PAYMENT_DEFAULT_CURRENCY: application.PAYMENT_CURRENCY_EUR,

};
