import {Injectable} from '@angular/core';
import {applicationProperties} from '../modules/ng-properties-application/application.properties';

/**
 * Service permettant de détecter les demandes des autres onglets. Utilise le localeStorage.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageListenerService {

  // Si true ce listener ne rechargera pas la page même si le token JWT_RELOAD_TABS_TRIGGER_KEY
  // a été modifié par un autre onglet.
  // Permet d'éviter le rechargement infini si deux onglets demandent le rechargement des autres
  // onglets dès leur ouverture.
  private _doNotReloadPage = false;
  get doNotReloadPage(): boolean {
    return this._doNotReloadPage;
  }
  set doNotReloadPage(value: boolean) {
    this._doNotReloadPage = value;
  }

  /**
   *
   */
  constructor() {
    // this.initListener();
  }

  /**
   *
   */
  public initListener(): void {
    // On ajoute un listener pour détecter les changements dans la localStorage et la sessionStorage
    window.addEventListener(
      // "keydown",        // Catch toutes les pressions de touche du clavier
      'storage',     // Catch toute les modifications des valeurs stockées dans la localStorage et la sessionStorage
      (event) => {
        // console.log('>>>>>>>>>>> this.router.url:', this.router.url);
        if (
          (event.key === applicationProperties.JWT_RELOAD_TABS_TRIGGER_KEY) &&
          !this._doNotReloadPage) {
          // On recharge l'onglet
          location.reload();
        }
      });
  }

}
