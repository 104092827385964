import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import {ErrorService} from '../../modules/ng-module-error/services/error.service';
import {AipError} from '../../modules/ng-models-ui';
import {ActivatedRoute, Router} from '@angular/router';
import i18next from 'i18next';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  adminRoot = environment.adminRoot;
  errors: AipError[];

  constructor(
    private errorService: ErrorService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    document.body.classList.add('background');
    const errorCode = this.route.snapshot.params.code;
    switch (errorCode) {
      case '404':
        // On a demandé l'url spécifique de l'erreur 404: Page not found
        this.errors = [{
          code: 404,
          msg: [i18next.t('error.404.message')]
        }] as any[];
        break;
      case '401':
        // On a demandé l'url spécifique de l'erreur 401: Unauthorized
        this.errors = [{
          code: 401,
          msg: [i18next.t('error.401.message')]
        }] as any[];
        break;
      default:
        this.errors = this.errorService.getErrorData() || [AipError.getDefaultAipError()];
        break;
    }
    // console.log('XXXXXXXXXXXX errorCode:', errorCode);
    // console.log('XXXXXXXXXXXX this.errorService.getErrorData():', this.errorService.getErrorData());
    // console.log('XXXXXXXXXXXX this.errors:', this.errors);
  }

  ngOnDestroy(): void {
    document.body.classList.remove('background');
  }

}
