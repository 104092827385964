import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getThemeColor, setThemeColor, isCookieEnabled } from './app/utils/util';
import {appLocalesProperties} from './app/config/appLocalesProperties';

// Détection des cookies
if (!isCookieEnabled()) {
  window.alert(appLocalesProperties.COOKIE_DISABLED);
}

// Détection de l'environnement
if (environment.production) {
  enableProdMode();
}

// Gestion du thème
const color =
  environment.isMultiColorActive || environment.isDarkSwitchActive
    ? getThemeColor()
    : environment.defaultColor;

import('./assets/css/sass/themes/vien.' + color + '.scss')
  .then((x) => {
    setThemeColor(color);
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  })
  .catch(() => {
    setThemeColor(null);
    window.location.reload();
  });
