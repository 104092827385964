import {Injectable} from '@angular/core';
import Dexie from 'dexie';
import {appConfigurationProperties} from '../config/appConfigurationProperties';
import {ObjectUtil} from '../modules/ng-helpers-util/object.util';

@Injectable({ providedIn: 'root' })
export class AipDbService extends Dexie {
  private db: any;

  /**
   *
   */
  constructor() {
    const databaseName = 'Aip'; // Le nom de la base de données
    super(databaseName);
    this.createDatabase(databaseName);
    this.openDatabase();
  }

  /**
   *
   */
  private createDatabase(databaseName) {
    this.db = new Dexie(databaseName);
    this.db.version(1).stores({
      common: '&key'
    });
  }

  /**
   *
   */
  private openDatabase() {
    this.db.open().catch(e => {
      console.error('Open failed: ' + e);
    });
  }

  /**
   *
   */
  public async clear(table?: string): Promise<string> {
    if (table) {
      return await this.db[table].clear();
    } else {
      // Vidage de toutes les tables
      try {
        await this.db.common.clear(); // Vidage de la table 'common'
      } catch (err) {
        // Erreur !!!
        return Promise.reject('An error occurred when clearing database!');
      }
      return Promise.resolve('Database cleared!');
    }
  }

  /*
   * Pour le token d'autrntification.
   */
  public async setAuthToken(token: string): Promise<string> {
    return await this.db.common.put({key: appConfigurationProperties.JWT_AUTH_TOKEN_KEY, value: token});
  }
  public async getAuthToken(): Promise<string> {
    const res = await this.db.common.get(appConfigurationProperties.JWT_AUTH_TOKEN_KEY);
    return (ObjectUtil.hasProperty(res, 'value')) ? res.value : undefined;
  }

  /*
   * Pour le token des crédentials.
   */
  public async setCreds(creds: string): Promise<string> {
    return await this.db.common.put({key: appConfigurationProperties.JWT_CREDS_KEY, value: creds});
  }
  public async getCreds(): Promise<string> {
    const res = await this.db.common.get(appConfigurationProperties.JWT_CREDS_KEY);
    return (ObjectUtil.hasProperty(res, 'value')) ? res.value : undefined;
  }

  /*
   * Pour le token remember me.
   */
  public async setRememberMe(value: string): Promise<string> {
    return await this.db.common.put({key: appConfigurationProperties.JWT_CREDS_REMEMBER_ME_KEY, value});
  }
  public async getRememberMe(): Promise<string> {
    const res = await this.db.common.get(appConfigurationProperties.JWT_CREDS_REMEMBER_ME_KEY);
    return (ObjectUtil.hasProperty(res, 'value')) ? res.value : undefined;
  }

}
