import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { environment } from 'src/environments/environment';
import {LoginResolver} from '../modules/ng-module-login/resolvers/login.resolver';
import {ViewsComponent} from './views.component';
import {BlankForReloadComponent} from './blankForReload/blankForReload.component';
// import { AuthGuard } from '../shared/auth.guard';
// import { UserRoleType } from '../shared/auth.roles';

const adminRoot = environment.adminRoot.substr(1); // path cannot start with a slash

let routes: Routes = [
  {
    path: '',
    component: ViewsComponent,
    pathMatch: 'full',
    resolve: {
      resolverUser: LoginResolver
    },
    data: {
      redirectToHomePageIfNotConnected: false,
    }
  },
  {
    path: adminRoot,
    loadChildren: () => import('./app/app.module').then(m => m.AppModule),
    resolve: {
      resolverUser: LoginResolver
    },
    data: {
      redirectToHomePageIfNotConnected: false,
    }
    /*
    canActivate: [AuthenticationGuard],  // On rend l'application accèssible sans authentification
    data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
    */
  },
  /*
  {
    path: adminRoot,
    loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
    data: { roles: [UserRoleType.Admin, UserRoleType.Editor] },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  */
  { path: 'blank', component: BlankForReloadComponent },
  { path: 'error/:code', component: ErrorComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', redirectTo: '/error/404' },
];

if (!environment.isAuthGuardActive) {
  routes = [
    {
      path: '',
      component: ViewsComponent,
      pathMatch: 'full',
      resolve: {
        resolverUser: LoginResolver
      },
      data: {
        redirectToHomePageIfNotConnected: false,
      }
    },
    {
      path: adminRoot,
      loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
    },
    /*
    {
      path: 'user',
      loadChildren: () =>
        import('./user/user.module').then((m) => m.UserModule),
    },
    */
    { path: 'error/:code', component: ErrorComponent },
    { path: 'error', component: ErrorComponent },
    { path: '**', redirectTo: '/error/404' },
  ];
}
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ViewRoutingModule {}
