import {Injectable} from '@angular/core';
import {Lesson} from '../../ng-models-atel3/models/db/Lesson';
import {Quiz} from '../../ng-models-atel3/models/db/Quiz';
import {Course} from '../../ng-models-atel3/models/db/Course';
import {Degree} from '../../ng-models-atel3/models/db/Degree';
import {Source} from '../../ng-models-ats/models/db/Source';
import {Publication} from "../../ng-models-atp/models/db/Publication";
import {appConfigurationProperties} from '../../../config/appConfigurationProperties';
import {ObjectUtil} from '../../ng-helpers-util/object.util';

/**
 * Service de gestion du catalogue.
 */
@Injectable({
  providedIn: 'root',
})
export class UserDependenciesService {

  // Les dépendances reçues du serveur
  lessonsDependencies: Lesson[] = [];
  quizDependencies: Quiz[] = [];
  coursesDependencies: Course[] = [];
  degreesDependencies: Degree[] = [];
  sourcesDependencies: Source[] = [];
  publicationsDependencies: Publication[] = [];

  /**
   *
   */
  constructor() {
  }

  /**
   *
   */
  init(dependenciesFromServer: { teachings?: any[], courses?: any[], degrees?: any[], sources?: any[], publications?: any[]}): void {

    // *** Construction des dépendences ***
    this.lessonsDependencies = (
      ObjectUtil.hasProperty(dependenciesFromServer, 'teachings') && ObjectUtil.isArray(dependenciesFromServer.teachings))
      ? dependenciesFromServer.teachings
          .filter(elem => elem.type === appConfigurationProperties.LEARNING_CONTAINER_TYPE_LESSON)
          .map(elem => new Lesson(elem))
      : [];
    this.quizDependencies = (
      ObjectUtil.hasProperty(dependenciesFromServer, 'teachings') && ObjectUtil.isArray(dependenciesFromServer.teachings))
      ? dependenciesFromServer.teachings
          .filter(elem => elem.type === appConfigurationProperties.LEARNING_CONTAINER_TYPE_QUIZ)
          .map(elem => new Quiz(elem))
      : [];
    this.coursesDependencies = (
      ObjectUtil.hasProperty(dependenciesFromServer, 'courses') && ObjectUtil.isArray(dependenciesFromServer.courses))
      ? dependenciesFromServer.courses
          .map(elem => new Course(elem))
      : [];
    this.degreesDependencies = (
      ObjectUtil.hasProperty(dependenciesFromServer, 'degrees') && ObjectUtil.isArray(dependenciesFromServer.degrees))
      ? dependenciesFromServer.degrees
          .map(elem => new Degree(elem))
      : [];
    this.sourcesDependencies = (
      ObjectUtil.hasProperty(dependenciesFromServer, 'sources') && ObjectUtil.isArray(dependenciesFromServer.sources))
      ? dependenciesFromServer.sources
          .map(elem => new Source(elem))
      : [];
    this.publicationsDependencies = (
      ObjectUtil.hasProperty(dependenciesFromServer, 'publications') && ObjectUtil.isArray(dependenciesFromServer.publications))
      ? dependenciesFromServer.publications
        .filter(elem => elem.type === appConfigurationProperties.PUBLISHING_CONTAINER_TYPE_PUBLICATION)
        .map(elem => new Publication(elem))
      : [];
  }

  /**
   *
   */
  reset(): void {
    this.lessonsDependencies = [];
    this.quizDependencies = [];
    this.coursesDependencies = [];
    this.degreesDependencies = [];
    this.sourcesDependencies = [];
    this.publicationsDependencies = [];
  }

  /**
   *
   */
  getElearningTeachingDependency(_id: string): Lesson | Quiz {
    return this.lessonsDependencies
      .concat(this.quizDependencies)
      .find(elem => elem._id === _id);
  }

  /**
   *
   */
  /*
  getPublishedProductDependency(_id: string, type: string): Lesson | Quiz | Course | Degree | Publication {
    switch (type) {
      case appConfigurationProperties.LEARNING_CONTAINER_TYPE_LESSON:
        return this.getElearningLessonDependency(_id);
      case appConfigurationProperties.LEARNING_CONTAINER_TYPE_QUIZ:
        return this.getElearningQuizDependency(_id);
      case appConfigurationProperties.LEARNING_CONTAINER_TYPE_COURSE:
        return this.getElearningCourseDependency(_id);
      case appConfigurationProperties.LEARNING_CONTAINER_TYPE_DEGREE:
        return this.getElearningDegreeDependency(_id);
      case appConfigurationProperties.PUBLISHING_CONTAINER_TYPE_PUBLICATION:
        return this.getPublicationDependency(_id);
      default:
        return undefined;
    }
  }
  */

  /**
   *
   */
  getElearningLessonDependency(_id: string): Lesson {
    return this.lessonsDependencies.find(elem => elem._id === _id);
  }

  /**
   *
   */
  getElearningQuizDependency(_id: string): Quiz {
    return this.quizDependencies.find(elem => elem._id === _id);
  }

  /**
   *
   */
  getElearningCourseDependency(_id: string): Course {
    return this.coursesDependencies.find(elem => elem._id === _id);
  }

  /**
   *
   */
  getElearningDegreeDependency(_id: string): Degree {
    return this.degreesDependencies.find(elem => elem._id === _id);
  }

  /**
   *
   */
  getSourceDependency(_id: string): Source {
    return this.sourcesDependencies.find(elem => elem._id === _id);
  }

  /**
   *
   */
  getPublicationDependency(_id: string): Publication {
    return this.publicationsDependencies.find(elem => elem._id === _id);
  }

}
