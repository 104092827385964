import {ObjectUtil} from "../../../../ng-helpers-util/object.util";
import {UserNotification} from "../../../../../shared/notificationBell.service";
import {appConfigurationProperties} from "../../../../../config/appConfigurationProperties";
import {UserProductInfoElearning} from "./UserProductElearning";
import {UserProductStatus} from "../../../../ng-models-shared/models/db/UserProduct";
import {LearningContainer} from "../LearningContainer";

// Déclaration de la variable $ (il faut que cette variable soit reconnue au niveau de l'application en faisant par exemple un "npm install --save-dev @type/jquery")
// declare var $: any;

/**
 * Encapsule les éléments du tableau userAtel.lessons
 */
export class UserTeachingDb {

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
    if (_data && _data.courses && ObjectUtil.isArray(_data.courses)) {
      this.courses = _data.courses.map(elem => {
        return new UserTeachingDbCourse(elem);
      })
    }
    if (_data && _data.degrees && ObjectUtil.isArray(_data.degrees)) {
      this.degrees = _data.degrees.map(elem => {
        return new UserTeachingDbDegree(elem);
      })
    }
    if (_data && _data.sources && ObjectUtil.isArray(_data.sources)) {
      this.sources = _data.sources.map(elem => {
        return new UserTeachingDbSource(elem);
      })
    }
    if (_data && _data.info) {
      this.info = new UserProductInfoElearning(_data.info);
    }
    if (_data && _data.status) {
      this.status = new UserProductStatus(_data.status);
    }
    if (_data && _data.actions && ObjectUtil.isArray(_data.actions)) {
      this.actions = _data.actions.map(elem => {
        return new UserNotification(elem);
      })
    }
  }

  public _id: string;
  public teaching_ref: LearningContainer | string;

  public teaching: {
    withoutPackage: boolean,
    action?: string
  };
  public courses: UserTeachingDbCourse[];
  public degrees: UserTeachingDbDegree[];
  public sources: UserTeachingDbSource[];

  public info: UserProductInfoElearning;
  public status: UserProductStatus;
  public actions: UserNotification[];

  /**
   *
   */
  public getTeachingRef(): string {
    if (ObjectUtil.isString(this.teaching_ref)) {
      return this.teaching_ref as string;
    } else if (ObjectUtil.hasProperty(this.teaching_ref, '_id')) {
      return (this.teaching_ref as LearningContainer)._id;
    } else {
      return undefined;
    }
  }

  /**
   * Fonction qui test si l'objet est valide
   */
  public canPlay(): boolean {
    if (this.status) {
      return this.isUserTeachingValid(true) && !this.status.frozen;
    } else {
      return false;
    }
  }

  /**
   * Fonction qui test si l'objet est valide
   */
  public isUserTeachingValid(considerUserTeachingActiveFlag: boolean = false): boolean {
    if (this.status) {
      return (considerUserTeachingActiveFlag)
        ? this.status.active && !this.status.expired && !this.status.deleted
        : !this.status.deleted;
    } else {
      return false;
    }
  }

  /**
   * Le userProduct (userLesson, userQuiz, userCourse, userDegree, userSource) est valide.
   */
  public isUserProductValid(considerUserTeachingActiveFlag: boolean = false): boolean {
    return this.isUserTeachingValid(considerUserTeachingActiveFlag);
  }

  /**
   *
   */
  public hasSource(sourceId: string): boolean {
    return (
      // Cas de la source personnelle
      (
        (sourceId === appConfigurationProperties.DEFAULT_SOURCE_ID) &&
        (
          !ObjectUtil.isArray(this.sources) ||
          (this.sources.length === 0) ||
          this.sources.some(src => (
            !ObjectUtil.hasProperty(src, 'source_ref') ||
            src.source_ref === null) ||
            (src.source_ref === appConfigurationProperties.DEFAULT_SOURCE_ID)
          )
        )
      ) ||
      // Cas d'une source autre que la source personnelle
      (
        (sourceId !== appConfigurationProperties.DEFAULT_SOURCE_ID) &&
        this.sources.some(src => (src.source_ref === sourceId))
      )
    );
    /*
    if (this.sources) {
      return this.sources.some(
        src => ObjectUtil.hasProperty(src, 'source_ref', '_id') && (sourceId === src.source_ref._id));
    } else {
      return false;
    }
    */
  }

  public getExpiryDate(): Date {
    return this.status.expiryDate;
  }

  public getPoints(): number {
    return this.info.points;
  }

  public getScore(): number {
    return this.info.score;
  }

  public getProgression(): number {
    return this.info.progress;
  }

}

/**
 *
 */
class UserTeachingDbCourse {

  // public courseAssociationId: string;
  // public course: Course;
  public course_ref: string;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
  }
}

/**
 *
 */
class UserTeachingDbDegree {

  // public degreeAssociationId: string;
  public degree_ref: string;

  // public courseAssociationId: string;
  public course_ref: string;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
  }

}

/**
 *
 */
class UserTeachingDbSource {

  public source_ref: string;
  public course_ref: string;
  public degree_ref: string;
  public teaching_ref: string;
  public sourceLibrary_ref: string;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
  }

}
