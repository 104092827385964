import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import i18n from 'i18next';
import * as jqueryI18next from 'jquery-i18next';
import XHR from 'i18next-xhr-backend';
import * as i18nextSprintfPostProcessor from 'i18next-sprintf-postprocessor';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as numeral from 'numeral';
import 'numeral/locales/fr'; // Pour éviter les erreurs de type: "Cannot read property 'delimiters' of undefined"
import {environment} from '../environments/environment';
import {ConfigurationUtil} from './config/configuration.util';
import {GlobalRef} from './utils/global/global.ref';
import {EnvService} from './env/env.service';
import {AuthenticationService} from './modules/ng-manager-authentication/authentication.service';
import {StateProperties} from './modules/ng-properties-state/state.properties';
import {ScriptUtil} from './modules/ng-helpers-util/script.util';
import {appConfigurationProperties} from './config/appConfigurationProperties';
import {LangService} from './shared/lang.service';
import {appLocalesProperties} from './config/appLocalesProperties';
import {EnumTypeService} from './modules/ng-manager-server/enumTypes';
import {CurrencyService} from './shared/currency.service';
import {UiUtil} from './modules/ng-helpers-util/ui.util';

/**
 * Service permettant d'accéder et modifier les paramètres de l'application.
 *
 * Pour modifier les valeurs de ces paramètres et les utiliser dans les fichiers template angular, il faut
 * utiliser la fonction "setTimeout" dans la fonction "ngAfterViewInit()" ce workaround permet
 * d'éviter une erreur de type "ExpressionChangedAfterItHasBeenCheckedError". Exemple :
 *      setTimeout(_ => this.appService.disableResponsive = true);
 *
 */
@Injectable({
  providedIn: 'root',
})
export class AppService {

  /**
   *
   */
  constructor(
    private http: HttpClient,
    public globalVariables: GlobalRef,
    private envService: EnvService,
    private langService: LangService,
    private enumTypeService: EnumTypeService,
    private currencyService: CurrencyService,
    private authenticationService: AuthenticationService) {
    // Configuration de l'authenticationService
    this.authenticationService.init(
      environment.REQUEST_SIGNATURE_APPLICATION_VALUE,
      environment.REQUEST_SIGNATURE_SOURCE_VALUE);
  }

  // Liste des notifications de clés manqauntes déjà envoyées au serveur.
  // Permet de ne pas envoyer plusieurs fois de suite la même clés manquante car angular recharge beaucoup de fois les vues.
  private missingKeysAlreadySent: string[] = [];


  /**
   * Fonction qui retourne une chaine de remplacement quand une clé de traduction n'a pas été trouvée.
   */
  public static parseMissingKeyHandler(key): string {
    // console.log("parseMissingKeyHandler:", key);
    return environment.LANGUAGE_MISSING_KEY_REPLACEMENT_VALUE;
  }

  /**
   * Fonction qui charge la configuration à partir des données envoyées par le serveur puis
   * retourne une promesse une fois que la configuration est chargée.
   */
  getConfigurationPromiseFromServer(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(environment.URL_SERVER_GET_CONFIGURATION, {
        headers: this.authenticationService.generateHeadersForUnprotectedRoutes()
      })
      // .catch(ErrorUtil.handleError)
        .subscribe(
          configuration => {
            // console.log('No error when loading configuration', configuration);
            // On enregistre les paramètre de configuration
            ConfigurationUtil.setProperties(configuration);
            // On charge les scripts externes en fonction du la plate-forme de paiement
            // On met à jour le langManager avec la langue du serveur
            ConfigurationUtil.setServerLngToManagerLangService(this.langService);

            // Pour les tests
            // this.langService.registerLocale("fr");
            // console.log("LLLLLLLL !!! REGISTERED locale:", localeFr);
            // registerLocaleData(localeFr);

            // On charge les script pour le paiement
            this.loadPaymentScripts();

            // On résout la promesse
            resolve(appLocalesProperties.CONFIGURATION_LOAD_SUCCESS);
          },
          err => {   // "Echec du chargement de la configuration"
            // console.log('Error when loading configuration', err);
            reject(appLocalesProperties.CONFIGURATION_LOAD_FAILURE);
          },
          () => {
            // console.log('Configuration initialized');
          }
        );
    });
  }

  /**
   * Fonction qui charge les devises à partir des données envoyées par le serveur puis
   * retourne une promesse une fois que les devises sont chargées.
   */
  getCurrencyPromiseFromServer(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.enumTypeService.getEnumType(['currency'])
        .subscribe(
          data => {
            // console.log('No error when loading data', data);
            this.currencyService.init(data.currency);
            // console.log('this.currencyService.currencies:', this.currencyService.currencies);
            // console.log('this.currencyService.currenciesForPayment:', this.currencyService.currenciesForPayment);
            // console.log('this.currencyService.defaultServerCurrency:', this.currencyService.defaultServerCurrency);
            // On résout la promesse
            resolve(appLocalesProperties.CURRENCY_LOAD_SUCCESS);
          },
          err => {   // "Echec du chargement de la configuration"
            // console.log('Error when loading configuration', err);
            reject(appLocalesProperties.CURRENCY_LOAD_FAILURE);
          },
          () => {
            // console.log('Configuration initialized');
          }
        );
    });
  }

  /**
   * Fonction d'initialisation de i18next.
   */
  initI18next(): Promise<any> {
    /*  For Debugging purpose only
      const languageDetectorDefault = new LanguageDetector();
      const languageDetector = new LanguageDetector(null, {
        order: ['i18nTestLangDetector']
      });
      languageDetector.addDetector({
        name: 'i18nTestLangDetector',
        lookup: () => {
          return 'fr-FR';
          /!*
          const i18nLang = uiSettings.i18nLang;
          return i18nLang === LANGUAGE_DEFAULT
            ? undefined
            : i18nLang;
           *!/
        }
      });
      console.log('RRRRRRRR languageDetectorDefault:', languageDetectorDefault);
      console.log('RRRRRRRR languageDetector:', languageDetector);
    */

    return new Promise((resolve, reject) => {
      /*
      i18nextIntervalPluralPostProcessor.setOptions({
        // this are the defaults
        intervalSeparator: ';',
        intervalRegex: /\((\S*)\).*{((.|\n)*)}/,
        intervalSuffix: '_intervale'
      });
      */

      i18n
        .use(XHR)
        .use(i18nextSprintfPostProcessor)
        .use(i18nextIntervalPluralPostProcessor)
        // .use(languageDetector)  // For Debugging purpose only
        .use(LanguageDetector)
        // .init({
        .init({
          debug: false,
          // whitelist: ['fr', 'en'],
          whitelist: appConfigurationProperties.SUPPORTED_LANGUAGES.map(elem => elem.code),
          // load: ['fr','en'],
          // load:'unspecific',
          // lng: 'en',       // Pour fixer une langue lors du démarrage de l'application
          // (commenté car la langue est déterminée par i18nextBrowserLanguageDetector)
          // fallbackLng: 'fr',  // Pour fixer une langue par défaut ("dev" si non spécifié)
          fallbackLng: this.langService.language,  // Pour fixer une langue par défaut ("dev" si non spécifié)
          ns: [
            appConfigurationProperties.I18NEXT_NAMESPACE_UI,
            appConfigurationProperties.I18NEXT_NAMESPACE_ENUM_TYPES,
          ],
          defaultNS: appConfigurationProperties.I18NEXT_NAMESPACE_UI,
          saveMissing: true,
          pluralSeparator: '_',
          // contextSeparator: "_",
          parseMissingKeyHandler: AppService.parseMissingKeyHandler.bind(this),
          missingKeyHandler: this.missingKeyHandler.bind(this),
          backend: {
            loadPath: `assets/locales/{{lng}}/{{ns}}.json?v=${appConfigurationProperties.I18NEXT_NAMESPACE_VERSION}`
            // loadPath: 'http://localhost:3030/locales/{{lng}}/{{ns}}.json'
            // loadPath: loadPath
          },
          detection: {
            // order and from where user language should be detected
            // order: ['path', 'session', 'querystring', 'cookie', 'header'],
            // order: ['session', 'querystring', 'header'],
            // order: ['querystring', 'header', 'session', 'path', 'cookie'],
            // order: ['i18nTestLangDetector'],  // For Debugging purpose only
            order: ['querystring', 'header', 'navigator'],

            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            // lookupCookie: 'i18next',
            // lookupSession: 'lng',
            // lookupPath: 'lng',
            // lookupFromPathIndex: 0,

            // cache user language
            // caches: ['localStorage', 'cookie']
            caches: false,

            // only detect languages that are in the whitelist
            checkWhitelist: true,

            // fallback to a similar whitelist language
            // Example 1: Browser language is 'es'
            // if 'es' is not found in whitelist, first fallback to any whitelist language that starts with 'es-', then fallback to fallbackLng ('es' -> 'es-*' -> fallbackLng)
            // Example 2: Browser language is 'es-MX'
            // if 'es-MX' is not found in whitelist, first fallback to 'es', then fallback to 'es-*', then fallback to fallbackLng ('es-MX' -> 'es' -> 'es-*' -> fallbackLng)
            // checkForSimilarInWhitelist: false, // For Debugging purpose only
            checkForSimilarInWhitelist: true,
          },
          // Définition de l'interpolation permettant de préciser le traitement à effectuer lorsque l'option "format" est passé à i18next.
          // Usage1:
          //   local.json - fr:
          //      "priceTag": "{{price, priceNumber}} {{currencySymbol}}"
          //   local.json - en:
          //      "priceTag": "{{currencySymbol}}{{price, priceNumber}}"
          //   js:
          //      i18next.t(
          //          'page.payment.cart.priceTag',
          //          {
          //              price: 1234567.123,
          //              currencySymbol: this.getUserCurrencySymbol(),
          //          });
          // Usage2:
          //   local.json - fr:
          //      "priceTag": "{{price}} {{currencySymbol}}"
          //   local.json - en:
          //      "priceTag": "{{currencySymbol}}{{price}}"
          //   js:
          //      i18next.t(
          //          'page.payment.cart.priceTag',
          //          {
          //              price: (<any>i18next).default.options.interpolation.format(1234567.123, 'priceNumber'),
          //              currencySymbol: this.getUserCurrencySymbol(),
          //          });
          interpolation: {
            format(value, format, lng): string {
              if (format === 'uppercase') {
                return value.toUpperCase();
              }
              if (format === 'lowercase') {
                return value.toLowerCase();
              }
              if (format === 'priceNumber_floatMax3') {
                return numeral(value).format('0,0.[000]');  // Formatage avec Numeral.js
              }
              if (format === 'priceNumber_2decimals') {
                return numeral(value).format('0,0.00');     // Formatage avec Numeral.js
              }
              if (format === 'priceNumber_3decimals') {
                return numeral(value).format('0,0.000');    // Formatage avec Numeral.js
              }
              if (format === 'timeInSecond') {
                return numeral(value).format('00:00:00');      // Formatage avec Numeral.js
              }
              // if(value instanceof Date) {
              //     return moment(value).format(format);
              // }
              return value;
            }
          }
        }, (err, t) => {
          jqueryI18next.init(i18n, jQuery, {
            // On lance la fonction i18next.init() qui rend disponibles les fonctions "resource handling" de l'api :
            // getResource, addResource, addResouceBundle, ...
            // window['i18next.init']();
            // (<any>window).i18next.init();

            // On initialise jqueryI18next
            tName: 't', // --> appends $.t = i18next.t
            i18nName: 'i18n', // --> appends $.i18n = i18next
            handleName: 'localize', // --> appends $(selector).localize(opts);
            selectorAttr: 'data-i18n', // selector for translating elements
            targetAttr: 'i18n-target', // data-() attribute to grab target element to translate (if diffrent then itself)
            optionsAttr: 'i18n-options', // data-() attribute that contains options, will load/set if useOptionsAttr = true
            useOptionsAttr: true, // see optionsAttr
            parseDefaultValueFromContent: true // parses default values from content ele.val or ele.text
          });

          // On précise que i18next a été initialisé
          StateProperties.IS_I18NEXT_INITIALIZED = true;
          // Démarrage de la plate-forme
          // this.startPlatform();

          // Tests
          // jQuery("[data-i18n]").localize();
          // Marche ici car on est dans le callback de la fonction qui est lancée quand la librairie est chargée
          // console.log('1. TEXT BOOT=' + i18next.t('key', { postProcess: 'sprintf', sprintf: ['YOUPI!'] }));
          // console.log('2. TEXT BOOT=' + i18next.t('notExisting'));
          // console.log('==> TEXT I18N enumTypes=' + i18next.t('publishedContainers.type.nbCourse_interval', {postProcess: 'interval', count: 2}));
          // console.log('==> TEXT I18N enumTypes=' + i18next.t('difficultyLevels.2'));
          /*
          console.log('########## i18next.language:', i18n.language);
          console.log('########## publishedContainers.type.nbLesson_interval:', i18next.t(
            'publishedContainers.type.nbLesson_interval',
            {postProcess: 'interval', count: 0}));
          console.log('########## error.paymentCurrencyNotFound.subTitle:', i18next.t('error.paymentCurrencyNotFound.subTitle'));
          */

          // Event handler détectant le changement de langue avec i18next.
          // Lorsque la langue change on synchronise la langue de numeral.js permettant de formater les nombres en fonction de la local.
          i18n.on('languageChanged', lng => {
            // console.log('########## languageChanged new language:', lng);
            // Récupération du code lang supporté le plus proche
            const lang = UiUtil.findLanguage(lng, appConfigurationProperties.LANGUAGE_DEFAULT);
            lng = (lang) ? lang.code : appConfigurationProperties.LANGUAGE_DEFAULT;
            numeral.locale(lng);
          });

          // On lance la fonction i18next.init() qui rend disponibles les fonctions "resource handling" de l'api :
          // getResource, addResource, addResouceBundle, ...
          // window['i18next.init']();
          // (<any>window).i18next = i18next.init();  // Pour rendre accéssible la variable i18next dans la variable window du navigateur
          // console.log("########## i18next:", i18next);
          // console.log("########## (<any>window).i18next:", (<any>window).i18next);

          // setTimeout(function () {  // Pour les tests
          resolve(i18n);
          // }, 5000);                 // Pour les tests
        });

      // Ne marche pas ici, car la librairie met un peu de temps pour se charger !
      // const hw = i18next.t('key', { postProcess: 'sprintf', sprintf: ['YOUPI!'] }); // hw = 'hello world'
      // console.log("APP=" + i18next.t('key', { postProcess: 'sprintf', sprintf: ['YOUPI!'] }));
    });
  }

  /**
   * Fonction lancée lorsqu'une clé de traduction n'a pas été trouvée.
   */
  missingKeyHandler(lng, ns, key, fallbackValue): void {
    // console.log("missingKeyHandler:", lng, ns, key, fallbackValue);
    // console.log("missingKeyHandler location.path():", window.location.href);
    if (this.missingKeysAlreadySent.indexOf(key) < 0) {  // On ne renvoie pas l'erreur si elle a dèjà été notifiée au serveur
      this.missingKeysAlreadySent.push(key);

      this.http.post(appConfigurationProperties.URL_SERVER_ERROR_TRANSLATION,
        JSON.stringify(
          {
            lng,
            ns,
            key,
            fallbackValue,
            sourceUrl: window.location.href
          }),
        {
          headers: this.authenticationService.generateHeadersForUnprotectedRoutes(lng)
        })
        .subscribe(
          data => {
            // console.log("erreur enregistrée");
            // console.log(data);
          },
          err => {
            // console.log("echec de l'enregistrement de l'erreur");
            // console.log(err);
          },
          () => {
            // console.log("Fin de la procédure d'enregistrement de l'erreur");
          });
    }
  }

  /**
   *
   */
  private loadPaymentScripts(): void {
    // console.log("environment ================>", environment);
    const scriptsToLoad: Array<any> = [];
    const scriptsNameToLoad: Array<any> = [];
    if (environment.PAYMENT_LOAD_STRIPE_SCRIPT_ON_STARTUP) {
      // - TODO: PASSER A LA VERSION 3 DE STRIPE ---
      // - TODO : https://stripe.com/docs/checkout/tutorial
      // - TODO : https://stripe.com/docs/stripe.js
      // - TODO : https://stripe.com/docs/elements/migrating
      // - TODO : https://stripe.com/docs/stripe.js/v2
      const scriptName = 'stripe';
      scriptsToLoad.push({
        name: scriptName,
        src: environment.production ?   // On choisi le script offline pour l'environnement de test
          'https://js.stripe.com/v2/' :
          '../../ui/assets/js/payment/stripe/stripe.v2.js'
      });
      scriptsNameToLoad.push(scriptName);
    }
    if (environment.PAYMENT_LOAD_BRAINTREE_SCRIPT_ON_STARTUP) {
      let scriptName = 'braintree-v2-dropin';
      /*
       scriptsToLoad.push({
           name: 'braintree',
           src: environment.production ?   // On choisi le script offline pour l'environnement de test
           'https://js.braintreegateway.com/web/dropin/1.6.1/js/dropin.min.js' :
           '../../ui/assets/js/payment/braintree/dropin-1.6.1.min.js'
       });
      */
      /*
      scriptsToLoad.push({
          name: scriptName,
          src: environment.production ?   // On choisi le script offline pour l'environnement de test
              'https://js.braintreegateway.com/web/dropin/1.7.0/js/dropin.min.js' :
              '../../ui/assets/js/payment/braintree/dropin-1.7.0.min.js'
      });
      */
      scriptName = 'braintree-v3-client';
      scriptsToLoad.push({
        name: scriptName,
        src: environment.production ?   // On choisi le script offline pour l'environnement de test
          'https://js.braintreegateway.com/web/3.22.2/js/client.min.js' :
          '../../ui/assets/js/payment/braintree/client-3.22.2.min.js'
      });
      scriptsNameToLoad.push(scriptName);
      scriptName = 'braintree-v3-hosted-fields';
      scriptsToLoad.push({
        name: scriptName,
        src: environment.production ?   // On choisi le script offline pour l'environnement de test
          'https://js.braintreegateway.com/web/3.22.2/js/hosted-fields.min.js' :
          '../../ui/assets/js/payment/braintree/hosted-fields-3.22.2.min.js'
      });
      scriptsNameToLoad.push(scriptName);
    }
    const scriptUtil = new ScriptUtil(scriptsToLoad);
    scriptUtil.load(...scriptsNameToLoad)
      .then(data => {
        // console.log('script loaded ', data);
      })
      .catch(error =>
        console.error(error)
      );
  }

  /**
   * Fonction qui retourne l'instance de ard en fonction du mode debug.
   */
  private getArd(): any {
    // tslint:disable-next-line:prefer-const
    let ard;
    if (this.envService.enableDebug) {
      // On est en mode débug => Chargement des librairies adoc manuellement via l'index dans le module adoc-dashboard-ui "debug_noUglify/index.html"
      // console.log("XXXXXXXXXXX appService->ard:", ard);
      // console.log("XXXXXXXXXXX appService->window:", window);
      // console.log("XXXXXXXXXXX appService->window.ard:", JSON.stringify((<any>window).ard));
      return (window as any).ard;
    } else {
      // On n'est pas en mode débug => Les librairies sont adoc chargées via angular à travers le module adoc-dashboard-ui
      return this.globalVariables.nativeGlobal.ard;
    }
  }

  /**
   * Fonction d'initialisation de ard.
   */
  initArd(): any {
    /* PHASE D'INIT DES INT DE EXTJS */
    // console.log("XXXXXXXXXXX appService->i18next:", i18next);
    // console.log("XXXXXXXXXXX appService->i18next.default:", (<any>i18next).default);
    // console.log("XXXXXXXXXXX appService->i18nextXHRBackend:", i18nextXHRBackend);
    // console.log("XXXXXXXXXXX appService->i18nextIntervalPluralPostProcessor:", i18nextIntervalPluralPostProcessor);
    // console.log("XXXXXXXXXXX appService->i18nextSprintfPostProcessor:", i18nextSprintfPostProcessor);

    const ard = this.getArd();
    const proxy = ard.portalInt.prototype.getProxyNonVs();
    const configInt = proxy.configInt;
    const ioInt = proxy.ioInt;
    const i18nInt = proxy.i18nInt;
    const constInt = proxy.constInt;

    configInt.init(() => {
    }); // Comme cette fct est syncrhone je ne fais pas de promis
        // FCT CALLBACK APPELE A LA FIN DE INIT
    // Lance le chargement des fichiers interne
    ioInt.registerPromise('ADOC', ioInt.init(() => {
    }));  // fct asyncrone avec promise
          // FCT CALLBACK APPELE A LA FIN DE INIT //lance le chargement des fichiers interne

    ioInt.registerPromise('i18next', i18nInt.init({
      lng: constInt.I18N_LNG_DEFAULT, // 'dev',
      load: [constInt.I18N_LNG_DEFAULT], // ['dev'], //only load one language at init
      ns: ['ns.tag', 'ns.gui'],
      // defaultNS: ['ns.data'],
      defaultNS: 'ns.gui',
      fallbackNS: ['ns.tag', 'ns.gui'],
      useLocalStorage: false,
      fallbackLng: [constInt.I18N_LNG_DEFAULT], // ['dev'], //TMP dev pour test->Ryad: au lieu de dev, utiliser en prioritairement //['en',dev']
      fallbackOnEmpty: false, // on accepte les empty string sans pb
      debug: true,
      // resGetPath: configInt.getConfig().localesDir+ '/kkk/{{lng}}/{{ns}}.json' , //v1 params not working for i18next v2
      interpolationPrefix: '{',
      interpolationSuffix: '}',
      keySeparator: '.',
      backend: {
        // loadPath: configInt.getConfig().localesDir + '/{{lng}}/{{ns}}.json'
        loadPath: appConfigurationProperties.URL_UI_LOCALES + '/{{lng}}/{{ns}}.json'

      },
      interpolation: {
        format: (value, format, lng) => { // extension pour l'affichage des rank ex: 1 => 1st
          switch (lng) {
            case 'fr':
              if (format === 'ordinal') {
                const s = ['th', 'st', 'nd', 'rd'];
                const v = value % 100;
                return (s[(v - 20) % 10] || s[v] || s[0]);
              }
              break;
            default:
              if (format === 'ordinal') {
                if (value === 1) {
                  return 'er';
                } else {
                  return 'eme';
                }
              }
              break;
          }
        }
      }
    }, () => { // FCT CALLBACK APPELE A LA FIN DE INIT
      // alert('test i18'+i18next.t('app.key', { date: new Date() }))
      // i18next.t('app.key', { date: new Date() });
    }, {
      // i18next:(<any>i18next).default,
      i18next: i18n,
      i18nextXHRBackend: XHR,
      i18nextIntervalPluralPostProcessor,
      i18nextSprintfPostProcessor
    }));
    // return Promise.all([chartboxAPI.io.ajaxStatic(),ioInt.getPromise("ADOC"),ioInt.getPromise("i18next")]);
    ioInt.getPromise('i18next').then(() => { // on a besoin de la langue par defaut pour demander les urls ajaxStatic
        return Promise.all([
          // ioInt.ajaxStatic(), //maintenant apelle dans portalInt
          ioInt.getPromise('ADOC')
        ]);
      }
    );
  }

}
