import {StringUtil} from '../ng-helpers-util/string.util';
import {ConfigurationProperties} from "../ng-properties-configuration/configuration.properties";
import UiJsonConfig from "../../../config/current/aip-ui.json";

/**
 * Propiétés de configuration de la plate-forme.
 *      Proriétés :
 *      - JWT_TOKEN_NAME : nom du token jwt utilisé pour l'authentifiaction
 */
const atel = {

  // *** Les urls de la plate-forme ***
  URL_DATA_TEACHING: (_id: string = ""): string =>   // Le path d'un teaching ou du répetoires des teachings si sans arguments
  {
    return (StringUtil.isUndefinedOrEmpty(_id)) ? `data/teachings/local/` : `data/teachings/local/${_id}/`;
  },
  URL_DATA_COURSE: (_id: string = ""): string =>   // Le path d'un course ou du répetoires des courses si sans arguments
  {
    return (StringUtil.isUndefinedOrEmpty(_id)) ? `data/courses/local/` : `data/courses/local/${_id}/`;
  },
  URL_DATA_DEGREE: (_id: string = ""): string =>   // Le path d'un degree ou du répetoires des degrees si sans arguments
  {
    return (StringUtil.isUndefinedOrEmpty(_id)) ? `data/degrees/local/` : `data/degrees/local/${_id}/`;
  },
  URL_DATA_THEME: (_id: string = ""): string =>    // Le path d'un theme ou du répetoires des themes si sans arguments
  {
    return (StringUtil.isUndefinedOrEmpty(_id)) ? `data/themes/local/` : `data/themes/local/${_id}/`;
  },

  // *** Les fichiers par défaut ***
  DEFAULT_FILE_PICTURE_COURSE: () => `${ConfigurationProperties.cli_file_common_picture_default_name}?v${UiJsonConfig.VERSION_COMMON_DEFAULT_IMAGE}`, // Le nom de image par défaut
  DEFAULT_FILE_PICTURE_DEGREE: () => `${ConfigurationProperties.cli_file_common_picture_default_name}?v${UiJsonConfig.VERSION_COMMON_DEFAULT_IMAGE}`, // Le nom de image par défaut
  DEFAULT_FILE_PICTURE_THEME: () => `${ConfigurationProperties.cli_file_common_picture_default_name}?v${UiJsonConfig.VERSION_COMMON_DEFAULT_IMAGE}`,  // Le nom de image par défaut
  DEFAULT_FILE_PICTURE_SOURCE: () => `${ConfigurationProperties.cli_file_default_source_picture}?v${UiJsonConfig.VERSION_COMMON_DEFAULT_IMAGE_SOURCE}`, // Le nom de image par défaut

  /*
  FILE_CATALOG_PDF: (lang: string): string =>
  {
    return (!StringUtil.isUndefinedOrEmpty(lang)) ? `Catalogue-elearning-${lang}.pdf?v1` : undefined;
  },
  */

  // *** Pour les types d'entités qui ne sont pas des produits ***
  ENTITY_TYPE_THEME: "theme",
  ENTITY_TYPE_SOURCE: "source",

  // *** Pour les learningContainers ***
  LEARNING_CONTAINER_TYPE: "learningContainer",  // Doit correspondre avec les constantes du serveur et du player
  LEARNING_CONTAINER_TYPE_DEGREE: "degree",      // Doit correspondre avec les constantes du serveur et du player
  LEARNING_CONTAINER_TYPE_COURSE: "course",      // Doit correspondre avec les constantes du serveur et du player
  LEARNING_CONTAINER_TYPE_LESSON: "lesson",      // Doit correspondre avec les constantes du serveur et du player
  LEARNING_CONTAINER_TYPE_QUIZ: "quiz",          // Doit correspondre avec les constantes du serveur et du player
  LEARNING_CONTAINER_TYPE_PUBLICATION: 'publication',  // Doit correspondre avec les constantes du serveur et du player

  // *** Pour les teachings ***
  TEACHING_CONTENT_TYPE_EMBEDDED: "embedded",   // Doit correspondre avec les constantes du serveur et du player
  TEACHING_CONTENT_TYPE_FILE: "file",           // Doit correspondre avec les constantes du serveur et du player
  TEACHING_CONTENT_TYPE_LOs: "LOs",             // Doit correspondre avec les constantes du serveur et du player

  /*
  // Les type de quiz
  QUIZ_TYPE_PRE_TEST: "pretest",
  QUIZ_TYPE_EXAM: "exam",
  */

  // Les statuts des lessons
  LESSON_COMPLETION_STATUS_UNKNOWN: "0",       // cmi.completion_status - doit correspondre aux valeurs enregistrées dans la partie client
  LESSON_COMPLETION_STATUS_NOT_ATTEMPTED: "1", // cmi.completion_status - doit correspondre aux valeurs enregistrées dans la partie client
  LESSON_COMPLETION_STATUS_INCOMPLETE: "2",    // cmi.completion_status - doit correspondre aux valeurs enregistrées dans la partie client
  LESSON_COMPLETION_STATUS_COMPLETED: "3",     // cmi.completion_status - doit correspondre aux valeurs enregistrées dans la partie client
  LESSON_COMPLETION_STATUS_BROWSED: "4",       // SCORM 1.2             - doit correspondre aux valeurs enregistrées dans la partie client

  // Les statuts des lessons
  LESSON_SUCCESS_STATUS_UNKNOWN: "10",         // cmi.success_status    - doit correspondre aux valeurs enregistrées dans la partie client
  LESSON_SUCCESS_STATUS_FAILED: "11",          // cmi.success_status    - doit correspondre aux valeurs enregistrées dans la partie client
  LESSON_SUCCESS_STATUS_PASSED: "12",          // cmi.success_status    - doit correspondre aux valeurs enregistrées dans la partie client

  // Les types de protocol
  TEACHING_PROTOCOL_ATEL3_V0: "atel_v3",

  /*
  // *** Pour les LOs ***
  LO_TYPE_LESSON: "lo_lesson",
  LO_TYPE_ASSESSMENT_EVALUATION: "lo_evaluation",
  LO_TYPE_ASSESSMENT_PRETEST: "lo_pretest",
  LO_TYPE_ASSESSMENT_PRACTICE: "lo_practice",

  // Les types de display
  LO_SECTION_DISPLAY_NONE: 0,
  LO_SECTION_DISPLAY_NORMAL: 1,
  LO_SECTION_DISPLAY_PREVIOUS: 2,
  LO_SECTION_DISPLAY_NOT_UNDERLINED: 3,

  // Les types de statistiques
  STATS_TYPE_NO_DATA: 0,          // pas d'envoi d'informations récaputilatives des résultats
  STATS_TYPE_DETAILED_DATA: 1,    // envoi d'informations générales et détaillées (par question) des résultats (valeur par défaut)
  STATS_TYPE_SUMMARY_DATA: 2,     // envoi d'informations générales des résultats sans détail par question

  // Les méthodes de notation
  GRADING_METHOD_NONE: 0, // L'évaluation n'est pas notée et les réponses de l'utilisateur ne sont pas enregistrées
  GRADING_METHOD_FIRST_ATTEMPT: 1,
  GRADING_METHOD_LAST_ATTEMPT: 2,
  GRADING_METHOD_BEST_ATTEMPT: 3,
  GRADING_METHOD_WORST_ATTEMPT: 4,
  GRADING_METHOD_AVERAGE_ATTEMPTS: 5,

  // *** Pour les themes ***
  THEME_DEFAUT_SEPARATOR: '>',
  THEME_DEFAUT_SEPARATOR_EXPENDED: ' > ',
  THEME_DEFAUT_ROOT_REF_NAME: 'root',

  // *** Les différents types d'exercice ***
  EXERCISE_TYPE_QCM: "qcm",
  EXERCISE_TYPE_DRAG_AND_DROP: "dragAndDrop",
  EXERCISE_TYPE_SELECTION: "selection",
  EXERCISE_TYPE_INPUT: "input",
  */

  // *** Les types d'ajout des teachings dans la librairie ***
  TEACHING_ADDITION_TYPE_DONT_RESET_TEACHINGS: "dontResetTeachings",
  TEACHING_ADDITION_TYPE_RESET_TEACHINGS: "resetTeachings",
};


/**
 * Surcharge pour pouvoir exporter des proprtiétés utilisant d'autres propriétés.
 */
export const atelProperties = {
  ...atel,

  // *** Les valeur par défaut des teachings ***
  TEACHING_DEFAUT_FORMAT: 'atel3_v0',
  TEACHING_DEFAUT_PROTOCOL: atel.TEACHING_PROTOCOL_ATEL3_V0,
  TEACHING_DEFAUT_PLAYER_VERSION: '0.2',

  // *** La type de contenu des LOs par défaut ***
  TEACHING_DEFAUT_CONTENT_TYPE: atel.TEACHING_CONTENT_TYPE_LOs,
};
