import {ObjectUtil} from '../../ng-helpers-util/object.util';
import {AipError} from '../../ng-models-ui/models/AipError';
import {ArrayUtil} from "../../ng-helpers-util/array.util";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationsService, NotificationType} from "angular2-notifications";
import i18next from "i18next";

/**
 * Classe assurant la gestion des erreurs.
 */
export class ErrorUtil {

    /**
     * Fonction qui récupère un message d'erreur en provenance du serveur et le format pour
     * l'affichage dans l'interface. La valeur de retour est un tableau contenant les textes
     * des messages d'erreur à afficher.
     *
     * Le champ err.message de l'erreur peut être :
     *      - une string => la fonction retourne un tableau de un seul element: le champ err.message
     *      - un tableau d'objets => les objets sont des messages à traduire et sont du type :
     *          { translate: {key: value}, interpolation: {key1: value1, key2: value2, ...}}
     *              ou
     *          { translate: {key: value}}
     *        La propriété "key" de "translate" est le nom de la clé de translation a rajouté dans le bundle
     *        des ressources json. La propriété "value" de "translate" est le nom le texte de traduction tel
     *        qu'il apparait dans le bundle des ressources json (attention, il ne s'agit pas du texte traduit!)
     *        Ex: key="validatorInvalid"
     *            value= "Le champ \"%(field)s\" est invalide."
     *        La propriété "interpolation" peut contenir plusieurs éléments. La propriété "key" de "interpolation"
     *        est le nom du champ d'interpolation figurant dans la propriété "key" de "translate",  La propriété
     *        "value" de "interpolation" est la valeur associée à l'interpolation (cf. paramètre "interpolationsMapping").
     *        Ex: key="field"
     *            value="title"
     *        La variante avec tableau d'objets à traduire est utilisé pour insérer des interpolations connu que par
     *        l'interface dans un message d'erreur connu que par le serveur.
     *
     * Le paramètre "namespace" est le namespace (fichier de langue) contenant la clé pour la traduction des messages de
     * l'application. Cet attribut n'est utilisé que si err.message est un tableau d'objets.
     * Ex: "atel3-admin"
     *
     * Le paramètre "language" est la langue pour la traduction des messages d'erreur. Cet attribut n'est utilisé que
     * si err.message est un tableau d'objets.
     *
     * Le paramètre "interpolationsMapping" est un tableau faisant la correspondance entre les champs "value" des
     * interpolations et leur clé de traduction dans le bundle. Cet attribut n'est utilisé que si err.message est un
     * tableau d'objets.
     * Ex: {title: 'page.settings.lesson.detail.field.title', code: 'page.settings.lesson.detail.field.code', ...}
     *
     * @param err
     * @param namespace
     * @param language
     * @param interpolationsMapping
     * @returns
     */
    public static getErrorMessage(err, namespace: string, language: string, interpolationsMapping: any): string[] {
        let messageToDisplay: string[] = [];
        if(typeof err.message === "string") {
            messageToDisplay.push(err.message);
        } else if(err.message instanceof Array) {
            err.message.forEach(error => {
                if(error.hasOwnProperty('translate')) { // On regarde s'il y a une clé à traduire
                  i18next.addResources(language, namespace, error.translate);
                    // (<any>window).i18next.addResources(language, namespace, error.translate);
                    if (error.hasOwnProperty('interpolation')) {   // On regarde s'il y a des arguments à traduire
                        for(let _field in error.interpolation) {
                            // traduction des arguments à partir du tableau de mapping des codes si on ne trouve pas de traduction, on prend le code de l'interolation
                            error.interpolation[_field] = i18next.t(interpolationsMapping[error.interpolation[_field]]) || error.interpolation[_field];
                        }
                        messageToDisplay.push(i18next.t(
                            Object.keys(error.translate)[0],
                            {postProcess: 'sprintf', sprintf: error.interpolation}));
                    } else {
                        messageToDisplay.push(i18next.t(Object.keys(error.translate)[0]));
                        // messageToDisplay.push(i18n.getResources(language, namespace, Object.keys(error.translate)[0]));
                    }
                } else {    // Il n'y a rien a traduire
                    messageToDisplay.push(error);
                }
            });
        }
        return messageToDisplay;
    }

    /**
     * Fonction formatant le résultat de la fonction getErrorMessage() pour sont affichage
     * dans une popup toastr.
     *
     * @param messageToDisplay
     * @returns
     */
    public static displayToastrErrorMessages(messageToDisplay: string[] | string): string {
        let message: string = "";
        if(ObjectUtil.isString(messageToDisplay)) {
            messageToDisplay = [<string>messageToDisplay];
        }
        if(messageToDisplay && messageToDisplay.length === 1) {
          message = messageToDisplay[0];
        } else if(messageToDisplay && messageToDisplay.length > 0) {
            // message += "<UL style='margin-left: -1em'>";
            message += "<UL>";
            (<string[]>messageToDisplay).forEach(msg => {
                message += "<LI>" + msg + "</LI>";
            });
            message += "</UL>";
        }
        return message;
    }

  /**
   * Fonction qui affiche les erreurs reçu du serveur.
   */
  public static displayErrorsNotifications(
    err: HttpErrorResponse | {error: {subTitle?: string, msg?: string}[]},
    notifications: NotificationsService,
    type: NotificationType = NotificationType.Error,
    timeOut: number = 15000,
    showProgressBar: boolean = true) {
    if (err && ObjectUtil.isArray(err.error) && (err.error.length > 0)) {
      for(const error of err.error) {
        // if(err.statusCode !== 401) {  // 401 gérés dans "authentication.interceptor.ts"
        notifications.create(
          error.subTitle || i18next.t('error.unknown.subTitle'),
          error.msg || i18next.t('error.unknown.message'),
          type,
          {
            timeOut: timeOut,
            showProgressBar: showProgressBar
          });
        // }
      }
    }
  }

}
