import { Injectable } from '@angular/core';
import {StringUtil} from '../modules/ng-helpers-util/string.util';
import {ObjectUtil} from '../modules/ng-helpers-util/object.util';

/**
 *
 */
export class DocumentEnumType {
  type: string;
  code: string;
  name: string;
  options: {
    type: string;
    url: string;
    lang: string;
  };
}

/**
 * Service permettant de gérer les documents récupérer de la base de données enumTypes.
 * Les urls des documents ont pour racine le répertoire 'public'.
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentService {

  // La liste des documents
  private _documents: DocumentEnumType[] = [];
  public get documents(): DocumentEnumType[] {
    return this._documents;
  }
  public set documents(value: DocumentEnumType[]) {
    // this._documents = value.filter(elem => elem.type === 'document');
    this._documents = value;
  }

  /**
   * Fonction qui retourne l'url du catalogue elearning ou publishing au format pdf.
   * Les urls ont pour racine le répertoire 'public'.
   */
  private getCatalogUrl(lang: string, type: string): string {
    const doc = this.documents.find(elem => (
      ObjectUtil.hasProperty(elem, 'options', 'type') &&
      ObjectUtil.hasProperty(elem, 'options', 'lang') &&
      ObjectUtil.hasProperty(elem, 'options', 'url') &&
      (elem.options.type === type) &&
      (elem.options.lang === lang) &&
      !StringUtil.isUndefinedOrEmpty(elem.options.url)));
    return (doc) ? doc.options.url : undefined;
  }

  /**
   * Fonction qui retourne l'url du catalogue elearning au format pdf.
   * Les urls ont pour racine le répertoire 'public'.
   */
  public getElearningCatalogUrl(lang: string): string {
    return this.getCatalogUrl(lang, 'catalogElearning');
  }

  /**
   * Fonction qui retourne l'url du catalogue elearning au format pdf.
   * Les urls ont pour racine le répertoire 'public'.
   */
  public getPublishingCatalogUrl(lang: string): string {
    return this.getCatalogUrl(lang, 'catalogPublishing');
  }

}
