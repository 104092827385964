/**
 * Classe contenant les properiétés de configuartion à partir du fichier du serveur.
 * Cette classe est inialisée par le configuration.service au démarrage de l'application.
 *      Proriétés :
 *      - cli_url_atel : l'url root de la plate-forme.
 *      - cli_language_default : la langue par defaut de la plate-forme.
 *      - cli_error_type_fatal : le type d'erreur fatal.
 *      - cli_error_type_notification : le type d'erreur notification.
 *      - cli_createur_type_from_admin_ui : code pour les utilisateurs crées à partir de l'interface graphique administrateur
 *      - cli_user_username_min_length : la taille minimale de l'identifiant
 *      - cli_user_password_min_length : la taille minimale du mot de passe
 */
import {ConfigurationProperties} from '../modules/ng-properties-configuration/configuration.properties';

export class ConfigurationUtil extends ConfigurationProperties {

  /* Variables récupérés du serveur */

  // Les codes des durées des produits
  public static cli_products_duration: {
    default_delay_expiry_after_purchase_teaching: number,
    default_delay_expiry_after_purchase_course: number,
    default_delay_expiry_after_purchase_degree: number,
    default_delay_expiry_after_purchase_corporate_purchases: number,
    default_duration_teaching: number,
    default_duration_course: number,
    default_duration_degree: number,
  };

  // Les propriétés de la plate-forme atel
  public static cli_aip_atel: {
    url_root_player: string,
    url_player: {
      atel3_v0: string
    }
  };

  // Les propriétés de la plate-forme atp
  public static cli_aip_atp: {
    url_root_player: string,
    url_player: {
      atp_v0: string
    }
  };

  /**
   * Attribution des propriétés reçues du serveur aux propriétés du service de configuration.
   *
   * @param configurationObjectFromServer - objet reçu du serveur
   */
  public static setProperties(configurationObjectFromServer): void {
    super.setProperties(configurationObjectFromServer);

    ConfigurationUtil.cli_products_duration = {
      default_delay_expiry_after_purchase_teaching:
        configurationObjectFromServer.cli_aip_atel.cli_products_duration.default_delay_expiry_after_purchase_teaching,
      default_delay_expiry_after_purchase_course:
        configurationObjectFromServer.cli_aip_atel.cli_products_duration.default_delay_expiry_after_purchase_course,
      default_delay_expiry_after_purchase_degree:
        configurationObjectFromServer.cli_aip_atel.cli_products_duration.default_delay_expiry_after_purchase_degree,
      default_delay_expiry_after_purchase_corporate_purchases:
        configurationObjectFromServer.cli_aip_atel.cli_products_duration.default_delay_expiry_after_purchase_corporate_purchases,
      default_duration_teaching: configurationObjectFromServer.cli_aip_atel.cli_products_duration.default_duration_teaching,
      default_duration_course: configurationObjectFromServer.cli_aip_atel.cli_products_duration.default_duration_course,
      default_duration_degree: configurationObjectFromServer.cli_aip_atel.cli_products_duration.default_duration_degree,
    };

    ConfigurationUtil.cli_aip_atel = {
      url_root_player: configurationObjectFromServer.cli_aip_atel.url_root_player,
      url_player: {
        atel3_v0: configurationObjectFromServer.cli_aip_atel.url_player.atel3_v0
      }
    };

    ConfigurationUtil.cli_aip_atp = {
      url_root_player: configurationObjectFromServer.cli_aip_atp.url_root_player,
      url_player: {
        atp_v0: configurationObjectFromServer.cli_aip_atp.url_player.atp_v0
      }
    };
  }
}
