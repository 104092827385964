import {Injectable} from '@angular/core';
import {CurrentUserService} from '../../../modules/ng-manager-user/services/current-user.service';
import {appConfigurationProperties} from '../../../config/appConfigurationProperties';
import {ArrayUtil} from '../../../modules/ng-helpers-util/array.util';
import {ObjectUtil} from '../../../modules/ng-helpers-util/object.util';
import {UserDependenciesService} from '../../../modules/ng-module-login/services/user-dependencies.service';
import {UserSource} from '../../../data/catalogueProduct/elearning/UserSource';
import {LibraryUtil} from '../../../modules/ng-manager-user/utils/atel3/library.util';
import {UserPublication} from '../../../data/catalogueProduct/publishing/UserPublication';
import {Publication} from '../../../modules/ng-models-atp/models/db/Publication';

/**
 * Service de gestion du catalogue.
 */
@Injectable({
  providedIn: 'root',
})
export class PublishingProductLibraryService {

  // Les informations sur la bibliothèque de l'utilisateur
  userPublications: UserPublication[] = []; // Les publications de l'utilisateur
  userSources: UserSource[] = []; // Les sources de l'utilisateur

  /**
   *
   */
  constructor(
    private currentUserService: CurrentUserService,
    private userDependenciesService: UserDependenciesService) {
    this.init();
  }

  /**
   *
   */
  public static getUserPublications(
    currentUserService: CurrentUserService,
    userDependenciesService: UserDependenciesService): UserPublication[] {
    let userPublications: UserPublication[] = []; // Les publications de l'utilisateur
    userPublications = (
      ObjectUtil.hasProperty(currentUserService, 'user', 'atp', 'publications') &&
      ObjectUtil.isArray(currentUserService.user.atp.publications))
      ? currentUserService.user.atp.publications
        // Commenter tous les filtres pour garder un badge "supprimé" de library user
        // .filter(elem => !elem.status.deleted) // On ne prend pas en compte les publications supprimés
        .reduce((accumulator: UserPublication[], currentValue: UserPublication) => {
          const pub = userDependenciesService.getPublicationDependency(currentValue.publication_ref);
          return (pub && (pub.type === appConfigurationProperties.PUBLISHING_CONTAINER_TYPE_PUBLICATION))
            ? accumulator.concat([new UserPublication(pub as Publication, currentUserService, currentValue)])
            : accumulator;
        }, [])
      : [];
    return userPublications;
  }

  /**
   *
   */
  public static getUserSources(
    currentUserService: CurrentUserService,
    userDependenciesService: UserDependenciesService): UserSource[] {
    let userSources: UserSource[] = []; // Les sources de l'utilisateur
    userSources = (
      ObjectUtil.hasProperty(currentUserService, 'user', 'atp', 'publications') &&
      ObjectUtil.isArray(currentUserService.user.atp.publications))
      ? currentUserService.user.atp.publications
      // Commenter tous les filtres pour garder un badge "supprimé" de library user" (actuellement desactivé car non testé pour les source: a réactiver ?)
        .filter(elem => !elem.status.deleted) // On ne prend pas en compte les publications supprimés
        .reduce((accumulator: string[], currentValue: UserPublication) => {  // Récupération des _id des sources de l'utilisateur
          if (ObjectUtil.isArray(currentValue.sources)) {
            accumulator = accumulator.concat(currentValue.sources.map(item => item.source_ref));
          }
          return ArrayUtil.arrayUnique(accumulator);  // Suppression des doublons
        }, [])
        .map(elem => {
          const product = userDependenciesService.getSourceDependency(elem);
          return (product) ? new UserSource(product, currentUserService, userDependenciesService) : undefined;
        })
        .filter(elem => elem !== undefined)
      : [];
    // Dans le cas ou on n'est pas loggé user est non defini
    if (currentUserService.user) {
      userSources.unshift(  // Ajout de la source personnelle
        new UserSource(LibraryUtil.createPersonalSource(currentUserService.user), currentUserService, userDependenciesService));
    }
    return userSources;
  }

  /**
   *
   */
  private _getUserPublications(): UserPublication[] {
    return PublishingProductLibraryService.getUserPublications(
      this.currentUserService, this.userDependenciesService);
  }

  /**
   *
   */
  private _getUserSources(): UserSource[] {
    return PublishingProductLibraryService.getUserSources(
      this.currentUserService, this.userDependenciesService);
  }

  /**
   *
   */
  init(): void {
    // *** Construction de la librairie de l'utilisateur ***
    if (this.currentUserService.isUserConnected()) {
      this.userPublications = this._getUserPublications();  // Pour les publications
      this.userSources = this._getUserSources();  // Pour les sources
    }
  }

  /**
   *
   */
  reset(): void {
    this.userPublications = [];
    this.userSources = [];
  }

  /**
   *
   */
  getUserPublicationFromUserPublicationId(_id: string): UserPublication {
    return this.userPublications.find(elem => elem._id === _id);
  }

  /**
   *
   */
  getUserPublicationFromPublicationId(publication_ref: string): UserPublication {
    return this.userPublications.find(elem => elem.publication_ref === publication_ref);
  }

  /**
   *
   */
  getUserSourceFromSourceId(_id: string): UserSource {
    return this.userSources.find(elem => elem.source._id === _id);
  }

}
