/**
 * Classe stockant les variable d'environnement par défaut pour l'application angular.
 * Elles peuvent être overidées dans les fichiers :
 *    - ngui/src/assets/js/envStatic.js => Attribution static des variables d'environnement (nécessite une recompilation de l'application angular)
 *    - public/js/envDynamic.js => Attribution dynamique des variables d'environnement (ne nécessite pas de recompilation de l'application angular)
 */
// import {appConfigurationProperties} from '../config/appConfigurationProperties';

export class EnvService {

    // Variable de test
    public testVariable = '';

    // Activation du mode debug
    // Charge les librairie de développement du package "adoc-dashbord-ui"
    public enableDebug = true;

    constructor() {
    }

}
