import {BrowserModule} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app.routing';
import {AppComponent} from './app.component';
import {ViewsModule} from './views/views.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {LayoutContainersModule} from './containers/layout/layout.containers.module';
import {changeUserLanguageFunction} from './config/packages/ng-manager-user/current-user.service';
import {logoutFunction} from './config/packages/ng-module-login/account.service';
import {ModuleErrorConfigService} from './modules/ng-module-error/models/ModuleErrorConfig';
import {ModuleLibraryConfigInt} from './modules/ng-models-ui';
import {ModuleLoginConfigService} from './modules/ng-module-login/models/ModuleLoginConfig';
import {ManagerUserConfigService} from './modules/ng-manager-user/models/ModuleLoginConfig';
import {ErrorService} from './modules/ng-module-error/services/error.service';
import {CurrentUserService} from './modules/ng-manager-user/services/current-user.service';
import {AuthenticationService} from './modules/ng-manager-authentication/authentication.service';
import {AppService} from './app.service';
import {SessionService} from './modules/ng-manager-user/services/user-session.service';
import {EnvServiceProvider} from './env/env.service.provider';
import {GlobalModule} from './utils/global/global.module';
import {AuthenticationInterceptor} from './modules/ng-manager-server/interceptors/authentication.interceptor';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {CustomErrorsInterceptor} from './utils/interceptors/custom-errors.interceptor';
import {DropDownResolver} from './modules/ng-manager-server/enumTypes';
import {appConfigurationProperties} from './config/appConfigurationProperties';
import {LoginResolver} from './modules/ng-module-login/resolvers/login.resolver';
import {MessageService} from './shared/message.service';
import {LocalStorageUserListenerService} from './shared/localStorageUserListener.service';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {NavigationService} from './shared/navigation.service';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import {LocalizationService} from './modules/ng-manager-user/services/localization.service';
import {ShoppingCartServiceDataContent} from './modules/ng-module-shopping/cart/services/shopping-cart-dataContent.service';
import {HttpService} from './modules/ng-manager-server/services';
import {LocalStorageListenerService} from './shared/localStorageListener.service';
import {SearchService} from './shared/search.service';
import {DeadlinesService} from './containers/dashboards/deadlines/deadlines.service';
import {UserDependenciesService} from './modules/ng-module-login/services/user-dependencies.service';
import {LangService} from './shared/lang.service';
import {CurrencyService} from './shared/currency.service';
import {DataFromServerService} from './shared/data-from-server.service';
import {DocumentService} from './shared/document.service';
import {ElearningProductLibraryService} from './views/app/elearning/elearning-product-library.service';
import {PublishingProductLibraryService} from './views/app/publishing/publishing-product-library.service';
import {DatabaseUtil} from './utils/database/databaseUtil';

registerLocaleData(localeFr);

/**
 * Cette fonction permet de retarder le lancement de l'application angular en attendant que certains services s'initialisent.
 */
export function initApp(appService: AppService): () => Promise<any[]> {
  // Do initing of services that is required before app loads
  // NOTE: this factory needs to return a function (that then returns a promise)

  // Initialisation du module de traduction i18next.
  // Peut prendre du temps car les fichiers de langues doivent être chargés.
  return () => Promise.all([
    appService.initI18next(),
    appService.getConfigurationPromiseFromServer(),
    appService.getCurrencyPromiseFromServer()
  ]).then(() => {
    // Phase d'init des int de extjs
    if (!DatabaseUtil.isMenuButtonAdocHidden()) {
      // Initialisation de ard si le bouton n'est pas masqué
      appService.initArd();
    }
    return Promise.resolve(true as any);
    //// return Promise.resolve(true as any);
  });
}

/**
 * Cette fonction permet de recupérer la langue de l'utilisateur.
 * Cette fonction est appelé une seule fois à chaque lancement d'un composant utilisant les fonctions
 * de localisation de angular comme les pipes date.
 */
export function getUserLanguage(currentUserService: CurrentUserService): string {
  return currentUserService.getUserPreferredLanguage();
}

/**
 * La liste des intercepteurs
 */
const interceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    deps: [Router, CurrentUserService],  // dépendances
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CustomErrorsInterceptor,
    // deps: [NotificationsService],  // dépendances non nécessaire
    multi: true
  }
];

/**
 *
 */
@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot({...appConfigurationProperties.NOTIFICATIONS_OPTIONS}),
    HttpClientModule,
    ModalModule.forRoot(),

    GlobalModule.forBrowser(),
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    AppService,
    interceptorProviders,
    { // LOCALE_ID prend en charge la localisation pour angular (par exemple pour le pipe date)
      provide: LOCALE_ID,
      useValue: 'fr',
      deps: [CurrentUserService],  // dépendances
      useFactory: getUserLanguage  // retourne langue sous forme de string. Utilisé au chargement des composant utilisant le pipe
    },
    { // APP_INITIALIZER permet de retarder le lancement de l'application angular en attendant que certains services s'initialisent
      provide: APP_INITIALIZER,
      deps: [AppService],  // dépendances
      useFactory: initApp,
      multi: true,
    },
    {
      provide: ModuleErrorConfigService,
      useValue: {
        data: {
          urlUiError: environment.URL_UI_ERROR,
          urlServerErrorNotification: environment.URL_SERVER_ERROR_NOTIFICATION
        }
      } as ModuleLibraryConfigInt
    },
    {
      provide: ModuleLoginConfigService,
      useValue: {
        data: {
          urlLoginSuccess: environment.URL_UI_HOME,
          urlLoginFail: environment.URL_UI_LOGIN,
          logoutFunction, // Variable injectée dans le service AccountService
          // homeRouting,    // Variable injectée dans le service LoginService
        }
      } as ModuleLibraryConfigInt
    },
    {   // Variable injectée dans le ng-manager-user
      provide: ManagerUserConfigService,
      useValue: {
        data: {
          changeUserLanguageFunction,
          urlUpdateUserCommon: environment.URL_SERVER_UPDATE_USER_COMMON,
          urlReloadUser: environment.URL_SERVER_RELOAD_USER
        }
      } as ModuleLibraryConfigInt
    },
    {   // Variable injectée dans le service EnumTypeService via le DropDownResolver
      provide: 'urlGetEnumType',
      useValue: appConfigurationProperties.URL_SERVER_GET_ENUM_TYPE
    },
    {   // Variable injectée dans le resolver DropDownResolver
      provide: 'enumTypeNamespace',
      useValue: appConfigurationProperties.I18NEXT_NAMESPACE_ENUM_TYPES
    },
    DropDownResolver,
    LoginResolver,
    EnvServiceProvider,
    ErrorService,
    MessageService,
    LocalStorageListenerService,
    LocalStorageUserListenerService,
    CurrentUserService,
    SessionService,
    LocalizationService,
    BsModalService,
    AuthenticationService,
    HttpService,
    CurrencyService,
    LangService,
    NavigationService,
    SearchService,
    ShoppingCartServiceDataContent,
    DeadlinesService,
    UserDependenciesService,
    DataFromServerService,
    DocumentService,
    ElearningProductLibraryService,
    PublishingProductLibraryService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
