import i18next from 'i18next';
import {StringUtil} from "../../../ng-helpers-util/string.util";
import {applicationProperties} from "../../../ng-properties-application/application.properties";

export class AddressSchema {
  street: string;
  city: string;
  zip: string;
  country: string;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
  }

  /**
   * Fonction affiche l'adresse de l'utilisateur par ligne.
   */
  getAddressLines(): string[] {
    let res = [];
    if (!StringUtil.isUndefinedOrEmpty(this.street)) {
      res.push(this.street);
    }
    let line2 = '';
    if (!StringUtil.isUndefinedOrEmpty(this.zip)) {
      line2 += this.zip
    }
    if (!StringUtil.isUndefinedOrEmpty(this.city)) {
      line2 += (line2.length > 0)
        ? `, ${this.city}`
        : this.city;
    }
    if (line2.length > 0) {
      res.push(line2);
    }
    if (!StringUtil.isUndefinedOrEmpty(this.country)) {
      res.push(i18next.t(`${applicationProperties.I18NEXT_NAMESPACE_ENUM_TYPES}:country.${this.country}`));
    }
    return res;
  }

}
