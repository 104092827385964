import {UserAtel} from './UserAtel';
import {UserAdoc} from './UserAdoc';
import {ConfigurationProperties} from '../../../ng-properties-configuration/configuration.properties';
import {Source} from "./Source";
import {AddressSchema} from "../schemas/AddressSchema";
import {UserAtp} from "./UserAtp";

class UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  job: UserJob;
  title: string;
  picture: string;
  language: string;
  locale: string;
  preferredCurrency: string;
  birth: UserBirth;
  phone: UserPhone;
  address: AddressSchema;
  timezone: UserTimeZone;
}

class UserExtraInfo {
  gender: boolean;
  timezoneOffset: number;
  changeEmailRequested: string;
}

class UserCompany {
  name: string;
  activity: string;
  email: string;
  phone: string;
  website: string;
  address: AddressSchema;
}

export class UserCreation {
  type: number;

  constructor(type: number) {
    this.type = type;
  }
}

class UserBirth {
  date: Date;
  city: string;
  country: string;
}

class UserPhone {
  home: string;
  office: string;
  mobile: string;
}

export class UserJob {
  position: string;
  department: string;
  service: string;
}

class UserTimeZone {
  code: string;
  dst: boolean;
}

class UserStatistics {
  nbConn: number;
  firstConn: Date;
  lastConn: Date;
  lastIp: string;
}

class UserStatus {
  confirmed: boolean;
  frozen: boolean;
  active: boolean;
  activationDate: Date;
  expired: boolean;
  expiryDate: Date;
  modificationDate: Date;
  deleted: boolean;
  deletionDate: Date;
}

export class UserRole {
  app: string;
  role: string;

  constructor(app: string, role: string) {
    this.app = app;
    this.role = role;
  }
}

export class User {
  _id: string;
  // Ce champs n'a jamais été utilisé
  // shortId: string; // Ryad a repris ce code de la GUI de aip-admin => Idriss a dit que ce champs n' a jamais ete utilise d'ou sa mise en commentaire
  username: string;
  roles: UserRole[];
  atel: UserAtel;
  // atel: UserAtel = new UserAtel();
  atp: UserAtp;
  // atp: UserAtp = new UserAtp();
  adoc: UserAdoc;
  // adoc: UserAdoc = new UserAdoc();
  source: Source | string;
  // source: Source = new Source();
  info: UserInfo;
  extraInfo: UserExtraInfo;
  company: UserCompany;
  statistics?: UserStatistics;
  status: UserStatus;
  creation: UserCreation;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(
    // userDependenciesService: UserDependenciesService,
    _data: any = undefined) {
    $.extend(this, _data);
    if (_data && _data.atel) {
      this.atel = new UserAtel(_data.atel);
    }
    if (_data && _data.atp) {
      this.atp = new UserAtp(_data.atp);
    }
    if (_data && _data.adoc) {
      this.adoc = new UserAdoc(_data.adoc);
    }
  }

  /**
   * Fonction qui intialise une nouvel utilisateur.
   *
   *
   * @param reset - Si false, n'initialise que les propriétés undefined.
   */
  public init(reset: boolean = true): User {
    if (reset) {
      this.initUser();
    }

    if (reset || !this.atel) {
      this.atel = new UserAtel().init();
    }
    if (reset || !this.atp) {
      this.atp = new UserAtp().init();
    }
    if(reset || !this.adoc) {
      this.adoc = new UserAdoc().init();
    }

    if (reset || !this.info) {
      this.initUserInfo();
    }
    if (reset || !this.info.job) {
      this.initUserInfoJob();
    }
    if (reset || !this.info.birth) {
      this.initUserInfoBirth();
    }
    if (reset || !this.info.phone) {
      this.initUserInfoPhone();
    }
    if (reset || !this.info.address) {
      this.initUserInfoAddress();
    }
    if (reset || !this.info.timezone) {
      this.initUserInfoTimezone();
    }

    if (reset || !this.extraInfo) {
      this.initUserExtraInfo();
    }

    if (reset || !this.company) {
      this.initUserCompany();
    }
    if (reset || !this.company.address) {
      this.initUserCompanyAddress();
    }

    if (reset || !this.creation) {
      this.initUserCreation();
    }

    if (reset || !this.statistics) {
      this.initUserStatistics();
    }

    if (reset || !this.status) {
      this.initUserStatus();
    }

    return this;
  }

  public initUser(): void {
    this._id = undefined;
    // this.shortId = undefined; // Ryad a repris ce code de la GUI de aip-admin => Idriss a dit que ce champs n' a jamais ete utilise d'ou sa mise en commentaire
    this.username = undefined;
  }

  public initUserInfo(): void {
    this.info = new UserInfo();
    this.info.firstName = undefined;
    this.info.lastName = undefined;
    this.info.email = undefined;
    this.info.title = undefined;
    this.info.picture = undefined;
    this.info.language = undefined;
    this.info.locale = undefined;
    this.info.preferredCurrency = undefined;
  }

  public initUserInfoJob(): void {
    this.info.job = new UserJob();
    this.info.job.position = undefined;
    this.info.job.department = undefined;
    this.info.job.service = undefined;
  }

  public initUserInfoBirth(): void {
    this.info.birth = new UserBirth();
    this.info.birth.city = undefined;
    this.info.birth.country = undefined;
  }

  public initUserInfoPhone(): void {
    this.info.phone = new UserPhone();
    this.info.phone.home = undefined;
    this.info.phone.office = undefined;
    this.info.phone.mobile = undefined;
  }

  public initUserInfoAddress(): void {
    this.info.address = new AddressSchema();
    this.info.address.street = undefined;
    this.info.address.city = undefined;
    this.info.address.zip = undefined;
    this.info.address.country = undefined;
  }

  public initUserInfoTimezone(): void {
    this.info.timezone = new UserTimeZone();
    this.info.timezone.code = undefined;
    this.info.timezone.dst = false;

  }

  public initUserExtraInfo(): void {
    this.extraInfo = new UserExtraInfo();
    this.extraInfo.gender = undefined;
    this.extraInfo.timezoneOffset = 0;
    this.extraInfo.changeEmailRequested = undefined;

  }

  public initUserCompany(): void {
    this.company = new UserCompany();
    this.company.name = undefined;
    this.company.activity = undefined;
    this.company.email = undefined;
    this.company.phone = undefined;
    this.company.website = undefined;
  }

  public initUserCompanyAddress(): void {
    this.company.address = new AddressSchema();
    this.company.address.street = undefined;
    this.company.address.city = undefined;
    this.company.address.zip = undefined;
    this.company.address.country = undefined;

  }

  public initUserCreation(): void {
    this.creation = new UserCreation(
      ConfigurationProperties.cli_creator_type_from_admin_ui
    );
  }

  public initUserStatistics(): void {
    this.statistics = new UserStatistics();
    this.statistics.nbConn = 0;
    this.statistics.lastIp = '';
  }

  public initUserStatus(): void {
    this.status = new UserStatus();
    this.status.confirmed = false;
    this.status.frozen = false;
    this.status.active = true;
    this.status.expired = false;
    this.status.deleted = false;
  }
}
