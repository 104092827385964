import {
  Component,
  OnInit,
  Renderer2,
  AfterViewInit,
  ViewChild,
  TemplateRef,
  OnDestroy,
  HostListener
} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {MessageService} from './shared/message.service';
import {NotificationsService} from 'angular2-notifications';
import {appConfigurationProperties} from './config/appConfigurationProperties';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {LocalizationService} from './modules/ng-manager-user/services/localization.service';
import {LocalStorageListenerService} from './shared/localStorageListener.service';
import {LangService} from './shared/lang.service';
import {filter} from 'rxjs/operators';
import {StringUtil} from './modules/ng-helpers-util/string.util';
import {Subscription} from 'rxjs';

// tslint:disable-next-line:ban-types
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

@Injectable()
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  subscriptionNotification: Subscription;
  subscriptionModal: Subscription;

  isMultiColorActive = environment.isMultiColorActive;

  // Informations pour les modales
  modalConfig = {
    backdrop: true,             // Pour afficher un fond opaque
    ignoreBackdropClick: false, // Pour désactiver la fermeture du modal lorsque l'on clique sur le fond
    class: 'gray modal-lg'
  };
  modalWelcomeRef: BsModalRef;
  @ViewChild('templateWelcome') public modalTemplateWelcome: TemplateRef<any>;

  /*
  // Détection de la récupération du focus de l'onglet du navigateur
  @HostListener('window:focus', ['$event'])
  onFocus(event): void {
    console.log('FOCUS !!!!!!!!! hasFocus:', document.hasFocus(), ' - event:', event);
  }
  // Détection de la perte du focus de l'onglet du navigateur
  @HostListener('window:blur', ['$event'])
  onBlur(event): void {
    console.log('BLUR !!!!!!!!! hasFocus:', document.hasFocus(), ' - event:', event);
  }
  */

  /**
   *
   */
  constructor(
    private router: Router,
    private langService: LangService,
    private renderer: Renderer2,
    private notifications: NotificationsService,
    private modalService: BsModalService,
    private messageService: MessageService,
    private localizationService: LocalizationService,
    private localStorageListenerService: LocalStorageListenerService, // Détection des évènements des autres onglets
    // private envService: EnvService     // chargement des variables d'environnement  // PATTERN - env : Pour les tests
  ) {
    // On détecte demande d'affichage de notifications
    this.subscriptionNotification = messageService.displayNotification$.subscribe(
      notification => {
        this.notifications.create(
          notification.title,
          notification.content,
          notification.type,
          notification.override,
          notification.context);
      });
    // On détecte demande d'affichage de modals
    this.subscriptionModal = messageService.displayModal$.subscribe( // FIXME : Modal à faire ou a supprimer !!!
      modalName => {
        switch (modalName) {
          case appConfigurationProperties.MESSAGE_SERVICE_MODAL_NAME_WELCOME:
            // TODO : Rediriger vers la page de modification du profile
            this.modalWelcomeRef = this.modalService.show(this.modalTemplateWelcome, this.modalConfig);
            break;
        }
      });
    // Initialisation de la localisation
    // TODO : Activer la localisation
    // this.localizationService.getLocalization();


    // console.log('appConfigurationProperties.GOOGLE_ANALYTICS_MEASUREMENT_ID:', appConfigurationProperties.GOOGLE_ANALYTICS_MEASUREMENT_ID);

    // == Installation de google analytics ==

    this.installGoogleAnalyticsMeasurementStructures();
    if (!StringUtil.isUndefinedOrEmpty(appConfigurationProperties.GOOGLE_ANALYTICS_MEASUREMENT_ID)) {
      this.grabGoogleAnalyticsMeasurementId();
      // On envoie à google Analytics les routes accédées
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        /*
        gtag(
          'config',
          appConfigurationProperties.GOOGLE_ANALYTICS_MEASUREMENT_ID,
          {page_path: event.urlAfterRedirects});
         */
        gtag(
          'event',
          'page_view',
          {
            page_path: event.urlAfterRedirects,
            // page_location: this.document.location.href
          });
      });
    }
  }

  ngOnInit(): void {
    this.langService.changeLanguage();
    // Détection des évènements des autres onglets
    this.localStorageListenerService.initListener();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
  }

  /**
   * Désinscription afin de ne pas accumuler les appels aux évènements
   */
  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.subscriptionNotification.unsubscribe();
    this.subscriptionModal.unsubscribe();
  }

  /*
   * Afin de regrouper tout le code analytics on installe les structures ici en javascript au lieu de le mettre inline dans index.html
   * Installation des structures de données pour google analytics (on defini la fct gtag meme si GOOGLE_ANALYTICS_MEASUREMENT_ID non defini)
   */
  private installGoogleAnalyticsMeasurementStructures() {


    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).gtag = function () {
      (window as any).dataLayer.push(arguments);
    };
    gtag('js', new Date());
    /*
    var customGInitScript = document.createElement('script');
    customGInitScript.type = 'text/javascript';
    let code = `
       window.dataLayer = window.dataLayer || [];
       function gtag() {
           dataLayer.push(arguments);
       }
       gtag('js', new Date());
    `;
    try { // Methode d'insertion pour certains browsers
      customGInitScript.appendChild(document.createTextNode(code));
      document.head.appendChild(customGInitScript);
    } catch (e) { // // Methode d'insertion pour d'autres browsers
      customGInitScript.text = code;
      document.head.appendChild(customGInitScript);
    }
    */
  }

  /**
   *
   */
  private grabGoogleAnalyticsMeasurementId(): void {
    // Ajout dynamique des informations pour google analytics dans le script head
    const customGtagScriptEle: HTMLScriptElement = document.createElement('script');
    customGtagScriptEle.async = true;
    customGtagScriptEle.src = 'https://www.googletagmanager.com/gtag/js?id=' + appConfigurationProperties.GOOGLE_ANALYTICS_MEASUREMENT_ID;
    document.head.prepend(customGtagScriptEle);

    /*
    gtag(
      'config',
      appConfigurationProperties.GOOGLE_ANALYTICS_MEASUREMENT_ID,
      // {send_page_view: false}
    );
*/


    gtag('config',appConfigurationProperties.GOOGLE_ANALYTICS_MEASUREMENT_ID);


  }
}
