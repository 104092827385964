import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {applicationProperties} from '../modules/ng-properties-application/application.properties';
import {ObjectUtil} from '../modules/ng-helpers-util/object.util';

/**
 * Service permettant de détecter les modifications de l'utilisateur
 * dans d'autres onglets. Utilise le localeStorage.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageUserListenerService {

  // Date à laquelle la dernière demande de modification de l'utilisateur a été enregistré
  lastUserUpdateDate: number;

  // Observable pour la détection de modification des tokens du localStorage
  private updateUserSource = new Subject<number>(); // Contient la valeur du token du localStorage
  updateUser$ = this.updateUserSource.asObservable();

  /**
   *
   */
  constructor() {
    this.initListener();
  }

  /**
   * Listener gérant l'ouverture et la fermeture des onglets (connexion et deconnexion automatique des utilisateurs).
   * Utilise le browser storage.
   */
  initListener(): void {
    window.addEventListener(
      // "keydown",        // Catch toutes les pressions de touche du clavier
      'storage',     // Catch toute les modifications des valeurs stockées dans la localStorage et la sessionStorage
      (event) => {
        // Gestion de l'évènement JWT_USER_TRIGGER_KEY
        if (event.key === applicationProperties.JWT_USER_TRIGGER_KEY) {
          // On spécifie que l'affichage doit être rafraîchi car des changements ont été opérés sur l'utilisateur
          // (notamment dans le cas ou le player met à jour les informations de progression des lessons).
          // const tokenValue = sessionStorage.getItem(applicationProperties.JWT_USER_TRIGGER_KEY);
          const tokenValue = event.newValue;
          const tokenValueInt = (ObjectUtil.isNumeric(tokenValue)) ? parseInt(tokenValue, 10) : undefined;
          if (!this.lastUserUpdateDate || (this.lastUserUpdateDate && (this.lastUserUpdateDate < tokenValueInt))) {
            // Il n'y a pas de  dernière de modification ou elle plus ancienne
            this.lastUserUpdateDate = tokenValueInt;
            this.updateUserSource.next(tokenValueInt);
          }
        }
      });
  }

}
