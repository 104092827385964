/**
 * Service permettant de récupérer et de rendre accessible dans angular les proriétés contenue
 * dans la variable du navigateur "window.__env".
 * La variable "window.__env" est construite dans les scripts :
 *    - ngui/src/assets/js/envStatic.js => Attribution static des variables d'environnement (nécessite une recompilation de l'application angular)
 *    - public/js/envDynamic.js => Attribution dynamique des variables d'environnement (ne nécessite pas de recompilation de l'application angular)
 * Ces scripts sont chargés dans le header du fichier "index.html" avant le chagement de l'application angular.
 * Les propriétés du fichier "window.__env" sont donc disponible lors du chargement de l'application angular.
 */

import { EnvService } from './env.service';

// tslint:disable-next-line:variable-name
export const EnvServiceFactory = () => {
    // Create env
    const env = new EnvService();

    // Read environment variables from browser window
    const browserWindow = window || {};
    // tslint:disable-next-line:no-string-literal
    const browserWindowEnv = browserWindow['__env'] || {};

    // Assign environment variables from browser window to env
    // In the current implementation, properties from env.js overwrite defaults from the EnvService.
    // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
    for (const key in browserWindowEnv) {
        if (browserWindowEnv.hasOwnProperty(key)) {
            // tslint:disable-next-line:no-string-literal
            env[key] = window['__env'][key];
        }
    }

    return env;
};

// tslint:disable-next-line:variable-name
export const EnvServiceProvider = {
    provide: EnvService,
    useFactory: EnvServiceFactory,
    deps: [],
};
