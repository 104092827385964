import * as dateFormat from "dateformat";
import {applicationProperties} from "../../../ng-properties-application/application.properties";
import {DateUtil} from "../../../ng-helpers-util/date.util";

/**
 * Le statut pour les UserTeachingDb, UserCourse, UserDegree, UserPublication et UserPublication.
 */
export class UserProductStatus {
  frozen: boolean;
  active: boolean;
  expired: boolean;
  duration: number;
  activationDate: Date;
  expiryDate: Date;
  creationDate: Date;
  modificationDate: Date;
  modificationUser: string; // tache 82
  deleted: boolean;
  deletionDate: Date;

  // Les dates au format string (pour la modification)
  strActivationDate: string;
  strExpiryDate: string;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
    // Gestion des dates
    if (_data) {
      this.activationDate = (_data.activationDate) ? new Date(_data.activationDate) : undefined;
      this.expiryDate = (_data.expiryDate) ? new Date(_data.expiryDate) : undefined;
      this.creationDate = (_data.creationDate) ? new Date(_data.creationDate) : undefined;
      this.modificationDate = (_data.modificationDate) ? new Date(_data.modificationDate) : undefined;
      this.deletionDate = (_data.deletionDate) ? new Date(_data.deletionDate) : undefined;

      // Les dates au format string (pour la modification)
      this.updateStrDates();
    }
  }

  /**
   *
   */
  updateDates() {
    this.activationDate = (this.strActivationDate)
        ? DateUtil.stringToDate(this.strActivationDate, "/", "day", "month", "year", "hour", "minute", "second")
        : undefined;
    this.expiryDate = (this.strExpiryDate)
        ? DateUtil.stringToDate(this.strExpiryDate, "/", "day", "month", "year", "hour", "minute", "second")
        : undefined;
  }

  /**
   *
   */
  updateStrDates() {
    this.strActivationDate = (this.activationDate) ? dateFormat(this.activationDate, applicationProperties.FORMAT_DISPLAY_DATE_DETAIL).toString() : "";
    this.strExpiryDate = (this.expiryDate) ? dateFormat(this.expiryDate, applicationProperties.FORMAT_DISPLAY_DATE_DETAIL).toString() : "";
  }
}
