import {Injectable} from '@angular/core';
import {Lesson} from '../../../modules/ng-models-atel3/models/db/Lesson';
import {Quiz} from '../../../modules/ng-models-atel3/models/db/Quiz';
import {CurrentUserService} from '../../../modules/ng-manager-user/services/current-user.service';
import {appConfigurationProperties} from '../../../config/appConfigurationProperties';
import {ArrayUtil} from '../../../modules/ng-helpers-util/array.util';
import {ObjectUtil} from '../../../modules/ng-helpers-util/object.util';
import {UserTeachingDb} from '../../../modules/ng-models-atel3/models/db/userAtel/UserTeachingDb';
import {UserDependenciesService} from '../../../modules/ng-module-login/services/user-dependencies.service';
import {UserLesson} from '../../../data/catalogueProduct/elearning/UserLesson';
import {UserQuiz} from '../../../data/catalogueProduct/elearning/UserQuiz';
import {UserCourse} from '../../../data/catalogueProduct/elearning/UserCourse';
import {UserDegree} from '../../../data/catalogueProduct/elearning/UserDegree';
import {UserSource} from '../../../data/catalogueProduct/elearning/UserSource';
import {LibraryUtil} from '../../../modules/ng-manager-user/utils/atel3/library.util';
import {StringUtil} from '../../../modules/ng-helpers-util/string.util';

/**
 * Service de gestion du catalogue.
 */
@Injectable({
  providedIn: 'root',
})
export class ElearningProductLibraryService {

  // Les informations sur la bibliothèque de l'utilisateur
  userLessons: UserLesson[] = []; // Les lessons de l'utilisateur
  userQuiz: UserQuiz[] = [];      // Les quizs de l'utilisateur
  userCourses: UserCourse[] = []; // Les cours de l'utilisateur
  userDegrees: UserDegree[] = []; // Les cursus de l'utilisateur
  userSources: UserSource[] = []; // Les sources de l'utilisateur

  /**
   *
   */
  constructor(
    private currentUserService: CurrentUserService,
    private userDependenciesService: UserDependenciesService) {
    this.init();
  }

  /**
   * Fonction qui retourne la liste des thèmes des teachings de l'utilisateur.
   * Retourne un tableau d'objets {themeId, teachings}
   * où "key" est l'_id du thème (undefined pour theme racine) et "value" la liste des teachings de ce thème.
   * Si lng est défini alors filtre avec la langue donnee
   */
  public static getUserElearningThemeCatalogue(
    _currentUserService: CurrentUserService,
    _userDependenciesService: UserDependenciesService,
    {lng}: { lng?: string } = {}): { themeId: string, teachings: UserTeachingDb[] }[] {
    const teachings: (Lesson | Quiz)[] = []
      .concat(
        ElearningProductLibraryService.getUserLessons(_currentUserService, _userDependenciesService, {lng}),
        ElearningProductLibraryService.getUserQuiz(_currentUserService, _userDependenciesService, {lng}))
      .map(elem => {
        const obj = elem.getEmbeddedObject();
        obj.theme = (ObjectUtil.hasProperty(obj, 'theme') && !StringUtil.isUndefinedOrEmpty(obj.theme))
          ? obj.theme : undefined;
        return obj;
      });
    return ArrayUtil.groupBy(teachings, 'theme')
      .map(elem => {
        return {
          themeId: elem.key,
          teachings: elem.values
        };
      });
  }

  /**
   * Si lng est défini alors filtre avec la langue donnee
   */
  public static getUserLessons(
    _userTeachingsGetter: CurrentUserService | UserTeachingDb[],
    _userDependenciesService: UserDependenciesService,
    {lng}: { lng?: string } = {}): UserLesson[] {
    // Les lessons de l'utilisateur
    return LibraryUtil.getUserTeachingDbListFromGetter(_userTeachingsGetter)
      // Commenter tous les filtres pour garder un badge "supprimé" de library user
      // .filter(elem => !elem.status.deleted) // On ne prend pas en compte les teachings supprimés
      .reduce((accumulator: UserLesson[], currentValue: UserTeachingDb) => {
        const teach = _userDependenciesService.getElearningTeachingDependency(currentValue.teaching_ref as string);
        return (teach && (teach.type === appConfigurationProperties.LEARNING_CONTAINER_TYPE_LESSON) &&
          (!lng || (teach.details && teach.details.language === lng)))
          ? accumulator.concat([new UserLesson(teach as Lesson, _userTeachingsGetter, currentValue)])
          : accumulator;
      }, []);
  }

  /**
   *  Si lng est défini alors filtre avec la langue donnee
   */
  public static getUserQuiz(
    _userTeachingsGetter: CurrentUserService | UserTeachingDb[],
    _userDependenciesService: UserDependenciesService,
    {lng}: { lng?: string } = {}): UserQuiz[] {
    // Les quizs de l'utilisateur
    return LibraryUtil.getUserTeachingDbListFromGetter(_userTeachingsGetter)
      // Commenter tous les filtres pour garder un badge "supprimé" de library user
      // .filter(elem => !elem.status.deleted) // On ne prend pas en compte les teachings supprimés
      .reduce((accumulator: UserQuiz[], currentValue: UserTeachingDb) => {
        const teach = _userDependenciesService.getElearningTeachingDependency(currentValue.teaching_ref as string);
        return (teach && (teach.type === appConfigurationProperties.LEARNING_CONTAINER_TYPE_QUIZ) &&
          (!lng || (teach.details && teach.details.language === lng)))
          ? accumulator.concat([new UserQuiz(teach as Quiz, _userTeachingsGetter, currentValue)])
          : accumulator;
      }, []);
  }

  /**
   * Si lng est défini alors filtre avec la langue donnee
   */
  public static getUserCourses(
    _userTeachingsGetter: CurrentUserService | UserTeachingDb[],
    _userDependenciesService: UserDependenciesService,
    {lng}: { lng?: string } = {}): UserCourse[] {
    // Les cours de l'utilisateur
    return LibraryUtil.getUserTeachingDbListFromGetter(_userTeachingsGetter)
      // Commenter tous les filtres pour garder un badge "supprimé" de library user
      .filter(elem => !elem.status.deleted) // On ne prend pas en compte les teachings supprimés (car leur association est supprimé dans le teaching - FIXME: Perte de traçabilité)
      .reduce((accumulator: string[], currentValue: UserTeachingDb) => {  // Récupération des _id des cours de l'utilisateur
        if (ObjectUtil.isArray(currentValue.courses)) {
          accumulator = accumulator.concat(currentValue.courses.map(item =>
            item.course_ref
          ));
        }
        if (ObjectUtil.isArray(currentValue.degrees)) {
          accumulator = accumulator.concat(currentValue.degrees.map(item =>
            item.course_ref
          ));
        }
        return ArrayUtil.arrayUnique(accumulator);  // Suppression des doublons
      }, [])
      .map(elem => {
        const product = _userDependenciesService.getElearningCourseDependency(elem);
        if (!product || (lng && product.details && product.details.languages && !product.details.languages.includes(lng))) {
          // if (lng && (!product || !product.details || !product.details.languages.includes(lng))) {
          return undefined;
        }
        product.populate(_userDependenciesService.lessonsDependencies);
        return (product) ? new UserCourse(product, _userTeachingsGetter) : undefined;
      })
      .filter(elem => elem !== undefined);
  }

  /**
   * Si lng est défini alors filtre avec la langue donnee
   */
  public static getUserDegrees(
    _userTeachingsGetter: CurrentUserService | UserTeachingDb[],
    _userDependenciesService: UserDependenciesService,
    {lng}: { lng?: string } = {}): UserDegree[] {
    // Les cursus de l'utilisateur
    return LibraryUtil.getUserTeachingDbListFromGetter(_userTeachingsGetter)
      // Commenter tous les filtres pour garder un badge "supprimé" de library user
      .filter(elem => !elem.status.deleted) // On ne prend pas en compte les teachings supprimés (car leur association est supprimé dans le teaching - FIXME: Perte de traçabilité)
      .reduce((accumulator: string[], currentValue: UserTeachingDb) => {  // Récupération des _id des degree de l'utilisateur
        if (ObjectUtil.isArray(currentValue.degrees)) {
          accumulator = accumulator.concat(currentValue.degrees.map(item => item.degree_ref));
        }
        return ArrayUtil.arrayUnique(accumulator);  // Suppression des doublons
      }, [])
      .map(elem => {
        const product = _userDependenciesService.getElearningDegreeDependency(elem);
        if (!product || (lng && product.details && product.details.languages && !product.details.languages.includes(lng))) {
          // if (lng && (!product || !product.details || !product.details.languages.includes(lng))) {
          return undefined;
        }
        product.populate(
          _userDependenciesService.lessonsDependencies, _userDependenciesService.coursesDependencies);
        return (product) ? new UserDegree(product, _userTeachingsGetter, _userDependenciesService) : undefined;
      })
      .filter(elem => elem !== undefined);
  }

  /**
   *
   */
  public static getUserSources(
    _currentUserService: CurrentUserService,
    _userDependenciesService: UserDependenciesService): UserSource[] {
    let userSources: UserSource[] = []; // Les sources de l'utilisateur
    userSources = (
      ObjectUtil.hasProperty(_currentUserService, 'user', 'atel', 'teachings') &&
      ObjectUtil.isArray(_currentUserService.user.atel.teachings))
      ? _currentUserService.user.atel.teachings
        // Commenter tous les filtres pour garder un badge "supprimé" de library user" (actuellement desactivé car non testé pour les source: a réactiver ?)
        .filter(elem => !elem.status.deleted) // On ne prend pas en compte les teachings supprimés
        .reduce((accumulator: string[], currentValue: UserTeachingDb) => {  // Récupération des _id des sources de l'utilisateur
          if (ObjectUtil.isArray(currentValue.sources)) {
            accumulator = accumulator.concat(currentValue.sources.map(item => item.source_ref));
          }
          return ArrayUtil.arrayUnique(accumulator);  // Suppression des doublons
        }, [])
        .map(elem => {
          const product = _userDependenciesService.getSourceDependency(elem);
          return (product) ? new UserSource(product, _currentUserService, _userDependenciesService) : undefined;
        })
        .filter(elem => elem !== undefined)
      : [];
    // Dans le cas ou on n'est pas loggé user est non defini
    if (_currentUserService.user) {
      userSources.unshift(  // Ajout de la source personnelle
        new UserSource(LibraryUtil.createPersonalSource(_currentUserService.user), _currentUserService, _userDependenciesService));
    }
    return userSources;
  }

  /**
   *
   */
  private _getUserLessons(): UserLesson[] {
    /*
        // On extrait les userTeachings du currentUserService car si on passe le currentUserService
        // en paramètre, cela bug en compilation prod
        const userTeachingDbs = this.currentUserService.user.atel.teachings;
        return ElearningProductLibraryService.getUserLessons(
          userTeachingDbs, this.userDependenciesService);
    */
    return ElearningProductLibraryService.getUserLessons(
      this.currentUserService, this.userDependenciesService);
  }

  /**
   *
   */
  private _getUserQuiz(): UserQuiz[] {
    /*
        // On extrait les userTeachings du currentUserService car si on passe le currentUserService
        // en paramètre, cela bug en compilation prod
        const userTeachingDbs = this.currentUserService.user.atel.teachings;
        return ElearningProductLibraryService.getUserQuiz(
          userTeachingDbs, this.userDependenciesService);
    */
    return ElearningProductLibraryService.getUserQuiz(
      this.currentUserService, this.userDependenciesService);
  }

  /**
   *
   */
  private _getUserCourses(): UserCourse[] {
    /*
        // On extrait les userTeachings du currentUserService car si on passe le currentUserService
        // en paramètre, cela bug en compilation prod
        const userTeachingDbs = this.currentUserService.user.atel.teachings;
        return ElearningProductLibraryService.getUserCourses(
          userTeachingDbs, this.userDependenciesService);
    */
    return ElearningProductLibraryService.getUserCourses(
      this.currentUserService, this.userDependenciesService);
  }

  /**
   *
   */
  private _getUserDegrees(): UserDegree[] {
    /*
        // On extrait les userTeachings du currentUserService car si on passe le currentUserService
        // en paramètre, cela bug en compilation prod
        const userTeachingDbs = this.currentUserService.user.atel.teachings;
        return ElearningProductLibraryService.getUserDegrees(
          userTeachingDbs, this.userDependenciesService);
    */
    return ElearningProductLibraryService.getUserDegrees(
      this.currentUserService, this.userDependenciesService);
  }

  /**
   *
   */
  private _getUserSources(): UserSource[] {
    return ElearningProductLibraryService.getUserSources(
      this.currentUserService, this.userDependenciesService);
  }


  /**
   *
   */
  init(): void {
    // *** Construction de la librairie de l'utilisateur ***
    if (this.currentUserService.isUserConnected()) {
      this.userLessons = this._getUserLessons();  // Pour les lessons
      this.userQuiz = this._getUserQuiz();        // Pour les quizs
      this.userCourses = this._getUserCourses();  // Pour les cours
      this.userDegrees = this._getUserDegrees();  // Pour les degrees
      this.userSources = this._getUserSources();  // Pour les sources
    }
  }

  /**
   *
   */
  reset(): void {
    this.userLessons = [];
    this.userQuiz = [];
    this.userCourses = [];
    this.userDegrees = [];
    this.userSources = [];
  }

  /**
   *
   */
  getUserTeachingFromUserTeachingId(_id: string): UserLesson | UserQuiz {
    const arr = (this.userLessons as UserTeachingDb[]).concat(this.userQuiz as UserTeachingDb[]);
    return (arr.find(elem => elem._id === _id) as UserLesson | UserQuiz);
  }

  /**
   *
   */
  getUserTeachingFromTeachingId(teaching_ref: string): UserLesson | UserQuiz {
    const arr = (this.userLessons as UserTeachingDb[]).concat(this.userQuiz as UserTeachingDb[]);
    return (arr.find(elem => elem.teaching_ref === teaching_ref) as UserLesson | UserQuiz);
  }

  /**
   *
   */
  getUserLessonFromUserTeachingId(_id: string): UserLesson {
    return this.userLessons.find(elem => elem._id === _id);
  }

  /**
   *
   */
  getUserLessonFromTeachingId(teaching_ref: string): UserLesson {
    return this.userLessons.find(elem => elem.teaching_ref === teaching_ref);
  }

  /**
   *
   */
  getUserQuizFromUserTeachingId(_id: string): UserQuiz {
    return this.userQuiz.find(elem => elem._id === _id);
  }

  /**
   *
   */
  getUserQuizFromTeachingId(teaching_ref: string): UserQuiz {
    return this.userQuiz.find(elem => elem.teaching_ref === teaching_ref);
  }

  /**
   *
   */
  getUserCourseFromCourseId(_id: string): UserCourse {
    return this.userCourses.find(elem => elem.course._id === _id);
  }

  /**
   *
   */
  getUserDegreeFromDegreeId(_id: string): UserDegree {
    return this.userDegrees.find(elem => elem.degree._id === _id);
  }

  /**
   *
   */
  getUserSourceFromSourceId(_id: string): UserSource {
    return this.userSources.find(elem => elem.source._id === _id);
  }

}
