import {ObjectUtil} from "./object.util";
import {StringUtil} from "./string.util";
import {ArrayUtil} from "./array.util";

export class DateUtil {

  /**
   * Fonction qui génère un objet date à partir d'une string saisie en paramètre.
   *
   * @param date - la date au format string. Ex: "11/22/79" ou "22/11/1979" ou "22/11/1979 10:45:38"
   *               ATTENTION : La partie heures, minutes, seconde doit être séparée de la partie date soit avec :
   *                   - le caratère "separator"
   *                   - le caratère espace
   *                   - le caractère ":"
   * @param separator - le séparateur entre les jour, mois et années de la date. Ex : "/"
   * @param order - l'ordre des elements composant la date contient soit :
   *                   - 6 éléments (valeur par défaut : "day", "month", "year", "hour", "minute", "second").
   *                     Ex : "month", "day", "year", "hour", "minute", "second"
   *                   - 3 éléments (valeur par défaut : "day", "month", "year").
   *                     Ex : "month", "day", "year"
   * @returns
   */
  static stringToDate(date: string, separator: string = "/", ...order: string[]): Date {
    if (order.length === 6) {
      order = (
        (order.indexOf("day") !== -1) &&
        (order.indexOf("month") !== -1) &&
        (order.indexOf("year") !== -1) &&
        (order.indexOf("hour") !== -1) &&
        (order.indexOf("minute") !== -1) &&
        (order.indexOf("second") !== -1)
      )
        ? order
        : ["day", "month", "year", "hour", "minute", "second"];
      date = StringUtil.replaceAll(date, [   // Traitement du séparateur des heures, minutes et secondes
          {search: ':', replacement: separator},
          {search: ' ', replacement: separator}
        ],
        {safeMode: true});
      let dateOrder = date.split(separator);
      return new Date(
        parseInt(dateOrder[order.indexOf("year")]),
        parseInt(dateOrder[order.indexOf("month")]) - 1,
        parseInt(dateOrder[order.indexOf("day")]),
        parseInt(dateOrder[order.indexOf("hour")]),
        parseInt(dateOrder[order.indexOf("minute")]),
        parseInt(dateOrder[order.indexOf("second")])
      );
    } else {
      order = (
        (order.length === 3) &&
        (order.indexOf("day") !== -1) &&
        (order.indexOf("month") !== -1) &&
        (order.indexOf("year") !== -1)
      )
        ? order
        : ["day", "month", "year"];
      let dateOrder = date.split(separator);
      return new Date(
        parseInt(dateOrder[order.indexOf("year")]),
        parseInt(dateOrder[order.indexOf("month")]) - 1,
        parseInt(dateOrder[order.indexOf("day")])
      );
    }
  }

  /**
   * Fonction qui génère un objet date à partir du jour, du mois et de l'année
   * saisis en paramètre.
   * Retourne undefined si aucune date n'a pu être générée à partir des paramètres
   * fournis.
   *
   * @param day
   * @param month
   * @param year
   * @returns
   */
  static calendarToDate(day: number | string, month: number | string, year: number | string): Date {
    if (typeof day === 'string') {
      day = parseInt(day);
    }
    if (typeof month === 'string') {
      month = parseInt(month);
    }
    if (typeof year === 'string') {
      year = parseInt(year);
    }

    if (ObjectUtil.isInt(day) && ObjectUtil.isInt(month) && ObjectUtil.isInt(year)) {
      let date = new Date(year, month - 1, day);
      if (!ObjectUtil.isDate(date)) {
        return undefined;
      } else {
        return date;
      }
    }
  }

  /**
   * Fonction qui teste si la date entrée en paramètre est une date valide
   * (existante dans le calendrier).
   * Prend en compte les années bissextile:
   *      isDate(29,02,2008) => true
   *      isDate(29,02,2017) => false
   *
   * @param day
   * @param month
   * @param year
   * @returns
   */
  static isValidDate(day: number | string, month: number | string, year: number | string): boolean {
    let date = DateUtil.calendarToDate(day, month, year);
    if (!ObjectUtil.isDate(date)) {
      return false;
    }
    let convertedDate = "" + date.getFullYear() + (date.getMonth() + 1) + date.getDate();
    day = (typeof day === 'string') ? parseInt(day) : day;
    month = (typeof month === 'string') ? parseInt(month) : month;
    year = (typeof year === 'string') ? parseInt(year) : year;
    let givenDate = "" + year + month + day;
    return (givenDate == convertedDate);
  }

  /**
   * Fonction qui calcule l'age à partir d'une date.
   * Retourne undefined si l'age n'a pas pu être déterminé.
   *
   * @param birthday
   * @returns
   */
  static calculateAgeFromDate(birthday: Date): number {
    if (!ObjectUtil.isDate(birthday)) {
      return undefined;
    }
    let ageDifMs = Date.now() - birthday.getTime();
    let ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  /**
   * Fonction qui calcule l'age à partir d'une date au format jour, mois, année.
   * Retourne undefined si l'age n'a pas pu être déterminé.
   *
   * @param day
   * @param month
   * @param year
   * @returns
   */
  // static calculateAge(day: number | string, month: number | string, year: number | string) {
  static calculateAge(day: number, month: number, year: number) {
    // return DateUtil.calculateAgeFromDate(new Date(`${month} ${day} ${year}`)); // ATTENTION IL FAUT RAJOUTER 1 MOIS CAR IL SONT INDEXER A PARTIR DE 1 MOIS
    return DateUtil.calculateAgeFromDate(new Date(year, month, day));
  }

  /**
   * Fonction qui retourne l'offset (décalage) en minutes entre le temps utc et le fuseau
   * horaire du navigateur.
   *
   * @returns
   */
  static getCurrentTimezoneOffset(): number {
    return new Date().getTimezoneOffset();
  }

  /**
   * Fonction qui retourne le fuseau horaire le plus proche du currentOffset passé en paramètre.
   * Retourne 'undefined' en cas de probléme.
   *
   * @param currentOffset L'offset du fuseau horaire du navigateur par rapport au temps UTC.
   * @param timezoneList La liste des fuseaux horaires. Format: [{code, name, options: {gmt, offset}}, ...]
   */
  static getCloserTimezoneFromList(currentOffset: number, timezoneList: any[]): any {
    if (ObjectUtil.isArray(timezoneList)) {
      const closestOffset = ArrayUtil.closestValue(
        currentOffset,
        timezoneList.map(elem => elem.options.offset));
      if (closestOffset === undefined) {
        return (timezoneList.length > 0) ? timezoneList[0] : undefined;
      } else {
        return timezoneList.find(elem => closestOffset === elem.options.offset);
      }
    } else {
      return undefined;
    }
  }

  /**
   * Fonction qui convertit un nombre de secondes en un objet au fomat suivant :
   *  {
   *    hour: <nb of hours (supéieur ou égale à 0 sans borne max)>,
   *    minute: <nb of minutes (compris entre 0 et 59)>,
   *    second : <nb of seconds (compris entre 0 et 59)>
   *  }
   * Si "showDays" est égale à true ajoute le nombre de jours :
   *  {
   *    day: <nb of days (supéieur ou égale à 0 sans borne max)>,
   *    ...
   *  }
   * Si "prettyPrint" est égale à true ajoute une propriété pour un affichage formaté :
   *  {
   *    prettyPrint: <string>,
   *    ...
   *  }
   * Retourne undefined si le paramètre est invalide.
   *
   * @param {int} nbSecond
   * @param {boolean} showDays Indique que l'objet de retour doit comporter le nombre de jour (valeur par défaut false)
   * @param {*} options Les options spécifiques
   */
  static secondsToHms(
    nbSecond: number, // Le nombre de secondes
    {
      showDays = false,     // Doit on ajouter une propriété "day" au résultat contenant le nombre de jours (boolean, défaut: false)
      prettyPrint = false,  // Doit on ajouter une propriété "prettyPrint" au résultat contenant une string pour un affichage formaté (boolean, défaut: false)
      prettyPrintZero = "",   // Le texte à afficher si nbSecond === 0 (string, défaut: "0 second")
      separator = ", ",    // Le séparateur entre les éléments de la propriété "prettyPrint" (string, défaut: ", ")
      second = "second",      // Le label pour les secondes au singulier pour la propriété "prettyPrint" (string, défaut: "second")
      seconds = "seconds",    // Le label pour les secondes au pluriel pour la propriété "prettyPrint" (string, défaut: "seconds")
      minute = "minute",      // Le label pour les minutes au singulier pour la propriété "prettyPrint" (string, défaut: "minute")
      minutes = "minutes",    // Le label pour les minutes au pluriel pour la propriété "prettyPrint" (string, défaut: "minutes")
      hour = "hour",          // Le label pour les heures au singulier pour la propriété "prettyPrint" (string, défaut: "hour")
      hours = "hours",        // Le label pour les heures au pluriel pour la propriété "prettyPrint" (string, défaut: "hours")
      day = "day",            // Le label pour les jours au singulier pour la propriété "prettyPrint" (string, défaut: "day")
      days = "days",          // Le label pour les jours au pluriel pour la propriété "prettyPrint" (string, défaut: "days")
      spaceBeforeLabel = true,    // Doit on ajouter un espace avant les labels  pour la propriété "prettyPrint" (boolean, défaut: true)
    } = {}): any {

    // Les valeurs par défaut
    showDays = (ObjectUtil.isBoolean(showDays)) ? showDays : false;
    prettyPrint = (ObjectUtil.isBoolean(prettyPrint)) ? prettyPrint : false;
    spaceBeforeLabel = (ObjectUtil.isBoolean(spaceBeforeLabel)) ? spaceBeforeLabel : true;

    separator = (ObjectUtil.isString(separator)) ? separator : ", ";
    second = (ObjectUtil.isString(second)) ? second : "second";
    seconds = (ObjectUtil.isString(seconds)) ? seconds : "seconds";
    minute = (ObjectUtil.isString(minute)) ? minute : "minute";
    minutes = (ObjectUtil.isString(minutes)) ? minutes : "minutes";
    hour = (ObjectUtil.isString(hour)) ? hour : "hour";
    hours = (ObjectUtil.isString(hours)) ? hours : "hours";
    day = (ObjectUtil.isString(day)) ? day : "day";
    days = (ObjectUtil.isString(days)) ? days : "days";
    prettyPrintZero = (ObjectUtil.isString(prettyPrintZero)) ? prettyPrintZero : `0 ${second}`;

    if (spaceBeforeLabel) {
      second = ` ${second}`;
      seconds = ` ${seconds}`;
      minute = ` ${minute}`;
      minutes = ` ${minutes}`;
      hour = ` ${hour}`;
      hours = ` ${hours}`;
      day = ` ${day}`;
      days = ` ${days}`;
    }

    nbSecond = Number(nbSecond);
    if (isNaN(nbSecond)) {
      return undefined;
    } else {
      // Calcule du temps
      let res;
      let h, d;
      let m = Math.floor(nbSecond % 3600 / 60);
      let s = Math.floor(nbSecond % 3600 % 60);
      if (showDays) {
        d = Math.floor(nbSecond / 3600 / 24);
        h = Math.floor(nbSecond / 3600 % 24);
        res = {
          day: d,
          hour: h,
          minute: m,
          second: s
        };
      } else {
        h = Math.floor(nbSecond / 3600);
        res = {
          hour: h,
          minute: m,
          second: s
        };
      }
      // Formattage pretty print
      if (prettyPrint) {
        let dDisplay, hDisplay, mDisplay, sDisplay;
        if (showDays) {
          dDisplay = d > 0 ? d + (d == 1 ? day : days) + (h > 0 || m > 0 || s > 0 ? separator : "") : "";
          hDisplay = h > 0 ? h + (h == 1 ? hour : hours) + (m > 0 || s > 0 ? separator : "") : "";
          mDisplay = m > 0 ? m + (m == 1 ? minute : minutes) + (s > 0 ? separator : "") : "";
          sDisplay = s > 0 ? s + (s == 1 ? second : seconds) : "";
          res.prettyPrint = dDisplay + hDisplay + mDisplay + sDisplay;
        } else {
          hDisplay = h > 0 ? h + (h == 1 ? hour : hours) + (m > 0 || s > 0 ? separator : "") : "";
          mDisplay = m > 0 ? m + (m == 1 ? minute : minutes) + (s > 0 ? separator : "") : "";
          sDisplay = s > 0 ? s + (s == 1 ? second : seconds) : "";
          res.prettyPrint = hDisplay + mDisplay + sDisplay;
        }
        if (StringUtil.isUndefinedOrEmpty(res.prettyPrint)) {
          res.prettyPrint = prettyPrintZero
        }
      }
      /*
      let hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      let mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
      let sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      // console.log(hDisplay + mDisplay + sDisplay);
      */
      return res;
    }
  }

  /**
   * Fonction qui compte le nombre de jour entre deux dates.
   * Le resulat sera négatif si date1 > date2.
   * Cette fonction prend en compte les horaires d'été (DST).
   * "date1" et "date2" sont deux objets javascript de type Date, ex:
   *      new Date()
   *      new Date("2017-07-25")
   *
   * @param date1
   * @param date2
   * @returns {number}
   */
  static dateDiffInDays(date1: Date, date2: Date): number {
    let _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    let utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    let utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };


  /**
   * Fonction qui determine le format des dates en fonction de la locale.
   * Format de retour :
   *  - pour la locale en-US : MM{separator}dd{separator}yyyy
   *  - pour les autres locales : dd{separator}MM{separator}yyyy
   *
   * Cette fonction est utile pour être utilisée avec les pipes angular.
   *
   * @param locale
   * @param separator
   */
  /*
  static localizeDateFormat(locale: string, separator: string = "-") {
    let defaultFormat = `dd${separator}MM${separator}yyyy`;
    const _locale = new (<any>Intl).Locale(locale);
    if((_locale.language === 'en') && (_locale.region === 'US')) {
      return `MM${separator}dd${separator}yyyy`;
    } else {
      return defaultFormat;
    }
  }
 */
}
