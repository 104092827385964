/*
import {ELearningCatalogueItemType} from './elearning/ELearningCatalogueItemType';
import {PublishingCatalogueItemType} from './publishing/PublishingCatalogueItemType';

// Cette methode fusionne tous les enum types dans une même classe.
// Le problème est que cela génère des erreurs de typage car on mix les trois classes dans le code.
export const CatalogueItemType = {
  ...ELearningCatalogueItemType,
  ...PublishingCatalogueItemType
};
*/
/*
export const CatalogueItemType = {
  ELearningCatalogueItemType,
  PublishingCatalogueItemType
};
export type CatalogueItemType = typeof CatalogueItemType;
*/

export enum CatalogueItemType {
  // Common
  SOURCE = 'EL-0',  // Les sources ne sont pas réllement des produits du catalogue !
  // Atel
  LESSON = 'EL-1',
  QUIZ = 'EL-2',
  COURSE = 'EL-3',
  DEGREE = 'EL-4',
  // Atp
  PUBLICATION = 'PUB-1'
}
