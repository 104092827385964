import {Course} from './Course';
import {atelProperties} from '../../../ng-properties-application/atel.properties';
import {applicationProperties} from '../../../ng-properties-application/application.properties';
import {Theme} from "./Theme";
import {LearningContainer} from "./LearningContainer";
import {LearningElementsUtil} from "../../utils/learningElements.util";
import {Lesson} from "./Lesson";
import {IElearningProduct} from "../../../../data/catalogueProduct/elearning/IElearningProduct";
import {ObjectUtil} from "../../../ng-helpers-util/object.util";

/**
 *
 */
export class Degree extends LearningContainer implements IElearningProduct {

  public themes: Theme[] = [];
  public courses: DegreeCourse[] = [];

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    super(_data);
    $.extend(this, _data);
    if (_data && _data.courses && ObjectUtil.isArray(_data.courses)) {
      // this.courses = _data.courses;
      this.courses = _data.courses
        .map(elem => new DegreeCourse(elem));
      // .filter(elem => elem !== undefined);
    } else {
      this.courses = [];
    }
  }

  /**
   * Fonction qui met à jour les urls des images du degree avec les paths du système.
   */
  public updatePictureUrls() {
    this.picture = this.formatDegreeForDisplay().picture;
  }

  /**
   * Fonction qui retounre un degree formaté pour l'affichage :
   *    - mise à jour des urls des images
   */
  public formatDegreeForDisplay(): Degree {
    let newDegree: Degree = JSON.parse(JSON.stringify(this));
    if (!newDegree.picture) {
      newDegree.picture = atelProperties.URL_DATA_DEGREE() + applicationProperties.FOLDER_PICTURES + atelProperties.DEFAULT_FILE_PICTURE_DEGREE();
    } else {
      newDegree.picture = atelProperties.URL_DATA_DEGREE(newDegree._id) + applicationProperties.FOLDER_PICTURES + newDegree.picture;
    }
    return newDegree;
  }


  /**
   * Fonction qui récupère le min et le max des difficultyLevels des sections du cours.
   *
   * @override
   */
  public getDegreeDifficultyLevel(): { min: number, max: number } {
    let difficultyLevels: Array<number> = [];
    if (this.details && this.details.difficultyLevels &&
      (this.details.difficultyLevels.length > 0)) {
      difficultyLevels = this.details.difficultyLevels;
    } else {
      if (this.isPopulated()) {
        difficultyLevels = this.courses.reduce((accumulator, currentValue) => {
          if (
            currentValue.active &&
            ObjectUtil.isObject(currentValue._ref) &&
            (currentValue._ref as Course).isValid()) {
            accumulator = accumulator.concat(
              [(currentValue._ref as Course).getCourseDifficultyLevel().min],
              [(currentValue._ref as Course).getCourseDifficultyLevel().max])
          }
          return accumulator;
        }, []);
      } else {
        return undefined;
      }
    }
    let min: number = Math.min(...difficultyLevels);    // Si le tableau est vide, retourne "Infinity"
    let max: number = Math.max(...difficultyLevels);    // Si le tableau est vide, retourne "Infinity"
    return {
      min: isFinite(min) ? min : undefined,
      max: isFinite(max) ? max : undefined
    }
  }

  /**
   * Fonction qui affiche le difficultyLevel d'un cours
   */
  public displayDegreeDifficultyLevel(shortDisplay?: boolean): string {
    let minMax: { min: number, max: number } = this.getDegreeDifficultyLevel();
    return LearningElementsUtil.displayDifficultyLevel(minMax, shortDisplay);
  }

  /**
   * Fonction qui retourne le nombre de cours
   */
  public getNbCourses(): number {
    if (this.isPopulated()) {
      return this.courses.filter(course => (
        (ObjectUtil.isObject(course._ref))
          ? course.active && (course._ref as Course).isValid()
          : course.active
      )).length;
    }
    return 0;
  }

  /**
   * Fonction qui retourne le nombre de teachings
   */
  public getNbTeachings(): number {
    let nbTeachings: number = 0;
    if (this.isPopulated()) {
      this.courses.forEach(course => {
        if (
          course.active &&
          ObjectUtil.isObject(course._ref) &&
          (course._ref as Course).isValid()) {
          nbTeachings += (course._ref as Course).getNbTeachings();
        }
      });
    }
    return nbTeachings;
  }

  /**
   * Fonction qui retourne la durée du degree (en secondes)
   *
   * @override
   */
  public getDuration(): number {
    let duration: number = 0;
    if (this.isPopulated()) {
      this.courses.forEach(course => {
        if (
          course.active &&
          ObjectUtil.isObject(course._ref) &&
          (course._ref as Course).isValid()) {
          duration += (course._ref as Course).getDuration();
        }
      });
    }
    return duration;
  }

  /**
   * Fonction qui retourne la ou les langues du degree
   *
   * @override
   */
  public getLanguages(): Array<string> {
    let languages: string[] = [];
    if (this.isPopulated()) {
      this.courses.forEach(course => {
        if (
          course.active &&
          ObjectUtil.isObject(course._ref) &&
          (course._ref as Course).isValid()) {
          languages = languages.concat((course._ref as Course).getLanguages());
        }
      });
    }
    return [... new Set(languages)];
  }

  /**
   * Fonction qui retourne la ou les zones du degree
   *
   * @override
   */
  public getZones(): Array<string> {
    let zones: string[] = [];
    if (this.isPopulated()) {
      this.courses.forEach(course => {
        if (
          course.active &&
          ObjectUtil.isObject(course._ref) &&
          (course._ref as Course).isValid()) {
          zones = zones.concat((course._ref as Course).getZones());
        }
      });
    }
    return [... new Set(zones)];
  }

  /**
   * Fonction qui retourne la ou les auteurs du degree
   *
   * @override
   */
  public getAuthors(): Array<string> {
    let authors: string[] = [];
    if (this.courses) {
      this.courses.forEach(course => {
        if (
          course.active &&
          ObjectUtil.isObject(course._ref) &&
          (course._ref as Course).isValid()) {
          authors = authors.concat((course._ref as Course).getAuthors());
        }
      });
    }
    return [... new Set(authors)];
  }

  /**
   * Retourne true si le degree est peuplé avec des cours qui sont eux même peuplés avec des lesson.
   */
  isPopulated(): boolean {
    return this.courses
      .reduce((accumulator, currentValue) => { // Suppression des cours non actifs
        return (currentValue.active) ? accumulator.concat([currentValue._ref]) : accumulator;
      }, [])
      .every(courseRef => ObjectUtil.isFunction(courseRef.isPopulated) && courseRef.isPopulated());
  }

  /**
   * Peuple le degree avec les cours et les lessons passées en paramètre.
   */
  populate(lessons: Lesson[], courses: Course[]): void {
    if (ObjectUtil.isArray(lessons) && ObjectUtil.isArray(courses)) {
      this.courses
        .forEach(courseDegree => {
          if (courseDegree.active && ObjectUtil.isString(courseDegree._ref)) { // Non prise en compte des cours non actifs
            const course = courses.find(elem => courseDegree._ref === elem._id);
            if (course) {
              course.populate(lessons);
              courseDegree._ref = course;
            }
          }
        }, []);
    }
  }

}

/**
 *
 */
export class DegreeCourse {
  public _id: string;
  public _ref: string | Course;
  public active: boolean;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
    if (_data && ObjectUtil.isObject(_data._ref)) {
      this._ref = new Course(_data._ref)
    }
  }

  isValid(): boolean {
    return (
      this.active &&
      ObjectUtil.isObject(this._ref) && new Course(this._ref).isValid()
    );
  }

}
