import {ObjectUtil} from "../../../ng-helpers-util/object.util";
import {UserPublicationDb} from "../../../ng-models-atp/models/db/userAtp/UserPublicationDb";

export class UserAtp {

    _id?: string;
    // roles?: string[];
    // statistics?: UserAtpStatistics;
    publications?: UserPublicationDb[];
    settings?: UserAtpSettings;

    /**
     * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
     * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
     * ainsi que les fonctions définies dans la classe.
     *
     * @param _data
     */
    constructor(
      // userDependenciesService: UserDependenciesService,
      _data: any = undefined) {
      $.extend(this, _data);
      if (_data && _data.publications && ObjectUtil.isArray(_data.publications)) {
        // this.publications = _data.publications;
        // this.publications = LibraryUtil.buildPublicationsList(userDependenciesService, _data.publications);
        this.publications = _data.publications
          .map(elem => new UserPublicationDb(elem))
          .filter(elem => elem !== undefined);
      } else {
        this.publications = [];
      }
    }

    /**
     * Fonction qui intialise une nouvel utilisateur Atp.
     *
     * @param reset - Si false, n'initialise que les propriétés undefined.
     */
    public init(reset: boolean = true): UserAtp {
        this.initUserAtp();
        /*
        if (reset || !this.statistics) {
            this.initUserAtpStatistics();
        }
        */
        return this;
    }

    public initUserAtp(): void {
        // this.roles = [];
        this.publications = [];
    }

    /*
    public initUserAtpStatistics(): void {
        this.statistics = new UserAtpStatistics();
        this.statistics.totalTime = 0;
        this.statistics.totalTimeEffective = 0;
    }
    */
}

class UserAtpSettings {
    lastLibraryTab: number;
}

/*
class UserAtpStatistics {
    totalTime: number;
    totalTimeEffective: number;
}
*/
