import {ObjectUtil} from "./object.util";
import * as CryptoJS from "crypto-js";

//import * as removeAccents from 'remove-accents';

// Ryad: je ne sais pas pourquoi Idriss a desactivé la suppression des accents (et n'utilise plus removeAccents) dans la recherche sur une str.
// Grace à la recherche sans accent les utilisateurs trouvent société quand il tape societe dans le champs recherche de la bibliotheque.
// C'est dans 90% des recherches utilisateurs le comportement voulu.
// Sans la suppression des accents, dans la bibliothèque, la recherche sans accent ne matche pas les accents
// (ie qd on recheche societe dans un champs de recherche on ne trouve pas société).
// Ce comportement n'est pas bon car dans le reste du système (champs de recherche global dans le header du site) la recherche sans accent matche les accents
// (cf http://serveur2:8181/projets/aip-atel/wiki/tasks/76).
function removeAccentsDiacritics(str) {
  // Technique classique de suppression des accents (je suppose que removeAccents qu'a desactivé Idriss fait pareil)
  // Cf https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}


export class StringUtil {

  /**
   * Fonction qui remplace un caractère d'indice spacifique dans une chaine.
   *
   * @param string
   * @param index
   * @param replace
   * @returns
   */
  public static replaceAt(string, index, replace) {
    return string.substring(0, index) + replace + string.substring(index + 1);
  };

  /**
   * Fonction qui remplace uns liste d'occurrences d'une sous-chaîne spécifique dans une chaine.
   * La fonction retourne la chaîne d'origine en cas de problème.
   *
   * @param str
   * @param occurrences - Tableau des occurrences à remplacer.
   *                      Format : [{
   *                          search: (string), // pattern a rechercher dans la chaîne "str"
   *                          replacement: (string) // chaîne de remplacement du pattern
   *                      }, ...]
   * @param options - Options de la fonction.
   *                  Format: {
   *                      safeMode: (boolean - défaut: true), // méthode plus lente mais gère les caractères spéciaux
   *                      throwExceptionOnError: (boolean - défaut: true - si true prioritaire sur "returnOriginalStringOnError"), // leve une exception en cas d'erreur de la fonction (ex: quand "str" est undefined)
   *                      returnOriginalStringOnError: (boolean - si true prioritaire sur "returnUndefinedOnError"), // retourne la chaîne originale "str" en cas d'erreur de la fonction (ex: quand "str" est undefined)
   *                      returnUndefinedOnError: (boolean - si true prioritaire sur "returnOnError"), // retourne undefined en cas d'erreur de la fonction (ex: quand "str" est undefined)
   *                      returnOnError: (boolean), // valeur à retourner en cas d'erreur de la fonction (ex: quand "str" est undefined)
   *                  }
   * @returns {*}
   */
  public static replaceAll(str: string, occurrences: { search: string, replacement: string }[], options: any): string {
    options = typeof options !== 'undefined' ? options : {
      // safeMode: true,
      throwExceptionOnError: true
    };
    let safeMode = typeof options.safeMode !== 'undefined' ? options.safeMode : true;
    // let returnOnError = typeof options.returnOnError !== 'undefined' ? options.returnOnError : undefined;
    // let returnOriginalStringOnError = typeof options.returnOriginalStringOnError !== 'undefined' ? options.returnOriginalStringOnError : undefined;
    // let throwExceptionOnError = typeof options.throwExceptionOnError !== 'undefined' ? options.throwExceptionOnError : true;

    // console.log("safeMode:", safeMode);
    // console.log("returnOnError:", options.returnOnError);
    // console.log("returnOriginalStringOnError:", options.returnOriginalStringOnError);
    // console.log("returnUndefinedOnError:", options.returnUndefinedOnError);
    // console.log("throwExceptionOnError:", options.throwExceptionOnError);

    let processString = (str, search, replacement) => {
      return (safeMode) ?
        //str.replace(new RegExp(escapeCharactersInRegExp(find), 'g'), replace) :
        str.split(search).join(replacement) :
        str.replace(new RegExp(search, 'g'), replacement);
    };

    try {
      if (ObjectUtil.isArray(occurrences)) {
        occurrences.forEach(elem => {
          if ((elem.search !== undefined) && !ObjectUtil.isString(elem.search)) {
            elem.search = elem.search.toString();
          }
          if ((elem.replacement !== undefined) && !ObjectUtil.isString(elem.replacement)) {
            elem.replacement = elem.replacement.toString();
          }
          if (elem &&
            ObjectUtil.isString(elem.search) &&
            !StringUtil.isUndefinedOrEmpty(elem.search) &&
            ObjectUtil.isString(elem.replacement)) {
            str = processString(str, elem.search, elem.replacement);
          } else {
            // On ne fait rien
          }
        });
        return str;
      } else {
        throw new Error("Invalid parameters");
      }
    } catch (err) {
      if (options.throwExceptionOnError === true) {
        throw err;
      } else if (options.returnOriginalStringOnError === true) {
        return str;
      } else if (options.returnUndefinedOnError === true) {
        return undefined;
      } else if (options.returnOnError !== undefined) {
        return options.returnOnError;
      } else {
        throw err;
      }
    }
  }

  /**
   * Fonction qui retourne une chaine de caractères de taille "size" composée de caractère de
   * l'alphabet "alphabet".
   *
   * @param size
   * @param alphabet - defaut: ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789
   * @returns
   */
  public static randomString(size: number, alphabet?: string) {
    let text = "";
    let possible = alphabet || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < size; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };

  /**
   * Fonction qui retourne true si l'email entré en paramètre est valide.
   *
   * @returns
   */
  public static isValidEmail(email): boolean {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  /**
   * Fonction qui retourne true si la chaine de caractères répond aux exigences requises, retourne false sinon.
   *
   * @param {string} str - La chaîne de caractères à tester
   * @param {number} minLength - La taille minimale du mot de passe (valeur par défaut : 8, si -1 pas de contrainte)
   * @param {number} minUpper - Le nombre mimimum de caractères majscules contenus dans le mot de passe (valeur par défaut : 1, si -1 pas de contrainte, si 0 pas de caractère autorisé)
   * @param {number} minLower - Le nombre mimimum de caractères minuscules contenus dans le mot de passe (valeur par défaut : 1, si -1 pas de contrainte, si 0 pas de caractère autorisé)
   * @param {number} minNums - Le nombre mimimum de caractères numériques contenus dans le mot de passe (valeur par défaut : 1, si -1 pas de contrainte, si 0 pas de caractère autorisé)
   * @param {number} minSpecials - Le nombre mimimum de caractères spéciaux contenus dans le mot de passe (valeur par défaut : 1, si -1 pas de contrainte, si 0 pas de caractère autorisé)
   */
  public static isValidString(
    str: string,
    minLength: number = 8,
    minUpper: number = 1,
    minLower: number = 1,
    minNums: number = 1,
    minSpecials: number = 1): boolean {

    let anUpperCase = /[A-Z]/;
    let aLowerCase = /[a-z]/;
    let aNumber = /[0-9]/;
    let aSpecial = /[?|!|@|#|$|%|^|&|*|(|)|\\|\-|+|/|_|.|,|:|;|=|§]/;

    /*
    let obj = {};
    obj.result = true;
    */

    if ((minLength !== -1) && (!ObjectUtil.isString(str) || (str.length < minLength))) {
      /*
      obj.result=false;
      obj.error="Not long enough!"
      return obj;
      */
      return false;
    }

    let numUpper = 0;
    let numLower = 0;
    let numNums = 0;
    let numSpecials = 0;
    for (let i = 0; i < str.length; i++) {
      if (anUpperCase.test(str[i]))
        numUpper++;
      else if (aLowerCase.test(str[i]))
        numLower++;
      else if (aNumber.test(str[i]))
        numNums++;
      else if (aSpecial.test(str[i]))
        numSpecials++;
    }

    if (((minUpper !== -1) && (numUpper < minUpper)) || ((minUpper === 0) && (numUpper > 0)) ||
      ((minLower !== -1) && (numLower < minLower)) || ((minLower === 0) && (numLower > 0)) ||
      ((minNums !== -1) && (numNums < minNums)) || ((minNums === 0) && (numNums > 0)) ||
      ((minSpecials !== -1) && (numSpecials < minSpecials)) || ((minSpecials === 0) && (numSpecials > 0))) {
      /*
      obj.result=false;
      obj.error="Wrong Format!";
      return obj;
      */
      return false;
    }
    // return obj;
    return true;
  };

  /**
   * Fonction qui retourne true si la chaine passée en paramètre est null, undefined ou vide.
   * Retourne false sinon.
   *
   * @param str
   * @returns
   */
  public static isUndefinedOrEmpty(str: string): boolean {
    return (!str) || (str.length === 0)
  };

  /**
   * Fonction qui met en majscule les premières lettre des mots d'une chaine de caractères.
   * Retourne undefined si la chaine saisie en paramètre est invalide
   *
   * @param str
   */
  public static toTitleCase = function (str: string): string {
    if (ObjectUtil.isString(str)) {
      return str.replace(
        /\w\S*!/g,
        function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    } else {
      return undefined;
    }
  };

  /**
   * Fonction qui met une majuscule la première lettre d'une string.
   *
    * @param str
   */
  public static capitalizeFirstLetter = function (str: string): string {
    return (ObjectUtil.isString(str) && !StringUtil.isUndefinedOrEmpty(str))
      ? str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase()
      : str;
  };

  /**
   * Fonction qui transforme un objet json en texte HTML indenté.
   * Retourne undefined si la chaine saisie en paramètre est invalide.
   *
   * @param obj
   */
  public static jsonToHtml = function (obj: string): string {
    // if (ObjectUtil.isObject(str)) {
    return JSON
      .stringify(obj, undefined, '&emsp;')
      .replace(/(?:\r\n|\r|\n)/g, '<br>');
    // } else {
    // return undefined;
    // }
  };

  /**
   * Fonction qui transforme une string en boolean.
   *
   * @param value
   */
  public static stringToBoolean = function (value: any): boolean {
    return value != null && `${value}` !== 'false';
  };

  /**
   * Fonction qui format une expression à rechercher dans la base de données.
   *  => suppression des caractères spéciaux qui pourraient hacker la regExp,
   *  => retourne les expressions sous forme de tableau en retirant les chaines vides.
   *
   *  Exemple:
   *      "mon chien est gentil" => ["mon", "chien", "est", "gentil"]
   *
   * @param searchExpression - string
   * @returns {Array}
   */
  public static formatSearchTerms = function (searchExpression) {
    var search = [];
    if ((searchExpression !== undefined) && (searchExpression.length > 0)) {
      // On nettoie la chaîne
      searchExpression = searchExpression.trim();

      // On supprime les caracères spéciaux
      searchExpression = searchExpression.replace(/[\"\^\[\]\\|*%?!;{}/().,+]+/g, ' ');

      // On transforme en tableau separant termes de recherches envoyés sous forme de string
      search = searchExpression.split(/[ \s]+/);

      // Suppression des chaines vides
      search = search.filter(function (searchTerm) {
        return (searchTerm.length !== 0);
      });
    }
    return search;
  };

  /**
   * Fonction qui recherche tous les termes dans une string.
   *
   * La regExp utilisée est du type : new RegExp('(?=.*?Toto)(?=.*?Titi).*', 'i')
   */
  public static searchAllTerms = function (
    str: string, keywords: string,
    {caseSensitive = false, diacriticSensitive = false} = {}): boolean {
    let arrKeywords = StringUtil.formatSearchTerms(keywords);
    if (StringUtil.isUndefinedOrEmpty(str) || (arrKeywords.length === 0)) {
      return false;
    } else {
      if (!diacriticSensitive) {
        //str = removeAccents.remove(str);
        //arrKeywords = arrKeywords.map(elem => removeAccents.remove(elem));
        str = removeAccentsDiacritics(str);
        arrKeywords = arrKeywords.map(elem => removeAccentsDiacritics(elem));
      }
      const options = (!caseSensitive) ? 'i' : '';
      const regExp = new RegExp(
        arrKeywords.reduce((accumulator, currentValue) => {
          return accumulator += `(?=.*?${currentValue})`
        }, '') + '.*',
        options
      );
      return regExp.test(str);
    }
  };

  /**
   * Fonction de chiffrage des données.
   *
   * @param message
   * @param secretpassphrase
   * @returns
   */
  public static e(message: string, secretpassphrase: string): string {
    return CryptoJS.AES.encrypt(message, secretpassphrase).toString();
  };

  /**
   * Fonction de déchiffrage des données.
   *
   * @param encrypted
   * @param secretpassphrase
   * @returns
   */
  public static d(encrypted: string, secretpassphrase: string): string {
    return CryptoJS.AES.decrypt(encrypted, secretpassphrase).toString(CryptoJS.enc.Utf8);
  };
}

