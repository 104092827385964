import { Injectable } from '@angular/core';
import {IconUtils} from '../utils/icon.utils';

/**
 * Service permettant de demander l'affichage de notification sur l'écran d'accueil.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationBellService {

  private _notifications: UserNotification[] = [];
  public set notifications(value: UserNotification[]) {
    this._notifications = value;
  }
  public get notifications(): UserNotification[] {
    return this._notifications;
  }
}

/**
 *
 */
export class UserNotification {

  _id: string;
  type: string;
  date: Date;
  application: string;
  data: any;
  message: string;
  notificationBell: boolean;
  displayBellBadge?: boolean;  // Uniquement pour l'affichage

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   */
  constructor(_data?: any) {
    $.extend(this, _data);
  }

  /**
   * Fonction qui retourne le message a afficher.
   */
  getMessage(): string {
    // Faire un switch en fonction du type pour interpréter la propriété "message"
    return this.message;
  }

  /**
   * Fonction qui retourne l'icone a afficher.
   */
  getIcon(): string {
    return IconUtils.getIcon(this.application);
  }

}

