import i18next from "i18next";

/**
 * Les informations pour les UserTeachingDb, UserCourse et UserDegree.
 */
export class UserProductInfoElearning {
  nbConn: number;
  firstConn: Date;
  lastConn: Date;
  totalTime: number;
  totalTimeEffective: number;
  success: string;
  status: string;
  progress: number;
  scoreTemporary: number;
  scoreAuto: number;
  score: number;
  points: number;
  nbAttempt: UserProductNbAttemptElearning;
  bookmarks: UserProductInfoBookmarksElearning[];
  notes: string;
  data: any;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
    // Gestion des dates
    this.firstConn = (_data.firstConn) ? new Date(_data.firstConn) : undefined;
    this.lastConn = (_data.lastConn) ? new Date(_data.lastConn) : undefined;
  }

  /**
   *
   * @param fallbackZero Si true et si pas de progression affiche une progression à 0. Retourne undefined sinon.
   * @param base
   * @param i18nKey
   */
  public displayProgression(
      i18nKey: string = 'page.catalogueElearning.tile.progression',
      fallbackZero: boolean = true,
      base: number = 100): string {
    let progress = this.getProgression(fallbackZero, base);
    if ((progress === undefined) && !fallbackZero) {
      return undefined;
    } else {
      return i18next.t(i18nKey, {progression: progress || 0});
    }
  }

  /**
   *
   * @param fallbackZero Si true et si pas de progression affiche une progression à 0. Retourne undefined sinon.
   * @param base La base, si le pourcentage est compris entre 0 et 1, il faut le mutiplier par 100 pour avoir sa valeur réelle.
   */
  public getProgression(fallbackZero: boolean = true, base: number = 100): number {
    if ((this.progress === undefined) && !fallbackZero) {
      return undefined;
    } else {
      return (Math.round((this.progress * base) * 100) / 100) || 0;
    }
  }

  /**
   *
   * @param fallbackZero Si true et si pas de score affiche un score à 0. Retourne undefined sinon.
   * @param base
   * @param i18nKey
   */
  public displayScore(
      i18nKey: string = 'page.catalogueElearning.tile.score',
      fallbackZero: boolean = true,
      base: number = 100): string {
    let score = this.getScore(fallbackZero, base);
    if ((score === undefined) && !fallbackZero) {
      return undefined;
    } else {
      return i18next.t(i18nKey, {score: score || 0});
    }
  }

  /**
   *
   * @param fallbackZero Si true et si pas de score affiche un score à 0. Retourne undefined sinon.
   * @param base La base, si le pourcentage est compris entre 0 et 1, il faut le mutiplier par 100 pour avoir sa valeur réelle.
   */
  public getScore(fallbackZero: boolean = true, base: number = 100): number {
    if ((this.score === undefined) && !fallbackZero) {
      return undefined;
    } else {
      return (Math.round((this.score * base) * 100) / 100) || 0;
    }
  }

  /**
   *
   * @param fallbackZero Si true et si pas de score affiche un score à 0. Retourne undefined sinon.
   * @param base
   * @param i18nKey
   */
  public displayScoreTemporary(
      i18nKey: string = 'page.catalogueElearning.tile.scoreTemporary',
      fallbackZero: boolean = true,
      base: number = 100): string {
    let score = this.getScoreTemporary(fallbackZero, base);
    if ((score === undefined) && !fallbackZero) {
      return undefined;
    } else {
      return i18next.t(i18nKey, {score: score || 0});
    }
  }

  /**
   *
   * @param fallbackZero Si true et si pas de score affiche un score à 0. Retourne undefined sinon.
   * @param base La base, si le pourcentage est compris entre 0 et 1, il faut le mutiplier par 100 pour avoir sa valeur réelle.
   */
  public getScoreTemporary(fallbackZero: boolean = true, base: number = 100): number {
    if ((this.scoreTemporary === undefined) && !fallbackZero) {
      return undefined;
    } else {
      return (Math.round((this.scoreTemporary * base) * 100) / 100) || 0;
    }
  }

  /**
   *
   * @param fallbackZero Si true et si pas de score affiche un score à 0. Retourne undefined sinon.
   * @param base
   * @param i18nKey
   */
  public displayScoreAuto(
      i18nKey: string = 'page.catalogueElearning.tile.scoreAuto',
      fallbackZero: boolean = true,
      base: number = 100): string {
    let score = this.getScoreAuto(fallbackZero, base);
    if ((score === undefined) && !fallbackZero) {
      return undefined;
    } else {
      return i18next.t(i18nKey, {score: score || 0});
    }
  }

  /**
   *
   * @param fallbackZero Si true et si pas de score affiche un score à 0. Retourne undefined sinon.
   * @param base La base, si le pourcentage est compris entre 0 et 1, il faut le mutiplier par 100 pour avoir sa valeur réelle.
   */
  public getScoreAuto(fallbackZero: boolean = true, base: number = 100): number {
    if ((this.scoreAuto === undefined) && !fallbackZero) {
      return undefined;
    } else {
      return (Math.round((this.scoreAuto * base) * 100) / 100) || 0;
    }
  }

  /**
   *
   * @param fallbackZero Si true et si pas de nbConn retourne 0. Retourne undefined sinon.
   */
  public displayNbConn(fallbackZero: boolean = true): number {
    return ((this.nbConn === undefined) && !fallbackZero) ? undefined : this.nbConn || 0;
  }

  /**
   *
   * @param fallbackZero Si true et si pas de nbAttempt retourne 0. Retourne undefined sinon.
   */
  public displayNbAttempt(fallbackZero: boolean = true): number {
    return (((this.nbAttempt === undefined) || (this.nbAttempt.current === undefined)) && !fallbackZero)
      ? undefined : this.nbAttempt.current || 0;
  }

  /**
   *
   * @param fallbackZero Si true et si pas de points retourne 0. Retourne undefined sinon.
   */
  public displayPoints(fallbackZero: boolean = true): number {
    return ((this.points === undefined) && !fallbackZero) ? undefined : this.points || 0;
  }

  /**
   *
   * @param inHours Si true retourne le temps en heures, si false en secondes.
   * @param fallbackZero Si true et si pas de totalTime retourne 0. Retourne undefined sinon.
   */
  public getTotalTime(inHours: boolean = false, fallbackZero: boolean = true): number {
    return ((this.totalTime === undefined) && !fallbackZero)
      ? undefined
      : ((inHours) ? (Math.round((this.totalTime/(60 * 60)) * 100) / 100) : (Math.round(this.totalTime * 100) / 100)) || 0;
  }

  /**
   *
   * @param fallbackZero Si true et si pas de score affiche un score à 0. Retourne undefined sinon.
   * @param inHours Si true retourne le temps en heures, si false en secondes.
   * @param i18nKey
   */
  public displayTotalTime(
    i18nKey: string = 'page.catalogueElearning.tile.totalTime',
    inHours: boolean = true,
    fallbackZero: boolean = true): string {
    let totalTime = this.getTotalTime(inHours, fallbackZero);
    if ((totalTime === undefined) && !fallbackZero) {
      return undefined;
    } else {
      return i18next.t(i18nKey, {time: totalTime || 0});
    }
  }

}

export class UserProductNbAttemptElearning {
  current: number;
  // incrementIfFinished: boolean
}

export class UserProductInfoBookmarksElearning {
  _id: string;
  id: string;
  title: string;
  comment: string;
  date: Date
}
