/**
 * Etats de la plate-forme.
 *      Proriétés :
 *      - JWT_TOKEN_NAME : nom du token jwt utilisé pour l'authentifiaction
 */
import {StringUtil} from '../ng-helpers-util/string.util';

// @dynamic
export class StateProperties {

    // Le nom de l'instance applicative
    private static _appInstanceName: string = `${StringUtil.randomString(15)}${new Date().getTime()}`;
    public static get APP_INSTANCE_NAME(): string { return this._appInstanceName; }

    // Pour le chargement de la configuration de l'internationnalisation
    private static _i18nextInit: Boolean = false;
    public static get IS_I18NEXT_INITIALIZED(): Boolean { return this._i18nextInit; }
    public static set IS_I18NEXT_INITIALIZED(newState: Boolean) { this._i18nextInit = newState; }

    // Indicateur de connexion de l'utilisateur
    private static _isUserConnected: Boolean = false;
    public static get IS_USER_CONNECTED(): Boolean { return this._isUserConnected; }
    public static set IS_USER_CONNECTED(newState: Boolean) { this._isUserConnected = newState; }

    // La date de la dernière importation d'un nouvelle news (tracker)
    private static _newsLastImportDate: any = {}; // Objet contenant une propriété pour chaque source de news et dont la valeur est la dernière date d'importation des news pour cette source
    public static NEWS_LAST_IMPORT_DATE_GET = function(source: string): number { return this._newsLastImportDate[source]; };
    public static NEWS_LAST_IMPORT_DATE_SET = function(source: string, newDate: number) { this._newsLastImportDate[source] = newDate; };
    public static NEWS_LAST_IMPORT_DATE_RESET = function() { this._newsLastImportDate = {}; };

}
