/**
 * Les codes d'erreur de la plate-forme.
 * Les code pour l'interface angular sont précédés par un "UI-"
 *
 * Cette classe override la classe ErrorProperties afin d'ajouter de nouveaux
 * codes d'erreur spécifiques.
 */
import {errorProperties} from '../modules/ng-properties-error/error.properties';

export const appErrorProperties = {
    ...errorProperties,

    // Pour les errreurs de la librairie ard
    ERROR_CHARTBOX_RESIZE_CHARTBOX: 'UI-7001',
    ERROR_CHARTBOX_DISPLAY_SEARCH_WIZARD: 'UI-7002',
    ERROR_CHARTBOX_DISPLAY_HISTORY_WIDGET: 'UI-7003',
    ERROR_CHARTBOX_SAVE_DESKTOP: 'UI-7004',
    ERROR_CHARTBOX_RESTORE_DESKTOP: 'UI-7005',
    ERROR_CHARTBOX_GET_ENTITY: 'UI-7006',
    ERROR_CHARTBOX_GET_ENTITY_LIST: 'UI-7007',
    ERROR_CHARTBOX_GET_ENTITY_NAME_FORM_ID: 'UI-7008',
    ERROR_CHARTBOX_SEARCH_ENTITY: 'UI-7009',
    ERROR_CHARTBOX_POLICY_OPTION: 'UI-7010',
};
