import {IElearningProduct} from "../../../../data/catalogueProduct/elearning/IElearningProduct";
import {LearningContainer} from "../../../ng-models-atel3/models/db/LearningContainer";
import {StringUtil} from "../../../ng-helpers-util/string.util";
import {appConfigurationProperties} from "../../../../config/appConfigurationProperties";

export class Publication extends LearningContainer implements IElearningProduct {

  public type: string;
  public url: string;
  public urlPreview: string;
  public urlHelp: string;
  public data: PublicationData;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    super(_data);
    $.extend(this, _data);
    // Attribution du type
    this.type = (StringUtil.isUndefinedOrEmpty(this.type)) ? appConfigurationProperties.LEARNING_CONTAINER_TYPE_PUBLICATION : this.type;
  }

  /**
   *
   */
  isPopulated(): boolean {
    return true;
  }

  /**
   *
   */
  populate(): void {}

}

/**
 *
 */
class PublicationData {
  format: string;
  playerVersion: string;
  password: string;
  viewingMethods: string[];

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    $.extend(this, _data);
  }
}

