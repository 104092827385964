import i18next from 'i18next';
import {ObjectUtil} from "../../ng-helpers-util/object.util";
import {StringUtil} from "../../ng-helpers-util/string.util";

// @dynamic
export class EnumTypeUtil {

  /**
   * Fonction qui transforme la liste des themes en une liste au format suivant :
   * [ {code: <code de l'item, name: <nom de l'item>}, ... ]
   *
   * @param enumTypes
   * @param i18nextPrefixKey
   * @param additionalProperties - propriété supplémentaire à ajouter aux objets de retour.
   * @param language
   * @returns
   */
  static getEnumTypesForDropDown(
    enumTypes: any,
    i18nextPrefixKey: string,
    additionalProperties: string[] = [],
    language): string[][] {
    return enumTypes
      .map(enumType => {
        let obj = {
          code: enumType.code,
          name: i18next.t(i18nextPrefixKey + enumType.code)
        };
        additionalProperties.forEach(property => {
          // obj[property] = enumType[property];
          ObjectUtil.setNestedProperty(obj, property, ObjectUtil.getValueByPath(enumType, property));
        });
        return obj;
      })
      .sort((a, b) => {
        if (
          ObjectUtil.hasProperty(a, 'name') && ObjectUtil.isString(a.name) &&
          ObjectUtil.hasProperty(b, 'name') && ObjectUtil.isString(b.name)) {
          return a.name.localeCompare(b.name, language, {sensitivity: 'base'});
        } else if (
          ObjectUtil.hasProperty(a, 'code') && ObjectUtil.isString(a.code) &&
          ObjectUtil.hasProperty(b, 'code') && ObjectUtil.isString(b.code)) {
          return a.code.localeCompare(b.code, language, {sensitivity: 'base'});
        } else {
          return 0;
        }
      });
    // .sort(enumType => (a, b) => a.localeCompare(b, language, { ignorePunctuation: true }));
  }

  /**
   * Fonction qui retourne le premier item de l'enum type correspondant à un code spécifique.
   * Retourne undefined si aucun code n'est trouvé.
   *
   * @param enumTypes
   * @param code
   */
  static getItemInEnumTypes(enumTypes: any[], code: string): any {
    return enumTypes.find(enumType => {
      return enumType.code === code;
    })
  }
}
