import {Injectable} from '@angular/core';
import {ObjectUtil} from '../modules/ng-helpers-util/object.util';
import {applicationProperties} from '../modules/ng-properties-application/application.properties';
import i18next from 'i18next';
import {AipError, Localization} from '../modules/ng-models-ui';
import {ConfigurationProperties} from '../modules/ng-properties-configuration/configuration.properties';
import {errorProperties, ErrorStatusCode} from '../modules/ng-properties-error/error.properties';
import {StringUtil} from '../modules/ng-helpers-util/string.util';
import {appConfigurationProperties} from '../config/appConfigurationProperties';
import {CommonUtil} from '../modules/ng-helpers-util/common.util';

/**
 * Service de gestion des devises.
 */
@Injectable({
  providedIn: 'root',
})
export class CurrencyService {

  // Le getter de _currencies
  get currencies(): Currency[] {
    return this._currencies;
  }

  // Le getter de _currenciesForPayment
  get currenciesForPayment(): Currency[] {
    return this._currenciesForPayment;
  }

  // Le getter de _currenciesCodeForPayment
  get currenciesCodeForPayment(): string[] {
    return this._currenciesCodeForPayment;
  }

  // Le getter de _defaultServerCurrency
  get defaultServerCurrency(): Currency {
    return this._defaultServerCurrency;
  }

  // Le getter de _defaultServerCurrencyCode
  get defaultServerCurrencyCode(): string {
    return (ObjectUtil.hasProperty(this._defaultServerCurrency, 'code'))
      ? this._defaultServerCurrency.code
      : appConfigurationProperties.PAYMENT_DEFAULT_CURRENCY;
  }

  private _currencies: Currency[] = []; // La liste des devises dans la collection enumTypes de la base de données
  private _currenciesForPayment: Currency[] = []; // La liste des devises "suitableForPayment" dans la collection enumTypes de la base de données
  private _currenciesCodeForPayment: string[] = []; // Les codes de la liste "_currenciesForPayment"
  private _defaultServerCurrency: Currency; // La devise par défaut dans la collection enumTypes de la base de données
  private _defaultServerCurrencyCode: string; // Le code de la devise par défaut "_defaultServerCurrency"

  /**
   *
   */
  constructor() {
  }

  /**
   * Initialisation des valeurs du service.
   */
  init(currencies: Currency[] = []): void {
    if (ObjectUtil.isArray(currencies)) {
      // Transformation des éléments en instance de Currency
      currencies = currencies.map(elem => new Currency(elem));
      // Affectation des propriétés du service
      this._currencies = currencies;
      this._currenciesForPayment = currencies
        .filter(
          elem => (
            ObjectUtil.hasProperty(elem, 'options', 'suitableForPayment') &&
            ObjectUtil.isBoolean(elem.options.suitableForPayment) &&
            elem.options.suitableForPayment)
        );
      this._currenciesCodeForPayment = this._currenciesForPayment.map(
        elem => elem.code
      );
      this._defaultServerCurrency = currencies.find(
        elem => (
          ObjectUtil.hasProperty(elem, 'options', 'defaultCurrency') &&
          ObjectUtil.isBoolean(elem.options.defaultCurrency) &&
          elem.options.defaultCurrency)
      );
    }
  }

  /**
   * Fonction qui retourne une devise à partir de son code.
   * Retourne undefined si la devise n'a pas été trouvée.
   */
  public getCurrencyFromCode(currencyCode: string): Currency {
    return this.currencies.find(elem => elem.code === currencyCode);
  }

  /**
   * Fonction qui retourne le code de la devise.
   */
  public getCurrencyCode(currency: string | Currency): string {
    // Nouveau mettre le test de string avant le test de currency pour ne pas avoir d'erreur
    // car hasProperty ne marche pas sur les string
    if (ObjectUtil.isString(currency)) {
      return currency as string;
    } else if (
      (currency instanceof Currency) ||
      ObjectUtil.hasProperty(currency, 'code')) {
      return (currency as Currency).code;
    }
  }

  /**
   * Fonction qui retourne le symbole de la devise.
   * Lève une exception si le symbole de la devise n'a pas été trouvée.
   * @throws AipError
   */
  public getCurrencySymbol(currency: string | Currency): string {
    let symbol;
    // Nouveau mettre le test de string avant le test de currency pour ne pas avoir d'erreur
    // car hasProperty ne marche pas sur les string
    if (ObjectUtil.isString(currency)) {
      const curr = this.getCurrencyFromCode(currency as string);
      return this.getCurrencySymbol(curr);
    } else if (
      (currency instanceof Currency) ||
      ObjectUtil.hasProperty(currency, 'options', 'symbol')) {
      symbol = (currency as Currency).options.symbol;
    }
    if (!StringUtil.isUndefinedOrEmpty(symbol)) {
      return symbol;
    } else {
      throw new AipError(
        ConfigurationProperties.cli_error_type_fatal,
        errorProperties.ERROR_PAYMENT_CURRENCY_NOT_FOUND,
        i18next.t('error.title'),
        i18next.t('error.paymentCurrencyNotFound.subTitle'),
        i18next.t('error.paymentCurrencyNotFound.message'),
        ErrorStatusCode.INTERNAL_ERROR);
    }
  }

  /**
   * Fonction qui retourne le displayPriceBeforeSymbol de la devise.
   * Lève une exception si le displayPriceBeforeSymbol de la devise n'a pas été trouvée.
   * @throws AipError
   */
  public getDisplayPriceBeforeSymbol(currency: string | Currency): string {
    let displayPriceBeforeSymbol;
    // Nouveau mettre le test de string avant le test de currency pour ne pas avoir d'erreur
    // car hasProperty ne marche pas sur les string
    if (ObjectUtil.isString(currency)) {
      const curr = this.getCurrencyFromCode(currency as string);
      return this.getDisplayPriceBeforeSymbol(curr);
    } else if ((currency instanceof Currency) ||
      ObjectUtil.hasProperty(currency, 'options', 'displayPriceBeforeSymbol')) {
      displayPriceBeforeSymbol = (currency as Currency).options.displayPriceBeforeSymbol;
    }
    if (displayPriceBeforeSymbol !== undefined) {
      return displayPriceBeforeSymbol;
    } else {
      throw new AipError(
        ConfigurationProperties.cli_error_type_fatal,
        errorProperties.ERROR_PAYMENT_CURRENCY_NOT_FOUND,
        i18next.t('error.title'),
        i18next.t('error.paymentCurrencyNotFound.subTitle'),
        i18next.t('error.paymentCurrencyNotFound.message'),
        ErrorStatusCode.INTERNAL_ERROR);
    }
  }

  /**
   * Fonction retourne le nombre de décimales pour une devise spécifique.
   * Lève une exception si le symbole de la devise n'a pas été trouvée.
   * @throws AipError
   */
  public getNumberOfDecimals(currency: string | Currency): number {
    let decimals;
    // Nouveau mettre le test de string avant le test de currency pour ne pas avoir d'erreur
    // car hasProperty ne marche pas sur les string
    if (ObjectUtil.isString(currency)) {
      const curr = this.getCurrencyFromCode(currency as string);
      return this.getNumberOfDecimals(curr);
    } else if ((currency instanceof Currency) ||
      ObjectUtil.hasProperty(currency, 'options', 'decimals')) {
      decimals = (currency as Currency).options.decimals;
    }
    if (ObjectUtil.isInt(decimals)) {
      return decimals;
    } else {
      throw new AipError(
        ConfigurationProperties.cli_error_type_fatal,
        errorProperties.ERROR_PAYMENT_CURRENCY_NOT_FOUND,
        i18next.t('error.title'),
        i18next.t('error.paymentCurrencyNotFound.subTitle'),
        i18next.t('error.paymentCurrencyNotFound.message'),
        ErrorStatusCode.INTERNAL_ERROR);
    }
  }

  /**
   * Fonction qui retourne la valeur numérique d'un prix selon son format :
   *  - number (ex: 10}
   *  - objet (ex: {EUR: 10, USD: 20, TND: 30}
   *  - array (ex: [{currency: EUR, price: 10}, {currency: USD, price: 20}, {currency: TND, price: 30}]
   *  Retourne 'undefined' si le prix n'a pas été trouvé dans la devise recherché
   */
  getPriceValueInSelectedCurrency(
    price: any,
    selectedCurrency: string | Currency,
    round: boolean = false): number {
    // Recherche du prix selon le format
    const currencyCode = this.getCurrencyCode(selectedCurrency);
    let _price: number;
    if (ObjectUtil.isNumeric(price)) {
      _price = price;
    } else if (ObjectUtil.isArray(price)) {
      const priceInCurrency = price.find(
        elem => (
          ObjectUtil.hasProperty(elem, 'currency') &&
          (elem.currency === currencyCode))
      );
      _price = (ObjectUtil.hasProperty(priceInCurrency, 'price'))
        ? priceInCurrency.price : undefined;
    } else if (
      ObjectUtil.isObject(price) &&
      ObjectUtil.hasProperty(price, currencyCode) &&
      ObjectUtil.isNumeric(price[currencyCode])) {
      _price = price[currencyCode];
    }
    // On retourne le résutat
    if (_price !== undefined) {
      return (round)
        ? this.getPriceInSelectedCurrency(_price, selectedCurrency)
        : _price;
    } else {
      return undefined;
    }
  }

  /**
   * Fonction qui retourne sous forme de string le prix dans la devise spécifiée.
   * Utilisé pour l'affichage.
   *
   * ATTENTION : PROPAGE l'exception lancée par "getCurrencySymbol()".
   *             A utiliser dans un bloc try ... catch si on veut exploiter l'erreur
   * @throws AipError
   */
  public displayPriceInSelectedCurrency(price: number, currency: string | Currency): string {
    try {
      const decimals = this.getNumberOfDecimals(currency);
      let key: string;
      switch (decimals) {
        case 2:
          key = 'common.priceTag.2decimals';
          break;
        case 3:
          key = 'common.priceTag.3decimals';
          break;
        default:
          key = 'common.priceTag.floatMax3';
          break;
      }
      return i18next.t(
        key,
        {
          price: CommonUtil.round(price, decimals),
          currencySymbol: this.getCurrencySymbol(currency),
          context: (this.getDisplayPriceBeforeSymbol(currency)) ? 'priceBeforeSymbol' : 'priceAfterSymbol'
        }
      );
    } catch (err) {  // Le throw est implicite mais c'est plus claire comme cela.
      throw err;
    }
  }


  /**
   * Fonction qui retourne sous forme de numbre le prix dans la devise spécifiée.
   * Retourne le prix avec le bon nombre de décimales.
   * Si la devise n'est pas trouvé, retourne le prix passé en paramètre sans modification.
   *
   * ATTENTION : Cette fonction retourne un number, et non un string; en conséquence le chiffre de retour
   *             prend bien en considération le nombre de décimale de la devise mais supprime les "0"
   *             inutiles après la virgule. Il faudra les rajouter pour l'affichage.
   * @throws AipError
   */
  public getPriceInSelectedCurrency(price: number, currency: string | Currency): number {
    const nbOfDecimals: number = this.getNumberOfDecimals(currency);
    if (nbOfDecimals) {
      const roundBase = Math.pow(10, nbOfDecimals);   // Permet d'afficher le prix avec le bon nombre de décimales
      return Math.round(price * roundBase) / roundBase;
    } else {
      return price;
    }
  }

  /**
   * Fonction qui retourne la devise à partir d'une localization.
   * La fonction recherche la devise dans l'ordre suivant:
   *      1. à partir du code devise de la localization
   *      2. à partir du code pays de la localization
   *      3. à partir du code continent de la localization
   * (du plus précis au moins précis)
   * Si aucune devise n'a pu être déterminée, la fonction retourne la devise par défaut.
   */
  public getCurrencyFromLocalization(localization: Localization): string {
    // On recherche à partir du code devise
    let res: string = this._getCurrencyFromCurrencyCode(localization);
    if (res) {
      return res;
    }
    // On recherche à patir du code pays
    res = this._getCurrencyFromCountryCode(localization);
    if (res) {
      return res;
    }
    // On recherche à patir du code contient
    res = this._getCurrencyFromContinentCode(localization);
    if (res) {
      return res;
    }
    // On retourne la devise par défaut
    return this.defaultServerCurrencyCode;
  }


  /**
   * Fonction qui retourne la devise si elle fait partie des devises géré par le serveur.
   * Retourne undefined sinon.
   * Retourne undefined si la localisation est invalide ou si elle ne contient pas de code devise.
   *
   * Liste extraite à partir du site : https://en.wikipedia.org/wiki/ISO_4217
   */
  private _getCurrencyFromCurrencyCode(localization: Localization): string {
    if (localization && localization.currencyCode) {
      if ((localization.currencyCode === applicationProperties.PAYMENT_CURRENCY_EUR) ||
        (localization.currencyCode === applicationProperties.PAYMENT_CURRENCY_USD) ||
        (localization.currencyCode === applicationProperties.PAYMENT_CURRENCY_TND)
      ) {
        // La devise est gérée par le serveur
        return localization.currencyCode;
      } else {
        // La devise n'est gérée par le serveur
        return undefined;
      }
    } else {
      return undefined;
    }
  }


  /**
   * Fonction qui retourne la devise en fonction du continent.
   * Retourne undefined si la localisation est invalide ou si elle ne contient pas de code continent.
   *
   * Liste extraite à partir du site : https://en.wikipedia.org/wiki/List_of_sovereign_states_and_dependent_territories_by_continent_(data_file)
   */
  private _getCurrencyFromContinentCode(localization: Localization): string {
    if (localization && localization.continentCode) {
      switch (localization.continentCode) {
        case 'AF' : // Africa
          return applicationProperties.PAYMENT_CURRENCY_EUR;
        case 'AS' : // Asia
          return applicationProperties.PAYMENT_CURRENCY_USD;
        case 'EU' : // Europe
          return applicationProperties.PAYMENT_CURRENCY_EUR;
        case 'NA' : // North America
          return applicationProperties.PAYMENT_CURRENCY_USD;
        case 'SA' : // South America
          return applicationProperties.PAYMENT_CURRENCY_USD;
        case 'OC' : // Oceania
          return applicationProperties.PAYMENT_CURRENCY_USD;
        case 'AN' : // Antarctica
          return applicationProperties.PAYMENT_CURRENCY_USD;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }


  /**
   * Fonction qui retourne la devise en fonction du pays.
   * Retourne undefined si la localisation est invalide ou si elle ne contient pas de code pays, ou si le pays n'est pas reconnu.
   *
   * Liste extraite à partir du site : https://en.wikipedia.org/wiki/List_of_sovereign_states_and_dependent_territories_by_continent_(data_file)
   * Des pays peuvent appartenir à plusieurs continents.
   */
  private _getCurrencyFromCountryCode(localization: Localization): string {
    if (localization && localization.countryCode) {
      switch (localization.countryCode) {
        // *** Les pays africains ***
        case 'DZ' :
        case 'AO' :
        case 'BW' :
        case 'BI' :
        case 'CM' :
        case 'CV' :
        case 'CF' :
        case 'TD' :
        case 'KM' :
        case 'YT' :
        case 'CG' :
        case 'CD' :
        case 'BJ' :
        case 'GQ' :
        case 'ET' :
        case 'ER' :
        case 'DJ' :
        case 'GA' :
        case 'GM' :
        case 'GH' :
        case 'GN' :
        case 'CI' :
        case 'KE' :
        case 'LS' :
        case 'LR' :
        case 'LY' :
        case 'MG' :
        case 'MW' :
        case 'ML' :
        case 'MR' :
        case 'MU' :
        case 'MA' :
        case 'MZ' :
        case 'NA' :
        case 'NE' :
        case 'NG' :
        case 'GW' :
        case 'RE' :
        case 'RW' :
        case 'SH' :
        case 'ST' :
        case 'SN' :
        case 'SC' :
        case 'SL' :
        case 'SO' :
        case 'ZA' :
        case 'ZW' :
        case 'SS' :
        case 'EH' :
        case 'SD' :
        case 'SZ' :
        case 'TG' :
        // case "TN" :  // ===> exception
        case 'UG' :
        case 'EG' :
        case 'TZ' :
        case 'BF' :
        case 'ZM' :
          return applicationProperties.PAYMENT_CURRENCY_EUR;
        case 'TN':
          return applicationProperties.PAYMENT_CURRENCY_TND;
        // *** Les pays d'antartique ***
        case 'AQ' :
        case 'BV' :
        case 'GS' :
        case 'TF' :
        case 'HM' :
          return applicationProperties.PAYMENT_CURRENCY_USD;
        // *** Les pays d'asie ***
        case 'AF' :
        case 'AZ' :
        case 'BH' :
        case 'BD' :
        case 'AM' :
        case 'BT' :
        case 'IO' :
        case 'BN' :
        case 'MM' :
        case 'KH' :
        case 'LK' :
        case 'CN' :
        case 'TW' :
        case 'CX' :
        case 'CC' :
        // case "CY" :
        // case "GE" :
        case 'PS' :
        case 'HK' :
        case 'IN' :
        case 'ID' :
        case 'IR' :
        case 'IQ' :
        case 'IL' :
        case 'JP' :
        case 'KZ' :
        case 'JO' :
        case 'KP' :
        case 'KR' :
        case 'KW' :
        case 'KG' :
        case 'LA' :
        case 'LB' :
        case 'MO' :
        case 'MY' :
        case 'MV' :
        case 'MN' :
        case 'OM' :
        case 'NP' :
        case 'PK' :
        case 'PH' :
        case 'TL' :
        case 'QA' :
        // case "RU" :
        case 'SA' :
        case 'SG' :
        case 'VN' :
        case 'SY' :
        case 'TJ' :
        case 'TH' :
        case 'AE' :
        case 'TR' :
        case 'TM' :
        case 'UZ' :
        case 'YE' :
        case 'XE' :
        case 'XD' :
        case 'XS' :
          return applicationProperties.PAYMENT_CURRENCY_USD;
        // *** Les pays d'europe ***
        case 'AL' :
        case 'AD' :
        // case "AZ" :
        case 'AT' :
        // case "AM" :
        case 'BE' :
        case 'BA' :
        case 'BG' :
        case 'BY' :
        case 'HR' :
        case 'CY' :
        case 'CZ' :
        case 'DK' :
        case 'EE' :
        case 'FO' :
        case 'FI' :
        case 'AX' :
        case 'FR' :
        case 'GE' :
        case 'DE' :
        case 'GI' :
        case 'GR' :
        case 'VA' :
        case 'HU' :
        case 'IS' :
        case 'IE' :
        case 'IT' :
        // case "KZ" :
        case 'LV' :
        case 'LI' :
        case 'LT' :
        case 'LU' :
        case 'MT' :
        case 'MC' :
        case 'MD' :
        case 'ME' :
        case 'NL' :
        case 'NO' :
        case 'PL' :
        case 'PT' :
        case 'RO' :
        case 'RU' :
        case 'SM' :
        case 'RS' :
        case 'SK' :
        case 'SI' :
        case 'ES' :
        case 'SJ' :
        case 'SE' :
        case 'CH' :
        // case "TR" :
        case 'UA' :
        case 'MK' :
        case 'GB' :
        case 'GG' :
        case 'JE' :
        case 'IM' :
          return applicationProperties.PAYMENT_CURRENCY_EUR;
        // *** Les pays d'amérique du nord ***
        case 'AG' :
        case 'BS' :
        case 'BB' :
        case 'BM' :
        case 'BZ' :
        case 'VG' :
        case 'CA' :
        case 'KY' :
        case 'CR' :
        case 'CU' :
        case 'DM' :
        case 'DO' :
        case 'SV' :
        case 'GL' :
        case 'GD' :
        case 'GP' :
        case 'GT' :
        case 'HT' :
        case 'HN' :
        case 'JM' :
        case 'MQ' :
        case 'MX' :
        case 'MS' :
        case 'AN' :
        case 'CW' :
        case 'AW' :
        case 'SX' :
        case 'BQ' :
        case 'NI' :
        case 'PA' :
        case 'PR' :
        case 'BL' :
        case 'KN' :
        case 'AI' :
        case 'LC' :
        case 'MF' :
        case 'PM' :
        case 'VC' :
        case 'TT' :
        case 'TC' :
        case 'US' :
        case 'VI' :
          return applicationProperties.PAYMENT_CURRENCY_USD;
        case 'AS' :
        case 'AU' :
        case 'SB' :
        case 'CK' :
        case 'FJ' :
        case 'PF' :
        case 'KI' :
        case 'GU' :
        case 'NR' :
        case 'NC' :
        case 'VU' :
        case 'NZ' :
        case 'NU' :
        case 'NF' :
        case 'MP' :
        case 'UM' :
        case 'FM' :
        case 'MH' :
        case 'PW' :
        case 'PG' :
        case 'PN' :
        case 'TK' :
        case 'TO' :
        case 'TV' :
        case 'WF' :
        case 'WS' :
        case 'XX' :
          return applicationProperties.PAYMENT_CURRENCY_USD;
        // *** Les pays d'amérique du sud ***
        case 'AR' :
        case 'BO' :
        case 'BR' :
        case 'CL' :
        case 'CO' :
        case 'EC' :
        case 'FK' :
        case 'GF' :
        case 'GY' :
        case 'PY' :
        case 'PE' :
        case 'SR' :
        case 'UY' :
        case 'VE' :
          return applicationProperties.PAYMENT_CURRENCY_USD;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

}

/**
 *
 */
export class Currency {

  public code: string;
  public name: string;
  public options: {
    abbreviation: string,
    symbol: string,
    displayPriceBeforeSymbol: boolean,
    decimals: number,
    suitableForPayment: boolean,
    defaultCurrency: boolean,
  };

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   */
  constructor(_data) {
    $.extend(this, _data);
  }
}
