import i18next from "i18next";
import {appConfigurationProperties} from "../../../config/appConfigurationProperties";
import {CurrentUserService} from "../services/current-user.service";
import {ConfigurationUtil} from "../../../config/configuration.util";
import {User} from "../../ng-models-ats/models/db/User";
import {Order} from "../../ng-models-ats/models/db/Order";
import {ObjectUtil} from "../../ng-helpers-util/object.util";

export class UserUtil {

  /**
   * Retourne true si l'utilisateur est connecté. Retounre false sinon.
   *
   * @param currentUserService
   */
    public static isUserConnected(currentUserService: CurrentUserService): boolean {
      return currentUserService && currentUserService.isUserConnected();
    }

  /**
   * Fonction qui retourne l'url de l'image du profile d'un utilisateur.
   *
   * @param user
   * @param addClearfix - ajout d'un clearfix à l'url pour forcer le rechargement de la photo.
   * @param forceDefault Pour forcer l'image par défaut même si l'utilisateur a une image.
   *                     Permet de gérer les cas où l'image n'a pas été trouvée sur le disque.
   */
  public static getPictureUrl(user: User, addClearfix?: string, forceDefault = false): string {
    let imageFilePath: string;
    let imageFileName: string;
    if (!forceDefault && user && user.info && user.info.picture) {
      // L'utilisateur a une photo de profile
      imageFilePath = `/${appConfigurationProperties.URL_DATA_USER(user._id)}`;
      imageFileName = ConfigurationUtil.cli_file_common_picture_name + "." + ConfigurationUtil.cli_file_common_picture_extension;
    } else {
      // L'utilisateur n'a pas de photo de profile
      imageFilePath = `/${appConfigurationProperties.URL_DATA_USER()}`;
      if (user && user.extraInfo && (user.extraInfo.gender === true)) {
        // L'utilisateur est un homme
        imageFileName = ConfigurationUtil.cli_file_default_user_picture_male;
      } else if (user && user.extraInfo && (user.extraInfo.gender === false)) {
        // L'utilisateur est une femme
        imageFileName = ConfigurationUtil.cli_file_default_user_picture_female;
      } else {
        // Le sexe de l'utilisateur n'a pas pu être déterminé
        imageFileName = ConfigurationUtil.cli_file_default_user_picture_no_gender;
      }
    }
    if (addClearfix) {
      imageFileName += addClearfix;  // Fait un changement d'url pour forcer le réaffichage de l'image
    }
    return imageFilePath + imageFileName;
  }


  /**
   * Fonction qui affiche le nom du code de la langue passée en paramètre.
   */
  public static displayLanguage(langCode: string): string {
    let res = i18next.t(`aip-enumTypes:language.${langCode}`);
    if (langCode !== 'en') {
      // En anglais 'English' doit avoir une majuscule
      res = res.toLowerCase();
    }
    return res;
  }

  /**
   * Retourne true si l'élément est un facture. Retounre false sinon (facture proforma).
   *
   * @param order
   */
  public static isInvoice(order: Order): boolean {
    return (
      order &&
      // La commande a été payée
      ObjectUtil.hasProperty(order, 'status', 'payment') &&
      (order.status.payment === appConfigurationProperties.PAYMENT_STATUS_SUCCESS) &&
      // La commande n'est pas gratuite
      ObjectUtil.isNumeric(order.price) &&
      (order.price > 0)
    );
  }

}
