import i18next from "i18next";
import {applicationProperties} from "../../ng-properties-application/application.properties";
import {atelProperties} from "../../ng-properties-application/atel.properties";

export class LearningElementsUtil {

  /**
   * Fonction qui affiche le difficultyLevel d'un learningContainer
   */
  public static displayDifficultyLevel(interval: { min: number, max: number }, shortDisplay?: boolean): string {
    if(interval === undefined) {
      return undefined;
    }
    let i18nextOptions: any = (shortDisplay) ? {context: "SHORT"} : undefined;
    if ((interval.min === undefined) && (interval.max === undefined)) {
      return undefined;
    } else if ((interval.min !== undefined) && (interval.max !== undefined) && (interval.min === interval.max)) {
      return i18next.t(`${applicationProperties.I18NEXT_NAMESPACE_ENUM_TYPES}:difficultyLevel.${interval.min}`, i18nextOptions);
    } else if ((interval.min !== undefined) && (interval.max !== undefined)) {   // minMax.min !== minMax.max
      if (interval.min === applicationProperties.DIFFICULTY_LEVELS_ALL_LEVELS) {  // Niveau "Approprié pour tous"
        return i18next.t(`${applicationProperties.I18NEXT_NAMESPACE_ENUM_TYPES}:difficultyLevel.${interval.min}`, i18nextOptions);
      } else {
        return i18next.t(
          'page.catalogueElearning.popup.difficultyLevels',
          Object.assign({
            postProcess: 'sprintf', sprintf: {
              min: i18next.t(`${applicationProperties.I18NEXT_NAMESPACE_ENUM_TYPES}:difficultyLevel.${interval.min}`, i18nextOptions).toLowerCase(),
              max: i18next.t(`${applicationProperties.I18NEXT_NAMESPACE_ENUM_TYPES}:difficultyLevel.${interval.max}`, i18nextOptions).toLowerCase()
            }
          }, i18nextOptions));
      }
    } else if (interval.min !== undefined) {   // minMax.max === undefined
      return i18next.t(`${applicationProperties.I18NEXT_NAMESPACE_ENUM_TYPES}:difficultyLevel.${interval.min}`, i18nextOptions);
    } else if (interval.max !== undefined) {   // minMax.min === undefined
      return i18next.t(`${applicationProperties.I18NEXT_NAMESPACE_ENUM_TYPES}:difficultyLevel.${interval.max}`, i18nextOptions);
    } else {
      // Cas impossible
      return undefined;
    }
  }

}
