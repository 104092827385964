import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import {CapitalizeFirst} from "./capitalize-first.pipe";

@NgModule({
  declarations:[
    CapitalizeFirst
  ],
  imports:[
    CommonModule
  ],
  exports:[
    CapitalizeFirst
  ]
})

export class HelpersUtilsPipesModule{}
