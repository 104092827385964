/**
 * Les message statics de l'application
 */

export const appLocalesProperties = {

  COOKIE_DISABLED: 'Cookies are disabled on your browser, please enable cookies and reload this page.',
  CONFIGURATION_LOAD_SUCCESS: 'Configuration chargée',
  CONFIGURATION_LOAD_FAILURE: 'Une erreur de chargement des paramétres de la plate-forme s\'est produite, veuillez réessayer plus tard. Contacter l\'administrateur si le problème persiste.',

  CURRENCY_LOAD_SUCCESS: 'Devises chargées',
  CURRENCY_LOAD_FAILURE: 'Une erreur de chargement des devises s\'est produite, veuillez réessayer plus tard. Contacter l\'administrateur si le problème persiste.',

};
