import { Injectable } from '@angular/core';
import {appConfigurationProperties} from '../config/appConfigurationProperties';
import {ObjectUtil} from '../modules/ng-helpers-util/object.util';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {mergeMap} from 'rxjs/operators';

/**
 * Service permettant de stocker des données issues du serveur.
 * Ces données sont généralement initiées par des résolveurs.
 */
@Injectable({
  providedIn: 'root',
})
export class DataFromServerService {

  private _themes: {tree: any[], list: any[]}; // Liste de thèmes - mis à jour dans ElearningResolver
  public get themes(): {tree: any[], list: any[]} {
    return this._themes;
  }
  public set themes(value: {tree: any[], list: any[]}) {
    this._themes = value;
  }

  /**
   *
   */
  constructor(private http: HttpClient) {
  }

  /**
   *
   */
  private doGetThemeList(postData?: any): Observable<any> {
    return this.http.post(
      `${appConfigurationProperties.URL_SERVER_AIP_ATEL}/${appConfigurationProperties.URL_SERVER_ELEARNING_GET_THEMES}`,
      postData);
      /*
      .subscribe({
        next: (themesList: any) => {
          this.themes = themesList;
          observer.next(themesList);
          observer.complete();
        },
        error: err => {
          observer.error(new Error('Themes Not Found!'));
          observer.complete();
        }
      });
      */
  }

  /**
   * Fonction qui retourne un observer contenant les données "value".
   */
  private setThemeListToObserver(value: any): Observable<any> {
    return Observable.create(observer => {
      // observer.next({language: value});
      // setTimeout(() => {   // Pour les tests
      observer.next(value);
      observer.complete();
      // Any cleanup logic might go here
      // return () => console.log('disposed')
      // }, 5000)
    });
  }

  /**
   * Fonction qui retourne un observer récupèrant depuis le serveur la liste des themes.
   * Cette fonction chaine deux observers: celui effectuant la requête au serveur et celui encpsulant
   * le résultat dans l'objet.
   */
  public getThemes(postData: any = {listOutput: 2, item: 'item'}): Observable<any> {
    if (this.isThemeListLoaded()) {
      // On a trouvé l'information dans le cache
      return this.setThemeListToObserver(this.themes);
    } else {
      // On n'a pas trouvé l'information dans le cache
      return this.doGetThemeListObserver(postData);
    }
  }

  /**
   * Fonction qui retourne un observer récupèrant depuis le serveur l'enumType de type "typeOfEnum"
   * et met le résultat dans un objet ne contenant qu'une propriété nommée "typeOfEnum".
   * Cette fonction chaine deux observers: celui effectuant la requête au serveur et celui encpsulant
   * le résultat dans l'objet.
   */
  private doGetThemeListObserver(postData?: any): Observable<any> {
    return this.doGetThemeList(postData).pipe(mergeMap((response: any) => {
      // Enregistrement des informations dans le service
      if (
        ObjectUtil.hasProperty(response, 'list') &&
        ObjectUtil.hasProperty(response, 'tree') &&
        ObjectUtil.isArray(response.list) &&
        ObjectUtil.isArray(response.tree)) {
        this.themes = response;
      }
      // Retour du résultat
      return this.setThemeListToObserver(response);
    }));
  }

  /**
   *
   */
  private isThemeListLoaded(): boolean {
    return (
      ObjectUtil.hasProperty(this.themes, 'list') &&
      ObjectUtil.hasProperty(this.themes, 'tree') &&
      ObjectUtil.isArray(this.themes.list) &&
      ObjectUtil.isArray(this.themes.tree));
  }

  /**
   *
   */
  public getThemeLabel(themeId, undefinedIfNotFound = false): string {
    let theme;
    if (ObjectUtil.hasProperty(this.themes, 'list') && ObjectUtil.isArray(this.themes.list)) {
      theme = this.themes.list.find(elem => elem._id === themeId);
    }
    if (theme) {
      return theme.label;
    } else {
      return (undefinedIfNotFound) ? undefined : appConfigurationProperties.LANGUAGE_MISSING_KEY_REPLACEMENT_VALUE;
    }
  }
}
