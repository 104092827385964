/**
 * Les onglets de la librairie
 */
export enum ELEARNING_LIBRARY_TAB {
  LESSONS,
  QUIZ,
  COURSES,
  DEGREES,
  SOURCES,
}

export enum PUBLISHING_LIBRARY_TAB {
  PUBLICATIONS,
  SOURCES,
}

