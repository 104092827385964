import {StringUtil} from '../ng-helpers-util/string.util';
import {ConfigurationProperties} from "../ng-properties-configuration/configuration.properties";
import UiJsonConfig from "../../../config/current/aip-ui.json";

/**
 * Propiétés de configuration de la plate-forme.
 *      Proriétés :
 *      - JWT_TOKEN_NAME : nom du token jwt utilisé pour l'authentifiaction
 */
const atp = {

  // *** Les urls de la plate-forme ***
  URL_DATA_PUBLICATION: (_id: string = ""): string =>   // Le path d'un teaching ou du répetoires des teachings si sans arguments
  {
    return (StringUtil.isUndefinedOrEmpty(_id)) ? `data/publications/local/` : `data/publications/local/${_id}/`;
  },

  // *** Les fichiers par défaut ***
  FILE_PICTURE_PUBLICATION: () => `${ConfigurationProperties.cli_file_common_picture_default_name}?v${UiJsonConfig.VERSION_COMMON_DEFAULT_IMAGE}`, // Le nom de image par défaut,  // Le nom de image par défaut

  /*
  FILE_CATALOG_PDF: (lang: string): string =>
  {
    return (!StringUtil.isUndefinedOrEmpty(lang)) ? `Catalogue-publishing-${lang}.pdf?v1` : undefined;
  },
  */

  // *** Pour le type publishing ***
  PUBLISHING_TYPE: "publishing",  // Doit correspondre avec les constantes du serveur

  // *** Pour les publishingContainers ***
  PUBLISHING_CONTAINER_TYPE_PUBLICATION: "publication",  // Doit correspondre avec les constantes du serveur

  // *** Pour les catégories de publication ***
  PUBLICATION_ENUM_TYPE_DIRECTORY_CONTACT: "directoryContact",        // Correspond à la valeur "publicationType" dans la collection "enumTypes"
  PUBLICATION_ENUM_TYPE_INSURANCE_MARKET: "insuranceMarket",          // Correspond à la valeur "publicationType" dans la collection "enumTypes"
  PUBLICATION_ENUM_TYPE_INSURANCE_STATISTICS: "insuranceStatistics",  // Correspond à la valeur "publicationType" dans la collection "enumTypes"
  PUBLICATION_ENUM_TYPE_INSURANCE_STUDIES: "insuranceStudies",        // Correspond à la valeur "publicationType" dans la collection "enumTypes"

  // *** Pour les méthodes de visualisation des publications ***
  VIEWING_METHOD_DOWNLOAD: "download",              // Doit correspondre avec les constantes du serveur et du player
  VIEWING_METHOD_INTERNAL_VIEWER: "internalViewer", // Doit correspondre avec les constantes du serveur et du player

};


/**
 * Surcharge pour pouvoir exporter des proprtiétés utilisant d'autres propriétés.
 */
export const atpProperties = {
  ...atp,

  // La liste des viewing methods
  VIEWING_METHODS: [
    atp.VIEWING_METHOD_DOWNLOAD,
    atp.VIEWING_METHOD_INTERNAL_VIEWER
  ],

  // La viewing méthode par défaut
  DEFAULT_VIEWING_METHOD: atp.VIEWING_METHOD_INTERNAL_VIEWER
};
