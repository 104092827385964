import {UserTeachingContainer} from './UserTeachingContainer';
import {IElearningUserProduct} from './IElearningUserProduct';
import {UserTeachingDb} from '../../../modules/ng-models-atel3/models/db/userAtel/UserTeachingDb';
import {CurrentUserService} from '../../../modules/ng-manager-user/services/current-user.service';
import {UserDependenciesService} from '../../../modules/ng-module-login/services/user-dependencies.service';
import {ElearningProductLibraryService} from '../../../views/app/elearning/elearning-product-library.service';
import {appConfigurationProperties} from '../../../config/appConfigurationProperties';
import {ObjectUtil} from '../../../modules/ng-helpers-util/object.util';
import {Degree} from '../../../modules/ng-models-atel3/models/db/Degree';
import {Course} from '../../../modules/ng-models-atel3/models/db/Course';
import {Lesson} from '../../../modules/ng-models-atel3/models/db/Lesson';
import {CommonUtil} from '../../../modules/ng-helpers-util/common.util';
import {UserCourse} from './UserCourse';
import {LibraryUtil} from '../../../modules/ng-manager-user/utils/atel3/library.util';
import {ArrayUtil} from '../../../modules/ng-helpers-util/array.util';

export class UserDegree extends UserTeachingContainer implements IElearningUserProduct {

  // Les teaching du degree de l'utilisateur (classés selon l'ordre des teachings dans les degree et les cours
  // établit lors de sa création, certains éléments du tableau peuvent être undefined).
  userTeachingsNested: UserTeachingDb[][] = [];

  /**
   *
   */
  constructor(
    public degree: Degree,
    userTeachingsGetter?: CurrentUserService | UserTeachingDb[],
    userDependenciesService?: UserDependenciesService) {

    super();
    this._id = `###${this.degree._id}###`; // Création d'un _id pour l'unicité dans les listes
    this.type = this.degree.type;
    this.initUserTeachings(userTeachingsGetter, userDependenciesService);
  }

  // Le type d'objet
  public getType(): string {
    return appConfigurationProperties.LEARNING_CONTAINER_TYPE_DEGREE;
  }

  // L'objet associé
  public getEmbeddedObject(): Degree {
    return this.degree;
  }

  // Fonction qui test si l'objet associé existe
  public hasEmbeddedObject(): boolean {
    return this.degree !== undefined;
  }

  // Fonction qui test si l'objet est valide
  public isEmbeddedObjectValid(): boolean {
    return this.degree.isValid();
  }

  // Fonction qui test si l'objet possède cette source
  public hasSource(sourceId: string): boolean {
    if (ObjectUtil.isArray(this.userTeachings)) {
      return this.userTeachings.every(usrTeach => {
        // Le teaching à été souscrit via une source externe
        const isExternalSource = (
          usrTeach.hasSource(sourceId) &&
          usrTeach.sources.some(
            src => (
              ObjectUtil.hasProperty(src, 'degree_ref') &&
              ((src as any).degree_ref === this.degree._id))
          ));
        // Le teaching a été sourcrit via la source personnel
        const isPersonalSource = (
          (sourceId === appConfigurationProperties.DEFAULT_SOURCE_ID) &&
          usrTeach.hasSource(sourceId) &&
          (
            (usrTeach.sources.length === 0) ||
            usrTeach.sources.some(
              src => (
                ObjectUtil.hasProperty(src, 'degree_ref') &&
                ((src as any).degree_ref === this.degree._id) &&
                ((src as any).source_ref === null)
              )
            )
          )
        );
        return isExternalSource || isPersonalSource;
      });
    } else {
      return false;
    }
  }

  /**
   * Fonction qui initialise les userTeachings du UserDegree.
   *
   * @param userTeachingsGetter Si undefined, la liste des teaching sera prise dans "this.userTeachings"
   * @param userDependenciesService Si undefined, la liste des cours sera prise dans "this.degree.courses"
   * @param considerUserTeachingActiveFlag Default: false
   */
  private initUserTeachings(
    userTeachingsGetter: CurrentUserService | UserTeachingDb[],
    userDependenciesService: UserDependenciesService,
    considerUserTeachingActiveFlag: boolean = false): void {
    // Création du tableau userTeachingsNested
    this.buildUserTeachingsNested(
      userTeachingsGetter, userDependenciesService, considerUserTeachingActiveFlag);
    // Création du tableau userTeachings
    this.userTeachings = this.flattenUserTeachings();
    // Génération des informations du userDegree
    this.generateProperties();
  }

  /**
   * Fonction qui construit la propriété "userTeachingsNested".
   */
  buildUserTeachingsNested(
    userTeachingsGetter?: CurrentUserService | UserTeachingDb[],
    userDependenciesService?: UserDependenciesService,
    considerUserTeachingActiveFlag: boolean = false): void {
    if (this.degree && ObjectUtil.isArray(this.degree.courses)) {
      // Initilisation du tableau des userCourse avec des valeurs undefined
      this.userTeachingsNested = Array(this.degree.courses.length).fill(undefined);
      // Remplissage du tableau des userTeaching avec les userTeachings trouvé dans l'utilisateur Atel
      this.degree.courses.forEach((courseDegree, courseIndex) => {
        if (courseDegree.active) {
          // On recherche les occurances de ce cours dans les cours du degree
          const courseId: string = (ObjectUtil.isString(courseDegree._ref))
            ? courseDegree._ref as string
            : (courseDegree._ref as Course)._id;
          const course: Course = (userDependenciesService)
            ? userDependenciesService.getElearningCourseDependency(courseId)
            : courseDegree._ref as Course;
          if (course && ObjectUtil.isArray(course.lessons)) {
            // Initilisation du tableau des userTeaching avec des valeurs undefined
            this.userTeachingsNested[courseIndex] = Array(course.lessons.length).fill(undefined);
            // Remplissage du tableau des userTeaching avec les userTeachings trouvé dans l'utilisateur Atel
            course.lessons.forEach((lessonCourse, lessonIndex) => {
              if (lessonCourse.active) {
                // On recherche les occurances de cette lesson dans les lessons du cours
                const lessonId: string = (ObjectUtil.isString(lessonCourse._ref))
                  ? lessonCourse._ref as string
                  : (lessonCourse._ref as Lesson)._id;
                const userTeaching = LibraryUtil.getUserTeachingFromGetter(
                  userTeachingsGetter, lessonId, this.getUserTeachingFromTeachingId);
                if (userTeaching && userTeaching.isUserTeachingValid(considerUserTeachingActiveFlag)) {
                  // On a trouvé la lesson correspondante dans le cours
                  this.userTeachingsNested[courseIndex][lessonIndex] = userTeaching;
                }
              }
            });
          }
        }
      });
    }
    // Création du tableau userTeachings
    this.userTeachings = this.flattenUserTeachings();
    // Génération des informations du userDegree
    this.generateProperties();
  }

  /**
   *
   */
  private flattenUserTeachings(): UserTeachingDb[] {
    const ret: UserTeachingDb[] = [];
    // try {
    if (ObjectUtil.isArray(this.userTeachingsNested)) {
      this.userTeachingsNested.forEach(userTeachingDegree => {
        if (ObjectUtil.isArray(userTeachingDegree)) {
          userTeachingDegree.forEach(userTeachingCourse => {
            ret.push(userTeachingCourse);
          });
        }
      });
    }
    // } catch (err) {
    //   console.error(err);
    //   ret = [];
    // }
    return ArrayUtil.arrayUniqueFct(
      ret.filter(elem => elem !== undefined), // Pour que le status du degree ne soit pas unknown
      (a, b) => a && b && (a.teaching_ref === b.teaching_ref));
    /*
    return this.userTeachingsNested
      .reduce(
        (accumulator, currentValue) => {
          if (ObjectUtil.isArray(currentValue)) {
            currentValue.forEach(elem => {
              if (elem) {
                const userTeaching = accumulator.find(item => item && (elem._id === item._id));
                if (!userTeaching) { // Suppression des doublons
                  accumulator.push(elem);
                }
              } else {
                // Ajout des userTeaching undefined (util pour le caclcul des status et du succès)
                accumulator.push(elem); // elem === undefined
              }
            });
          }
          return accumulator;
        }, [])
      .filter(elem => elem !== undefined);
    */
  }

  // L'objet est partiellement possédé par l'utilisateur
  public isPartiallyOwnByUser(
    userTeachingsGetter: CurrentUserService | UserTeachingDb[],
    userDependenciesService: UserDependenciesService,
    considerUserTeachingActiveFlag: boolean = false): boolean {
    if (this.degree && ObjectUtil.isArray(this.degree.courses)) {
      return this.degree.courses.some(courseDegree => {
        if (courseDegree.active) {
          // On recherche les occurances de ce cours dans les cours du degree
          const courseId: string = (ObjectUtil.isString(courseDegree._ref))
            ? courseDegree._ref as string
            : (courseDegree._ref as Course)._id;
          const course: Course = userDependenciesService.getElearningCourseDependency(courseId);
          if (course && ObjectUtil.isArray(course.lessons)) {
            return course.lessons.some(lessonCourse => {
              if (lessonCourse.active) {
                // On recherche les occurances de cette lesson dans les lessons du cours
                const lessonId: string = (ObjectUtil.isString(lessonCourse._ref))
                  ? lessonCourse._ref as string
                  : (lessonCourse._ref as Lesson)._id;
                const userTeaching = LibraryUtil.getUserTeachingFromGetter(
                  userTeachingsGetter, lessonId, this.getUserTeachingFromTeachingId);
                return (userTeaching && userTeaching.isUserTeachingValid(considerUserTeachingActiveFlag));
              } else {
                return false; // Traitement différent que pour la fontion isFullyOwnByUser
              }
            });
          } else {
            return false;
          }
        } else {
          return false; // Traitement différent que pour la fontion isFullyOwnByUser
        }
      });
    } else {
      return false;
    }
  }

  // L'objet est entièrement possédé par l'utilisateur
  public isFullyOwnByUser(
    userTeachingsGetter: CurrentUserService | UserTeachingDb[],
    userDependenciesService: UserDependenciesService,
    considerUserTeachingActiveFlag: boolean = false): boolean {
    if (this.degree && ObjectUtil.isArray(this.degree.courses)) {
      return this.degree.courses.every(courseDegree => {
        if (courseDegree.active) {
          // On recherche les occurances de ce cours dans les cours du degree
          const courseId: string = (ObjectUtil.isString(courseDegree._ref))
            ? courseDegree._ref as string
            : (courseDegree._ref as Course)._id;
          const course: Course = userDependenciesService.getElearningCourseDependency(courseId);
          if (course && ObjectUtil.isArray(course.lessons)) {
            return course.lessons.every(lessonCourse => {
              if (lessonCourse.active) {
                // On recherche les occurances de cette lesson dans les lessons du cours
                const lessonId: string = (ObjectUtil.isString(lessonCourse._ref))
                  ? lessonCourse._ref as string
                  : (lessonCourse._ref as Lesson)._id;
                const userTeaching = LibraryUtil.getUserTeachingFromGetter(
                  userTeachingsGetter, lessonId, this.getUserTeachingFromTeachingId);
                return (userTeaching && userTeaching.isUserTeachingValid(considerUserTeachingActiveFlag));
              } else {
                return true; // Traitement différent que pour la fontion isPartiallyOwnByUser
              }
            });
          } else {
            return false;
          }
        } else {
          return true; // Traitement différent que pour la fontion isPartiallyOwnByUser
        }
      });
    } else {
      return false;
    }
  }

  /**
   *
   */
  public hasInfoProgression({userTeachingsGetter, userDependenciesService}): boolean {
    return (
      this.isFullyOwnByUser(userTeachingsGetter, userDependenciesService, false) ||
      this.isPartiallyOwnByUser(userTeachingsGetter, userDependenciesService, false)
    );
  }

  // L'objet a été acheté par l'utilisateur
  public isPurchased(elearningProductLibraryService: ElearningProductLibraryService): boolean {
    return (elearningProductLibraryService.getUserDegreeFromDegreeId(this.degree._id) !== undefined);
  }

  // L'objet peut être acheté par cet utilisateur
  public isPurchasable({userTeachingsGetter, userDependenciesService}): boolean {
    return (
      this.getEmbeddedObject().hasPrice() &&
      (
        !this.hasInfoProgression({userTeachingsGetter, userDependenciesService}) ||
        !this.isUserProductValid(true) ||
        !this.isFullyOwnByUser(userTeachingsGetter, userDependenciesService, false)
      )
    );
  }

  /**
   * Fonction qui retourne le score de l'utilisateur à patir des userTeachings.
   * Retourne 0 si le score n'a pas pu être déterminé à patir des userTeachings.
   *
   * ATTENTION : Fonction overriddée pour les degrees.
   */
  public getScore(userTeachingsNested: UserTeachingDb[][] = this.userTeachingsNested, base: number = 100): number {
    // On calcule la moyenne du score de tous les cours
    const accumulateur = userTeachingsNested.reduce((accumulator, currentValue) => {
      return accumulator + (UserCourse.prototype.getScore.call(this, currentValue) || 0);
    }, 0);
    const res = (accumulateur / userTeachingsNested.length);
    return CommonUtil.round(res * base, 2) / base;
  }

  /**
   * Fonction qui retourne le score temporaire de l'utilisateur à patir des userTeachings.
   * Retourne 0 si le score temporaire n'a pas pu être déterminé à patir des userTeachings.
   *
   * ATTENTION : Fonction overriddée pour les degrees.
   */
  public getScoreTemporary(userTeachingsNested: UserTeachingDb[][] = this.userTeachingsNested, base: number = 100): number {
    // On calcule la moyenne du score de tous les cours
    const accumulateur = userTeachingsNested.reduce((accumulator, currentValue) => {
      return accumulator + (UserCourse.prototype.getScoreTemporary.call(this, currentValue) || 0);
    }, 0);
    const res = (accumulateur / userTeachingsNested.length);
    return CommonUtil.round(res * base, 2) / base;
  }

  /**
   * Fonction qui retourne le score auto de l'utilisateur à patir des userTeachings.
   * Retourne 0 si le score auto n'a pas pu être déterminé à patir des userTeachings.
   *
   * ATTENTION : Fonction overriddée pour les degrees.
   */
  public getScoreAuto(userTeachingsNested: UserTeachingDb[][] = this.userTeachingsNested, base: number = 100): number {
    // On calcule la moyenne du score de tous les cours
    const accumulateur = userTeachingsNested.reduce((accumulator, currentValue) => {
      return accumulator + (UserCourse.prototype.getScoreAuto.call(this, currentValue) || 0);
    }, 0);
    const res = (accumulateur / userTeachingsNested.length);
    return CommonUtil.round(res * base, 2) / base;
  }

  /**
   * Fonction qui retourne la progression de l'utilisateur à patir des userTeachings..
   * Retourne 0 si la progression n'a pas pu être déterminé à patir des userTeachings.
   * Fonction overriddée pour les degrees.
   *
   * ATTENTION : Fonction overriddée pour les degrees.
   */
  public getProgression(userTeachingsNested: UserTeachingDb[][] = this.userTeachingsNested, base: number = 100): number {
    // On calcule la moyenne du score de tous les cours
    const accumulateur = userTeachingsNested.reduce((accumulator, currentValue) => {
      return accumulator + (UserCourse.prototype.getProgression.call(this, currentValue) || 0);
    }, 0);
    const res = (accumulateur / userTeachingsNested.length);
    return CommonUtil.round(res * base, 2) / base;
  }

}
