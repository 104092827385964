/**
 * Interface contenant les variables globales de l'environnement d'exécution de la plate-forme (navigateur)
 */
export interface GlobalVariables {
    ard: any;
    chartboxAPI: any;
    POPUP_MANAGER: any;  // Cette variable est chargée dans le scripts "../js/ats/popupManager.js" dans le fichier .angular-cli.json
}

/**
 * Classe abstraite permettant d'accèder aux variables globales.
 * Cette classe n'est pas utilisable directement il faut utiliser des classes d'implémentations.
 */
export abstract class GlobalRef {
    abstract get nativeGlobal(): GlobalVariables;
}

/**
 * Classe d'implémentation spécifique pour les plate-forme de type navigateur web.
 */
export class BrowserGlobalRef extends GlobalRef {
    // get nativeGlobal(): GlobalVariables { return window as any; }
    get nativeGlobal(): GlobalVariables { return (window as any) as GlobalVariables; }
}

/**
 * Classe d'implémentation spécifique pour les plate-forme de type application node.
 */
// export class NodeGlobalRef extends GlobalRef {
//     // get nativeGlobal(): GlobalVariables { return global as any; }
//     get nativeGlobal(): GlobalVariables { return (<any>global) as GlobalVariables; }
// }
