export class Credentials {

  public email?: string;
  public username?: string;
  // public password?: string; // Dans le constructeur

  /**
   *
   * @param login - peut être l'email ou le username
   * @param password
   * @param isEmailLogin - true par défaut.
   * @param captcha
   */
  constructor(
    login: string,
    public password: string,
    isEmailLogin: boolean = true) {
    if (isEmailLogin) {
      this.email = login;
    } else {
      this.username = login;
    }
  }

  /**
   * Fonction qui converti le Credentials en string.
   *
   * @returns
   */
  toString() {
    return JSON.stringify(this);
  }


  /**
   * Fonction qui test si un object passé en paramètre est un
   * credential valide.
   *
   * @param cred
   */
  public static isValidCredentials(cred: any): boolean {
    return (
      cred &&
      (
        (cred.hasOwnProperty('email') && (typeof cred.email === 'string')) ||
        (cred.hasOwnProperty('username') && (typeof cred.username === 'string'))
      ) &&
      cred.hasOwnProperty('password') && (typeof cred.password === 'string'));
  }

  /**
   * Fonction qui converti un objet sous forme de string en Credentials.
   *
   * @param value
   * @param isEmailLogin
   * @returns
   */
  public static convertToCredentials(value: string, isEmailLogin: boolean = true): Credentials {
    let obj: any = JSON.parse(value);
    if (Credentials.isValidCredentials(obj)) {
      return new Credentials(
        (isEmailLogin) ? obj.email : obj.username,
        obj.password,
        isEmailLogin);
    } else {
      return undefined;
    }
  }
}
