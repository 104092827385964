export class UserAdoc {

    _id?: string;
    roles?: string[];
    statistics?: UserAdocStatistics;
    settings?: UserAdocSettings;

    /**
     * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
     * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
     * ainsi que les fonctions définies dans la classe.
     *
     * @param _data
     */
    constructor(_data: any = undefined) {
      $.extend(this, _data);
    }

    /**
     * Fonction qui intialise une nouvel utilisateur.
     *
     * @param reset - Si false, n'initialise que les propriétés undefined.
     */
    public init(reset: boolean = true): UserAdoc {
        this.initUserAdoc();
        if(reset || !this.statistics) {
            this.initUserAdocStatistics();
        }
        return this;
    }

    public initUserAdoc(): void {
        this.roles = [];
    }

    public initUserAdocStatistics(): void {
        this.statistics = new UserAdocStatistics();
        this.statistics.totalTime = 0;
        this.statistics.totalTimeEffective = 0;
    }

}

class UserAdocSettings {
}

class UserAdocStatistics {
  totalTime: number;
  totalTimeEffective: number;
}
