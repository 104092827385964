import {Observable} from 'rxjs';
import {SchedulerUtil} from '../../../modules/ng-helpers-util/scheduler.util';
// import {mergeMap} from 'rxjs/operators';

/**
 * Fonction logout customisée pour le service AccountService.
 * Elle sera exécutée dans le contexte du service AccountService.
 *
 * Fonction dupliquée dans ngui/src/app/modules/ng-module-login/services/account.service.ts - defaultLogoutFunction()
 */
export function logoutFunction(): Observable<any> {
  // On interrompt la tache de recherche des news
  SchedulerUtil.cancelJobFetchNews();
  // On procède au logout
  return Observable.create(observer => {
    this.serverLogout()
      /*  FIXME : désactivé
            .pipe(  // On procède à la déconnexion au niveau du seveur
              mergeMap((res) => {
                console.log('AAAAAAAAAAAAAA --- this:', this);
                console.log('AAAAAAAAAAAAAA --- this.shoppingCartService:', this.shoppingCartService);
                console.log('AAAAAAAAAAAAAA --- this.shoppingCartService.updateLocalShoppingCart:', this.shoppingCartService.updateLocalShoppingCart);
                return this.shoppingCartService.updateLocalShoppingCart();  // On met à jour (vide) le panier
              }))
      */
      .subscribe(() => {
        // Nettoyage de la session locale
        if (this.sessionService) {
          this.sessionService.resetSession();
        }
        // Demande aux autres onglets de se déconnecter
        this.authenticationService.triggerJwtLogout();
        observer.next();
        observer.complete();
      });
  });
}
