import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import {I18nextPipe} from "./i18next.pipe";

@NgModule({
  declarations:[
    I18nextPipe
  ],
  imports:[
    CommonModule
  ],
  exports:[
    I18nextPipe
  ]
})

export class I18nextPipeModule{}
