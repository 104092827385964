// @dynamic
export class MathUtil {

  /**
   * Fonction qui arrondi la valeur et tonque si nécessaire à la precision voulue.
   *
   * Ex1: round(12.11111111111111, 1) => 12.1
   * Ex2: round(12.11111111111111, 2) => 12.11
   * Ex3: round(2.18, 1) => 2.2 (arrondi sans tronquage)
   *
   * Cette fonction est particulirement interessante qd on arrondi les nombres qui sont des multiplicateurs (ex 0.13333).
   * Il est important de bien controller le nombre de chiffres derriere la virgule car ils peuvent avoir une grande incidence sur le nombres
   * (ex: 1000*1.3 est tres different de 1000*1.333).
   * Par contre qd on arrondi un prix (Ex:19999.1) on peut le faire avec Math.round car les centimes n'ont pas bcp d'importance.
   *
   * @returns {string}
   */
  static round(value: number, precision: number): number {
    let multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

}
