import {ObjectUtil} from "../ng-helpers-util/object.util";
import {StringUtil} from "../ng-helpers-util/string.util";
import {ArrayUtil} from "../ng-helpers-util/array.util";
import {CookieCheckAction, CookieOptions} from "../ng-models-ui";


export class CookieUtil {

    /**
     * Fonction qui crée un cookie.
     *
     * Remarque importante pour l'utilisation des cookies :
     *      Pour être considéré comme valide, le nom du domaine doit contenir au moins deux caractères '.'
     *      Pour utiliser un nom de domaine sur la machine localhost utiliser par exemple :
     *           atel.ats.localhost, adoc.ats.localhost, ...
     *      Pour que le cookie soit visible par tous ces sous-domaines utiliser comme paramètre domain: "ats.localhost"
     *      Pour que le cookie ne soit visible que par le sous-domaine atel utiliser comme paramètre domain: "atel.ats.localhost"
     *
     * @param cname
     * @param cvalue
     * @param cookieOptions
     */
    static setCookie(cname: string, cvalue: string, cookieOptions?: CookieOptions) {
        var expires = "";
        if ((cookieOptions && cookieOptions.expiryDays && ObjectUtil.isNumeric(cookieOptions.expiryDays))) {
            var d = new Date();
            d.setTime(d.getTime() + (cookieOptions.expiryDays * 24 * 60 * 60 * 1000));
            expires = ";expires=" + d.toUTCString();
        }
        if ((cookieOptions && cookieOptions.expirySeconds && ObjectUtil.isNumeric(cookieOptions.expirySeconds))) {
            var d = new Date();
            d.setTime(d.getTime() + (cookieOptions.expirySeconds * 1000));
            expires = ";expires=" + d.toUTCString();
        }
        var path = ((cookieOptions && cookieOptions.path)) ? ";path=" + cookieOptions.path : ";path=/";
        var domain = ((cookieOptions && cookieOptions.domain)) ? ";domain=" + cookieOptions.domain : "";
        document.cookie = cname + "=" + cvalue + expires + path + domain;
    }

    /**
     * Fonction qui recupère un cookie.
     *
     * @param cname
     * @param cookiesString - paramètre optionnel contenant la string de clé/valeur tous les cookies.
     *                        valeur par défaut: document.cookie
     * @returns
     */
    static getCookie(cname: string, cookiesString?: string): string {
        var name = cname + "=";
        cookiesString = cookiesString || document.cookie;
        var decodedCookie = decodeURIComponent(cookiesString);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    /**
     * Fonction qui supprime un cookie.
     *
     * Le paramètre cookieOptions permet de spécifier le domaine afin de
     * supprimer des cookies appartenant à des domaines spécifiques.
     * Il n'est pas possible de supprimer des cookies pour un dommaine auquel on n'appartient pas.
     *
     * @param cookieOptions
     * @param cname
     */
    static deleteCookie(cname: string, cookieOptions?: CookieOptions) {
        cookieOptions = cookieOptions || {};
        cookieOptions.expiryDays = -1;
        this.setCookie(cname, "", cookieOptions);
    }

    /**
     * Cookie Checker.
     *
     * Objet assurant la détection des modifications des cookies.
     * La manipulation de l'objet se fait à l'aide des fonctions :
     *      - addAction: ajoute une action de détection au Cookie Checker
     *      - removeAction: supprime une action de détection au Cookie Checker
     *      - start: démarre la détection des modifications des cookies
     *      - stop: interompt la détection des modifications des cookies
     *      - isRunning: indique si le Cookie Checker est en fonctionnement
     */
    public static checkCookie = {
        addAction: function (action: CookieCheckAction) {
            // Les actions sans propriétés "name" et "action" ne sont pas ajoutées.
            if (!StringUtil.isUndefinedOrEmpty(action.actionName) && ObjectUtil.isFunction(action.action)) {
                // On cherche si l'action existe déjà
                let index = CookieUtil.checkCookie._actions.findIndex(elem => {
                    return (action.actionName === elem.actionName);
                });
                if (index !== -1) { // On remplace l'action si elle existe déjà
                    CookieUtil.checkCookie._actions[index] = action;
                } else {            // On ajoute l'action si elle n'existait pas
                    CookieUtil.checkCookie._actions.push(action);
                }
            }
        },
        removeAction: function (actionName: string) {
            // On cherche l'index de l'action a supprimer
            let index = CookieUtil.checkCookie._actions.findIndex(elem => {
                return (actionName === elem.actionName);
            });
            if (index !== -1) { // On supprime l'action si elle existait
                ArrayUtil.removeElementsEqual(
                    CookieUtil.checkCookie._actions,
                    (a, b) => {
                        return a.actionName === b.actionName
                    },
                    {actionName: actionName})
            }
        },
        start: function(timeout) {
            CookieUtil.checkCookie._intervalID = window.setInterval(CookieUtil.checkCookie._checkProcess, timeout);
        },
        stop: function() {
            clearInterval(CookieUtil.checkCookie._intervalID);
            CookieUtil.checkCookie._intervalID = undefined;
        },
        isRunning: function() {
            return (CookieUtil.checkCookie._intervalID !== undefined);
        },
        _checkProcess: function () {
            let lastCookie = document.cookie; // 'static' memory between function calls
            // Défintion de la closure
            return () => {
                let currentCookie = document.cookie;
                if (currentCookie != lastCookie) {  // On a détecté un changement dans les cookies
                    // On détermine la liste des cookies ajoutés, modifiés ou supprimés
                    let elementsAddedRemovedOrModified = CookieUtil.checkCookie._cookiesModification(currentCookie, lastCookie);
                    // console.log("KKKKKKKKKKKKKKKKK");
                    // console.log("KKKKKKKKKKKKKKKKK currentCookie:", currentCookie);
                    // console.log("KKKKKKKKKKKKKKKKK lastCookie:", lastCookie);
                    // console.log("KKKKKKKKKKKKKKKKK elementsAddedRemovedOrModified:", elementsAddedRemovedOrModified);
                    // Récupétation de la liste des actions
                    let actions: CookieCheckAction[] = CookieUtil.checkCookie._actions || [];
                    actions.forEach(elem => {   // Exécution des actions
                        // On exécute les actions en fonction de leur propriété "cookieListerner"
                        if(StringUtil.isUndefinedOrEmpty(elem.cookieListerner) ||
                           (!StringUtil.isUndefinedOrEmpty(elem.cookieListerner) && elementsAddedRemovedOrModified.includes(elem.cookieListerner))) {
                            elem.action.apply(elem.myThis, elem.args)
                        }
                    });
                    lastCookie = currentCookie; // store latest cookie
                }
            };
        }(),
        _intervalID: undefined,
        _actions: [],   // Tableau de CookieCheckAction.
        // Fonction qui détermine le nom d'un cookie à partir d'une string "name=value"
        _getCookieName: function(cookieNameValue: string): string {
            return cookieNameValue.split("=")[0];
        },
        // Fonction qui retourne les nom des cookies ajoutés, modifiés ou supprimés
        _cookiesModification: function(currentCookie: string, lastCookie: string): string[] {
            // Le tableau des lastCookies au format "name=value"
            let lastCookieArray: string[] =
                lastCookie
                    .split(";")
                    .map(elem => {
                        return elem.trim();
                    })
                    .filter(elem => {
                        return !StringUtil.isUndefinedOrEmpty(elem);
                    });
            // Le tableau des currentCookies au format "name=value"
            let currentCookieArray: string[] =
                currentCookie
                    .split(";")
                    .map(elem => {
                        return elem.trim();
                    })
                    .filter(elem => {
                        return !StringUtil.isUndefinedOrEmpty(elem);
                    });
            // Les cookies ajoutés supprimés ou modifiés
            let elementsRemovedOrModified: string[] =
                ArrayUtil.arrayDifference(lastCookieArray, currentCookieArray)
                    .map(elem => {
                        return CookieUtil.checkCookie._getCookieName(elem);
                    });
            let elementsAddedOrModified: string[] =
                ArrayUtil.arrayDifference(currentCookieArray, lastCookieArray)
                    .map(elem => {
                        return CookieUtil.checkCookie._getCookieName(elem);
                    });
            return ArrayUtil.arrayUnique(elementsRemovedOrModified.concat(elementsAddedOrModified));
        }
    };
}

