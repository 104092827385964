import {TeachingData} from "./Teaching";
import {LearningContainer} from "./LearningContainer";
import {Theme} from "./Theme";
import {IElearningProduct} from "../../../../data/catalogueProduct/elearning/IElearningProduct";

export class Lesson extends LearningContainer implements IElearningProduct {

  public theme: Theme;
  public url: string;
  public data: TeachingData;

  /**
   * Constructeur qui remplit l'instance avec les données _data transmises en paramètre.
   * Il faut crée un objet avec ce constructeur pour pouvoir utiliser l'opérateur "instanceof"
   * ainsi que les fonctions définies dans la classe.
   *
   * @param _data
   */
  constructor(_data: any = undefined) {
    super(_data);
    $.extend(this, _data);
  }

  /**
   *
   */
  isPopulated(): boolean {
    return true;
  }

  /**
   *
   */
  populate(): void {}

}

