import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {appConfigurationProperties} from '../config/appConfigurationProperties';
import {CurrentUserService} from '../modules/ng-manager-user/services/current-user.service';
import { setCorporateForcedThemeColor } from '../utils/util';
import {environment} from '../../environments/environment';

/**
 * Service permettant de stocker les informations de navigation, notament la backUrl lorsque
 * l'AuthenticationGuard détecte que l'utilisateur n'est pas connecté, et qu'il faut le renvoyé
 * à l'url avant la page de connexion.
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  // Boolean indiquant si l'on doit faire un contrôle sur la correspondance du theme avec la route demandée
  public checkTheme = true;

  // L'url a accéder apres le login de l'utilisateur
  private _backUrlAfterLogin = appConfigurationProperties.URL_UI_HOME;
  get backUrlAfterLogin(): string {
    return this._backUrlAfterLogin;
  }
  set backUrlAfterLogin(url: string) {
    this._backUrlAfterLogin = url;
  }

  /**
   *
   */
  constructor(
    private router: Router,
    private currentUserService: CurrentUserService) {
  }

  public resetBackUrlAfterLogin(): void {
    this._backUrlAfterLogin = appConfigurationProperties.URL_UI_HOME;
    // this._backUrlAfterLogin = undefined;
  }

  /*
   * Fonction de retour arrière en fonction de la navigation
   */
  public goBack(location: Location): void {
    if (this.backUrlAfterLogin) {
      const url = this.backUrlAfterLogin;
      this.resetBackUrlAfterLogin();
      this.router.navigate([url]);
    } else {
      this.goPrevious(location);
    }
  }

  /*
   * Fonction de retour à la page précédente on la page d'accueil la page
   * précedente ne peut pas être déterminée.
   */
  public goPrevious(location: Location): void {
    if ((location.getState() as any).navigationId > 1) {
      // Ce n'est pas la première page du site sur laquelle on accède (l'historique contient plus d'une page)
      //  => on met la page précedente en page de fond
      location.back();
    } else {
      // C'est la première page du site sur laquelle on accède (l'historique contient qu'une seule page)
      //  => on met la page d'acceuil en page de fond
      this.router.navigate([appConfigurationProperties.URL_UI_HOME]);
    }
  }

  /*
   * Fonction de retour true si c'est la première page du site auquel on accède.
   * Retourne false sinon.
   */
  public isFirstAccededPage(location: Location): boolean {
    return ((location.getState() as any).navigationId <= 1);
  }

  /*
   *
   */
  public setForcedTheme(): void {
    if (this.currentUserService.isUserCorporate()) {
      setCorporateForcedThemeColor(environment.corporateColor);
    } else {
      setCorporateForcedThemeColor(undefined);
    }
  }

  /*
   *
   */
  /*
  public resetForcedTheme(): void {
    setCorporateForcedThemeColor(undefined);
  }
  */

}
