import {appConfigurationProperties} from '../config/appConfigurationProperties';

export class IconUtils {

  /**
   * Fonction qui retourne le message a afficher.
   */
  public static getIcon(application: string): string {
    switch (application) {
      case appConfigurationProperties.APPLICATION_NAME_AIP_ATEL:
        return 'iconsminds-student-male';
      case appConfigurationProperties.APPLICATION_NAME_AIP_ATP:
        return 'iconsminds-library';
      case appConfigurationProperties.APPLICATION_NAME_AIP_ADOC:
        return 'iconsminds-statistic';
      default:
        return 'simple-icon-exclamation';
    }
  }

}
