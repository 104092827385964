import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import {catchError, filter, finalize, switchMap, take} from "rxjs/operators";
import {ObjectUtil} from "../../ng-helpers-util/object.util";
import {appConfigurationProperties} from "../../../config/appConfigurationProperties";
import {CurrentUserService} from "../../ng-manager-user/services/current-user.service";
import {Router} from "@angular/router";
import {ContentType} from "../../ng-models-ui/enums/ContentType";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  /**
   *
   * @param router
   * @param currentUserService
   */
  constructor(private router: Router, private currentUserService: CurrentUserService) {
  }

  // private AUTH_HEADER = "Authorization";
  // private token = this.getAuthToken();
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private resendRequestTwiceIf401Error = false; // Si true l'intercepteur essaie de renvoyer la requete une deuxième fois pour récuprer le token s'il n'est pas trouvé la première fois

  /**
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }

    req = this.addAuthenticationToken(req);

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.has401Error(error) && this.resendRequestTwiceIf401Error) {
          // 401 errors are most likely going to be because we have an expired token that we need to refresh.
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(req)))
            );
          } else {
            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);

            return this.refreshAccessToken().pipe(
              switchMap((success: boolean) => {
                this.refreshTokenSubject.next(success);
                return next.handle(this.addAuthenticationToken(req));
              }),
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => this.refreshTokenInProgress = false)
            );
          }
        } else if((this.router.url !== appConfigurationProperties.URL_UI_LOGIN) && this.has401Error(error)) {
          // Redirection vers la page de login en cas d'erreur 401
          // this.router.navigate([appConfigurationProperties.URL_UI_LOGIN]);
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }

  /**
   *
   */
  private refreshAccessToken(): Observable<any> {
    return of(this.getAuthToken());
  }

  /**
   * Fonction qui ajoute le token d'authentification dans la requête.
   */
  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    let currentHeaders = request.headers; // Le header actuel a augmenter avec le token d'authentification.
    if (!this.getAuthToken()) {
      // return request;
      return request.clone({
        headers: this.updateHeaders(
          currentHeaders,
          this.currentUserService.generateHeadersForUnprotectedRoutes()),
      });
    }
    // If you are calling an outside domain then do not add the token.
    if (!appConfigurationProperties.LIST_DOMAIN.some(domain => request.url.match(new RegExp(domain)))) {
      // return request;
      return request.clone({
        headers: this.updateHeaders(
          currentHeaders,
          this.currentUserService.generateHeadersForUnprotectedRoutes()),
      });
    }
    return request.clone({
      headers: this.updateHeaders(
        currentHeaders,
        this.currentUserService.generateHeadersForProtectedRoutes( // Ignorer la dépreciation ici !
          currentHeaders.get('Content-Type'))),
      withCredentials: true
    });
  }

  /**
   * Fonction qui récupère le token d'authentification.
   */
  private getAuthToken(): any {
    return sessionStorage.getItem(appConfigurationProperties.JWT_AUTH_TOKEN_KEY);
  }

  /**
   * Fonction qui détecte si une erreur 401 fait parti des erreurs reçues dans la réponse.
   *
   * @param error
   */
  private has401Error(error: HttpErrorResponse): boolean {
    return (
      error &&
      ObjectUtil.isArray(error.error) &&
      error.error.some(err => err.status = 401)
    );
  }

  /**
   * Fonction qui fusionne les attributs de deux headers.
   * Si "overwriteInTarget" est sur true, les attributs de "sourceHeader" sont prioritaires.
   * Si "overwriteInTarget" est sur false, les attributs de "targerHeaders" sont prioritaires.
   *
   * @param sourceHeader
   * @param targerHeaders
   * @param overwriteInTarget
   */
  private updateHeaders(
    sourceHeader: HttpHeaders, targerHeaders: HttpHeaders,
    overwriteInTarget: boolean = true): HttpHeaders {
    let newHeaders = new HttpHeaders();
    let order = (overwriteInTarget) ? [sourceHeader, targerHeaders] : [targerHeaders, sourceHeader] ;
    for (const header of order) {
      for (const key of header.keys()) {
        if (
          !newHeaders.get(key) && // La clé n'existe déjà pas
          !this.removeSpecificKeysInHeader(header, key) // La clé doit être conservée dans le header
        ) {
          newHeaders = newHeaders.append(key, header.get(key));
        }
      }
    }
    return newHeaders;
  }

  /**
   *
   */
  private removeSpecificKeysInHeader(header: HttpHeaders, key: string): boolean {
    return (
      // Pour la suppression des Content-Type du header
      ((key === 'Content-Type') && (header.get(key) === ContentType.REMOVE_CONTENT_TYPE_FROM_HEADER)));
  }
}
