import {environment} from "../../../environments/environment";

export class LoginProperties {

    /*******************************************
     **** Les routes de l'interface angular ****
     *******************************************/
    // public static ROUTE_UI_LOGIN: string = "login";           // L'écran de login
    // public static ROUTE_UI_LOGOUT: string = "logout";         // L'écran de logout
    // public static ROUTE_UI_RECOVER_CREDENTIALS: string = "recover-credentials";  // L'écran de recuperation du mot de passe
    // public static ROUTE_UI_SIGNUP_CONFIRMATION_PENDING: string = "signup-confirmation-pending";  // L'écran d'attente de confirmation d'inscription

    /******************************************************
     **** Les urls de routage dans l'interface angular ****
     ******************************************************/
    // public static URL_UI_LOGIN: string = `/${LoginProperties.ROUTE_UI_LOGIN}`;           // L'écran de login
    // public static URL_UI_LOGOUT: string = `/${LoginProperties.ROUTE_UI_LOGOUT}`;         // L'écran de logout
    // public static URL_UI_SIGNUP: string = "/signup";                                     // L'écran de signup
    // public static URL_UI_RECOVER_CREDENTIALS: string = `/${LoginProperties.ROUTE_UI_RECOVER_CREDENTIALS}`;  // L'écran de recuperation du mot de passe
    // public static URL_UI_SIGNUP_CONFIRMATION_PENDING: string = `/${LoginProperties.ROUTE_UI_SIGNUP_CONFIRMATION_PENDING}`;  // L'écran d'attente de confirmation d'inscription

    /**********************************
     **** Les urls vers le serveur ****
     **********************************/
    public static URL_SERVER_CREATE_USER_SESSION = `${environment.URL_SERVER_AIP_COMMON}/user/create-session`;    // Pour la création d'une session utilisateur
    public static URL_SERVER_LOGOUT = `${environment.URL_SERVER_AIP_COMMON}/user/logout`;    // Pour la déconnexion d'utilisateur
    public static URL_SERVER_REQUEST_RESET_PASSWORD = `${environment.URL_SERVER_AIP_COMMON}/user/requestResetPassword`;    // Pour l'initition d'une procédure de modification du mot de passe perdu
    public static URL_SERVER_RESET_PASSWORD = `${environment.URL_SERVER_AIP_COMMON}/user/resetPassword`;    // Pour la modification du mot de passe perdu
    public static URL_SERVER_REGISTER = `${environment.URL_SERVER_AIP_COMMON}/user/signup`;    // Pour la création d'un utilisateur
    public static URL_SERVER_REGISTER_CONFIRMATION = `${environment.URL_SERVER_AIP_COMMON}/user/signupConfirmation`;    // Pour la confirmation d'inscription
    // public static URL_SERVER_RESEND_CONFIRMATION_EMAIL = (email: string) => `${environment.URL_SERVER_AIP_COMMON}/user/resendConfirmationEmail/${email}`;    // Pour le renvoi d'un email de confirmation suit à la création d'un utilisateur
    public static URL_SERVER_RESEND_CONFIRMATION_EMAIL = `${environment.URL_SERVER_AIP_COMMON}/user/resendConfirmationEmail`;    // Pour le renvoi d'un email de confirmation suit à la création d'un utilisateur
    public static URL_SERVER_CHANGE_EMAIL_CONFIRMATION = `${environment.URL_SERVER_AIP_COMMON}/user/updateUserEmail`;    // Pour la confirmation de la modification d'email
    public static URL_SERVER_CHANGE_EMAIL_CANCEL = `${environment.URL_SERVER_AIP_COMMON}/user/cancelUpdateUserEmail`;    // Pour l'annulation de la modification d'email
}

/**
 * Les différentes causes d'attente de confirmation.
 */
export enum SignupConfirmationPendingAction {
    NEW_INSCRIPTION = "signup",
    NOT_CONFIRMED_YET = "login"
}


