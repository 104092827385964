import {Source} from "../../../ng-models-ats/models/db/Source";
import {User} from "../../../ng-models-ats/models/db/User";
import {appConfigurationProperties} from "../../../../config/appConfigurationProperties";
import {CurrentUserService} from "../../services/current-user.service";
import {UserTeachingDb} from "../../../ng-models-atel3/models/db/userAtel/UserTeachingDb";
import {ObjectUtil} from "../../../ng-helpers-util/object.util";
import {LearningContainer} from "../../../ng-models-atel3/models/db/LearningContainer";

export class LibraryUtil {

  /**
   * Fonction qui créé une source personnel.
   */
  public static createPersonalSource(user: User): Source {
    let personalSourceName: string =
      appConfigurationProperties.DEFAULT_SOURCE_NAME(user.info.firstName, user.info.lastName);
    return new Source({ // Création de la source personnelle
      _id: appConfigurationProperties.DEFAULT_SOURCE_ID,
      name: personalSourceName,
      status: {
        active: true,
        deleted: false
      }
    });
  }

  /**
   *
   */
  public static getUserTeachingFromGetter(
    userTeachingsGetter: CurrentUserService | UserTeachingDb[],
    userTeachingId: string,
    fallback?: (userTeachingId: string) => UserTeachingDb): UserTeachingDb {
    let userTeaching;
    if (ObjectUtil.hasProperty(userTeachingsGetter, 'isCurrentUserServiceInstance')) {
      userTeaching = (userTeachingsGetter as CurrentUserService).getUserTeachingFromTeachingId(userTeachingId);
    } else if (ObjectUtil.isArray(userTeachingsGetter)) {
      userTeaching = (userTeachingsGetter as UserTeachingDb[]).find(elem => {
        const _userTeachingId = (ObjectUtil.hasProperty(elem, 'teaching_ref', '_id'))
          ? (elem.teaching_ref as LearningContainer)._id : elem.teaching_ref;
        return _userTeachingId === userTeachingId
      });
    } else if (fallback) {
      userTeaching = fallback(userTeachingId);
    }
    return userTeaching;
  }

  /**
   * Fonction qui extrait les userTeachings du currentUserService.
   */
  public static getUserTeachingDbListFromGetter(_userTeachingsGetter: CurrentUserService | UserTeachingDb[]): UserTeachingDb[] {
    let userTeachings: UserTeachingDb[] = [];
    if (ObjectUtil.hasProperty(_userTeachingsGetter, 'isCurrentUserServiceInstance')) {
      userTeachings = (
        ObjectUtil.hasProperty(_userTeachingsGetter, 'user', 'atel', 'teachings') &&
        ObjectUtil.isArray((_userTeachingsGetter as CurrentUserService).user.atel.teachings))
        ? (_userTeachingsGetter as CurrentUserService).user.atel.teachings
        : [];
    } else if (ObjectUtil.isArray(_userTeachingsGetter)) {
      userTeachings = _userTeachingsGetter as UserTeachingDb[];
    }
    return userTeachings;
  }


}
