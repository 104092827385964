import {Injectable} from '@angular/core';
import {AipError} from '../../ng-models-ui';

/**
 * Service stockant les données sur l'erreur qui seront affichées par le composant "ErrorComponent".
 * Ces données sont remplies par le service "ErrorService" lorsque l'affichage d'une erreur est demandée par l'interface.
 */
@Injectable({
    providedIn: 'root'
})
export class ErrorDataService {

    private _appErrors: AipError[];

    constructor() {
    }

    get appErrors(): AipError[] {
        return this._appErrors;
    }
    set appErrors(newValue: AipError[]) {
        this._appErrors = newValue;
    }

}
