export class Localization {

    constructor(
        public ip: string,
        public countryCode: string,     // Code sur deux caractères : https://en.wikipedia.org/wiki/List_of_sovereign_states_and_dependent_territories_by_continent_(data_file)
        public countryName: string,
        public continentCode: string,   // Code sur 2 caractères : ISO 3166 Country Code
        public currencyCode: string     // Code sur 3 caractères : https://en.wikipedia.org/wiki/ISO_4217
    ) {
    }
}
