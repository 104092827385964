import {InjectionToken}         from '@angular/core';
import {ModuleLibraryConfig, ModuleLibraryConfigInt} from "../../ng-models-ui";

// On définit un token pour l'injection des paramètres de configuration du module
export const ModuleLoginConfigService = new InjectionToken<ModuleLibraryConfigInt>(
    'ModuleLoginConfigInt'
);

export class ModuleLoginConfig extends ModuleLibraryConfig {

    /**
     *
     * @param data
     */
    constructor(public data: any) {     // Objet comprenant les propriétés de configuration du module
        super(data);
    }
}
