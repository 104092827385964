import i18next from 'i18next';
import {appConfigurationProperties} from '../config/appConfigurationProperties';
import {StringUtil} from '../modules/ng-helpers-util/string.util';
import {ObjectUtil} from '../modules/ng-helpers-util/object.util';
import {IAnnouncement} from '../modules/ng-models-ats/interfaces/announcement.interface';
import {CurrentUserService} from '../modules/ng-manager-user/services/current-user.service';
import {ElearningUtil} from './elearning/elearningUtil';
import {PublishingUtil} from './publishing/publishingUtil';
import {environment} from '../../environments/environment';
import {IIllustratedListItem} from '../data/IIllustratedListItem.interface';
import {ConfigurationUtil} from '../config/configuration.util';
import {UrlUtil} from "../modules/ng-helpers-util/url.util";

/**
 *
 */
export class AnnouncementUtils {

  /**
   * Fonction qui converti un IIllustratedList en IAnnouncement.
   * Les propriétés "_id" et "" sont undefined car elle ne sont
   * pas présentes dans "IIllustratedListItem".
   */
  public static IIllustratedListItemToIAnnouncement(
    iIllustratedListItem: IIllustratedListItem): IAnnouncement {
    return {
      _id: iIllustratedListItem.element._id,
      type: iIllustratedListItem.element.type,
      date: iIllustratedListItem.element.date,
      application: iIllustratedListItem.element.application,
      title: iIllustratedListItem.element.title,
      message: iIllustratedListItem.element.message,
      data: iIllustratedListItem.element.data,
      product: iIllustratedListItem.element.product,
      picture: iIllustratedListItem.element.picture,
      status: undefined,
    };
  }

  /**
   * Fonction qui détermine les couleurs du badge d'un announcement
   */
  public static getBadge(applicationName: string): {
    application: string, applicationColorBackground: string, applicationColorText: string} {
    switch (applicationName) {
      case appConfigurationProperties.APPLICATION_NAME_AIP_ATEL:
        return {
          application: i18next.t('common.badge.atel'),
          applicationColorBackground: environment.applicationColors.elearning.background,
          applicationColorText: environment.applicationColors.elearning.color
        };
      case appConfigurationProperties.APPLICATION_NAME_AIP_ADOC:
        return {
          application: i18next.t('common.badge.adoc'),
          applicationColorBackground: environment.applicationColors.database.background,
          applicationColorText: environment.applicationColors.database.color
        };
      case appConfigurationProperties.APPLICATION_NAME_AIP_CORPORATE:
        return {
          application: i18next.t('common.badge.corporate'),
          applicationColorBackground: environment.applicationColors.corporate.background,
          applicationColorText: environment.applicationColors.corporate.color
        };
      case appConfigurationProperties.APPLICATION_NAME_AIP_ATP:
        return {
          application: i18next.t('common.badge.atp'),
          applicationColorBackground: environment.applicationColors.publishing.background,
          applicationColorText: environment.applicationColors.publishing.color
        };
      case appConfigurationProperties.APPLICATION_NAME_AIP_COMMON:
      default:
        return {
          application: i18next.t('common.badge.aip'),
          applicationColorBackground: environment.applicationColors.default.background,
          applicationColorText: environment.applicationColors.default.color
        };
    }
  }

  /**
   *
   */
  public static getStyleBadge(applicationColorBackground: string, applicationColorText: string): any {
    const style = {};
    if (!StringUtil.isUndefinedOrEmpty(applicationColorBackground)) {
      (style as any).background = applicationColorBackground;
    }
    if (!StringUtil.isUndefinedOrEmpty(applicationColorText)) {
      (style as any).color = applicationColorText;
    }
    return style;
  }

  /**
   * Fonction obsolete deplacee cote serveur
   */
  public static isValidAnnouncement(announcement: IAnnouncement): boolean {
    const hasTitle =
      ObjectUtil.hasProperty(announcement, 'title') &&
      !StringUtil.isUndefinedOrEmpty(announcement.title);
    const hasMessage =
      ObjectUtil.hasProperty(announcement, 'message') &&
      !StringUtil.isUndefinedOrEmpty(announcement.message);
    return hasTitle || hasMessage;
  }

  /**
   *
   */
  public static getMessage(announcement: IAnnouncement, currentUserService?: CurrentUserService): string {
    switch (announcement.type) {
      case appConfigurationProperties.ANNOUNCEMENT_AIP_SIMPLE_MESSAGE:
      case appConfigurationProperties.ANNOUNCEMENT_AIP_I18NEXT_MESSAGE:
        let msg;
        const userLang = currentUserService.getUserPreferredLanguage();
        if (
          !StringUtil.isUndefinedOrEmpty(userLang) &&
          ObjectUtil.hasProperty(announcement, 'data', 'i18next') &&
          ObjectUtil.isArray(announcement.data.i18next)) {
          const i18nElem = announcement.data.i18next.find(elem => elem.lng === userLang);
          msg = (i18nElem) ? i18nElem.message : undefined;
        }
        return msg;
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_LESSON:
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_QUIZ:
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_COURSE:
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_DEGREE:
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_LEARNING_CONTAINER:
        let urlAtel;
        if (
          ObjectUtil.hasProperty(announcement, 'data', 'type') &&
          ObjectUtil.hasProperty(announcement, 'data', '_id')) {
          urlAtel = `${appConfigurationProperties.URL_SERVER_AIP_COMMON}${appConfigurationProperties.APP_BASE_HREF}${ElearningUtil.getDetailUrl(announcement.data.type)}/${announcement.data._id}`;
        }
        return AnnouncementUtils.getMessageAtelNewProduct(announcement, urlAtel);
      case appConfigurationProperties.ANNOUNCEMENT_ATP_NEW_REPORT:
      case appConfigurationProperties.ANNOUNCEMENT_ATP_NEW_PUBLICATION:
        let urlAtp;
        if (
          ObjectUtil.hasProperty(announcement, 'data', 'type') &&
          ObjectUtil.hasProperty(announcement, 'data', '_id')) {
          urlAtp = `${appConfigurationProperties.URL_SERVER_AIP_COMMON}${appConfigurationProperties.APP_BASE_HREF}${PublishingUtil.getDetailUrl(announcement.data.type)}/${announcement.data._id}`;
        }
        return AnnouncementUtils.getMessageAtpNewProduct(announcement, urlAtp);

      default:
        return undefined;
    }
  }

  /**
   *
   */
  private static getMessageAtelNewProduct(announcement: IAnnouncement, url: string): string {
    let i18nextKey;
    switch (announcement.type) {
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_LESSON:
        i18nextKey = 'announcement.atel.message.atelNewLesson';
        break;
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_QUIZ:
        i18nextKey = 'announcement.atel.message.atelNewQuiz';
        break;
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_COURSE:
        i18nextKey = 'announcement.atel.message.atelNewCourse';
        break;
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_DEGREE:
        i18nextKey = 'announcement.atel.message.atelNewDegree';
        break;
    }
    return (
      !StringUtil.isUndefinedOrEmpty(i18nextKey) &&
      ObjectUtil.hasProperty(announcement, 'product', 'learningContainer', 'title'))
      ? i18next.t(
        i18nextKey,
        {title: announcement.product.learningContainer.title, url})
      : undefined;
  }

  /**
   *
   */
  private static getMessageAtpNewProduct(announcement: IAnnouncement, url: string): string {
    let i18nextKey;
    switch (announcement.type) {
      case appConfigurationProperties.ANNOUNCEMENT_ATP_NEW_REPORT:
        i18nextKey = 'announcement.atp.message.atpNewReport';
        break;
    }
    return (
      !StringUtil.isUndefinedOrEmpty(i18nextKey) &&
      ObjectUtil.hasProperty(announcement, 'product', 'publication', 'title'))
      ? i18next.t(
        i18nextKey,
        {title: announcement.product.publication.title, url})
      : undefined;
  }

  /**
   * Fonction qui extrait l'image de l'annoucement
   */
  public static getImageUrl(announcement: IAnnouncement): string {
    if (ObjectUtil.hasProperty(announcement , 'picture') &&
        !StringUtil.isUndefinedOrEmpty(announcement.picture)) {
      return AnnouncementUtils.getPictureUrl(announcement);
    } else if (ObjectUtil.hasProperty(announcement , 'application')) {
      switch (announcement.application) {
        case appConfigurationProperties.APPLICATION_NAME_AIP_ATEL:
          return AnnouncementUtils.getImageUrlAtel(announcement);
        case appConfigurationProperties.APPLICATION_NAME_AIP_ATP:
          return AnnouncementUtils.getImageUrlAtp(announcement);
        case appConfigurationProperties.APPLICATION_NAME_AIP_COMMON:
        case appConfigurationProperties.APPLICATION_NAME_AIP_ADOC:
        case appConfigurationProperties.APPLICATION_NAME_AIP_CORPORATE:
        default:
          return appConfigurationProperties.URL_IMAGE_ANNOUNCEMENT(announcement);
      }
    } else {
      return appConfigurationProperties.URL_IMAGE_ANNOUNCEMENT();
    }
  }

  /**
   * Fonction qui extrait l'image pour le projet Atel
   */
  public static getImageUrlAtel(announcement: IAnnouncement): string {
    switch (announcement.type) {
      case appConfigurationProperties.ANNOUNCEMENT_AIP_SIMPLE_MESSAGE:
      case appConfigurationProperties.ANNOUNCEMENT_AIP_I18NEXT_MESSAGE:
        return appConfigurationProperties.URL_IMAGE_ANNOUNCEMENT(announcement);
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_LESSON:
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_QUIZ:
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_COURSE:
      case appConfigurationProperties.ANNOUNCEMENT_ATEL_NEW_DEGREE:
        let dataType;
        let dataId;
        if (
          ObjectUtil.hasProperty(announcement, 'data', 'type') &&
          ObjectUtil.hasProperty(announcement, 'data', '_id')) {
          dataType = announcement.data.type;
          dataId = announcement.data._id;
        }
        return `${appConfigurationProperties.URL_IMAGE_PRODUCT(dataType, dataId)}${UrlUtil.generateStaticUrlVersion(
          announcement,
          undefined,
          'product.learningContainer.status.modificationDate',
          'product.learningContainer.status.creationDate')}`;
      default:
        const application = (ObjectUtil.hasProperty(announcement, 'application'))
          ? announcement.application
          : undefined;
        return appConfigurationProperties.URL_IMAGE_ANNOUNCEMENT(announcement);
    }
  }

  /**
   * Fonction qui extrait l'image pour le projet Atp
   */
  public static getImageUrlAtp(announcement: IAnnouncement): string {
    switch (announcement.type) {
      case appConfigurationProperties.ANNOUNCEMENT_AIP_SIMPLE_MESSAGE:
      case appConfigurationProperties.ANNOUNCEMENT_AIP_I18NEXT_MESSAGE:
        return appConfigurationProperties.URL_IMAGE_ANNOUNCEMENT(announcement);
      case appConfigurationProperties.ANNOUNCEMENT_ATP_NEW_REPORT:
        let dataType;
        let dataId;
        if (
          ObjectUtil.hasProperty(announcement, 'data', 'type') &&
          ObjectUtil.hasProperty(announcement, 'data', '_id')) {
          dataType = announcement.data.type;
          dataId = announcement.data._id;
        }
        return appConfigurationProperties.URL_IMAGE_PRODUCT(dataType, dataId);
      default:
        return appConfigurationProperties.URL_IMAGE_ANNOUNCEMENT(announcement);
    }
  }

  /**
   *
   */
  public static getDefaultImageUrl(announcement: IAnnouncement): string {
    let url;
    if (!StringUtil.isUndefinedOrEmpty(announcement.application)) {
      switch (announcement.application) {
        case appConfigurationProperties.APPLICATION_NAME_AIP_ATEL:
          url = AnnouncementUtils.getDefaultImageUrlAtel(announcement);
          break;
        case appConfigurationProperties.APPLICATION_NAME_AIP_ATP:
          url = AnnouncementUtils.getDefaultImageUrlAtp(announcement);
          break;
        case appConfigurationProperties.APPLICATION_NAME_AIP_COMMON:
        case appConfigurationProperties.APPLICATION_NAME_AIP_ADOC:
        case appConfigurationProperties.APPLICATION_NAME_AIP_CORPORATE:
        default:
          url = AnnouncementUtils.getImageUrl(announcement);
          break;
      }
    }
    return url;
  }

  /**
   * Fonction qui extrait l'image par défaut pour le projet Atel
   */
  private static getDefaultImageUrlAtel(announcement: IAnnouncement): string {
    let dataType;
    if (ObjectUtil.hasProperty(announcement, 'data', 'type')) {
      dataType = announcement.data.type;
    }
    return ElearningUtil.getDefaultImageUrl(dataType);
  }

  /**
   * Fonction qui extrait l'image par défaut pour le projet Atp
   */
  private static getDefaultImageUrlAtp(announcement: IAnnouncement): string {
    let dataType;
    if (ObjectUtil.hasProperty(announcement, 'data', 'type')) {
      dataType = announcement.data.type;
    }
    return PublishingUtil.getDefaultImageUrl(dataType);
  }

  /**
   * Fonction qui retourne l'url de l'image d'un annoucement
   * ou "undefined" si l'annoucement n'a pas d'image.
   */
  public static getPictureUrl(announcement: IAnnouncement): string {
    let imageFilePath: string;
    let imageFileName: string;
    if (announcement && announcement.picture) {    // L'announcement a une photo
      imageFilePath = `/${appConfigurationProperties.URL_DATA_ANNOUNCEMENT(announcement._id)}`;
      imageFileName = ConfigurationUtil.cli_file_common_picture_name + '.' + ConfigurationUtil.cli_file_common_picture_extension;
      return imageFilePath + imageFileName + UrlUtil.generateStaticUrlVersion(
        announcement,
        undefined,
        'product.learningContainer.status.modificationDate',
        'product.learningContainer.status.creationDate');
    } else {    // L'announcement n'a pas de photo
      return undefined;
    }
  }
}
